import TopNavigation from "@cloudscape-design/components/top-navigation";
import useAuth from "../hooks/useAuth";
import { useGetProfileDetailsQuery } from "../redux/api/profile/profile";
import { useGetOrganizationDetailsQuery } from "../redux/api/organization/organization";
import logo from "../logos/simple-logo.svg";

export default function TopHeaderBar() {
  const { logout } = useAuth();

  const { data: organization } = useGetOrganizationDetailsQuery();

  const { data: user } = useGetProfileDetailsQuery();

  return (
    <TopNavigation
      identity={{
        href: "#",
        title: "Ready Doc™",
        logo: {
          src: logo,
          alt: "Readydoc logo",
        },
      }}
      utilities={[
        {
          type: "menu-dropdown",
          text: organization?.name,
          description: user?.email ?? "",
          items: [
            {
              id: "org_setting",
              text: "Organization",
              items: [
                { id: "general", text: "General", href: "/org" },
                { id: "members", text: "Members", href: "/manageadmin" },
              ],
            },
            {
              id: "account",
              text: "Account",
              items: [
                { id: "profile", text: "Profile", href: "/settings/profile" },
                { id: "support", text: "Support", href: "/support" },
                { id: "signout", text: "Sign out" },
              ],
            },
          ],
          onItemClick: (itemClickDetails) => {
            console.log(itemClickDetails.detail);
            if (itemClickDetails.detail.id === "signout") {
              logout();
            }
          },
        },
      ]}
    ></TopNavigation>
  );
}
