import { Button, Header, Modal } from "@cloudscape-design/components";
import Grid from "@mui/material/Grid";
import { SectionForm } from "./section-form";
import { useEffect, useState } from "react";
import { SectionMetadata } from "../redux/api/section/types";
import { useCreateSectionMutation } from "../redux/api/section/section";
import useIsLoading from "../hooks/useIsLoading";
import { setModalName } from "../redux/UI/actions";
import { connect } from "react-redux";
import useAsyncNotifyWrapper from "../hooks/useAsyncNotifyWrapper";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function CreateSectionModal({ setModalName }: PropsFromDispatch) {
  const [section, setSection] = useState<SectionMetadata>({
    id: "",
    name: "",
    description: "",
    type: "single",
    facility: false,
    excludeReporting: false,
    excludeDigitalformMapping: false,
  });
  const { notifyWrapper } = useAsyncNotifyWrapper();
  useEffect(() => {
    setSection({
      id: "",
      name: "",
      description: "",
      type: "single",
      facility: false,
      excludeReporting: false,
      excludeDigitalformMapping: false,
    });
  }, []);

  const [createSection, { isLoading }] = useCreateSectionMutation();

  useIsLoading(isLoading);

  return (
    <Modal
      visible={true}
      size="large"
      header={<Header variant="h2">Create section</Header>}
      onDismiss={() => {
        setModalName("");
      }}
      footer={
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              onClick={() => {
                setModalName("");
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="primary"
              onClick={async () => {
                await notifyWrapper({
                  promise: createSection({ ...section }),
                  resourceName: "manage profile",
                  actionName: "add",
                });
                setModalName("");
              }}
            >
              Create
            </Button>
          </Grid>
        </Grid>
      }
    >
      <SectionForm section={section} setSection={setSection} />
    </Modal>
  );
}

const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(CreateSectionModal);
