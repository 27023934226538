import { api } from "..";
import {
  PayerEnrollment,
  PayerEnrollmentRequest,
  PayerEnrollmentStatus,
} from "./types";

const payerEnrollmentAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAllPayerEnrollments: build.query<PayerEnrollment[], { payerId: string }>(
      {
        query: ({ payerId }) => `payer/${payerId}/enrollments`,
        providesTags: ["PayerEnrollment"],
      },
    ),
    getPayerEnrollment: build.query<
      PayerEnrollment,
      { payerEnrollmentId: string }
    >({
      query: ({ payerEnrollmentId }) =>
        `payer/enrollments/${payerEnrollmentId}`,
      providesTags: (result, error, { payerEnrollmentId }) => [
        { type: "PayerEnrollment", id: payerEnrollmentId },
      ],
    }),
    getAllPayerEnrollmentStatus: build.query<PayerEnrollmentStatus[], void>({
      query: () => `payer/enrollments/status/all`,
      providesTags: ["PayerEnrollment"],
    }),
    createPayerEnrollment: build.mutation<
      PayerEnrollment,
      { payerId: string } & PayerEnrollmentRequest
    >({
      query: ({ payerId, ...body }) => {
        return {
          url: `payer/${payerId}/enroll`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["PayerEnrollment"],
    }),
    updatePayerEnrollment: build.mutation<
      PayerEnrollment,
      Pick<PayerEnrollment, "id"> & Partial<PayerEnrollmentRequest>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `payer/enrollments/${id}`,
          method: "POST",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "PayerEnrollment", id: arg.id },
        { type: "PayerEnrollment" },
      ],
    }),
    deletePayerEnrollment: build.mutation<
      void,
      {
        payerEnrollmentId: string;
      }
    >({
      query: ({ payerEnrollmentId }) => ({
        url: `payer/enrollments/${payerEnrollmentId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PayerEnrollment"],
    }),
    payerenrollmentSaveToSafe: build.mutation<
      void,
      Pick<PayerEnrollment, "id"> & {
        xfdfString: string;
        providerId: string;
      }
    >({
      query: ({ id, ...body }) => ({
        url: `payer/payerenrollment/${id}/save`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "PayerEnrollment" },
        { type: "PayerEnrollment", id: arg.id },
        { type: "Signature", id: arg.id },
      ],
    }),
    saveAllEnrollmentStatus: build.mutation<void, PayerEnrollmentStatus[]>({
      query: (types) => {
        return {
          url: `payer/enrollments/status/all`,
          method: "POST",
          body: types,
        };
      },
      invalidatesTags: ["PayerEnrollment"],
    }),
  }),
});
export const {
  useGetAllPayerEnrollmentsQuery,
  useGetPayerEnrollmentQuery,
  useGetAllPayerEnrollmentStatusQuery,
  useCreatePayerEnrollmentMutation,
  useUpdatePayerEnrollmentMutation,
  useDeletePayerEnrollmentMutation,
  usePayerenrollmentSaveToSafeMutation,
  useSaveAllEnrollmentStatusMutation,
} = payerEnrollmentAPI;
