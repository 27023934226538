import {
  AttributeEditor,
  Box,
  Button,
  Form,
  Header,
  Input,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import Divider from "@mui/material/Divider";
import { setModalName } from "../../redux/UI/actions";
import {
  useGetAllFacilityTypesQuery,
  useSaveAllFacilityTypesMutation,
} from "../../redux/api/facility/facility";
import { FacilityType } from "../../redux/api/facility/types";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import { useTranslation } from "react-i18next";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function FacilityTypeModal({ setModalName }: PropsFromDispatch) {
  const { t } = useTranslation();
  const { data = [], fulfilledTimeStamp } = useGetAllFacilityTypesQuery();
  const [facilityType, setFacilityType] = useState<FacilityType[]>([]);
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [saveAllFacilityTypes] = useSaveAllFacilityTypesMutation();
  const handleSubmit = async () => {
    setModalName("");
    const tmp = [...facilityType].filter((item) => !!item.name);
    await notifyWrapper({
      promise: saveAllFacilityTypes(tmp),
      resourceName: "Facility Types",
      actionName: "save",
    });
  };
  useEffect(() => {
    const tmpItems = [...data];
    setFacilityType(tmpItems);
  }, [data, fulfilledTimeStamp]);

  return (
    <Modal
      header={<Header>{t("facility.facilityType.header.label")}</Header>}
      visible={true}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              onClick={() => {
                setModalName("");
              }}
              formAction="none"
            >
              {t("facility.facilityType.header.actions.cancel")}
            </Button>
            <Button
              formAction="submit"
              onClick={handleSubmit}
              variant="primary"
            >
              {t("facility.facilityType.header.actions.save")}
            </Button>
          </SpaceBetween>
        </Box>
      }
      size="large"
      onDismiss={() => {
        setModalName("");
      }}
    >
      <div>
        <SpaceBetween size={"m"}>
          <Divider></Divider>

          <div
            style={{
              maxHeight: "80vh",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <form
              onSubmit={(event) => {
                event.preventDefault();
                handleSubmit();
              }}
            >
              <Form>
                <AttributeEditor
                  onAddButtonClick={() =>
                    setFacilityType([
                      ...facilityType,
                      { id: "", name: "", description: "", deleted: false },
                    ])
                  }
                  onRemoveButtonClick={({ detail: { itemIndex } }) => {
                    const tmp = [...facilityType];
                    const item = { ...tmp[itemIndex] };
                    tmp[itemIndex] = {
                      id: item.id,
                      name: item.name,
                      description: item.description,
                      deleted: true,
                    };
                    setFacilityType(tmp);
                  }}
                  items={facilityType.filter((item) => item.deleted !== true)}
                  addButtonText={t(
                    "facility.facilityType.header.actions.addFacilityType",
                  )}
                  definition={[
                    {
                      label: `${t("facility.facilityType.header.fields.type.label")}`,
                      control: (item, itemIndex: number) => (
                        <Input
                          value={item.name}
                          onChange={(changeDetails) => {
                            const tmp = [...facilityType];
                            tmp[itemIndex] = {
                              id: item.id,
                              name: changeDetails.detail.value,
                              description: item.description,
                              deleted: item.deleted,
                            };
                            setFacilityType(tmp);
                          }}
                          placeholder={t(
                            "facility.facilityType.header.fields.type.placeHolder",
                          )}
                        />
                      ),
                    },
                  ]}
                />
              </Form>
            </form>
          </div>
        </SpaceBetween>
      </div>
    </Modal>
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(FacilityTypeModal);
