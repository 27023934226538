// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import Header from "@cloudscape-design/components/header";
import { Box, ColumnLayout, Link } from "@cloudscape-design/components";
import { WidgetConfig } from "../types";
import { useGetAllDashboardQuery } from "../../../redux/api/dashboards/dashboard";
import LoadingScreen from "../../../components/LoadingScreen";

export const serviceOverview: WidgetConfig = {
  title: "Service overview",
  description: "Overview of all your resources",
  header: ServiceOverviewHeader,
  content: ServiceOverviewWidget,
};

function ServiceOverviewHeader() {
  return <Header variant="h2">Provider Overview</Header>;
}

function ServiceOverviewWidget() {
  const { data, isLoading, isFetching, isError } = useGetAllDashboardQuery();

  //const { overview } = dashboardData;
  return (
    <>
      <LoadingScreen isOpen={isLoading} />

      <ColumnLayout columns={4} variant="text-grid" minColumnWidth={170}>
        <div>
          <Box variant="awsui-key-label">Active Providers</Box>
          <Link variant="awsui-value-large" href="/manageprovider">
            {data?.overview.totalActiveProviders}
          </Link>
        </div>
        <div>
          <Box variant="awsui-key-label">Documents Per Provider</Box>
          <Link variant="awsui-value-large" href="/safe">
            {data?.overview.documentsPerProvider}
          </Link>
        </div>
        <div>
          <Box variant="awsui-key-label">Documents Expiring</Box>
          <Link variant="awsui-value-large" href="/safe">
            {data?.overview.totalExpiringDocuments}
          </Link>
        </div>
        <div>
          <Box variant="awsui-key-label">Documents Expired</Box>
          <Link variant="awsui-value-large" href="/safe">
            {data?.overview.totalExpiredDocuments}
          </Link>
        </div>
      </ColumnLayout>
    </>
  );
}
