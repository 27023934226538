import { BackgroundColorType } from "../redux/api/signaturerequest/types";

export const CircleIcon = ({
  backgroundColor,
}: {
  backgroundColor?: BackgroundColorType;
}) => {
  const circleStyle = {
    backgroundColor: `rgba(${backgroundColor?.r}, ${backgroundColor?.g}, ${backgroundColor?.b}, ${backgroundColor?.a})`,
    border: `1px solid rgba(${backgroundColor?.r}, ${backgroundColor?.g}, ${backgroundColor?.b}, ${backgroundColor?.a})`,
    height: "22px",
    width: "22px",
    borderRadius: "100px",
    display: "inline-block",
  };

  return <span style={circleStyle}></span>;
};
