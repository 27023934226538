import {
  Box,
  Button,
  ButtonDropdown,
  Header,
  Pagination,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import "./index.css";
import { useState, useEffect, useMemo } from "react";
import { Privileges } from "../redux/api/privileges/types";
import ChangeTimeZone from "../components/Timezone";
import { useNavigate, useParams } from "react-router-dom";
import {
  useDeletePrivilegeMutation,
  useGetAllPrivilegesQuery,
} from "../redux/api/privileges/privileges";
import { useLocalStorage } from "../common/localStorage";
import {
  PRIVILEGE_CONTENT_DISPLAY_OPTIONS,
  PRIVILEGE_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../common/table-config";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableEmptyState } from "../common/common-components";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";
import StyledRouterLink from "../components/styled-route-link";
import DeleteAlertModal from "../components/Modal/delete-alert-modal";
import useAsyncNotifyWrapper from "../hooks/useAsyncNotifyWrapper";
import LoadingScreen from "../components/LoadingScreen";
import { useTranslation } from "react-i18next";

export default function Previleges() {
  const { t } = useTranslation();
  const {
    data = [],
    isLoading,
    isFetching,
    refetch,
  } = useGetAllPrivilegesQuery();
  const { id } = useParams();
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [deletePrivilege] = useDeletePrivilegeMutation();
  const [selectedItem, setSelectedItem] = useState<Privileges>();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Privilege-Table-Preferences",
    PRIVILEGE_TABLE_DEFAULT_PREFERENCES,
  );
  const { items, collectionProps, paginationProps } = useCollection(data, {
    propertyFiltering: {
      filteringProperties: [],
      empty: <TableEmptyState resourceName="Privileges" />,
    },
    sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
  });
  useEffect(() => {
    const temp = data.find((privilege) => "" + privilege.id === "" + id);
    if (temp) setSelectedItem(temp);
  }, [id, selectedItem]);

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("privileges.edit") ?? false),
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("privileges.delete") ?? false),
  );

  const tableProps: TableProps<Privileges> = useMemo(() => {
    return {
      header: (
        <Header
          variant="h2"
          //TODO: add counter
          actions={
            <div>
              <span className="awsui-util-action-stripe-group">
                <Button
                  variant="normal"
                  iconName="refresh"
                  onClick={() => {
                    refetch();
                  }}
                ></Button>
              </span>
              <span className="awsui-util-action-stripe-group">
                <Button
                  iconName="external"
                  iconAlign="right"
                  disabled={!selectedItem}
                >
                  {t("privileges.table.header.actions.templateEditor")}
                </Button>
              </span>
              <span className="awsui-util-action-stripe-group">
                <Button
                  onClick={() => {
                    navigate("add");
                  }}
                  disabled={!selectedItem}
                >
                  {t("privileges.table.header.actions.requestSignature")}
                </Button>
              </span>
              <span className="awsui-util-action-stripe-group">
                <ButtonDropdown
                  expandableGroups
                  disabled={!selectedItem}
                  items={[
                    {
                      id: "edit_form",
                      text: hasPermission
                        ? `${t("privileges.table.header.actions.privilegeEdit")}`
                        : `${t("privileges.table.header.actions.privilegeView")}`,
                    },
                    {
                      id: "delete_form",
                      text: `${t("privileges.table.header.actions.privilegeDelete")}`,
                      //  disabled: !hasDeletePermission
                    },
                    {
                      id: "download_form",
                      text: `${t("privileges.table.header.actions.privilegeDownload")}`,
                    },
                  ]}
                  onItemClick={(itemClickDetails) => {
                    const { id } = itemClickDetails.detail;
                    if (id === "delete_form") {
                      if (selectedItem) OpenDeleteModal(true);
                    } else if (id === "edit_form") {
                      navigate(`/privileges/${selectedItem?.id}`);
                    }
                  }}
                >
                  {t("privileges.table.header.actions.privilegeActions")}
                </ButtonDropdown>
              </span>
              <span className="awsui-util-action-stripe-group">
                <Button
                  onClick={() => {
                    navigate("add");
                  }}
                  variant="primary"
                >
                  {t("privileges.table.header.actions.addPrivilegeForm")}
                </Button>
              </span>
            </div>
          }
        >
          {t("privileges.table.header.label")}
        </Header>
      ),
      items,
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <TablePreferences
          title="Privilege Table Preferences"
          preferences={tablePreferences}
          setPreferences={(preferences) => {
            setTablePreferences(preferences);
          }}
          contentDisplayOptions={PRIVILEGE_CONTENT_DISPLAY_OPTIONS}
        />
      ),
      columnDisplay: tablePreferences?.contentDisplay,
      wrapLines: tablePreferences?.wrapLines,
      stripedRows: tablePreferences?.stripedRows,
      contentDensity: tablePreferences?.contentDensity,
      stickyColumns: tablePreferences?.stickyColumns,
      ...collectionProps,
      variant: "container",
      selectionType: "single",
      loading: isLoading || isFetching,
      loadingText: "Loading...",
      resizableColumns: false,
      stickyHeader: true,
      empty: <p>No results to display.</p>,
      selectedItems: selectedItem ? [selectedItem] : [],
      onSelectionChange: ({ detail }) => {
        if (detail.selectedItems && detail.selectedItems.at(0))
          setSelectedItem(detail.selectedItems.at(0));
      },
      onRowClick: ({ detail }) => {
        if (detail.item) setSelectedItem(detail.item);
      },
      trackBy: (item) => "" + item.id,
      columnDefinitions: [
        {
          id: "id",
          header: <div> {t("privileges.table.header.fields.id")}</div>,
          cell: (item) => <span>{item.id}</span>,
        },
        {
          id: "name",
          header: <div>{t("privileges.table.header.fields.name")}</div>,
          cell: (item) => (
            <StyledRouterLink
              className={hasPermission ? "edit_link" : "normal"}
              to={hasPermission ? `/privileges/${item.id}` : `#`}
              label={item?.name}
            />
          ),
          sortingField: "name",
        },
        {
          id: "privilegeType",
          header: <div>{t("privileges.table.header.fields.type")}</div>,
          cell: (item) => <span>{item?.privilegeType?.name}</span>,
          sortingField: "privilegeType.name",
          sortingComparator: (a, b) =>
            (a?.privilegeType?.name ?? "").localeCompare(
              b?.privilegeType?.name ?? "",
            ),
        },
        {
          id: "description",
          header: <div>{t("privileges.table.header.fields.description")}</div>,
          cell: (item) => <span>{item.description}</span>,
        },
        {
          id: "category",
          header: <div>{t("privileges.table.header.fields.category")}</div>,
          cell: (item) => <span>{item?.category?.name}</span>,
          sortingField: "category.name",
          sortingComparator: (a, b) =>
            (a?.category?.name ?? "").localeCompare(b?.category?.name ?? ""),
        },
        {
          id: "template",
          header: <div>{t("privileges.table.header.fields.template")}</div>,
          cell: (item) => <span>{item?.attachment?.name}</span>,
        },
        {
          id: "licenseType",
          header: <div>{t("privileges.table.header.fields.licenseType")}</div>,
          cell: (item) => <span>{item?.licenseType?.name}</span>,
          sortingField: "licenseType.name",
          sortingComparator: (a, b) =>
            (a?.licenseType?.name ?? "").localeCompare(
              b?.licenseType?.name ?? "",
            ),
        },
        {
          id: "createdDate",
          header: <div>{t("privileges.table.header.fields.createdDate")}</div>,
          cell: (item) => (
            <span>{item.createdDate && ChangeTimeZone(item.createdDate)}</span>
          ),
        },
      ],
    };
  }, [selectedItem, data, tablePreferences, items]);

  return (
    <div style={{ paddingTop: "25px" }}>
      <LoadingScreen isOpen={isLoading} />
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedItem?.id) {
            OpenDeleteModal(false);
            await notifyWrapper({
              promise: deletePrivilege({
                privilegeId: selectedItem?.id,
              }),
              resourceName: "privilege",
              actionName: "delete",
            });
          }
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={t("privileges.delete.header.label")}
        content={
          <>
            <Box>{t("privileges.delete.header.content.label")}</Box>
            <Box variant="awsui-key-label">{selectedItem?.name}</Box>
          </>
        }
        description={t("privileges.delete.header.content.description")}
      />
    </div>
  );
}
