import { FormProvider, useForm } from "react-hook-form";
import { ProfessionalInfoRequestType } from "../../../../redux/api/provider/section/types";
import {
  BreadcrumbGroup,
  Button,
  Container,
  ExpandableSection,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { Divider } from "semantic-ui-react";
import FormSection from "../../../../components/FormSection";
import RHFTextField from "../../../../components/RHF/RHFTextField";
import RHFSelect from "../../../../components/RHF/RHFSelect";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAddProviderProfessionalinfoMutation,
  useGetAllLicenseTypesQuery,
  useGetAllSpecialtyQuery,
  useGetProviderQuery,
} from "../../../../redux/api/provider/provider";
import { useEffect } from "react";
import RHFDatePicker from "../../../../components/RHF/RHFDatePicker";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";

type FormInputType = ProfessionalInfoRequestType;

export default function EditProfessionalInfo() {
  const methods = useForm<FormInputType>();
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [addProviderProfessionalinfo] =
    useAddProviderProfessionalinfoMutation();

  const { data: licenses = [] } = useGetAllLicenseTypesQuery();
  const { data: specialities = [] } = useGetAllSpecialtyQuery();

  const { providerId } = useParams();

  const { data: provider, fulfilledTimeStamp } = useGetProviderQuery(
    providerId ? { providerId } : skipToken,
  );

  const onSubmit = async (data: FormInputType) => {
    if (!!providerId) {
      await notifyWrapper({
        promise: addProviderProfessionalinfo({
          providerId: providerId,
          ...data,
        }),
        resourceName: "professional info",
        actionName: "upload",
      });
      navigate(-1);
    }
  };

  useEffect(() => {
    if (!!provider) {
      const providerProfessionalInfo = provider.professionalInfo;

      if (providerProfessionalInfo) {
        methods.setValue("id", providerProfessionalInfo.id);
        methods.setValue("caqh", providerProfessionalInfo.caqh);
        methods.setValue(
          "licenseTypeId",
          providerProfessionalInfo.licenseType?.id,
        );
        methods.setValue(
          "primarySpecialtyId",
          providerProfessionalInfo.primarySpecialty?.id,
        );
        methods.setValue("title", providerProfessionalInfo.title);
        methods.setValue("workPhone", providerProfessionalInfo.workPhone);
        methods.setValue("workPhoneAlt", providerProfessionalInfo.workPhoneAlt);
        methods.setValue(
          "practiceInfo.name",
          providerProfessionalInfo?.practiceInfo?.name,
        );
        methods.setValue(
          "practiceInfo.yearsOfPractice",
          providerProfessionalInfo?.practiceInfo?.yearsOfPractice,
        );
        methods.setValue(
          "practiceInfo.startDate",
          providerProfessionalInfo?.practiceInfo?.startDate,
        );
        methods.setValue(
          "practiceInfo.endDate",
          providerProfessionalInfo?.practiceInfo?.endDate,
        );
        methods.setValue(
          "practiceInfo.isCurrent",
          providerProfessionalInfo?.practiceInfo?.isCurrent,
        );
        methods.setValue(
          "practiceInfo.addressLane1",
          providerProfessionalInfo?.practiceInfo?.addressLane1,
        );
        methods.setValue(
          "practiceInfo.addressLane2",
          providerProfessionalInfo?.practiceInfo?.addressLane2,
        );
        methods.setValue(
          "practiceInfo.city",
          providerProfessionalInfo?.practiceInfo?.city,
        );
        methods.setValue(
          "practiceInfo.state",
          providerProfessionalInfo?.practiceInfo?.state,
        );
        methods.setValue(
          "practiceInfo.zipcode",
          providerProfessionalInfo?.practiceInfo?.zipcode,
        );
        methods.setValue(
          "practiceInfo.country",
          providerProfessionalInfo?.practiceInfo?.country,
        );
        methods.setValue(
          "practiceInfo.contactName",
          providerProfessionalInfo?.practiceInfo?.contactName,
        );
        methods.setValue(
          "practiceInfo.contactTitle",
          providerProfessionalInfo?.practiceInfo?.contactTitle,
        );
        methods.setValue(
          "practiceInfo.contactPhone",
          providerProfessionalInfo?.practiceInfo?.contactPhone,
        );
        methods.setValue(
          "practiceInfo.contactEmail",
          providerProfessionalInfo?.practiceInfo?.contactEmail,
        );
        methods.setValue(
          "practiceInfo.contactFax",
          providerProfessionalInfo?.practiceInfo?.contactFax,
        );
      }
    }
  }, [fulfilledTimeStamp, provider]);

  return (
    <div style={{ paddingTop: "25px" }}>
      <SpaceBetween size={"l"}>
        <BreadcrumbGroup
          items={[
            { text: "providers", href: `/providers` },
            {
              text: provider?.firstName ?? "",
              href: `#`,
            },
            { text: `Professional info`, href: "#" },
          ]}
          ariaLabel="Breadcrumbs"
        />
        <Container
          header={
            <SpaceBetween size="xxs">
              <Header>Professional Info</Header>
              <Divider></Divider>
            </SpaceBetween>
          }
        >
          <div style={{ paddingTop: "12px" }}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Form
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        onClick={() => {
                          navigate(-1);
                        }}
                        formAction="none"
                      >
                        Cancel
                      </Button>
                      <Button formAction="submit" variant="primary">
                        Submit
                      </Button>
                    </SpaceBetween>
                  }
                >
                  <SpaceBetween size="m">
                    <FormSection columns={4}>
                      <RHFTextField
                        label="CAQH"
                        name="caqh"
                        rules={{ required: "This field is required" }}
                        stretch
                      />
                      <RHFSelect
                        name="licenseTypeId"
                        label="License Type"
                        options={licenses.map((license) => ({
                          value: license.id,
                          label: license.name,
                        }))}
                        rules={{ required: "This field is required" }}
                      />
                      <RHFSelect
                        name="primarySpecialtyId"
                        label="Primary Specialty"
                        options={specialities.map((speciality) => ({
                          value: speciality.id,
                          label: speciality.name,
                        }))}
                        rules={{ required: "This field is required" }}
                      />
                      <RHFTextField label="Title" name="title" stretch />
                      <RHFTextField
                        label="Work Phone"
                        name="workPhone"
                        rules={{
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Please enter a number",
                          },
                          required: "This field is required",
                        }}
                        stretch
                      />
                      <RHFTextField
                        label="Work Phone Alt"
                        name="workPhoneAlt"
                        rules={{
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Please enter a number",
                          },
                        }}
                        stretch
                      />
                    </FormSection>

                    <ExpandableSection
                      defaultExpanded
                      headerText="Practice Info"
                    >
                      <div style={{ paddingTop: "12px" }}>
                        <FormSection columns={4}>
                          <RHFTextField
                            label="Practice Name"
                            name="practiceInfo.name"
                            stretch
                            rules={{ required: "This field is required" }}
                          />
                          <RHFTextField
                            label="Years Of Practice"
                            name="practiceInfo.yearsOfPractice"
                            rules={{
                              pattern: {
                                value: /^[0-9]+$/,
                                message: "Please enter a number",
                              },
                            }}
                            stretch
                          />
                          <RHFDatePicker
                            label="Start Date"
                            name="practiceInfo.startDate"
                            stretch
                            rules={{ required: "This field is required" }}
                          />
                          <RHFDatePicker
                            label="End Date"
                            name="practiceInfo.endDate"
                            stretch
                          />
                          <RHFTextField
                            label="Address Lane1"
                            name="practiceInfo.addressLane1"
                            rules={{ required: "This field is required" }}
                            stretch
                          />
                          <RHFTextField
                            label="Address Lane2"
                            name="practiceInfo.addressLane2"
                            rules={{ required: "This field is required" }}
                            stretch
                          />
                          <RHFTextField
                            label="City"
                            name="practiceInfo.city"
                            rules={{ required: "This field is required" }}
                            stretch
                          />
                          <RHFTextField
                            label="State"
                            name="practiceInfo.state"
                            rules={{ required: "This field is required" }}
                            stretch
                          />
                          <RHFTextField
                            label="Zip Code"
                            name="practiceInfo.zipcode"
                            rules={{ required: "This field is required" }}
                            stretch
                          />
                          <RHFTextField
                            label="Country"
                            name="practiceInfo.country"
                            rules={{ required: "This field is required" }}
                            stretch
                          />
                          <RHFTextField
                            label="Practice Contact Name"
                            name="practiceInfo.contactName"
                            stretch
                          />
                          <RHFTextField
                            label="Practice Contact Title"
                            name="practiceInfo.contactTitle"
                            stretch
                          />
                          <RHFTextField
                            label="Practice Contact Phone"
                            name="practiceInfo.contactPhone"
                            rules={{
                              pattern: {
                                value: /^[0-9]+$/,
                                message: "Please enter a number",
                              },
                            }}
                            stretch
                          />
                          <RHFTextField
                            label="Practice Contact Email"
                            name="practiceInfo.contactEmail"
                            stretch
                          />
                          <RHFTextField
                            label="Practice Contact Fax"
                            name="practiceInfo.contactFax"
                            rules={{
                              pattern: {
                                value: /^[0-9]+$/,
                                message: "Please enter a number",
                              },
                            }}
                            stretch
                          />
                        </FormSection>
                      </div>
                    </ExpandableSection>
                  </SpaceBetween>
                </Form>
              </form>
            </FormProvider>
          </div>
        </Container>
      </SpaceBetween>
    </div>
  );
}
