import {
  Box,
  Button,
  ButtonDropdown,
  Header,
  Pagination,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import { useMemo, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";
import DeleteAlertModal from "../components/Modal/delete-alert-modal";
import { Contract } from "../redux/api/contracts/types";
import {
  useDeleteContractMutation,
  useGetAllContractsQuery,
} from "../redux/api/contracts/contract";
import useAsyncNotifyWrapper from "../hooks/useAsyncNotifyWrapper";
import { useLocalStorage } from "../common/localStorage";
import {
  CONTRACT_CONTENT_DISPLAY_OPTIONS,
  CONTRACT_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../common/table-config";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableEmptyState } from "../common/common-components";
import ChangeTimeZone from "../components/Timezone";
import StyledRouterLink from "../components/styled-route-link";
import LoadingScreen from "../components/LoadingScreen";
import { useTranslation } from "react-i18next";

function Contracts() {
  const { t } = useTranslation();
  const {
    data = [],
    isLoading,
    isFetching,
    refetch,
  } = useGetAllContractsQuery();
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedItem, setSelectedItem] = useState<Contract>();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const [deleteContract] = useDeleteContractMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Contracts-Table-Preferences",
    CONTRACT_TABLE_DEFAULT_PREFERENCES,
  );
  const { items, collectionProps, paginationProps } = useCollection(data, {
    propertyFiltering: {
      filteringProperties: [],
      empty: <TableEmptyState resourceName="Contracts" />,
    },
    sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
  });
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("contracts.edit") ?? false),
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("contracts.delete") ?? false),
  );
  useEffect(() => {
    const temp = data.find((contract) => "" + contract.id === "" + id);
    if (temp) setSelectedItem(temp);
  }, [id, selectedItem]);

  const tableProps: TableProps<Contract> = useMemo(() => {
    return {
      header: (
        <Header
          counter={`(${data.length})`}
          actions={
            <div>
              <span className="awsui-util-action-stripe-group">
                <Button
                  variant="normal"
                  iconName="refresh"
                  onClick={() => {
                    refetch();
                  }}
                ></Button>
              </span>
              <span className="awsui-util-action-stripe-group">
                <ButtonDropdown
                  expandableGroups
                  disabled={!selectedItem}
                  items={[
                    {
                      id: "edit_form",
                      text: hasPermission
                        ? `${t("contracts.table.header.actions.contractEdit")}`
                        : `${t("contracts.table.header.actions.contractView")}`,
                    },
                    {
                      id: "delete_form",
                      text: `${t("contracts.table.header.actions.contractDelete")}`,
                      // disabled: !hasDeletePermission,
                    },
                  ]}
                  onItemClick={(itemClickDetails) => {
                    const { id } = itemClickDetails.detail;
                    if (id === "delete_form") {
                      if (selectedItem) OpenDeleteModal(true);
                    } else if (id === "edit_form") {
                      navigate(`/contract/${selectedItem?.id}`);
                    }
                  }}
                >
                  {t("contracts.table.header.actions.contractActions")}
                </ButtonDropdown>
              </span>
              <span className="awsui-util-action-stripe-group">
                <Button
                  onClick={() => {
                    navigate(`addnew`);
                  }}
                  variant="primary"
                >
                  {t("contracts.table.header.actions.addContract")}
                </Button>
              </span>
            </div>
          }
        >
          {t("contracts.table.header.label")}
        </Header>
      ),
      items,
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <TablePreferences
          title="Contract table Preferences"
          preferences={tablePreferences}
          setPreferences={(preferences) => {
            setTablePreferences(preferences);
          }}
          contentDisplayOptions={CONTRACT_CONTENT_DISPLAY_OPTIONS}
        />
      ),
      columnDisplay: tablePreferences?.contentDisplay,
      wrapLines: tablePreferences?.wrapLines,
      stripedRows: tablePreferences?.stripedRows,
      contentDensity: tablePreferences?.contentDensity,
      stickyColumns: tablePreferences?.stickyColumns,
      ...collectionProps,
      variant: "container",
      loading: isLoading || isFetching,
      loadingText: "Loading...",
      selectionType: "single",
      resizableColumns: false,
      stickyHeader: true,
      empty: <p>No results to display.</p>,
      selectedItems: selectedItem ? [selectedItem] : [],
      onSelectionChange: ({ detail }) => {
        if (detail.selectedItems && detail.selectedItems.at(0))
          setSelectedItem(detail.selectedItems.at(0));
      },
      onRowClick: ({ detail }) => {
        if (detail.item) setSelectedItem(detail.item);
      },
      trackBy: (item) => "" + item.id,
      columnDefinitions: [
        {
          id: "id",
          header: <div>ID</div>,
          cell: (item) => <span>{item.id}</span>,
        },
        {
          id: "title",
          header: <div>Contract Title</div>,
          cell: (item) => (
            <StyledRouterLink
              className={hasPermission ? "edit_link" : "normal"}
              to={hasPermission ? `/contract/${item.id}` : `#`}
              label={item.title}
            />
          ),
          sortingField: "title",
        },
        {
          id: "contractType",
          header: <div>Contract Type</div>,
          cell: (item) => <div>{item?.contractType?.type}</div>,
          sortingField: "contractType.type",
          sortingComparator: (a, b) =>
            (a?.contractType?.type ?? "").localeCompare(
              b?.contractType?.type ?? "",
            ),
        },
        {
          id: "attachmentName",
          header: <div>Attachment Name</div>,
          cell: (item) => <div>{item.attachment?.name}</div>,
        },
        {
          id: "attachmentSize",
          header: <div>Attachment Size</div>,
          cell: (item) => <div>{item.attachment?.size}</div>,
        },
        {
          id: "attachmentContentType",
          header: <div>Attachment Content Type</div>,
          cell: (item) => <div>{item.attachment?.contentType}</div>,
        },
        {
          id: "facilityName",
          header: <div>Facility Name</div>,
          cell: (item) => <div>{item.facility?.name}</div>,
          sortingField: "facility.name",
          sortingComparator: (a, b) =>
            (a?.facility?.name ?? "").localeCompare(b?.facility?.name ?? ""),
        },
        {
          id: "facilityNPI",
          header: <div>Facility NPI</div>,
          cell: (item) => <div>{item.facility?.npiNumber}</div>,
        },
        {
          id: "facilityDescription",
          header: <div>Facility Description</div>,
          cell: (item) => <div>{item.facility?.description}</div>,
        },
        {
          id: "primaryContact",
          header: <div>Primary Contact</div>,
          cell: (item) => (
            <div>
              {`${item.primaryContact?.firstName} ${item.primaryContact?.firstName}`}
            </div>
          ),
        },
        {
          id: "executionDate",
          header: <div>Execution Date</div>,
          cell: (item) => <div>{ChangeTimeZone(item.executionDate)}</div>,
        },
        {
          id: "expirationDate",
          header: <div>Expiration Date</div>,
          cell: (item) => <div>{ChangeTimeZone(item.expirationDate)}</div>,
        },
        {
          id: "lastRenewalDate",
          header: <div>Last Renewal Date</div>,
          cell: (item) => <div>{item.lastRenewalDate}</div>,
        },
        {
          id: "description",
          header: <div>Description</div>,
          cell: (item) => <div>{item.description}</div>,
        },
        {
          id: "entityName",
          header: <div>Entity Name</div>,
          cell: (item) => <div>{item.entityName}</div>,
        },
        {
          id: "entityPhone",
          header: <div>Entity Phone</div>,
          cell: (item) => <div>{item.entityPhone}</div>,
        },
        {
          id: "entityEmail",
          header: <div>Entity Email</div>,
          cell: (item) => <div>{item.entityEmail}</div>,
        },
      ],
    };
  }, [selectedItem, data, tablePreferences, items]);
  return (
    <div style={{ paddingTop: "25px" }}>
      <LoadingScreen isOpen={isLoading} />
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedItem?.id) {
            OpenDeleteModal(false);
            await notifyWrapper({
              promise: deleteContract({
                contractId: selectedItem?.id,
              }),
              resourceName: "contract",
              actionName: "delete",
            });
          }
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={t("contracts.delete.header.label")}
        content={
          <>
            <Box>{t("contracts.delete.header.content.label")}</Box>
            <Box variant="awsui-key-label">{selectedItem?.title}</Box>
          </>
        }
        description={t("contracts.delete.header.content.description")}
      />
    </div>
  );
}

export default Contracts;
