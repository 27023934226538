import { Grid, GridProps, SpaceBetween } from "@cloudscape-design/components";
import "./InnerAppLayout.css";
interface InnerAppLayoutProps {
  breadcrumbGroup?: React.ReactNode;
  leftPanel?: React.ReactNode;
  rightPanel?: React.ReactNode;
  content: React.ReactNode;
  gridDefinition?: readonly GridProps.ElementDefinition[] | undefined;
}

export default function InnerAppLayout({
  breadcrumbGroup,
  gridDefinition,
  rightPanel,
  leftPanel,
  content,
}: InnerAppLayoutProps) {
  if (!!rightPanel) {
    return (
      <SpaceBetween size={"m"}>
        {breadcrumbGroup && breadcrumbGroup}
        {
          <Grid
            gridDefinition={gridDefinition ?? [{ colspan: 8 }, { colspan: 4 }]}
          >
            <div>{rightPanel}</div>
            <div>{content}</div>
          </Grid>
        }
      </SpaceBetween>
    );
  } else if (!!leftPanel) {
    return (
      <SpaceBetween size={"m"}>
        {breadcrumbGroup && breadcrumbGroup}
        {
          <Grid
            gridDefinition={gridDefinition ?? [{ colspan: 3 }, { colspan: 9 }]}
          >
            <div>{leftPanel}</div>
            <div>{content}</div>
          </Grid>
        }
      </SpaceBetween>
    );
  } else
    return (
      <SpaceBetween size={"m"}>
        {breadcrumbGroup && breadcrumbGroup}
        <div>{content}</div>
      </SpaceBetween>
    );
}
