import { Provider } from "../api/provider/types";
import { ProviderActionTypes } from "./types";

export const selectProvider = (selectedItems: Provider[]) => ({
  type: ProviderActionTypes.SELECT_PROVIDER,
  payload: selectedItems,
});

export const setActiveTabId = (activeTabId: string) => ({
  type: ProviderActionTypes.SET_ACTIVE_TAB_ID,
  payload: activeTabId,
});
