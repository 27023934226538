import {
  Button,
  Modal,
  SpaceBetween,
  Header,
} from "@cloudscape-design/components";
import Grid from "@mui/material/Grid";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  useGetCategoryGroupQuery,
  useUpdateGroupMutation,
} from "../../../../redux/api/managecategory/managecategory";
import { setModalName } from "../../../../redux/UI/actions";
import { FormProvider, useForm } from "react-hook-form";
import RHFTextArea from "../../../../components/RHF/RHFTextArea";
import RHFTextField from "../../../../components/RHF/RHFTextField";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import LoadingScreen from "../../../../components/LoadingScreen";

interface PropsFromDispatch {
  openModalName: typeof setModalName;
}
type FormInputType = {
  name: string;
  description: string;
};

function GroupsEditModal({ openModalName }: PropsFromDispatch) {
  const { groupId } = useParams();
  const { data: group, isLoading } = useGetCategoryGroupQuery(
    !!groupId
      ? {
          id: groupId,
        }
      : skipToken,
  );
  const methods = useForm<FormInputType>();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [updateGroup] = useUpdateGroupMutation();

  const onSubmit = async (data: FormInputType) => {
    if (group)
      await notifyWrapper({
        promise: updateGroup({
          id: group.id,
          name: data.name,
          description: data.description,
        }),
        resourceName: "group",
        actionName: "update",
      });
    openModalName("");
  };

  return (
    <Modal
      visible={true}
      size={"large"}
      header={<Header variant="h3">Edit group</Header>}
      onDismiss={() => {
        openModalName("");
      }}
    >
      <LoadingScreen isOpen={isLoading} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <SpaceBetween direction="vertical" size="l">
            <RHFTextField
              label="Name"
              name="name"
              stretch
              rules={{ required: "This field is required" }}
            />

            <RHFTextArea label="Description" name="description" stretch />
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  onClick={() => {
                    openModalName("");
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button variant="primary">Save</Button>
              </Grid>
            </Grid>
          </SpaceBetween>
        </form>
      </FormProvider>
    </Modal>
  );
}
const mapDispatchToProps = {
  openModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(GroupsEditModal);
