import {
  Button,
  ExpandableSection,
  Header,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import Grid from "@mui/material/Grid";
import { FormProvider, useForm } from "react-hook-form";
import { connect } from "react-redux";
import RHFTextField from "../../components/RHF/RHFTextField";
import { setModalName } from "../../redux/UI/actions";
import {
  useGetAllFacilityTypesQuery,
  useGetFacilityQuery,
  useUpdateFacilityMutation,
} from "../../redux/api/facility/facility";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import useIsLoading from "../../hooks/useIsLoading";
import { useEffect } from "react";
import { useParams } from "react-router";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import FormSection from "../../components/FormSection";
import { FACILITY_TYPE } from "../../helpers/constants";
import RHFFloatingSelect from "../../components/RHF/RHFFloatingSelect";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

type FormInputType = {
  name: string;
  npiNumber: string;
  description: string;
  facilityTypeId: string;
  cliaId: string;
  immunizationId: string;
  taxonomyCode: string;
  addressLane1: string;
  addressLane2: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
};

function EditFacility({ setModalName }: PropsFromDispatch) {
  const methods = useForm<FormInputType>();
  const { id: facilityId } = useParams();
  const { data: facility, fulfilledTimeStamp } = useGetFacilityQuery(!!facilityId?{
    id: facilityId ,
  }:skipToken);
  const { data: facilityType=[] } = useGetAllFacilityTypesQuery();
  const [updateFacility, { isLoading: isCreating }] =
    useUpdateFacilityMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();

  useIsLoading(isCreating);
  useEffect(() => {
    if (facility) {
      methods.setValue("name", facility?.name ?? "");
      methods.setValue("npiNumber", facility?.npiNumber ?? "");
      methods.setValue("description", facility?.description ?? "");
      methods.setValue("facilityTypeId", facility?.facilityType?.id ?? "");
      methods.setValue("cliaId", facility?.cliaId ?? "");
      methods.setValue("immunizationId", facility?.immunizationId ?? "");
      methods.setValue("taxonomyCode", facility?.taxonomyCode ?? "");
      methods.setValue("addressLane1", facility?.facilityAddress?.addressLane1);
      methods.setValue("addressLane2", facility?.facilityAddress?.addressLane2);
      methods.setValue("state", facility?.facilityAddress?.state);
      methods.setValue("city", facility?.facilityAddress?.city);
      methods.setValue("zipcode", facility?.facilityAddress?.zipcode);
      methods.setValue("country", facility?.facilityAddress?.country);
    }
  }, [fulfilledTimeStamp, methods, facility]);

  const onSubmit = async (data: FormInputType) => {
    if (facilityId)
      await notifyWrapper({
        promise: updateFacility({
          id: facilityId,
          name: data?.name,
          npiNumber: data?.npiNumber,
          description: data?.description,
          facilityTypeId: data?.facilityTypeId,
          cliaId: data?.cliaId,
          immunizationId: data?.immunizationId,
          taxonomyCode: data?.taxonomyCode,
          facilityAddress: {
            addressLane1: data?.addressLane1,
            addressLane2: data?.addressLane2,
            city: data?.city,
            state: data?.state,
            zipcode: data?.zipcode,
            country: data?.country,
        },  
        }),
        resourceName: "facility",
        actionName: "update",
      });
    setModalName("");
  };

  return (
    <Modal
      visible={true}
      size="medium"
      header={<Header variant="h2">Edit Facility</Header>}
      onDismiss={() => {
        setModalName("");
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
        <SpaceBetween direction="vertical" size="s">
            <FormSection columns={2}>
              <RHFTextField
                label="Name"
                name="name"
                stretch
                rules={{ required: "This field is required" }}
              />
              <RHFTextField
                label="NPI Number"
                name="npiNumber"
                stretch
                rules={{ required: "This field is required" }}
              />
               
               <RHFFloatingSelect
                    name="facilityTypeId"
                    label="Facility Type"
                    options={facilityType.map((type) => ({
                      label: type.name,
                      value: "" + type.id,
                    }))}
                    rules={{ required: "This field is required" }}
                    stretch={false}
                    action={{
                      label: "Modify",
                      onClick: () => {
                        setModalName(FACILITY_TYPE);
                      },
                    }}
                  />
               <RHFTextField
                label="CLIA Id"
                name="cliaId"
                stretch
              />
               <RHFTextField
                label="Immunization Id"
                name="immunizationId"
                stretch
              />
               <RHFTextField
                label="Taxonomy Code"
                name="taxonomyCode"
                stretch
              />
              <RHFTextArea label={"Description"} name={"description"} />
            </FormSection>
            <ExpandableSection headerText="Facility Address">
              <FormSection header="" columns={2}>
                <RHFTextField
                 label="Address Lane1"
                 name="addressLane1"
                 stretch={false}
                />
                <RHFTextField
                  label="Address Lane2"
                  name="addressLane2"
                  stretch={false}
                />
                <RHFTextField label="City" name="city" stretch={false} />
                <RHFTextField
                  label="State"
                  name="state"
                  stretch={false}
                />
                <RHFTextField
                  label="Zipcode"
                  name="zipcode"
                  stretch={false}
                />  
                <RHFTextField
                  label="Country"
                  name="country"
                  stretch={false}
                />     
              </FormSection>
              </ExpandableSection>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  formAction="none"
                  onClick={() => {
                    setModalName("");
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button formAction="submit" variant="primary">
                  Add
                </Button>
              </Grid>
            </Grid>
          </SpaceBetween>
        </form>
      </FormProvider>
    </Modal>
  );
}

const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(EditFacility);
