import {
  Box,
  BreadcrumbGroup,
  Button,
  ButtonDropdown,
  ButtonDropdownProps,
  Flashbar,
  FlashbarProps,
  Form,
  Header,
  Input,
  SpaceBetween,
  Table,
} from "@cloudscape-design/components";
import { CancelableEventHandler } from "@cloudscape-design/components/internal/events";
import { capitalize, sortBy } from "lodash";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingScreen from "../components/LoadingScreen";
import {
  useGetSectionQuery,
  useUpdateSectionMutation,
} from "../redux/api/section/section";
import { Attribute, Section, SubSection } from "../redux/api/section/types";
import { ConfigModal } from "./modal/config-modal";
import useIsLoading from "../hooks/useIsLoading";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";

const AddAttribute = ({
  isChildNode,
  onItemClick,
}: {
  isChildNode?: boolean;
  onItemClick?: CancelableEventHandler<ButtonDropdownProps.ItemClickDetails>;
}) => (
  <ButtonDropdown
    expandToViewport
    onItemClick={onItemClick}
    items={[
      {
        disabled: false,
        items: [
          {
            text: "String",
            id: "string",
            disabled: false,
          },
          {
            text: "Number",
            id: "number",
            disabled: false,
          },
          {
            text: "Date",
            id: "date",
            disabled: false,
          },
          {
            text: "CheckBox",
            id: "checkbox",
            disabled: false,
          },
          {
            text: "DropDown",
            id: "dropdown",
            disabled: false,
          },
        ],
      },
      {
        items: [{ text: "Sub Section", id: "sub_section" }],
        disabled: isChildNode,
      },
    ]}
  >
    Add attribute
  </ButtonDropdown>
);

function ConfigureSection() {
  const [count, setCount] = React.useState<number>(0);
  const [flashbarItems, setFlashbarItems] = React.useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = React.useState<RowType | null>(null);
  
  const { data, fulfilledTimeStamp } = useGetSectionQuery(!!id?{
    sectionId: id ,
  }:skipToken);

  const [postSection, { isLoading }] = useUpdateSectionMutation();

  useIsLoading(isLoading);

  const [section, setSection] = React.useState<
    SectionWithTimeStamp | undefined
  >();

  React.useEffect(() => {
    if (data) setSection({ ...data });
  }, [fulfilledTimeStamp]);

  if (!section) {
    return (
      <div style={{ paddingTop: "25px" }}>
        <LoadingScreen isOpen={!section} />
      </div>
    );
  } else
    return (
      <div style={{ paddingTop: "25px" }}>
        <SpaceBetween size={"s"}>
          <BreadcrumbGroup
            items={[
              { text: "Manage Section", href: "/managesection" },
              { text: section.name, href: "#" },
            ]}
            ariaLabel="Breadcrumbs"
          />
          {flashbarItems && <Flashbar items={flashbarItems} />}
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  onClick={() => {
                    navigate(`/managesection`);
                  }}
                  formAction="none"
                >
                  Cancel
                </Button>
                <Button
                  formAction="submit"
                  onClick={async () => {
                    try {
                      await postSection(section).unwrap();
                      navigate(`/managesection`);
                    } catch (error: any) {
                      if (error?.status === 400) {
                        console.error("rejected", error);
                        setFlashbarItems([
                          {
                            header: error?.data?.errorMessage,
                            type: "error",
                            content: error?.data?.errorDescription,
                            dismissible: true,
                            onDismiss: () => setFlashbarItems([]),
                            id: section.id,
                          },
                        ]);
                      }
                    }
                  }}
                  variant="primary"
                >
                  Save
                </Button>
              </SpaceBetween>
            }
          >
            <Table
              columnDefinitions={[
                {
                  id: "attribute_name",
                  header: "Attribute name",
                  cell: (e) => (
                    <Box float={e.isChildNode ? "right" : "left"}>
                      <Input value={e.name} onChange={() => {}}></Input>
                    </Box>
                  ),
                  editConfig: {
                    editingCell: (item, ctx) => {
                      return (
                        <Input
                          value={ctx.currentValue ?? item.name}
                          onChange={(event) => {
                            ctx.setValue(event.detail.value);
                          }}
                        />
                      );
                    },
                  },
                },
                {
                  id: "type",
                  header: "Type",
                  cell: (e) => (
                    <div>
                      {e.type === "sub_section" ? (
                        <AddAttribute
                          onItemClick={(itemClickDetails) => {
                            let temp: SectionWithTimeStamp = JSON.parse(
                              JSON.stringify(section)
                            );
                            temp.subSections.forEach((subSection) => {
                              if (
                                subSection.id === e.id &&
                                subSection.time_stamp === e.time_stamp
                              ) {
                                subSection.attributes = [
                                  ...subSection.attributes,
                                  {
                                    id: "",
                                    name: `attribute${count}`,
                                    description: "",
                                    type: itemClickDetails.detail.id, //FIELD.TYPE
                                    required: false,
                                    isDeleted: false,
                                    sort_order: 0,
                                    isKey: false,
                                    sensitive: false,
                                  },
                                ];
                              }
                            });
                            setCount(count + 1);
                            setSection(temp);
                          }}
                          isChildNode
                        />
                      ) : e.isKey ? (
                        "Key |" + capitalize(e.type)
                      ) : (
                        capitalize(e.type)
                      )}
                    </div>
                  ),
                },
                {
                  id: "sort_value",
                  header: "Sort value",
                  cell: (e) => (
                    <Box float={e.isChildNode ? "right" : "left"}>
                      <div style={{ width: "48px" }}>
                        <Input
                          onChange={() => {}}
                          value={
                            (e.isChildNode
                              ? e.child_sort_value
                              : e.sortValue
                            )?.toString() ?? "0"
                          }
                        ></Input>
                      </div>
                    </Box>
                  ),
                  editConfig: {
                    editingCell: (item, ctx) => {
                      return (
                        <div style={{ width: "48px" }}>
                          <Input
                            value={
                              ctx.currentValue ??
                              (item.isChildNode
                                ? item.child_sort_value
                                : item.sortValue) ??
                              ""
                            }
                            onChange={(event) => {
                              let sort_value = (
                                event.detail.value.match(/\d+/g) || []
                              ).join("");
                              const number = Number.parseInt(sort_value, 10);
                              if (!Number.isNaN(number)) {
                                return ctx.setValue(number);
                              } else return ctx.setValue(0);
                            }}
                          />
                        </div>
                      );
                    },
                  },
                },
                {
                  id: "description",
                  header: "Description",
                  cell: (e) => e.description,
                },
                {
                  id: "action",
                  header: "Actions",
                  cell: (e) => (
                    <div>
                      <span className="awsui-util-action-stripe-group">
                        <Button
                          onClick={() => {
                            setSelectedItem(e);
                          }}
                          variant="normal"
                        >
                          Configure
                        </Button>
                      </span>
                      <span className="awsui-util-action-stripe-group">
                        <Button
                          onClick={() => {
                            if (e.isChildNode) {
                              let temp: SectionWithTimeStamp = JSON.parse(
                                JSON.stringify(section)
                              );
                              //Note: here e is attribute
                              temp.subSections.forEach((subSection) => {
                                if (
                                  subSection.id === e.parent_id &&
                                  subSection.time_stamp === e.parent_time_stamp
                                ) {
                                  let attribute = subSection.attributes.find(
                                    (attribute) =>
                                      attribute.name === e.name &&
                                      attribute.type === e.type
                                  );
                                  if (attribute) {
                                    attribute.isDeleted = true;
                                    setSection(temp);
                                  }
                                }
                              });
                            } else if (e.type === "sub_section") {
                              let temp: SectionWithTimeStamp = JSON.parse(
                                JSON.stringify(section)
                              );
                              let subSection = temp.subSections.find(
                                (subSection) =>
                                  subSection.id === e.id &&
                                  subSection.time_stamp === e.time_stamp
                              );
                              if (subSection) {
                                subSection.isDeleted = true;
                                setSection(temp);
                              }
                            } else {
                              let temp: Section = JSON.parse(
                                JSON.stringify(section)
                              );
                              let attribute = temp.attributes.find(
                                (attribute) =>
                                  attribute.name === e.name &&
                                  attribute.type === e.type
                              );
                              if (attribute) {
                                attribute.isDeleted = true;
                                setSection(temp);
                              }
                            }
                          }}
                          variant="normal"
                        >
                          Remove
                        </Button>
                      </span>
                    </div>
                  ),
                },
              ]}
              submitEdit={(item, colDef, newValue) => {
                if (colDef.id === "attribute_name") {
                  if (item.isChildNode) {
                    let temp: SectionWithTimeStamp = JSON.parse(
                      JSON.stringify(section)
                    );
                    temp.subSections.forEach((subSection) => {
                      if (
                        subSection.id === item.parent_id &&
                        subSection.time_stamp === item.parent_time_stamp
                      ) {
                        subSection.attributes.forEach((attribute) => {
                          if (
                            attribute.name === item.name &&
                            attribute.type === item.type
                          ) {
                            attribute.name = newValue + "";
                          }
                        });
                      }
                    });
                    setSection(temp);
                  } else if (item.type === "sub_section") {
                    let temp: SectionWithTimeStamp = JSON.parse(
                      JSON.stringify(section)
                    );
                    temp.subSections.forEach((subSection) => {
                      if (
                        subSection.id === item.id &&
                        subSection.time_stamp === item.time_stamp
                      ) {
                        subSection.name = newValue + "";
                      }
                    });
                    setSection(temp);
                  } else {
                    let temp: Section = JSON.parse(JSON.stringify(section));
                    temp.attributes?.forEach((attribute) => {
                      if (
                        attribute.name === item.name &&
                        attribute.type === item.type
                      ) {
                        attribute.name = newValue + "";
                      }
                    });
                    setSection(temp);
                  }
                }
                if (colDef.id === "sort_value") {
                  if (item.isChildNode) {
                    let temp: SectionWithTimeStamp = JSON.parse(
                      JSON.stringify(section)
                    );
                    temp.subSections.forEach((subSection) => {
                      if (
                        subSection.id === item.parent_id &&
                        subSection.time_stamp === item.parent_time_stamp
                      ) {
                        subSection.attributes.forEach((attribute) => {
                          if (
                            attribute.name === item.name &&
                            attribute.type === item.type
                          ) {
                            attribute.sort_order = parseInt(newValue + "");
                          }
                        });
                      }
                    });
                    setSection(temp);
                  } else if (item.type === "sub_section") {
                    let temp: SectionWithTimeStamp = JSON.parse(
                      JSON.stringify(section)
                    );
                    temp.subSections.forEach((subSection) => {
                      if (
                        subSection.name === item.name &&
                        subSection.id === item.id &&
                        subSection.time_stamp === item.time_stamp
                      ) {
                        subSection.sort_order = parseInt(newValue + "");
                      }
                    });
                    setSection(temp);
                  } else {
                    let temp: Section = JSON.parse(JSON.stringify(section));
                    temp.attributes?.forEach((attribute) => {
                      if (
                        attribute.name === item.name &&
                        attribute.type === item.type
                      ) {
                        attribute.sort_order = parseInt(newValue + "");
                      }
                    });
                    setSection(temp);
                  }
                }
              }}
              items={convertSectionToRows({ ...section })}
              selectionType={undefined}
              header={
                <Header
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        variant="primary"
                        onClick={() => {
                          let temp: SectionWithTimeStamp = JSON.parse(
                            JSON.stringify(section)
                          );
                          temp.subSections.push({
                            id: "",
                            name: `sub-section${count}`,
                            time_stamp: Date.now().toString(),
                            description: "",
                            sort_order: 0,
                            attributes: [],
                          });
                          setCount(count + 1);
                          setSection(temp);
                        }}
                      >
                        Add sub-section
                      </Button>
                    </SpaceBetween>
                  }
                >
                  {section?.name}
                </Header>
              }
            />
          </Form>
          <div>
            {selectedItem && (
              <ConfigModal
                row={selectedItem}
                setSection={setSection}
                setSelectedItem={setSelectedItem}
                section={section}
              />
            )}
          </div>
        </SpaceBetween>
      </div>
    );
}

export default ConfigureSection;

interface SubSectionWithTimeStamp extends SubSection {
  time_stamp?: string;
}
export interface SectionWithTimeStamp {
  id: string;
  name: string;
  description: string;
  type: string;
  facility: boolean;
  excludeReporting: boolean;
  excludeDigitalformMapping: boolean;

  subSections: Array<SubSectionWithTimeStamp>;
  attributes: Array<Attribute>;
}

export type RowType = {
  id: string;
  parent_id?: string;
  parent_time_stamp?: string;
  parent_name?: string;
  name: string;
  time_stamp?: string;
  isChildNode: boolean;
  isKey: boolean;
  type: string;
  description: string;
  sortValue: number;
  child_sort_value?: number;
};
const convertSectionToRows = (section: SectionWithTimeStamp) => {
  let rows: Array<RowType> = [];
  section.attributes?.forEach((attribute) => {
    rows.push({
      id: attribute.id,
      name: attribute.name,
      isChildNode: false,
      type: attribute.type,
      description: attribute.description,
      sortValue: attribute.sort_order,
      isKey: attribute.isKey ?? false,
    });
  });
  section.subSections.forEach((subSection) => {
    if (!subSection.isDeleted) {
      rows.push({
        id: subSection.id,
        name: subSection.name,
        isChildNode: false,
        type: "sub_section",
        time_stamp: subSection.time_stamp,
        description: subSection.description,
        sortValue: subSection.sort_order,
        child_sort_value: -1,
        isKey: false,
        //required for sorting purpose
        parent_name: subSection.name,
        parent_id: subSection.id,
        parent_time_stamp: subSection.time_stamp,
      });
      subSection.attributes.forEach((attribute) => {
        if (!attribute.isDeleted)
          rows.push({
            id: attribute.id,
            parent_id: subSection.id,
            parent_time_stamp: subSection.time_stamp,
            parent_name: subSection.name,
            name: attribute.name,
            isChildNode: true,
            type: attribute.type,
            description: attribute.description,
            sortValue: subSection.sort_order,
            child_sort_value: attribute.sort_order,
            isKey: attribute.isKey ?? false,
          });
      });
    }
  });
  // return rows;
  return sortBy(rows, [
    "sortValue",
    "parent_name",
    "parent_id",
    "parent_time_stamp",
    "child_sort_value",
    "name",
  ]);
};
