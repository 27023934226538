import {
  Button,
  ExpandableSection,
  Header,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import Grid from "@mui/material/Grid";
import { FormProvider, useForm } from "react-hook-form";
import { connect } from "react-redux";
import RHFTextField from "../../components/RHF/RHFTextField";
import { setModalName } from "../../redux/UI/actions";
import FormSection from "../../components/FormSection";
import { useAddFacilityMutation, useGetAllFacilityTypesQuery } from "../../redux/api/facility/facility";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import useIsLoading from "../../hooks/useIsLoading";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import RHFSelect from "../../components/RHF/RHFSelect";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

type FormInputType = {
  name: string;
  npiNumber: string;
  description: string;
  facilityTypeId: string;
  cliaId: string;
  immunizationId: string;
  taxonomyCode: string;
  addressLane1: string;
  addressLane2: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
};

function AddFacility({ setModalName }: PropsFromDispatch) {
  const methods = useForm<FormInputType>();
  const { data: facilityTypes = [] } = useGetAllFacilityTypesQuery();
  const [addFacility, { isLoading: isCreating }] = useAddFacilityMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();

  useIsLoading(isCreating);

  const onSubmit = async (data: FormInputType) => {
    await notifyWrapper({
      promise: addFacility({
        name: data?.name,
        npiNumber: data?.npiNumber,
        description: data?.description,
        facilityTypeId: data?.facilityTypeId,
        cliaId: data?.cliaId,
        immunizationId: data?.immunizationId,
        taxonomyCode: data?.taxonomyCode,
        facilityAddress: {
          addressLane1: data?.addressLane1,
          addressLane2: data?.addressLane2,
          city: data?.city,
          state: data?.state,
          zipcode: data?.zipcode,
          country: data?.country,
        },        
      }),
      resourceName: "facility",
      actionName: "add",
    });
    setModalName("");
  };

  return (
    <Modal
      visible={true}
      size="medium"
      header={<Header variant="h2">Add Facility</Header>}
      onDismiss={() => {
        setModalName("");
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <SpaceBetween direction="vertical" size="s">
            <FormSection columns={2}>
              <RHFTextField
                label="Name"
                name="name"
                stretch
                rules={{ required: "This field is required" }}
              />
              <RHFTextField
                label="NPI Number"
                name="npiNumber"
                stretch
                rules={{ required: "This field is required" }}
              />
               <RHFSelect
                    name="facilityTypeId"
                    label="Facility Type"
                    options={facilityTypes.map((type) => ({
                      label: type.name,
                      value: "" + type.id,
                    }))}
                    rules={{ required: "This field is required" }}
                    stretch={false}
                    // action={{
                    //   label: "Modify",
                    //   onClick: () => {
                    //     setModalName(FACILITY_TYPE);
                    //   },
                    // }}
                  />
               <RHFTextField
                label="CLIA Id"
                name="cliaId"
                stretch
              />
               <RHFTextField
                label="Immunization Id"
                name="immunizationId"
                stretch
              />
               <RHFTextField
                label="Taxonomy Code"
                name="taxonomyCode"
                stretch
              />
              <RHFTextArea label={"Description"} name={"description"} />
            </FormSection>
            <ExpandableSection headerText="Facility Address">
              <FormSection header="" columns={2}>
                <RHFTextField
                 label="Address Lane1"
                 name="addressLane1"
                 stretch={false}
                />
                <RHFTextField
                  label="Address Lane2"
                  name="addressLane2"
                  stretch={false}
                />
                <RHFTextField label="City" name="city" stretch={false} />
                <RHFTextField
                  label="State"
                  name="state"
                  stretch={false}
                />
                <RHFTextField
                  label="Zipcode"
                  name="zipcode"
                  stretch={false}
                /> 
                <RHFTextField
                  label="Country"
                  name="country"
                  stretch={false}
                />     
              </FormSection>
            </ExpandableSection>

            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  formAction="none"
                  onClick={() => {
                    setModalName("");
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button formAction="submit" variant="primary">
                  Add
                </Button>
              </Grid>
            </Grid>
          </SpaceBetween>
        </form>
      </FormProvider>
    </Modal>
  );
}

const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(AddFacility);
