// awsui
import {
  AppLayout,
  Box,
  Flashbar,
  FlashbarProps,
} from "@cloudscape-design/components";
// react
import { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
// constants
import {
  ADD_MANAGEPROVIDER_MODAL,
  ADD_USERGROUPS_MODAL,
  CATEGORY_CREATE_MODAL,
  CATEGORY_EDIT_MODAL,
  CREATE_FACILITY,
  ADD_ROLE,
  EDIT_ROLE,
  CREATE_SECTION_MODAL,
  DIGITALFORM_CREATE_MODAL,
  DIGITALFORM_EDIT_MODAL,
  DIGITALFORM_UPLOAD_MODAL,
  EDIT_FACILITY,
  EDIT_MEMBER,
  GROUP_CREATE_MODAL,
  GROUP_EDIT_MODAL,
  INVITE_MODAL,
  MANAGEPROVIDER_EDIT_MODAL,
  ADD_ASSOCIATED_FACILITY,
  ADD_ASSOCIATED_PROVIDER,
  ADD_CONTRACT_TYPE,
  EDIT_CONTRACT_TYPE,
  ADD_PRIVILEGE_TYPE,
  PACKAGE_TYPE,
  PACKAGE_STATUS,
  FACILITY_TYPE,
  EDIT_PSV,
  ADD_AUTO_PSV,
  CONTRACT_TYPE,
  PAYER_ENROLLMENT_STATUS,
  DIGITALFORMTYPE_MODAL,
  EDIT_PACKAGE,
  EDIT_TEMPLATE,
  CATEGORY_MODAL,
} from "../helpers/constants";
// redux
import { ApplicationState } from "../redux/store";
// modals
import DigitalFormCreateModal from "../digitalform/modals/digitalform-create-modal";
import DigitalFormEditModal from "../digitalform/modals/digitalform-edit-modal";
import DigitalformUploadModal from "../digitalform/modals/digitalform-upload-modal";
import createSectionModal from "../manageprofile/create-section-modal";
// css
import "./dashboard-layout.css";
import TopHeaderBar from "../components/TopHeaderBar";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import { dismissFlashBarItem } from "../redux/UI/actions";
import groupsCreateModal from "../managecategory/managecategory-details/groups/modals/groups-create-modal";
import categoryCreateModal from "../managecategory/managecategory-details/categories/modals/category-create-modal";
import categoryEditModal from "../managecategory/managecategory-details/categories/modals/category-edit-modal";
import groupsEditModal from "../managecategory/managecategory-details/groups/modals/groups-edit-modal";
import addUsergroupsModal from "../managecategory/managecategory-details/groups/modals/add-usergroups-modal";
import addManageproviderModal from "../manageprovider/modals/add-manageprovider";
import manageproviderEditModal from "../manageprovider/modals/manageprovider-edit";
import inviteModal from "../manageadmin/manageadmin-details/modals/invite-modal";
import addFacility from "../managefacility/modal/add-facility-modal";
import SideBar from "./side-bar";
import editFacilityModal from "../managefacility/modal/edit-facility-modal";
import editMemberModal from "../manageadmin/manageadmin-details/sections/active/modals/edit-member-modal";
import addRoleModal from "../roles/modals/add-role-modal";
import editRoleModal from "../roles/modals/edit-role-modal";
import addAssociatedFacilitiesModal from "../roles/modals/add-associated-facilities-modal";
import addAssociatedProvidersModal from "../roles/modals/add-associated-providers-modal";
import addContracttypeModal from "../contracts/contracttype/modals/add-contracttype";
import editContracttypeModal from "../contracts/contracttype/modals/edit-contracttype";
import addPrivilegeType from "../privileges/privilegesType/modals/addPrivilegeType";
import packagestypemodal from "../credentialpackages/packages/modals/packagestypemodal";
import packagestatusmodal from "../credentialpackages/packages/modals/packagestatusmodal";
import facilityTypeModal from "../managefacility/modal/facility-type-modal";
import editPsvModal from "../providersafe/psv/modals/edit-psv-modal";
import AddAutoPSVModal from "../providersafe/psv/modals/add-auto-psv-modal";
import contracttypemodal from "../contracts/modals/contracttypemodal";
import payerenrollmentstatusmodal from "../payerenrollment/modals/payerenrollmentstatusmodal";
import digitalformtypeModal from "../digitalform/modals/digitalformtype-modal";
import editpackagemodal from "../credentialpackages/packages/modals/editpackagemodal";
import editcredentialtemplatemodal from "../credentialpackages/templates/modals/editcredentialtemplatemodal";
import categorymodal from "../providersafe/document/modals/categorymodal";
import addManageprovider from "../manageprovider/modals/add-manageprovider";

const modals = new Map<string, any>();
modals.set(DIGITALFORM_CREATE_MODAL, DigitalFormCreateModal);
modals.set(DIGITALFORM_EDIT_MODAL, DigitalFormEditModal);
modals.set(DIGITALFORM_UPLOAD_MODAL, DigitalformUploadModal);
modals.set(DIGITALFORMTYPE_MODAL, digitalformtypeModal);
modals.set(GROUP_CREATE_MODAL, groupsCreateModal);
modals.set(CATEGORY_CREATE_MODAL, categoryCreateModal);
modals.set(CATEGORY_EDIT_MODAL, categoryEditModal);
modals.set(GROUP_EDIT_MODAL, groupsEditModal);
modals.set(ADD_USERGROUPS_MODAL, addUsergroupsModal);
modals.set(ADD_MANAGEPROVIDER_MODAL, addManageproviderModal);
modals.set(MANAGEPROVIDER_EDIT_MODAL, manageproviderEditModal);
modals.set(INVITE_MODAL, inviteModal);
modals.set(CREATE_SECTION_MODAL, createSectionModal);
modals.set(CREATE_FACILITY, addFacility);
modals.set(ADD_ROLE, addRoleModal);
modals.set(ADD_ASSOCIATED_FACILITY, addAssociatedFacilitiesModal);
modals.set(ADD_ASSOCIATED_PROVIDER, addAssociatedProvidersModal);
modals.set(EDIT_ROLE, editRoleModal);
modals.set(EDIT_FACILITY, editFacilityModal);
modals.set(EDIT_MEMBER, editMemberModal);
modals.set(ADD_CONTRACT_TYPE, addContracttypeModal);
modals.set(EDIT_CONTRACT_TYPE, editContracttypeModal);
modals.set(ADD_PRIVILEGE_TYPE, addPrivilegeType);
modals.set(PACKAGE_TYPE, packagestypemodal);
modals.set(PACKAGE_STATUS, packagestatusmodal);
modals.set(FACILITY_TYPE, facilityTypeModal);
modals.set(EDIT_PSV, editPsvModal);
modals.set(ADD_AUTO_PSV, AddAutoPSVModal);
modals.set(CONTRACT_TYPE, contracttypemodal);
modals.set(PAYER_ENROLLMENT_STATUS, payerenrollmentstatusmodal);
modals.set(EDIT_PACKAGE, editpackagemodal);
modals.set(EDIT_TEMPLATE, editcredentialtemplatemodal);
modals.set(CATEGORY_MODAL, categorymodal);
modals.set(ADD_MANAGEPROVIDER_MODAL, addManageprovider);

interface PropsFromState {
  modalName: string;
  flashBarItems: FlashbarProps.MessageDefinition[];
}
interface PropsFromDispatch {
  dismissFlashBarItem: typeof dismissFlashBarItem;
}

function DashboardLayout({
  modalName,
  flashBarItems,
  dismissFlashBarItem,
}: PropsFromState & PropsFromDispatch) {
  const [navOpen, setNavOpen] = useState<boolean>(false);
  const { pathname } = useLocation();

  const [searchParams] = useSearchParams();
  const [activeHref, setActiveHref] = useState<string>("");
  useEffect(() => {
    setActiveHref("/".concat(pathname.split("/").at(1) ?? "#"));
  }, [pathname, navOpen]);

  const disableContentPaddings = useMemo(() => {
    return pathname.includes("designer") || pathname.includes("list");
  }, [pathname]);

  const Modal = modals.get(modalName);

  return (
    <>
      {Modal && <Modal />}
      <div className="custom-main-header" id="h">
        <TopHeaderBar />
      </div>
      <AppLayout
        contentType="default"
        disableContentPaddings={disableContentPaddings}
        notifications={
          flashBarItems.length && (
            <Box padding={{ top: "s" }}>
              <Flashbar
                stackItems={true}
                items={[...flashBarItems].map((item) => {
                  if (item.dismissible) {
                    return {
                      ...item,
                      onDismiss: () => {
                        item.id && dismissFlashBarItem(item.id);
                      },
                    };
                  } else return item;
                })}
              />
            </Box>
          )
        }
        navigation={<SideBar activeHref={activeHref} />}
        navigationOpen={navOpen}
        onNavigationChange={(changeDetails) => {
          setNavOpen(changeDetails.detail.open);
          setActiveHref(pathname);
        }}
        navigationWidth={220}
        maxContentWidth={
          searchParams.has("preview") && searchParams.get("preview") === "true"
            ? Number.MAX_VALUE
            : undefined
        }
        content={<Outlet />}
        toolsHide={true}
      />
    </>
  );
}

const mapDispatchToProps = {
  dismissFlashBarItem: dismissFlashBarItem,
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    modalName: state.ui.modalName,
    flashBarItems: state.ui.flashBarItems,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);
