import { Reducer } from "redux";
import { DigitalformActionTypes, DigitalFormState } from "./types";

export const initialState: DigitalFormState = {
  selectedForms: [],
  filterTags: [],
};

const reducer: Reducer<DigitalFormState> = (state = initialState, action) => {
  switch (action.type) {
    case DigitalformActionTypes.SELECT_DIGITALFORM: {
      return { ...state, selectedForms: action.payload };
    }
    case DigitalformActionTypes.ADD_FILTER_TAG: {
      return { ...state, filterTags: [...state.filterTags, action.payload] };
    }
    case DigitalformActionTypes.REMOVE_FILTER_TAG: {
      const updatedFilterTags = [...state.filterTags].filter((tag) => {
        return !(
          tag.key === action.payload.key && tag.value === action.payload.value
        );
      });
      return { ...state, filterTags: [...updatedFilterTags] };
    }
    case DigitalformActionTypes.REMOVE_ALL_FILTER_TAG: {
      return { ...state, filterTags: [] };
    }
    default: {
      return state;
    }
  }
};

export { reducer as digitalFormReducer };
