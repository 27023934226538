import {
  Button,
  Header,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import Grid from "@mui/material/Grid";
import { FormProvider, useForm } from "react-hook-form";
import { connect } from "react-redux";
import RHFTextArea from "../../../../components/RHF/RHFTextArea";
import RHFTextField from "../../../../components/RHF/RHFTextField";
import { useCreateGroupMutation } from "../../../../redux/api/managecategory/managecategory";
import { setModalName } from "../../../../redux/UI/actions";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}
type FormInputType = {
  name: string;
  description: string;
};

function GroupCreateModal({ setModalName }: PropsFromDispatch) {
  const methods = useForm<FormInputType>();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const onSubmit = async (data: FormInputType) => {
    await notifyWrapper({
      promise: createGroup({
        name: data.name,
        description: data.description,
      }),
      resourceName: "group",
      actionName: "add",
    });
    setModalName("");
  };

  const [createGroup, { isLoading: isCreating }] = useCreateGroupMutation();
  return (
    <Modal
      visible={true}
      size="large"
      header={<Header variant="h2">Create Group</Header>}
      onDismiss={() => {
        setModalName("");
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <SpaceBetween direction="vertical" size="l">
            <RHFTextField
              label="Name"
              name="name"
              stretch
              rules={{ required: "This field is required" }}
            />

            <RHFTextArea label="Description" name="description" stretch />

            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  onClick={() => {
                    setModalName("");
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button variant="primary">Create</Button>
              </Grid>
            </Grid>
          </SpaceBetween>
        </form>
      </FormProvider>
    </Modal>
  );
}

const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(GroupCreateModal);
