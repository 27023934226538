import { useSearchParams } from "react-router-dom";
import PDFTronViewer from "./PDFTronViewer";

export function TestPdftronViewer() {
  const [searchParams] = useSearchParams();
  return (
    <div>
      <PDFTronViewer attachmentKey={searchParams.get("file") ?? undefined} />
    </div>
  );
}
