import {
  Button,
  Header,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import Grid from "@mui/material/Grid";
import { FormProvider, useForm } from "react-hook-form";
import { connect } from "react-redux";
import RHFTextField from "../../../components/RHF/RHFTextField";
import { useCreateInviteMutation } from "../../../redux/api/manageadmin/manageadmin";
import { setModalName } from "../../../redux/UI/actions";
import RHFSelect from "../../../components/RHF/RHFSelect";
import "./invite-modal.css";
import { useGetAllRoleQuery } from "../../../redux/api/role/role";
import useIsLoading from "../../../hooks/useIsLoading";
import useAsyncNotifyWrapper from "../../../hooks/useAsyncNotifyWrapper";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

type FormInputType = {
  email: string;
  role: string;
};

function InviteModal({ setModalName }: PropsFromDispatch) {
  const methods = useForm<FormInputType>();
  const onSubmit = async (data: FormInputType) => {
    await notifyWrapper({
      promise: createInvite({
        email: data.email,
        role: data.role,
      }),
      resourceName: "Invite",
      actionName: "add",
    });
    setModalName("");
  };
  const [createInvite, { isLoading: isCreating }] = useCreateInviteMutation();
  const { data: roles, isLoading: isRoleLoading } = useGetAllRoleQuery();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  useIsLoading(isCreating || isRoleLoading);

  return (
    <Modal
      visible={true}
      size="medium"
      header={
        <Header
          variant="h2"
          description="Invite a new member to join your organization."
        >
          Invite New Member
        </Header>
      }
      onDismiss={() => {
        setModalName("");
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <SpaceBetween direction="vertical" size="xxl">
            <SpaceBetween direction="vertical" size="s">
              <RHFTextField
                label="Email"
                name="email"
                stretch
                rules={{
                  required: "This field is required",
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: "Invalid email",
                  },
                }}
              />
              <RHFSelect
                label="Role"
                name={"role"}
                options={roles?.map((role) => {
                  return {
                    label: role.name,
                    value: role.id,
                    disabled: role.default,
                  };
                })}
              />
            </SpaceBetween>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  onClick={() => {
                    setModalName("");
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button variant="primary">Invite</Button>
              </Grid>
            </Grid>
          </SpaceBetween>
        </form>
      </FormProvider>
    </Modal>
  );
}

const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(InviteModal);
