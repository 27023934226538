import {
  BreadcrumbGroup,
  Button,
  Container,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import Grid from "@mui/material/Grid";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import RHFTextField from "../../components/RHF/RHFTextField";
import { useAddProviderMutation } from "../../redux/api/provider/provider";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import { useNavigate } from "react-router-dom";
import FormSection from "../../components/FormSection";
import UploadCSV from "../../components/UploadCSV";
import { useEffect, useState } from "react";
import useIsLoading from "../../hooks/useIsLoading";
import InnerAppLayout from "../../components/InnerAppLayout";

type FormInputType = {
  providers: {
    firstName: string;
    lastName: string;
    email: string;
    npiNumber: string;
  }[];
};

export default function AddManageProviderCsvUpload() {
  const [isUploadFileOpen, openUploadFileModal] = useState<boolean>(true);
  const [fileData, setFileData] = useState<any[]>([]);
  const methods = useForm<FormInputType>();
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "providers",
  });

  const { notifyWrapper } = useAsyncNotifyWrapper();
  const navigate = useNavigate();

  useEffect(() => {
    if (!!fileData) {
      fileData.forEach((p) => {
        if (!!p) {
          append({
            firstName: p.at(0) ?? "",
            lastName: p.at(1) ?? "",
            email: p.at(2) ?? "",
            npiNumber: p.at(3) ?? "",
          });
        }
      });
    }
  }, [fileData]);

  const onSubmit = async (data: FormInputType) => {
    const { providers } = data;

    await Promise.all(
      providers.map(
        async (provider) =>
          await notifyWrapper({
            promise: addProvider({
              firstName: provider.firstName,
              lastName: provider.lastName,
              email: provider.email,
              npiNumber: provider.npiNumber,
            }),
            resourceName: "provider",
            actionName: "add",
          }),
      ),
    );
    navigate(-1);
  };
  const [addProvider, { isLoading }] = useAddProviderMutation();

  useIsLoading(isLoading);

  return (
    <InnerAppLayout
      breadcrumbGroup={
        <>
          <BreadcrumbGroup
            items={[
              {
                text: "Providers",
                href: `/manageprovider`,
              },
              {
                text: "Add Providers",
                href: "#",
              },
            ]}
            ariaLabel="Breadcrumbs"
          />
        </>
      }
      content={
        <Container header={<Header variant="h2">Add Provider</Header>}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <SpaceBetween direction="vertical" size="l">
                <UploadCSV
                  visible={isUploadFileOpen}
                  setData={setFileData}
                  setVisible={openUploadFileModal}
                />
                {fields.map((field, index) => (
                  <Container>
                    <FormSection>
                      <FormSection
                        gridDefinition={[
                          { colspan: { default: 4, m: 4 } },
                          { colspan: { default: 4, m: 4 } },
                        ]}
                      >
                        <RHFTextField
                          testid="firstName"
                          label="First Name"
                          name={`providers.${index}.firstName`}
                          stretch={false}
                          rules={{ required: "This field is required" }}
                        />
                        <RHFTextField
                          testid="lastName"
                          name={`providers.${index}.lastName`}
                          label="Last Name"
                          stretch={false}
                          rules={{ required: "This field is required" }}
                        />
                      </FormSection>
                      <RHFTextField
                        testid="email"
                        label="Email"
                        name={`providers.${index}.email`}
                        stretch={false}
                        rules={{ required: "This field is required" }}
                      />
                      <RHFTextField
                        testid="npiNumber"
                        label="NPI Number"
                        name={`providers.${index}.npiNumber`}
                        stretch={false}
                        rules={{ required: "This field is required" }}
                      />
                      <Button
                        data-testid="cancel"
                        formAction="none"
                        iconName="remove"
                        onClick={() => {
                          remove(index);
                        }}
                      >
                        Remove
                      </Button>
                    </FormSection>
                  </Container>
                ))}
                <Button
                  formAction="none"
                  onClick={() => {
                    append({
                      firstName: "",
                      lastName: "",
                      email: "",
                      npiNumber: "",
                    });
                    // console.log(document.body.scrollHeight);
                    setTimeout(() => {
                      window.scrollTo({
                        top: document.body.scrollHeight,
                        behavior: "smooth",
                      });
                    }, 0);
                  }}
                >
                  Add new
                </Button>
                <Grid container justifyContent="flex-end" spacing={2}>
                  <Grid item>
                    <Button
                      data-testid="cancel"
                      formAction="none"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button data-testid="save" variant="primary">
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </SpaceBetween>
            </form>
          </FormProvider>
        </Container>
      }
    />
  );
}

const AttributeEditorLabel = ({ label }: { label: string }) => (
  <span>
    {label} {<span style={{ color: "#fc0a37" }}>*</span>}
  </span>
);
