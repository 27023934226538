import * as React from "react";
import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  FloatingFocusManager,
  offset,
  flip,
  size,
  autoUpdate,
  FloatingPortal,
} from "@floating-ui/react";
import * as awsui from "@cloudscape-design/design-tokens";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import MuiButton from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Icon, Input, Select } from "@cloudscape-design/components";
import MenuItem from "@mui/material/MenuItem";
import { sortBy } from "lodash";

export interface FloatingSelectOptionType {
  value: string;
  label: string;
  color?: string;
  description?: string;
}

const fontStyle = {
  fontSize: 14,
  fontFamily: "Open Sans",
  fontWeight: 400,
  fontStretch: "normal",
  fontStyle: "normal",
  fontVariant: "normal",
};

export type FloatingSelectProps = {
  disabled?: boolean;
  enableFiltering?: boolean;
  options: FloatingSelectOptionType[];
  selectedOption?: FloatingSelectOptionType;
  onSelectionChange: (changedOption: FloatingSelectOptionType) => void;
  action?: { label: string; onClick: VoidFunction };
};

//TODO: hightlight selected option
export default function FloatingSelect({
  action,
  options,
  disabled,
  selectedOption,
  enableFiltering,
  onSelectionChange,
}: FloatingSelectProps) {
  const [isOpen, setIsOpen] = React.useState(false);

  const { refs, floatingStyles, context } = useFloating({
    placement: "bottom-start",
    open: isOpen,
    onOpenChange: (open) => {
      setFilter("");
      setIsOpen(open);
    },
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({ padding: 10 }),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            minWidth: `${rects.reference.width}px`,
          });
        },
        padding: 10,
      }),
    ],
  });

  const click = useClick(context, { event: "mousedown" });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "listbox" });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    dismiss,
    role,
    click,
  ]);
  const [filter, setFilter] = React.useState<string>("");

  if (disabled)
    return (
      <Select
        selectedOption={{ label: selectedOption?.label }}
        onChange={({ detail }) => {}}
        options={[]}
        disabled
      />
    );
  else
    return (
      <>
        <div
          ref={refs.setReference}
          style={{ lineHeight: 2, margin: "auto" }}
          {...getReferenceProps()}
        >
          <Select
            selectedOption={{ label: selectedOption?.label }}
            onChange={({ detail }) => {}}
            options={[]}
          />
        </div>
        {isOpen && (
          <FloatingPortal>
            <FloatingFocusManager context={context} modal={false}>
              {/*   boxShadow:
                    "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
             */}
              <div
                ref={refs.setFloating}
                style={{
                  ...floatingStyles,
                  overflowY: "auto",
                  backgroundColor: "white",
                  boxShadow:
                    "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                }}
                {...getFloatingProps()}
              >
                {!!enableFiltering && (
                  <Input
                    onChange={({ detail }) => setFilter(detail.value)}
                    value={filter}
                    placeholder="Filter labels"
                    autoComplete={false}
                    autoFocus
                  />
                )}
                <div style={{ maxHeight: "25vh", overflow: "auto" }}>
                  {sortBy(options, (option) => {
                    if (option.value === selectedOption?.value) return -1;
                    return 1;
                  })
                    .filter(
                      (option) =>
                        option.label
                          ?.toLocaleLowerCase()
                          .includes(filter?.toLocaleLowerCase()) ||
                        option.description
                          ?.toLocaleLowerCase()
                          .includes(filter?.toLocaleLowerCase())
                    )
                    .map((option) => (
                      <MenuItem
                        key={option.value}
                        onClick={() => {
                          onSelectionChange(option);
                          // After the animation duration, remove the UI from the DOM
                          setTimeout(() => {
                            setIsOpen(false);
                          }, 90); // 300ms is the duration of the CSS transition
                        }}
                        style={{
                          borderBottom: `var(--border-field-width-09w7vk, 2px) solid ${awsui.colorBackgroundInputDisabled}`,
                        }}
                        value={option.label}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              ...fontStyle,
                            }}
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html:
                                  !!option.label && !!filter
                                    ? highlightText(option.label, filter)
                                    : option.label,
                              }}
                            />
                            <br />

                            <span
                              style={{
                                fontSize: "12px",
                                color: awsui.colorTextFormSecondary,
                                fontFamily: "Open Sans",
                                lineHeight: "16px",
                              }}
                              dangerouslySetInnerHTML={{
                                __html:
                                  !!option.description && !!filter
                                    ? highlightText(option.description, filter)
                                    : option.description ?? "",
                              }}
                            />
                          </div>
                          <div>
                            {option.value + "" ===
                              "" + selectedOption?.value && (
                              <Icon name="check" variant="link" />
                            )}
                          </div>
                        </div>
                      </MenuItem>
                    ))}
                </div>
                {!!action && (
                  <Box
                    sx={{
                      borderBottom: `1px solid "#eaecef"`,
                      fontWeight: 600,
                    }}
                  >
                    <MuiButton
                      sx={{
                        backgroundColor:
                          awsui.colorBackgroundButtonPrimaryDefault,
                        lineHeight: "20px",
                        color: "white",
                        textTransform: "none",
                        ...fontStyle,
                      }}
                      fullWidth
                      variant="contained"
                      onClick={() => {
                        action.onClick();
                        setIsOpen(false);
                        // After the animation duration, remove the UI from the DOM
                        setTimeout(() => {
                          setIsOpen(false);
                        }, 90); // 300ms is the duration of the CSS transition
                      }}
                      startIcon={<EditTwoToneIcon />}
                    >
                      {action.label}
                    </MuiButton>
                  </Box>
                )}
              </div>
            </FloatingFocusManager>
          </FloatingPortal>
        )}
      </>
    );
}

export function highlightText(text: string, searchTerm: string) {
  const regex = new RegExp(searchTerm, "gi");

  return text
    .replace(
      regex,
      (match) =>
        `<b
      style="color: ${awsui.colorTextDropdownItemFilterMatch};display: inline-block;"

    >
      ${match}
    </b>`
    )
    ?.replace("//g", "-");
}
