import { Core } from "@pdftron/webviewer";
import { get } from "lodash";
import { Provider } from "../redux/api/provider/types";

export const selectValueFromData = (
  data: Provider | undefined,
  annot: Core.Annotations.Annotation
): string => {
  let content = annot.getCustomData("name");

  if (!data || !annot.getCustomData("key")) return content;
  else {
    try {
      let property_path = annot.getCustomData("key")?.split(".");

      let index = annot.getCustomData("index");
      if (!!index) {
        property_path.splice(1, 0, index);
      }

      const value = get(data, property_path, content);

      console.log({ data, property_path, value });

      if (annot.getCustomData("type") === "bool")
        return value === "true" || value === true ? "On" : "Off";
      else return value;
    } catch (error) {
      console.log(error);
      return content;
    }
  }
};
