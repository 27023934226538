import {
  Alert,
  BreadcrumbGroup,
  Button,
  Container,
  Flashbar,
  Form,
  FormField,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import InnerAppLayout from "../../components/InnerAppLayout";
import FormSection from "../../components/FormSection";
import RHFFloatingSelect from "../../components/RHF/RHFFloatingSelect";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import { PrivilegeCreateRequest } from "../../redux/api/privileges/types";
import { useGetAllCategoryQuery } from "../../redux/api/managecategory/managecategory";
import { useGetAllLicenseTypesQuery } from "../../redux/api/provider/provider";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./add-privileges.css";
import {
  useGetAllPrivilegeTypesQuery,
  useGetPrivilegeQuery,
  useUpdatePrivilegeMutation,
  useUploadPrivilegeAttachmentMutation,
} from "../../redux/api/privileges/privileges";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import useNotify from "../../hooks/useNotify";
import UploadNewFile from "../../components/UploadNewFile";
import { ADD_PRIVILEGE_TYPE } from "../../helpers/constants";
import { setModalName } from "../../redux/UI/actions";
import { connect } from "react-redux";
import { Attachment } from "../../redux/api/document/types";
import prettyBytes from "pretty-bytes";
import LoadingScreen from "../../components/LoadingScreen";
import { useTranslation } from "react-i18next";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function EditPrivilege({ setModalName }: PropsFromDispatch) {
  const methods = useForm<PrivilegeCreateRequest>();
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    data: privilege,
    fulfilledTimeStamp,
    isLoading,
  } = useGetPrivilegeQuery({
    privilegeId: id ?? "",
  });
  const { notifyInProgress, notifySucess, notifyFailed } = useNotify();
  const [updatePrivilege] = useUpdatePrivilegeMutation();
  const [uploadPrivilegeAttachment] = useUploadPrivilegeAttachmentMutation();
  // fetch all categories
  const { data: privilegesType = [] } = useGetAllPrivilegeTypesQuery();
  const { data: categories = [] } = useGetAllCategoryQuery();
  const { data: licenses = [] } = useGetAllLicenseTypesQuery();
  const [uploadFile, setUploadFile] = useState<File>();
  const [template, setTemplate] = useState<Attachment>();
  const navigate = useNavigate();
  const onSubmit = async (data: PrivilegeCreateRequest) => {
    if (!!id) {
      const notificationId = uuidv4();
      notifyInProgress({
        name: "privilege",
        action: "updating",
        id: notificationId,
      });

      updatePrivilege({
        id: id,
        name: data?.name,
        description: data?.description,
        privilegeTypeId: data.privilegeTypeId,
        categoryId: data.categoryId,
        licenseTypeId: data.licenseTypeId,
      })
        .unwrap()
        .then(async (privilegeResponse) => {
          if (uploadFile) {
            await uploadPrivilegeAttachment({
              privilegeId: privilegeResponse.id,
              file: uploadFile,
            })
              .unwrap()
              .then((attachmentResponse) => {
                notifySucess({
                  name: "privilege",
                  action: "update",
                  id: notificationId,
                });
              })
              .catch((error) => {
                if (error.status < 500 && error.status >= 400) {
                  notifyFailed({
                    name: "privilege",
                    action: error.data.errorMessage,
                    content: error.data.errorDescription,
                    id: notificationId,
                  });
                } else
                  notifyFailed({
                    name: "privilege",
                    action: "update",
                    id: notificationId,
                  });
              });
          } else
            notifySucess({
              name: "privilege",
              action: "update",
              id: notificationId,
            });
        })
        .catch((error) => {
          // console.log({ error });
          if (error.status < 500 && error.status >= 400) {
            notifyFailed({
              name: "privilege",
              action: error.data.errorMessage,
              content: error.data.errorDescription,
              id: notificationId,
            });
          } else
            notifyFailed({
              name: "privilege",
              action: "update",
              id: notificationId,
            });
        });
    }
    navigate(-1);
  };
  useEffect(() => {
    if (privilege) {
      methods.setValue("name", privilege?.name);
      methods.setValue("description", privilege?.description);
      !!privilege?.privilegeType?.id &&
        methods.setValue("privilegeTypeId", privilege?.privilegeType?.id);
      methods.setValue("licenseTypeId", privilege?.licenseType?.id);
      methods.setValue("categoryId", privilege?.category?.id?.toString());
      if (!!privilege.attachment) {
        setTemplate(privilege?.attachment);
      }
    }
  }, [fulfilledTimeStamp, methods, privilege]);

  return (
    <>
      <LoadingScreen isOpen={isLoading} />
      <InnerAppLayout
        breadcrumbGroup={
          <BreadcrumbGroup
            items={[
              {
                text: `${t("privileges.editPrivilege.header.breadcrumb.text")}`,
                href: `/privileges`,
              },

              {
                text: `${t("privileges.editPrivilege.header.breadcrumb.currentPage")}`,
                href: "#",
              },
            ]}
          />
        }
        content={
          <Container
            header={
              <Header>{t("privileges.editPrivilege.header.label")}</Header>
            }
          >
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Form
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        onClick={() => {
                          navigate("/privileges");
                        }}
                        formAction="none"
                      >
                        {t("privileges.editPrivilege.header.actions.cancel")}
                      </Button>
                      <Button formAction="submit" variant="primary">
                        {t("privileges.editPrivilege.header.actions.submit")}
                      </Button>
                    </SpaceBetween>
                  }
                >
                  <FormSection columns={1}>
                    <FormSection
                      gridDefinition={[
                        { colspan: { default: 12, s: 2 } },
                        { colspan: { default: 12, s: 9 } },
                      ]}
                    >
                      <RHFFloatingSelect
                        name="privilegeTypeId"
                        label={t(
                          "privileges.editPrivilege.header.fields.type.label",
                        )}
                        options={privilegesType.map((type) => ({
                          label: type.name,
                          value: "" + type.id,
                        }))}
                        rules={{ required: "This field is required" }}
                        stretch={false}
                        action={{
                          label: `${t("privileges.editPrivilege.header.fields.type.actions.modify")}`,
                          onClick: () => {
                            setModalName(ADD_PRIVILEGE_TYPE);
                          },
                        }}
                      />
                      <RHFTextField
                        label={t("privileges.editPrivilege.header.fields.name")}
                        name="name"
                        stretch={false}
                        rules={{ required: "This field is required" }}
                      />
                    </FormSection>

                    <RHFFloatingSelect
                      name="licenseTypeId"
                      label={t(
                        "privileges.editPrivilege.header.fields.licenseType",
                      )}
                      options={licenses.map((license) => ({
                        value: "" + license.id,
                        label: license.name,
                      }))}
                      enableFiltering
                      stretch={false}
                    />

                    <RHFFloatingSelect
                      name="categoryId"
                      label={t(
                        "privileges.editPrivilege.header.fields.category",
                      )}
                      options={categories.map((category) => ({
                        label: category.name,
                        value: "" + category.id,
                      }))}
                      enableFiltering
                      stretch={false}
                    />
                    <FormField
                      label={t(
                        "privileges.editPrivilege.header.fields.uploadFile",
                      )}
                    >
                      <SpaceBetween size={"s"}>
                        {template && (
                          <Flashbar
                            items={[
                              {
                                type: "warning",
                                content: `${t("privileges.editPrivilege.header.template.flashbar.content")}`,
                              },
                            ]}
                          />
                        )}
                        {!!template ? (
                          <Alert
                            dismissible
                            onDismiss={() => {
                              setTemplate(undefined);
                            }}
                            header={
                              <div>
                                {t(
                                  "privileges.editPrivilege.header.template.alert.fileName",
                                )}
                                : {template?.name}
                              </div>
                            }
                          >
                            <div>
                              {t(
                                "privileges.editPrivilege.header.template.alert.fileSize",
                              )}
                              : {prettyBytes(template?.size)}
                            </div>
                            <div>
                              {t(
                                "privileges.editPrivilege.header.template.alert.fileType",
                              )}
                              : {template?.contentType}
                            </div>
                          </Alert>
                        ) : (
                          <UploadNewFile
                            file={uploadFile}
                            onFileChange={(uploadFile?: File) => {
                              setUploadFile(uploadFile);
                            }}
                          />
                        )}
                      </SpaceBetween>
                    </FormField>
                  </FormSection>
                </Form>
              </form>
            </FormProvider>
          </Container>
        }
      />
    </>
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(EditPrivilege);
