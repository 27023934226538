import { Alert, Button, Link } from "@cloudscape-design/components";
import { useEffect, useRef, useState } from "react";
import prettyBytes from "pretty-bytes";
import { useDropzone } from "react-dropzone";

import "./UploadNewFile.css";
type UploadNewFileProps = {
  header?: string;
  file?: File;
  maxHeight?: boolean;
  onFileChange: (file?: File) => void;
};

function UploadNewFile({
  header,
  file,
  maxHeight = false,
  onFileChange,
}: UploadNewFileProps) {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
  });

  useEffect(() => {
    onFileChange(acceptedFiles?.at(0));
  }, [acceptedFiles]);

  if (!!file) {
    return (
      <Alert
        dismissible
        onDismiss={() => {
          onFileChange(undefined);
        }}
        header={<div>File Name: {file?.name}</div>}
      >
        <div>File size: {prettyBytes(file?.size)}</div>
        <div>File type: {file?.type}</div>
      </Alert>
    );
  } else
    return (
      <div>
        <div>
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <p>Drag & drop file here, or click to select file.</p>
          </div>
        </div>
      </div>
    );
}
export default UploadNewFile;
