import { Button } from "@mui/material";

type MUIButtonCustomizedProps = {
  styleProps?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  label: string;
  type?: "button" | "submit" | "reset";
};
export default function MUIButtonCustomized({
  styleProps,
  onClick,
  label,
  type,
}: MUIButtonCustomizedProps) {
  return (
    <Button
      type={type}
      style={{
        width: 450,
        height: 30,
        fontSize: 12,
        fontFamily: "open-san !important",
        background:
          "var(--color-background-button-primary-default-w02sje, #0073bb)",
        borderColor:
          "var(--color-background-button-primary-default-w02sje, #0073bb)",
        color: "var(--color-text-button-primary-default-iy7hsx, #ffffff)",
        ...styleProps,
      }}
      variant="contained"
      onClick={onClick}
    >
      {label}
    </Button>
  );
}
