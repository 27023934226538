import { FormProvider, useForm } from "react-hook-form";
import {
  BreadcrumbGroup,
  Button,
  Container,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { PayerEnrollmentRequest } from "../../redux/api/payerenrollment/types";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import {
  useCreatePayerEnrollmentMutation,
  useGetAllPayerEnrollmentStatusQuery,
} from "../../redux/api/payerenrollment/payerenrollment";
import { Divider } from "semantic-ui-react";
import FormSection from "../../components/FormSection";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import RHFSelect from "../../components/RHF/RHFSelect";
import {
  PAYER_ENROLLMENT_STATUS,
  alertReminderOptions,
} from "../../helpers/constants";
import { useGetAllProvidersQuery } from "../../redux/api/provider/provider";
import { useGetAllPayerQuery } from "../../redux/api/payers/payers";
import RHFFloatingSelect from "../../components/RHF/RHFFloatingSelect";
import { setModalName } from "../../redux/UI/actions";
import { connect } from "react-redux";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function AddPayerEnrollment({ setModalName }: PropsFromDispatch) {
  const methods = useForm<PayerEnrollmentRequest>();
  const { id: payerId } = useParams();
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const { data: status = [] } = useGetAllPayerEnrollmentStatusQuery();
  const { data: payer = [] } = useGetAllPayerQuery();
  const { data: provider = [] } = useGetAllProvidersQuery({
    includeRecordData: false,
  });
  const onSubmit = async (data: PayerEnrollmentRequest) => {
    if (!!payerId) {
      await notifyWrapper({
        promise: addPayerEnrollment({
          payerId: payerId,
          ...data,
        }),
        resourceName: "payerenrollment",
        actionName: "add",
      });
      navigate(-1);
    }
  };
  useEffect(() => {
    if (payerId) {
      const temp = payer?.find(
        (payerName) => "" + payerName.id === "" + payerId,
      );
      if (temp) methods.setValue("payer", temp?.id);
    }
  }, [payerId]);

  const [addPayerEnrollment] = useCreatePayerEnrollmentMutation();

  return (
    <div style={{ paddingTop: "25px" }}>
      <SpaceBetween size={"l"}>
        <BreadcrumbGroup
          items={[
            { text: "PayerEnrollment", href: `/payerenrollment` },

            { text: `Add New`, href: "#" },
          ]}
          ariaLabel="Breadcrumbs"
        />
        <Container
          header={
            <SpaceBetween size="xxs">
              <Header>Add Payer Enrollment</Header>
              <Divider></Divider>
            </SpaceBetween>
          }
        >
          <div style={{ paddingTop: "12px" }}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Form
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        onClick={() => {
                          navigate(-1);
                        }}
                        formAction="none"
                      >
                        Cancel
                      </Button>
                      <Button formAction="submit" variant="primary">
                        Submit
                      </Button>
                    </SpaceBetween>
                  }
                >
                  <FormSection columns={4}>
                    <RHFSelect
                      name="payer"
                      label="Payer"
                      options={payer.map((temp) => ({
                        label: temp.name,
                        value: "" + temp.id,
                      }))}
                      stretch={false}
                      rules={{ required: "This field is required" }}
                    />
                    <RHFSelect
                      label="Provider"
                      name={"providerId"}
                      stretch={false}
                      options={provider.map((temp) => ({
                        label: temp.firstName,
                        value: "" + temp.id,
                        tags: temp?.providerLicenses?.map(
                          (type) => type.licenseType,
                        ),
                      }))}
                      rules={{ required: "This field is required" }}
                    />
                    <RHFFloatingSelect
                      name="enrollmentStatusId"
                      label="Status"
                      options={status.map((temp) => ({
                        label: temp.name,
                        value: "" + temp.id,
                      }))}
                      rules={{ required: "This field is required" }}
                      stretch={false}
                      action={{
                        label: "Modify",
                        onClick: () => {
                          setModalName(PAYER_ENROLLMENT_STATUS);
                        },
                      }}
                    />
                    <RHFDatePicker
                      label={"Submitted Date"}
                      name={"submittedDate"}
                      stretch={false}
                    />
                    <RHFDatePicker
                      label="Approved Date"
                      name="approvedDate"
                      stretch={false}
                    />
                    <RHFDatePicker
                      label="Effective Date"
                      name="effectiveDate"
                      stretch={false}
                    />
                    <RHFDatePicker
                      label={"Re-credentialing Date"}
                      name={"recredentialingDate"}
                      stretch={false}
                    />

                    <RHFSelect
                      label="Follow-up Date"
                      name={"followupDate"}
                      stretch={false}
                      options={alertReminderOptions}
                    />
                  </FormSection>
                </Form>
              </form>
            </FormProvider>
          </div>
        </Container>
      </SpaceBetween>
    </div>
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(AddPayerEnrollment);
