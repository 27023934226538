export const DIGITALFORM_CREATE_MODAL = "modals/digitalform-create-form-modal";
export const DIGITALFORM_MANAGE_TAGS_MODAL =
  "modals/digitalform-manage-tags-modal";
export const DIGITALFORM_DUPLICATE_MODAL = "modals/digitalform-duplicate-modal";
export const DIGITALFORM_EDIT_MODAL = "modals/digitalform-edit-modal";
export const DIGITALFORM_DISABLE_ALERT_MODAL =
  "modals/digitalform-disable-status-modal";
export const DIGITALFORM_ENABLE_ALERT_MODAL =
  "modals/digitalform-enable-status-modal";
export const DIGITALFORM_UPLOAD_MODAL = "modals/digitalform-upload-modal";
export const DIGITALFORM_CHOOSE_PROVIDER_MODAL =
  "modals/digitalform-choose-provider-modal";
export const DIGITALFORMTYPE_MODAL = "modals/digitalformtype-modal";

export const GROUP_CREATE_MODAL = "modals/groups-create-modal";
export const GROUP_EDIT_MODAL = "modals/groups-edit-modal";
export const CATEGORY_CREATE_MODAL = "modals/category-create-modal";
export const CATEGORY_EDIT_MODAL = "modals/category-edit-modal";
export const ADD_USERGROUPS_MODAL = "modals/add-usergroups-modal";

export const ADD_MANAGEADMIN_MODAL = "modals/add-manageadmin-modal";
export const MANAGEADMIN_EDIT_MODAL = "modals/manageadmin-edit-modal";

export const ADD_MANAGEPROVIDER_MODAL = "modals/add_manageprovider-modal";
export const MANAGEPROVIDER_EDIT_MODAL = "modals/manageprovider-edit-modal";

export const INVITE_MODAL = "modals/invite-modal";

export const CREATE_SECTION_MODAL = "modals/create-section-modal";

export const CREATE_FACILITY = "modals/create-facility";
export const EDIT_FACILITY = "modals/edit-facility";
export const FACILITY_TYPE = "modals/facility-type-modal";

export const ADD_ROLE = "modals/add-role";
export const EDIT_ROLE = "modals/edit-role";

export const ADD_ASSOCIATED_FACILITY = "modals/add-associated-facilities-modal";
export const ADD_ASSOCIATED_PROVIDER = "modals/add-associated-providers-modal";

export const EDIT_MEMBER = "modals/edit-member";

export const ADD_CONTRACT_MODAL = "modals/add-contract-modal";
export const EDIT_CONTRACT_MODAL = "modals/edit-contract-modal";
export const ADD_CONTRACT_TYPE = "modals/add-contracttype-modal";
export const EDIT_CONTRACT_TYPE = "modals/edit-contracttype-modal";

export const ADD_PRIVILEGE_TYPE = "modals/addPrivilegeTypeModal";
export const PACKAGE_TYPE = "modals/packageTypeModal";
export const PACKAGE_STATUS = "modals/packageStatusModal";
export const EDIT_PACKAGE = "modals/editpackagemodal";
export const EDIT_TEMPLATE = "modals/editcredentialtemplatemodal";

export const EDIT_PSV = "modals/edit-psv-modal";
export const ADD_AUTO_PSV = "modals/add-auto-psv-modal";

export const CONTRACT_TYPE = "modals/contractTypeModal";
export const PAYER_ENROLLMENT_STATUS = "modals/payerenrollmentstatusmodal";

export const CATEGORY_MODAL = "modals/categorymodal";

export const tag_i18nStrings = {
  keyPlaceholder: "Key",
  valuePlaceholder: "Value",
  addButton: "Add tag",
  removeButton: "Remove",
  undoButton: "Undo",
  undoPrompt: "Undo",
  loading: "loading",
  keyHeader: "Key",
  valueHeader: "Value",
  optional: "optional",
  keySuggestion: "",
  valueSuggestion: "",
  tooManyKeysSuggestion: "",
  tooManyValuesSuggestion: "",
  emptyTags: "No tags associated with the resource.",
  keysSuggestionLoading: "",
  keysSuggestionError: "",
  valuesSuggestionError: "",
  valuesSuggestionLoading: "",
  emptyKeyError: "Tag key cannot be empty",
  maxKeyCharLengthError: "maxKeyCharLengthError",
  maxValueCharLengthError: "maxValueCharLengthError",
  duplicateKeyError: "You must specify a unique tag key.",
  invalidKeyError: "You must specified a invalid tag key.",
  invalidValueError: "You must specified a invalid tag Value.",
  awsPrefixError: "",
  tagLimit: (availableTags: number) => "",
  tagLimitReached: (tagLimit: number) => "",
  tagLimitExceeded: (tagLimit: number) => "",
  enteredKeyLabel: (enteredText: string) => enteredText,
  enteredValueLabel: (enteredText: string) => enteredText,
};

export const FontFamily = [
  "Arial",
  "Calibri",
  "Helvetica",
  "Times New Roman",
  "Courier",
  "Georgia",
  "Serif",
  "Verdana",
  "Sans-serif",
  "Lucida Console",
  "Monospace",
  "Tahoma",
  "Trebuchet MS",
  "Garamond",
  "Brush Script MT",
];

export const disabledElements = [
  "ribbons",
  "toggleNotesButton",
  "searchButton",
  "menuButton",
  "annotationPopup",
  "thumbDelete",
  "pageManipulationOverlayButton",
  "thumbnailsControlInsertPopupTrigger",
  "thumbnailsControlReplace",
  "thumbnailsControlExtract",
  "thumbnailsControlDelete",
  "thumbnailsControlManipulatePopupTrigger",
  "selectToolButton",
  "searchButton",
  "toggleNotesButton",
  "menuButton",
  "moreButton",

  //text popup; appears on highlight of a text when using select tool
  "textHighlightToolButton",
  "textUnderlineToolButton",
  "textSquigglyToolButton",
  "textStrikeoutToolButton",
  "linkButton",

  //context menu popup; appears on right click of a blank space
  "contextMenuPopup",

  //hide ribbon tools
  "toolbarGroup-View",
  "toolbarGroup-Annotate",
  "toolbarGroup-Shapes",
  "toolbarGroup-Insert",
  "toolbarGroup-Measure",
  "toolbarGroup-Edit",
  "toolbarGroup-FillAndSign",

  //toolbarGroup-Forms
  "listBoxFieldToolGroupButton",
  "comboBoxFieldToolGroupButton",
  "applyFormFieldsButton",

  //Thumbnail
  "thumbRotateCounterClockwise",
  "thumbRotateClockwise",
];

export const category = [
  { label: "Government ID", value: "105" },
  { label: "Driver's License", value: "110" },
  { label: "Passport", value: "115" },
  { label: "Curriculum Vitae", value: "120" },
  { label: "Medical School", value: "125" },
  { label: "Internship", value: "130" },
  { label: "Residency", value: "135" },
  { label: "Fellowship", value: "140" },
  { label: "Post Graduate Education", value: "145" },
  { label: "Other Graduate Level Education", value: "150" },
  { label: "Reference Letters", value: "155" },
  { label: "Hospital Affiliations", value: "160" },
  { label: "State Licenses", value: "205" },
  { label: "State Applications", value: "210" },
  { label: "Board Certifications", value: "215" },
  { label: "DEA", value: "220" },
  { label: "BCLS", value: "225" },
  { label: "ACLS", value: "230" },
  { label: "PALS", value: "235" },
  { label: "Other Certifications", value: "240" },
  { label: "Malpractice Coverage Policy", value: "305" },
  { label: "Claims History", value: "310" },
  { label: "OIG Printed Copy", value: "315" },
  { label: "NPDB", value: "320" },
  { label: "CME Certificate", value: "405" },
  { label: "Appointment", value: "505" },
  { label: "Reappointment", value: "510" },
  { label: "Delineation of Privileges", value: "515" },
  { label: "Facility Orientation", value: "520" },
  { label: "Competency Evaluation", value: "525" },
  { label: "Performance Evaluation", value: "530" },
  { label: "Termination of Hospital Privileges", value: "535" },
  { label: "Timesheets", value: "540" },
  { label: "Other", value: "545" },
  { label: "Health Documents", value: "550" },
  { label: "Application", value: "555" },
];

export const USStatesList = [
  { label: "Alabama", value: "alabama" },
  { label: "Alaska", value: "alaska" },
  { label: "Arizona", value: "arizona" },
  { label: "Arkansas", value: "arkansas" },
  { label: "California", value: "california" },
  { label: "Colorado", value: "colorado" },
  { label: "Connecticut", value: "connecticut" },
  { label: "Delaware", value: "delaware" },
  { label: "Florida", value: "florida" },
  { label: "Georgia", value: "georgia" },
  { label: "Hawaii", value: "hawaii" },
  { label: "Idaho", value: "idaho" },
  { label: "Illinois", value: "illinois" },
  { label: "Indiana", value: "indiana" },
  { label: "Iowa", value: "iowa" },
  { label: "Kansas", value: "kansas" },
  { label: "Kentucky", value: "kentucky" },
  { label: "Louisiana", value: "louisiana" },
  { label: "Maine", value: "maine" },
  { label: "Maryland", value: "maryland" },
  { label: "Massachusetts", value: "massachusetts" },
  { label: "Michigan", value: "michigan" },
  { label: "Minnesota", value: "minnesota" },
  { label: "Mississippi", value: "mississippi" },
  { label: "Missouri", value: "missouri" },
  { label: "Montana", value: "montana" },
  { label: "Nebraska", value: "nebraska" },
  { label: "Neveda", value: "Nnveda" },
  { label: "New Hampshire", value: "newhampshire" },
  { label: "New Jersey", value: "newjersey" },
  { label: "New Mexico", value: "newmexico" },
  { label: "New York", value: "newyork" },
  { label: "North Carolina", value: "northcarolina" },
  { label: "North Dakota", value: "northdakota" },
  { label: "Ohio", value: "ohio" },
  { label: "Oklahoma", value: "oklahoma" },
  { label: "Oregon", value: "oregon" },
  { label: "Pennsylvania", value: "pennsylvania" },
  { label: "Rhode Island", value: "rhodeisland" },
  { label: "South Carolina", value: "southcarolina" },
  { label: "Tennessee", value: "tennessee" },
  { label: "Texas", value: "texas" },
  { label: "Utah", value: "utah" },
  { label: "Vermont", value: "vermont" },
  { label: "Virginia", value: "virginia" },
  { label: "Washington", value: "washington" },
  { label: "West Virginia", value: "westvirginia" },
  { label: "Wisconsin", value: "winsconsin" },
  { label: "Wyoming", value: "wyoming" },
];

export const alertReminderOptions = [
  { label: "120 days before expiration", value: "120" },
  { label: "90 days before expiration", value: "90" },
  { label: "60 days before expiration", value: "60" },
  { label: "30 days before expiration", value: "30" },
  { label: "Not Required", value: "-1" },
];

export const specialty_certification_boards = [
  {
    boardName: "American Board of Surgery",
    specialityName: "General Surgery",
  },
  {
    boardName: "American Board of Internal Medicine",
    taskName: "abimBoard",
    specialityName: "Internal Medicine",
  },
  {
    boardName: "American Board of Pediatrics",
    specialityName: "Pediatrics",
  },
  {
    boardName: "American Board of Obstetrics and Gynecology",
    specialityName: "Obstetrics and Gynecology",
  },
  {
    boardName: "American Board of Family Medicine",
    specialityName: "Family Medicine",
  },
  {
    boardName: "American Board of Anesthesiology",
    specialityName: "Anesthesiology",
  },
  {
    boardName: "American Board of Radiology",
    specialityName: "Radiology",
  },
  {
    boardName: "American Board of Emergency Medicine",
    specialityName: "Emergency Medicine",
  },
  {
    boardName: "American Board of Ophthalmology",
    specialityName: "Ophthalmology",
  },
  {
    boardName: "American Board of Psychiatry and Neurology",
    taskName: "abpnBoard",
    specialityName: "Psychiatry and Neurology",
  },
  {
    boardName: "American Board of Physical Medicine and Rehabilitation",
    specialityName: "Physical Medicine and Rehabilitation",
  },
  {
    boardName: "American Board of Dermatology",
    specialityName: "Dermatology",
  },
  {
    boardName: "American Board of Orthopaedic Surgery",
    specialityName: "Orthopaedic Surgery",
  },
  {
    boardName: "American Board of Pathology",
    specialityName: "Pathology",
  },
  {
    boardName: "American Board of Urology",
    specialityName: "Urology",
  },
  {
    boardName: "American Board of Neurological Surgery",
    specialityName: "Neurological Surgery",
  },
  {
    boardName: "American Board of Plastic Surgery",
    specialityName: "Plastic Surgery",
  },
  {
    boardName: "American Board of Thoracic Surgery",
    specialityName: "Thoracic Surgery",
  },
  {
    boardName: "American Board of Preventive Medicine",
    specialityName: "Preventive Medicine",
  },
  {
    boardName: "American Board of Allergy and Immunology",
    specialityName: "Allergy and Immunology",
  },
  {
    boardName: "American Board of Colon and Rectal Surgery",
    specialityName: "Colon and Rectal Surgery",
  },
  {
    boardName: "American Board of Nuclear Medicine",
    specialityName: "Nuclear Medicine",
  },
  {
    boardName: "American Board of Otolaryngology",
    specialityName: "Otolaryngology",
  },
  {
    boardName: "American Board of Pain Medicine",
    specialityName: "Pain Medicine",
  },
  {
    boardName: "American Board of Sleep Medicine",
    specialityName: "Sleep Medicine",
  },
  {
    boardName: "American Board of Podiatric Medicine",
    taskName: "abpmedBoard",
    specialityName: "Podiatrist",
    specialityValue: "Podiatrist",
  },
];
