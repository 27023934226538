import {
  Tabs,
  SpaceBetween,
  Header,
  Container,
  Box,
  BreadcrumbGroup,
} from "@cloudscape-design/components";
import { connect } from "react-redux";
import { ApplicationState } from "../../redux/store";
import { setActiveTabId } from "../../redux/provider/action";
import Overview from "./sections/overview";
import {
  useGetProviderQuery,
} from "../../redux/api/provider/provider";
import { useParams, useSearchParams } from "react-router-dom";
import { NoProviderSelected } from "..";
import { useEffect } from "react";
import useIsLoading from "../../hooks/useIsLoading";
import PersonalInfo from "./sections/sections/personal-info";
import ProfessionalInfo from "./sections/sections/professional-info";
import Affiliation from "./sections/sections/affiliations";
import Dea from "./sections/sections/dea";
import Disclosure from "./sections/sections/disclosures";
import Education from "./sections/sections/education";
import LiabilityInsurance from "./sections/sections/liability-insurance";
import License from "./sections/sections/license";
import PeerReference from "./sections/sections/peer-reference";
import SpecialtyInfo from "./sections/sections/specialty-info";
import ThirdPartyLogin from "./sections/sections/third-party-login";
import WorkHistory from "./sections/sections/work-history";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import LoadingScreen from "../../components/LoadingScreen";

interface PropsFromState {
  activeTabId: string;
}

interface PropsFromActions {
  changeActiveTabId: typeof setActiveTabId;
}

function ProviderDetail({
  activeTabId,
  changeActiveTabId,
}: PropsFromState & PropsFromActions) {
  const { providerId } = useParams();

  const { data: provider, isLoading } = useGetProviderQuery(
    !!providerId
      ? {
        providerId,
      }
      : skipToken,
  );

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const searchParam_activeTabId = searchParams.get("activeTabId");
    if (searchParam_activeTabId) changeActiveTabId(searchParam_activeTabId);
  }, []);

  useIsLoading(isLoading);

  if (!provider) return <NoProviderSelected />;
  else
    return (
      <SpaceBetween size={"s"}>
        <LoadingScreen isOpen={isLoading} />
        <BreadcrumbGroup
          items={[
            { text: "Providers", href: "/manageprovider" },
            {
              text: provider?.firstName + " " + provider?.lastName,
              href: "#",
            },
          ]}
        />
        <Box padding={{ right: "m", left: "m" }}>
          <Header>{provider?.firstName + " " + provider?.lastName}</Header>
          <Tabs
            variant="default"
            activeTabId={activeTabId}
            onChange={(changeDetail) => {
              setSearchParams({ activeTabId: changeDetail.detail.activeTabId });
              changeActiveTabId(changeDetail.detail.activeTabId);
            }}
            tabs={[
              {
                label: "Overview",
                id: "overview",
                content: <Overview />,
              },
              {
                label: "Personal Info",
                id: "personal_info",
                content: <PersonalInfo />,
              },
              {
                label: "Professional Info",
                id: "professional_info",
                content: <ProfessionalInfo />,
              },
              {
                label: "Affiliations",
                id: "affiliation",
                content: <Affiliation />,
              },
              {
                label: "DEA",
                id: "dea",
                content: <Dea />,
              },
              {
                label: "Disclosure",
                id: "disclosure",
                content: <Disclosure />,
              },
              {
                label: "Education",
                id: "education",
                content: <Education />,
              },
              {
                label: "Liability Insurance",
                id: "LiabilityInsurance",
                content: <LiabilityInsurance />,
              },
              {
                label: "License",
                id: "license",
                content: <License />,
              },
              {
                label: "Peer Reference",
                id: "peerreference",
                content: <PeerReference />,
              },
              {
                label: "Specialty Info",
                id: "specialtyinfo",
                content: <SpecialtyInfo />,
              },
              {
                label: "Third Party Login",
                id: "thirdpartylogin",
                content: <ThirdPartyLogin />,
              },
              {
                label: "Work History",
                id: "workhistory",
                content: <WorkHistory />,
              },
              {
                label: "Enrolled Payers",
                id: "enrolledpayers",
                /* 
              Table with list of enrolled payers and 
              edit button on header, which on click opens a modal with
              provider name as header, 
              associated & unassociated payers as transfer list
              */
                content: <div>Enrolled Payers</div>,
              },
            ]}
          />
        </Box>
      </SpaceBetween>
    );
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    activeTabId: state.provider.activeTabId,
  };
};

const mapDispatchToProps = {
  changeActiveTabId: setActiveTabId,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProviderDetail);
