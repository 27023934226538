import { api } from "..";
import {
  Role,
  Permission,
  RoleRequest,
  RolePermissions,
  RoleMetaData,
} from "./types";

const RoleAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAllRole: build.query<RoleMetaData[], void>({
      query: () => "/role/all",
      providesTags: ["Role"],
    }),
    getRole: build.query<Role, { id: string }>({
      query: ({ id }) => `/role/${id}`,
      providesTags: ["Role"],
    }),
    getAllPermissions: build.query<Permission[], void>({
      query: () => "/permission/all",
      providesTags: ["Permission"],
    }),

    addRole: build.mutation<void, RoleRequest>({
      query: ({ ...body }) => {
        return {
          url: "/role",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Role"],
    }),

    updateRole: build.mutation<void, Pick<Role, "id"> & Partial<RoleRequest>>({
      query: ({ id, ...patch }) => {
        return {
          url: `role/${id}`,
          method: "PUT",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Role", id: arg.id },
        { type: "Role" },
      ],
    }),

    updateRolePermissions: build.mutation<void, RolePermissions[]>({
      query: (body) => {
        return {
          url: `role/permissions`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: "Role" }],
    }),
    deleteRole: build.mutation<
      void,
      {
        roleId: string;
      }
    >({
      query: ({ roleId }) => ({
        url: `role/${roleId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Role"],
    }),
    deleteRoleFacilities: build.mutation<
      void,
      {
        roleId: string;
        facility_ids: string[];
      }
    >({
      query: ({ roleId, ...body }) => ({
        url: `role/${roleId}/facilities`,
        body: body,
        method: "DELETE",
      }),
      invalidatesTags: ["Role"],
    }),
    addRoleFacilities: build.mutation<
      void,
      {
        roleId: string;
        facility_ids: string[];
      }
    >({
      query: ({ roleId, ...body }) => ({
        url: `role/${roleId}/facilities`,
        body: body,
        method: "POST",
      }),
      invalidatesTags: ["Role"],
    }),
    addRoleProviders: build.mutation<
      void,
      {
        roleId: string;
        provider_ids: string[];
      }
    >({
      query: ({ roleId, ...body }) => ({
        url: `role/${roleId}/providers`,
        body: body,
        method: "POST",
      }),
      invalidatesTags: ["Role"],
    }),
    deleteRoleProviders: build.mutation<
      void,
      {
        roleId: string;
        provider_ids: string[];
      }
    >({
      query: ({ roleId, ...body }) => ({
        url: `role/${roleId}/providers`,
        body: body,
        method: "DELETE",
      }),
      invalidatesTags: ["Role"],
    }),
  }),
});

export const {
  useUpdateRolePermissionsMutation,
  useUpdateRoleMutation,
  useAddRoleFacilitiesMutation,
  useAddRoleProvidersMutation,
  useAddRoleMutation,
  useDeleteRoleMutation,
  useDeleteRoleFacilitiesMutation,
  useDeleteRoleProvidersMutation,
  useGetAllPermissionsQuery,
  useGetAllRoleQuery,
  useGetRoleQuery,
} = RoleAPI;
