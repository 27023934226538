import {
  Alert,
  BreadcrumbGroup,
  Button,
  Container,
  Form,
  FormField,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { FormProvider, useForm } from "react-hook-form";
import { PrivilegeCreateRequest } from "../../redux/api/privileges/types";
import { useGetAllCategoryQuery } from "../../redux/api/managecategory/managecategory";
import { useGetAllLicenseTypesQuery } from "../../redux/api/provider/provider";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./add-privileges.css";
import {
  useCreatePrivilegeMutation,
  useGetAllPrivilegeTypesQuery,
  useUploadPrivilegeAttachmentMutation,
} from "../../redux/api/privileges/privileges";
import InnerAppLayout from "../../components/InnerAppLayout";
import { Divider } from "semantic-ui-react";
import FormSection from "../../components/FormSection";
import RHFFloatingSelect from "../../components/RHF/RHFFloatingSelect";
import RHFTextField from "../../components/RHF/RHFTextField";
import { setModalName } from "../../redux/UI/actions";
import { connect } from "react-redux";
import { ADD_PRIVILEGE_TYPE } from "../../helpers/constants";
import { v4 as uuidv4 } from "uuid";
import useNotify from "../../hooks/useNotify";
import UploadNewFile from "../../components/UploadNewFile";
import RHFSelect from "../../components/RHF/RHFSelect";
import { useTranslation } from "react-i18next";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function AddPrivileges({ setModalName }: PropsFromDispatch) {
  const methods = useForm<PrivilegeCreateRequest>();
  const { t } = useTranslation();

  // fetch all categories
  const { data: privilegesType = [] } = useGetAllPrivilegeTypesQuery();
  const { data: categories = [] } = useGetAllCategoryQuery();
  const { data: licenses = [] } = useGetAllLicenseTypesQuery();
  const [uploadFile, setUploadFile] = useState<File>();
  const { notifyInProgress, notifySucess, notifyFailed } = useNotify();
  const navigate = useNavigate();
  const [addPrivilege] = useCreatePrivilegeMutation();
  const [uploadPrivilegeAttachment] = useUploadPrivilegeAttachmentMutation();
  const onSubmit = async (data: PrivilegeCreateRequest) => {
    const notificationId = uuidv4();
    notifyInProgress({
      name: "privilege",
      action: "creating",
      id: notificationId,
    });
    await addPrivilege({
      name: data?.name,
      description: data?.description,
      privilegeTypeId: data.privilegeTypeId,
      categoryId: data.categoryId,
      licenseTypeId: data.licenseTypeId,
    })
      .unwrap()
      .then(async (privilegeResponse) => {
        if (uploadFile) {
          await uploadPrivilegeAttachment({
            privilegeId: privilegeResponse?.id,
            file: uploadFile,
          })
            .unwrap()
            .then((attachmentResponse) => {
              notifySucess({
                name: "privilege",
                action: "created",
                id: notificationId,
              });
            })
            .catch((error) => {
              // console.log({ error });
              if (error.status < 500 && error.status >= 400) {
                notifyFailed({
                  name: "privilege",
                  action: error.data.errorMessage,
                  content: error.data.errorDescription,
                  id: notificationId,
                });
              } else
                notifyFailed({
                  name: "privilege",
                  action: "create",
                  id: notificationId,
                });
            });
        } else
          notifySucess({
            name: "privilege",
            action: "created",
            id: notificationId,
          });
      })
      .catch((error) => {
        // console.log({ error });
        if (error.status < 500 && error.status >= 400) {
          notifyFailed({
            name: "privilege",
            action: error.data.errorMessage,
            content: error.data.errorDescription,
            id: notificationId,
          });
        } else
          notifyFailed({
            name: "privilege",
            action: "create",
            id: notificationId,
          });
      });

    navigate(-1);
  };

  return (
    <InnerAppLayout
      breadcrumbGroup={
        <BreadcrumbGroup
          items={[
            {
              text: `${t("privileges.addPrivilege.header.breadcrumb.text")}`,
              href: `/privileges`,
            },

            {
              text: `${t("privileges.addPrivilege.header.breadcrumb.currentPage")}`,
              href: "#",
            },
          ]}
        />
      }
      content={
        <Container
          header={
            <SpaceBetween size="xxs">
              <Header>{t("privileges.addPrivilege.header.label")}</Header>
              <Divider></Divider>
            </SpaceBetween>
          }
        >
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Form
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      onClick={() => {
                        navigate("/privileges");
                      }}
                      formAction="none"
                    >
                      {t("privileges.addPrivilege.header.actions.cancel")}
                    </Button>
                    <Button formAction="submit" variant="primary">
                      {t("privileges.addPrivilege.header.actions.submit")}
                    </Button>
                  </SpaceBetween>
                }
              >
                <FormSection columns={1}>
                  <FormSection
                    gridDefinition={[
                      { colspan: { default: 12, s: 2 } },
                      { colspan: { default: 12, s: 9 } },
                    ]}
                  >
                    <RHFFloatingSelect
                      name="privilegeTypeId"
                      label={t(
                        "privileges.addPrivilege.header.fields.type.label",
                      )}
                      options={privilegesType.map((type) => ({
                        label: type.name,
                        value: "" + type.id,
                      }))}
                      rules={{ required: "This field is required" }}
                      stretch={false}
                      action={{
                        label: `${t("privileges.addPrivilege.header.fields.type.actions.modify")}`,
                        onClick: () => {
                          setModalName(ADD_PRIVILEGE_TYPE);
                        },
                      }}
                    />
                    <RHFTextField
                      label={t("privileges.addPrivilege.header.fields.name")}
                      name="name"
                      stretch={false}
                      rules={{ required: "This field is required" }}
                    />
                  </FormSection>
                  {/* <RHFTextArea
                    label="Description"
                    name="description"
                    stretch={false}
                  /> */}
                  <RHFSelect
                    name="licenseTypeId"
                    label={t(
                      "privileges.addPrivilege.header.fields.licenseType",
                    )}
                    options={licenses.map((license) => ({
                      value: "" + license.id,
                      label: license.name,
                    }))}
                    filteringType="auto"
                    stretch={false}
                  />

                  <RHFSelect
                    name="categoryId"
                    label={t("privileges.addPrivilege.header.fields.category")}
                    options={categories.map((category) => ({
                      label: category.name,
                      value: "" + category.id,
                    }))}
                    filteringType="auto"
                    stretch={false}
                  />
                  <FormField
                    label={t(
                      "privileges.addPrivilege.header.fields.uploadFile",
                    )}
                  >
                    <UploadNewFile
                      file={uploadFile}
                      onFileChange={(uploadFile?: File) => {
                        setUploadFile(uploadFile);
                      }}
                    />
                  </FormField>
                </FormSection>
              </Form>
            </form>
          </FormProvider>
        </Container>
      }
    />
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(AddPrivileges);
