import {
  Box,
  BreadcrumbGroup,
  Button,
  Container,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CredentialPackageRequest } from "../../../redux/api/credentialpackages/types";
import useAsyncNotifyWrapper from "../../../hooks/useAsyncNotifyWrapper";
import {
  useCreateCredentialPackageMutation,
  useGetAllCredentialTemplatesQuery,
  useGetAllPackageStatusQuery,
  useGetAllPackageTypesQuery,
} from "../../../redux/api/credentialpackages/credentialpackages";
import InnerAppLayout from "../../../components/InnerAppLayout";
import RHFTextField from "../../../components/RHF/RHFTextField";
import RHFFloatingSelect from "../../../components/RHF/RHFFloatingSelect";
import { setModalName } from "../../../redux/UI/actions";
import { connect } from "react-redux";
import {
  PACKAGE_STATUS,
  PACKAGE_TYPE,
  alertReminderOptions,
} from "../../../helpers/constants";
import RHFSelect from "../../../components/RHF/RHFSelect";
import RHFDatePicker from "../../../components/RHF/RHFDatePicker";
import { useGetAllProvidersQuery } from "../../../redux/api/provider/provider";
import FormSection from "../../../components/FormSection";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function AddCredentialPackage({ setModalName }: PropsFromDispatch) {
  const methods = useForm<CredentialPackageRequest>();
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [addCredentialPackage] = useCreateCredentialPackageMutation();
  const { data: packageType = [] } = useGetAllPackageTypesQuery();
  const { data: template = [] } = useGetAllCredentialTemplatesQuery();
  const { data: packageStatus = [] } = useGetAllPackageStatusQuery();
  const { data: provider = [] } = useGetAllProvidersQuery({
    includeRecordData: false,
  });
  const onSubmit = async (data: CredentialPackageRequest) => {
    await notifyWrapper({
      promise: addCredentialPackage(data),
      resourceName: "Credential Package",
      actionName: "create",
    });
    navigate(-1);
  };
  return (
    <InnerAppLayout
      breadcrumbGroup={
        <>
          <BreadcrumbGroup
            items={[
              { text: "Credential packages", href: `/credentialpackages` },
              { text: `Package`, href: "#" },
            ]}
          />
        </>
      }
      content={
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Form
              actions={
                <Box float="right">
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      onClick={() => {
                        navigate(-1);
                      }}
                      formAction="none"
                    >
                      Cancel
                    </Button>
                    <Button formAction="submit" variant="primary">
                      Submit
                    </Button>
                  </SpaceBetween>
                </Box>
              }
            >
              <SpaceBetween direction="vertical" size="xl">
                <Container header={<Header>Create Package</Header>}>
                  <SpaceBetween direction="vertical" size="m">
                    <FormSection columns={3}>
                      <RHFFloatingSelect
                        name="packageTypeId"
                        label="Package Type"
                        options={packageType.map((type) => ({
                          label: type.name,
                          value: "" + type.id,
                        }))}
                        rules={{ required: "This field is required" }}
                        stretch={false}
                        action={{
                          label: "Modify",
                          onClick: () => {
                            setModalName(PACKAGE_TYPE);
                          },
                        }}
                      />
                      <RHFTextField
                        label="Name"
                        name="name"
                        info="test"
                        stretch={false}
                        rules={{ required: "This field is required" }}
                      />
                      <RHFSelect
                        name="templateId"
                        label="Template"
                        options={template.map((temp) => ({
                          label: temp.name,
                          value: "" + temp.id,
                        }))}
                        stretch={false}
                        rules={{ required: "This field is required" }}
                      />
                      <RHFSelect
                        label="Provider"
                        name={"providerId"}
                        stretch={false}
                        options={provider.map((temp) => ({
                          label: temp.firstName,
                          value: "" + temp.id,
                        }))}
                        rules={{ required: "This field is required" }}
                      />
                      <RHFFloatingSelect
                        name="packageStatusId"
                        label="Package Status"
                        options={packageStatus.map((status) => ({
                          label: status.name,
                          value: "" + status.id,
                        }))}
                        rules={{ required: "This field is required" }}
                        stretch={false}
                        action={{
                          label: "Modify",
                          onClick: () => {
                            setModalName(PACKAGE_STATUS);
                          },
                        }}
                      />
                      <RHFDatePicker
                        label={"Submitted Date"}
                        name={"submittedDate"}
                        stretch={false}
                      />
                      <RHFDatePicker
                        label={"Re-credentialing Date"}
                        name={"recredentialingDate"}
                        stretch={false}
                      />

                      <RHFSelect
                        label="Follow-up Date"
                        name={"followupDate"}
                        stretch={false}
                        options={alertReminderOptions}
                      />
                    </FormSection>
                  </SpaceBetween>
                </Container>
              </SpaceBetween>
            </Form>
          </form>
        </FormProvider>
      }
    />
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(AddCredentialPackage);
