import {
  AttributeEditor,
  Box,
  Button,
  Container,
  Form,
  Header,
  Input,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import Divider from "@mui/material/Divider";
import { setModalName } from "../../../redux/UI/actions";
import {
  useGetAllCategoriesQuery,
  useSaveAllCategoriesMutation,
} from "../../../redux/api/managecategory/managecategory";
import useAsyncNotifyWrapper from "../../../hooks/useAsyncNotifyWrapper";
import { Category } from "../../../redux/api/managecategory/types";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function CategoryModal({ setModalName }: PropsFromDispatch) {
  const { data = [], fulfilledTimeStamp } = useGetAllCategoriesQuery();
  const [category, setCategory] = useState<Category[]>([]);
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [saveAllCategories] = useSaveAllCategoriesMutation();
  const handleSubmit = async () => {
    setModalName("");
    const tmp = [...category].filter((item) => !!item.name);
    await notifyWrapper({
      promise: saveAllCategories(tmp),
      resourceName: "category",
      actionName: "save",
    });
  };
  useEffect(() => {
    const tmpItems = [...data];
    setCategory(tmpItems);
  }, [data, fulfilledTimeStamp]);

  return (
    <Modal
      header={<Header>Category</Header>}
      visible={true}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              onClick={() => {
                setModalName("");
              }}
              formAction="none"
            >
              Cancel
            </Button>
            <Button
              formAction="submit"
              onClick={handleSubmit}
              variant="primary"
            >
              Save
            </Button>
          </SpaceBetween>
        </Box>
      }
      size="large"
      onDismiss={() => {
        setModalName("");
      }}
    >
      <SpaceBetween size={"m"}>
        <Divider></Divider>

        <div>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              handleSubmit();
            }}
          >
            <Form>
              <AttributeEditor
                onAddButtonClick={() =>
                  setCategory([
                    ...category,
                    {
                      id: "",
                      name: "",
                      description: "",
                      deleted: false,
                      stateRequired: false,
                      expirationRequired: false,
                    },
                  ])
                }
                onRemoveButtonClick={({ detail: { itemIndex } }) => {
                  const tmp = [...category];
                  const item = { ...tmp[itemIndex] };
                  tmp[itemIndex] = {
                    id: item.id,
                    name: item.name,
                    description: item.description,
                    deleted: true,
                    stateRequired: false,
                    expirationRequired: false,
                  };
                  setCategory(tmp);
                }}
                items={category.filter((item) => item.deleted !== true)}
                addButtonText="Add Category"
                definition={[
                  {
                    label: "Name",
                    control: (item, itemIndex: number) => (
                      <Input
                        value={item.name}
                        onChange={(changeDetails) => {
                          const tmp = [...category];
                          tmp[itemIndex] = {
                            id: item.id,
                            name: changeDetails.detail.value,
                            description: item.description,
                            deleted: item.deleted,
                            stateRequired: false,
                            expirationRequired: false,
                          };
                          setCategory(tmp);
                        }}
                        placeholder="Enter Type"
                      />
                    ),
                  },
                ]}
              />
            </Form>
          </form>
        </div>
      </SpaceBetween>
    </Modal>
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(CategoryModal);
