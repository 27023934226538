// @mui
import {
  Backdrop,
  Box,
  CircularProgress,
  CircularProgressProps,
} from "@mui/material";

// ----------------------------------------------------------------------

interface Props extends CircularProgressProps {
  isOpen: boolean;
}

export default function LoadingScreen({ isOpen, ...other }: Props) {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isOpen}
    >
      <Box sx={{ position: "relative" }}>
        <CircularProgress
          size={60}
          {...other}
          sx={{
            position: "absolute",
            top: -24,
            left: -48,
            zIndex: 1,
          }}
        />
      </Box>
    </Backdrop>
  );
}
