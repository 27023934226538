import {
  Box,
  Button,
  ButtonDropdown,
  FormField,
  Header,
  SpaceBetween,
  Table,
  BreadcrumbGroup,
} from "@cloudscape-design/components";
import { useGetAllExpiringDocumentsQuery } from "../redux/api/reporting/reporting";
import ChangeTimeZone from "../components/Timezone";
import { useState } from "react";
import { HOST_API } from "../config";

export default function ExpiringDocuments() {
  const [days, setDays] = useState<string>("180");
  const { data = [] } = useGetAllExpiringDocumentsQuery({
    days: days ?? "180",
  });
  return (
    <>
      <BreadcrumbGroup
        items={[
          { text: "Reports", href: "/reports" },
          { text: "Expiring Documents", href: "#" },
        ]}
        ariaLabel="Breadcrumbs"
      />
      <Table
        columnDefinitions={[
          {
            id: "DocumentName",
            header: "Document Name",
            cell: (item) => item.document?.name,
          },
          {
            id: "expirationDate",
            header: "Expiration Date",
            cell: (item) =>
              ChangeTimeZone(item.document?.expirationDate) ?? "-",
          },
          {
            id: "createdDate",
            header: "Created Date",
            cell: (item) => ChangeTimeZone(item.document?.createdDate) ?? "-",
          },
          {
            id: "category",
            header: "Category",
            cell: (item) => item.document?.category?.name ?? "-",
          },
          {
            id: "lastName",
            header: "Last Name",
            cell: (item) => item.provider.lastName,
            sortingField: "alt",
          },
          {
            id: "firstname",
            header: "First name",
            cell: (item) => item.provider.firstName,
            sortingField: "name",
            isRowHeader: true,
          },
          {
            id: "email",
            header: "Email",
            cell: (item) => item.provider.email,
          },
          {
            id: "npi",
            header: "NPI Number",
            cell: (item) => item.provider.npiNumber,
          },
          {
            id: "licenseType",
            header: "License Type",
            cell: (item) => item.provider?.licenseType?.name,
          },
          {
            id: "facility",
            header: "Facility",
            cell: (item) => item.provider?.facility?.name,
          },
        ]}
        items={data}
        loadingText="Loading resources"
        sortingDisabled
        stickyHeader
        filter={
          <FormField
            label="Date Range"
            description="Select the desired date range to filter expiring documents and focus on specific timeframes."
          >
            <ButtonDropdown
              onItemClick={({ detail }) => {
                setDays(detail.id);
              }}
              items={[
                { text: "Last 180 days", id: "180", disabled: false },
                { text: "Last 120 days", id: "120" },
                { text: "Last 90 days", id: "90" },
                {
                  text: "Last 30 days",
                  id: "30",
                },
              ]}
            >
              {`Last ${days} days`}
            </ButtonDropdown>
          </FormField>
        }
        empty={
          <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
            <SpaceBetween size="m">
              <b>No expired documents</b>
            </SpaceBetween>
          </Box>
        }
        header={
          <Header
            description="A detailed report on documents approaching their expiration date."
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  download
                  href={`${HOST_API}/api/reporting/documents/expiring?format=csv`}
                >
                  Download CSV
                </Button>
              </SpaceBetween>
            }
          >
            Expiring Documents Report
          </Header>
        }
      />
    </>
  );
}
