import { useDispatch } from "react-redux";
import {
  dismissFlashBarItem,
  updateOrAddFlashBarItem,
} from "../redux/UI/actions";
import { NotificationProps } from "../redux/UI/types";

const AutoDismissNotificationInterval = 30000;

export default function useNotify() {
  const dispatch = useDispatch();
  const notifyFailed = ({ name, action, id, content }: NotificationProps) => {
    const actionCreator = updateOrAddFlashBarItem({
      type: "error",
      dismissible: true,
      dismissLabel: "Dismiss message",
      header: content ? `${action}` : `Failed to ${action ?? "Update"} ${name}`,
      content:
        content ??
        "Your request couldn’t be processed because of an issue with the server. Please contact support",
      id: id,
    });
    dispatch(actionCreator);
    //create a delay to remove the message
    setTimeout(() => {
      dispatch(dismissFlashBarItem(id));
    }, AutoDismissNotificationInterval);
  };
  const notifySucess = ({ name, action, id }: NotificationProps) => {
    const content = `Successfully ${action ?? "Updated"} ${name}.`;
    dispatch(
      updateOrAddFlashBarItem({
        type: "success",
        dismissible: true,
        dismissLabel: "Dismiss message",
        content,
        id: id,
      })
    );
    //create a delay to remove the message
    setTimeout(() => {
      dispatch(dismissFlashBarItem(id));
    }, AutoDismissNotificationInterval);
  };
  const notifyInProgress = ({ name, action, id }: NotificationProps) => {
    const content = `${action ?? "Updating"} ${name}...`;
    const actionCreator = updateOrAddFlashBarItem({
      loading: true,
      type: "info",
      dismissible: false,
      content,
      id: id,
    });
    dispatch(actionCreator);
  };
  return { notifyFailed, notifyInProgress, notifySucess };
}
