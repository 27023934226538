import {
  Button,
  Header,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import Grid from "@mui/material/Grid";
import { useEffect } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../../redux/store";
import { setModalName } from "../../../../redux/UI/actions";
import {
  useGetAllCategoryGroupQuery,
  useGetCategoryQuery,
  useUpdateCategoryMutation,
} from "../../../../redux/api/managecategory/managecategory";
import { find } from "lodash";
import { FormProvider, useForm } from "react-hook-form";
import RHFCheckBox from "../../../../components/RHF/RHFCheckBox";
import RHFSelect from "../../../../components/RHF/RHFSelect";
import RHFTextField from "../../../../components/RHF/RHFTextField";
import RHFTextArea from "../../../../components/RHF/RHFTextArea";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import LoadingScreen from "../../../../components/LoadingScreen";

interface PropsFromDispatch {
  openModalName: typeof setModalName;
}

type FormInputType = {
  name: string;
  description: string;
  stateRequired?: boolean;
  expirationRequired: boolean;
  categoryGroup: string;
};

function CategoryEditModal({ openModalName }: PropsFromDispatch) {
  const { managecategoryId } = useParams();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const { data: category, isLoading } = useGetCategoryQuery(
    !!managecategoryId
      ? {
          categoryId: managecategoryId,
        }
      : skipToken,
  );
  const { data = [], fulfilledTimeStamp } = useGetAllCategoryGroupQuery();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("category.edit") ?? false),
  );
  const categoryGroup = data.map((group) => {
    return { label: group.name, value: group.id };
  });
  const methods = useForm<FormInputType>();
  const onSubmit = async (data: FormInputType) => {
    if (category && categoryGroup) {
      await notifyWrapper({
        promise: updateCategory({
          id: category?.id,
          name: data.name,
          description: data.description,
          stateRequired: data.stateRequired,
          expirationRequired: data.expirationRequired,
          categoryGroup: {
            id: data.categoryGroup,
          },
        }),
        resourceName: "category",
        actionName: "update",
      });
      openModalName("");
    }
  };
  useEffect(() => {
    if (category) {
      methods.setValue("name", category.name);
      methods.setValue("description", category.description);
      methods.setValue("stateRequired", category.stateRequired);
      methods.setValue("expirationRequired", category.expirationRequired);
      if (category.categoryGroup && !!category.categoryGroup?.id) {
        const selectedCategoryGroup = find(data, {
          id: category.categoryGroup.id,
        });
        if (selectedCategoryGroup && selectedCategoryGroup.id)
          methods.setValue("categoryGroup", selectedCategoryGroup.id);
      }
    }
  }, [category]);

  const [updateCategory] = useUpdateCategoryMutation();

  return (
    <Modal
      visible={true}
      size={"large"}
      header={<Header variant="h2">Edit Category</Header>}
      onDismiss={() => {
        openModalName("");
      }}
    >
      <LoadingScreen isOpen={isLoading} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <SpaceBetween direction="vertical" size="l">
            <RHFTextField
              label="Name"
              name="name"
              stretch
              rules={{ required: "This field is required" }}
              readOnly={!hasPermission}
            />

            <RHFTextArea
              label="Description"
              name="description"
              stretch
              disabled={!hasPermission}
            />
            <RHFSelect
              name="categoryGroup"
              label="Category Group"
              stretch={true}
              options={categoryGroup}
              rules={{ required: "This field is required" }}
              disabled={!hasPermission}
            />
            <RHFCheckBox
              label="State Required"
              name="stateRequired"
              disabled={!hasPermission}
            />
            <RHFCheckBox
              label="Expiration Required"
              name="expirationRequired"
              disabled={!hasPermission}
            />

            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  onClick={() => {
                    openModalName("");
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button disabled={!hasPermission} variant="primary">
                  Save
                </Button>
              </Grid>
            </Grid>
          </SpaceBetween>
        </form>
      </FormProvider>
    </Modal>
  );
}
const mapDispatchToProps = {
  openModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(CategoryEditModal);
