import {
  Box,
  BreadcrumbGroup,
  Button,
  ColumnLayout,
  Container,
  Form,
  Header,
  Pagination,
  SpaceBetween,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Label from "../../../components/Label";
import {
  useDeleteGroupsMutation,
  useGetCategoryGroupQuery,
} from "../../../redux/api/managecategory/managecategory";
import { setModalName } from "../../../redux/UI/actions";
import { GROUP_EDIT_MODAL } from "../../../helpers/constants";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../redux/store";
import InnerAppLayout from "../../../components/InnerAppLayout";
import DeleteAlertModal from "../../../components/Modal/delete-alert-modal";
import useAsyncNotifyWrapper from "../../../hooks/useAsyncNotifyWrapper";
import { useLocalStorage } from "../../../common/localStorage";
import { useCollection } from "@cloudscape-design/collection-hooks";
import {
  CATEGORY_CONTENT_DISPLAY_OPTIONS,
  CATEGORY_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../../../common/table-config";
import { TableEmptyState } from "../../../common/common-components";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import LoadingScreen from "../../../components/LoadingScreen";

interface PropsFromDispatch {
  openModalName: typeof setModalName;
}

function GroupsEditForm({ openModalName }: PropsFromDispatch) {
  const { groupId } = useParams();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const {
    data: group,
    fulfilledTimeStamp,
    isLoading,
  } = useGetCategoryGroupQuery(
    !!groupId
      ? {
          id: groupId,
        }
      : skipToken,
  );
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-GroupMembers-Table-Preferences",
    CATEGORY_TABLE_DEFAULT_PREFERENCES,
  );
  const { items, collectionProps, paginationProps } = useCollection(
    group?.categoryList ?? [],
    {
      propertyFiltering: {
        filteringProperties: [],
        empty: <TableEmptyState resourceName="Group Members" />,
      },
    },
  );
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("categroy.edit") ?? false),
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("category.delete") ?? false),
  );
  const [deleteGroups] = useDeleteGroupsMutation();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);

  useEffect(() => {
    if (group) {
      setGroupName(group.name);
      setDescription(group.description);
      setCreatedTime(group.createdDate);
    }
  }, [groupId, group, fulfilledTimeStamp]);

  const [groupName, setGroupName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [createdTime, setCreatedTime] = useState<string>("");

  const tableProps: TableProps = {
    items,
    pagination: <Pagination {...paginationProps} />,
    preferences: (
      <TablePreferences
        preferences={tablePreferences}
        setPreferences={(preferences) => {
          setTablePreferences(preferences);
        }}
        contentDisplayOptions={CATEGORY_CONTENT_DISPLAY_OPTIONS}
      />
    ),
    columnDisplay: tablePreferences?.contentDisplay,
    wrapLines: tablePreferences?.wrapLines,
    stripedRows: tablePreferences?.stripedRows,
    contentDensity: tablePreferences?.contentDensity,
    stickyColumns: tablePreferences?.stickyColumns,
    ...collectionProps,
    variant: "container",
    columnDefinitions: [
      {
        id: "id",
        header: "id",
        cell: (item) => <span>{item.id}</span>,
      },
      {
        id: "name",
        header: "Name",
        cell: (item) => <span>{item.name}</span>,
      },
      {
        id: "description",
        header: "Description",
        cell: (item) => <span>{item.description}</span>,
      },
      {
        id: "stateRequired",
        header: "State Required",
        cell: (item) => <span>{item.stateRequired ? "Yes" : "No"}</span>,
      },
      {
        id: "expirationRequired",
        header: "Expiration Required",
        cell: (e) => (e.expirationRequired ? "Yes" : "No"),
      },
    ],
    loadingText: "Loading resources",
    // trackBy: (item) => item.id,
    empty: (
      <Box textAlign="center" color="inherit">
        <b>No Categories</b>
        <Box padding={{ bottom: "s" }} variant="p" color="inherit">
          No category associated with this group.
        </Box>
      </Box>
    ),
  };

  return (
    <div>
      <LoadingScreen isOpen={isLoading} />
      <InnerAppLayout
        breadcrumbGroup={
          <BreadcrumbGroup
            items={[
              {
                text: "Manage category",
                href: "/managecategory?activeTab=groups",
              },
              {
                text: groupName,
                href: "#",
              },
            ]}
          />
        }
        content={
          <Form>
            <SpaceBetween direction="vertical" size="l">
              <Header
                variant="h1"
                actions={
                  <Button
                    disabled={!hasDeletePermission}
                    variant="normal"
                    onClick={() => {
                      OpenDeleteModal(true);
                    }}
                  >
                    Delete
                  </Button>
                }
              >
                {groupName}
              </Header>
              <Container
                header={
                  <Header
                    variant="h3"
                    actions={
                      <Button
                        disabled={!hasPermission}
                        onClick={() => {
                          if (groupId) openModalName(GROUP_EDIT_MODAL);
                        }}
                      >
                        Edit
                      </Button>
                    }
                  >
                    Group Information
                  </Header>
                }
              >
                <ColumnLayout columns={3} variant="default">
                  <Label label="Group Name" value={groupName} />
                  <Label label="Description" value={description} />
                  <Label label="Created Time" value={createdTime} />
                </ColumnLayout>
              </Container>
              <Container
                header={
                  <Header
                    variant="h3"
                    counter={`(${group?.categoryList.length})`}
                    actions={
                      <div>
                        <span className="awsui-util-action-stripe-group">
                          <Button
                            variant="normal"
                            iconName="refresh"
                            onClick={() => {
                              console.log("clicked refresh");
                            }}
                          ></Button>
                        </span>
                      </div>
                    }
                  >
                    Group Members
                  </Header>
                }
              >
                <Table {...tableProps} />
              </Container>
            </SpaceBetween>
          </Form>
        }
      />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (groupId) {
            await notifyWrapper({
              promise: deleteGroups({ groupsId: groupId }),
              resourceName: "group",
              actionName: "delete",
            });
          }
          OpenDeleteModal(false);
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={"Delete group"}
        content={
          <>
            <Box>Group Name</Box>
            <Box variant="awsui-key-label">{groupName}</Box>
          </>
        }
        description={"Are you sure you want to delete this group?"}
      />
    </div>
  );
}

const mapDispatchToProps = {
  openModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(GroupsEditForm);
