import {
    BreadcrumbGroup, Button, Container, Form, Header, SpaceBetween,
} from "@cloudscape-design/components";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useAsyncNotifyWrapper from "../../../hooks/useAsyncNotifyWrapper";
import InnerAppLayout from "../../../components/InnerAppLayout";
import { Divider } from "semantic-ui-react";
import FormSection from "../../../components/FormSection";
import RHFTextField from "../../../components/RHF/RHFTextField";
import RHFTextArea from "../../../components/RHF/RHFTextArea";
import { PrivilegeType } from "../../../redux/api/privileges/types";
import { useCreatePrivilegeTypeMutation } from "../../../redux/api/privileges/privileges";

export default function AddPrivilegeType() {
    const methods = useForm<PrivilegeType>();
    const navigate = useNavigate();
    const { notifyWrapper } = useAsyncNotifyWrapper();
    const [addPrivilegeType] = useCreatePrivilegeTypeMutation();
    const onSubmit = async (data: PrivilegeType) => {
        await notifyWrapper({
            promise: addPrivilegeType({
                ...data,
            }),
            resourceName: "privilegeType",
            actionName: "create",
        });
        navigate(-1);
    }

    return (
        <InnerAppLayout
            breadcrumbGroup={
                <BreadcrumbGroup
                    items={[
                        { text: "Privilege Type", href: `/privilegeType` },

                        { text: `Add privilege Type`, href: "#" },
                    ]}
                />
            }
            content={
                <Container
                    header={
                        <SpaceBetween size="xxs">
                            <Header>Privilege Types</Header>
                            <Divider></Divider>
                        </SpaceBetween>
                    }
                >
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                            <Form
                                actions={
                                    <SpaceBetween direction="horizontal" size="xs">
                                        <Button
                                            onClick={() => {
                                                navigate("/privilegetype");
                                            }}
                                            formAction="none"
                                        >
                                            Cancel
                                        </Button>
                                        <Button formAction="submit" variant="primary">
                                            Submit
                                        </Button>
                                    </SpaceBetween>
                                }
                            >
                                <FormSection
                                    gridDefinition={[
                                        { colspan: { default: 12, s: 2 } },
                                        { colspan: { default: 12, s: 9 } },
                                    ]}
                                >
                                    <RHFTextField
                                        label="Name"
                                        name="name"
                                        stretch={false}
                                        rules={{ required: "This field is required" }}
                                    />
                                    <RHFTextArea
                                        label="Description"
                                        name="description"
                                        stretch={false}
                                    />

                                </FormSection>
                            </Form>
                        </form>
                    </FormProvider>
                </Container>
            }
        />
    );
}
