import {
  Button,
  Form,
  Header,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import { useEffect } from "react";
import RHFTextField from "../../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import FormSection from "../../../components/FormSection";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetContractTypeQuery,
  useUpdateContractTypeMutation,
} from "../../../redux/api/contracts/contract";
import { setModalName } from "../../../redux/UI/actions";
import { connect } from "react-redux";
import RHFTextArea from "../../../components/RHF/RHFTextArea";
import useAsyncNotifyWrapper from "../../../hooks/useAsyncNotifyWrapper";
import { skipToken } from "@reduxjs/toolkit/dist/query";

interface PropsFromDispatch {
  openModalName: typeof setModalName;
}

type FormInputType = {
  type: string;
  description: string;
};

function EditContractType({ openModalName }: PropsFromDispatch) {
  const { id } = useParams();
  const { data: contractType } = useGetContractTypeQuery(!!id?{
    contractTypeId: id ,
  }:skipToken);
  
  const navigate = useNavigate();

  // useIsLoading(isLoading);
  const [updateContractType] = useUpdateContractTypeMutation();
  const methods = useForm<FormInputType>();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const onSubmit = async (data: FormInputType) => {
    if (contractType) {
      await notifyWrapper({
        promise: updateContractType({
          id: contractType?.id,
          type: data?.type,
        }),
        resourceName: "contract type",
        actionName: "update",
      });
    }
    openModalName("");
  };

  useEffect(() => {
    if (contractType) {
      methods.setValue("type", contractType.type);
      methods.setValue("description", contractType.description);
    }
  }, [contractType]);

  return (
    <Modal
      visible={true}
      size="medium"
      header={<Header variant="h2">Edit Contract Type</Header>}
      onDismiss={() => {
        openModalName("");
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  onClick={(details) => {
                    openModalName("");
                  }}
                  formAction="none"
                >
                  Cancel
                </Button>
                <Button formAction="submit" variant="primary">
                  Submit
                </Button>
              </SpaceBetween>
            }
          >
            <FormSection columns={1}>
              <RHFTextField
                label="Type"
                name="type"
                stretch
                rules={{ required: "This field is required" }}
              />
              <RHFTextArea label="Description" name="description" stretch />
            </FormSection>
          </Form>
        </form>
      </FormProvider>
    </Modal>
  );
}
const mapDispatchToProps = {
  openModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(EditContractType);
