import { Provider } from "../api/provider/types";

export enum ProviderActionTypes {
  SELECT_PROVIDER = "provider/select-provider",
  SET_ACTIVE_TAB_ID = "provider/set-active-tab-id",
}

export interface ProviderState {
  selectedProviders: Provider[];
  activeTabId: string;
}
