import {
  Box,
  BreadcrumbGroup,
  Button,
  Container,
  Form,
  FormField,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import InnerAppLayout from "../../../components/InnerAppLayout";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import RHFTextField from "../../../components/RHF/RHFTextField";
import RHFTextArea from "../../../components/RHF/RHFTextArea";
import RHFSelect from "../../../components/RHF/RHFSelect";
import { useGetAllCategoriesQuery } from "../../../redux/api/managecategory/managecategory";
import {
  useCreateDigitalFormMutation,
  useGetAllDigitalFormTypesQuery,
  useUploadDigitalFormTemplateMutation,
} from "../../../redux/api/digitalform/digitalforms";
import { useNavigate } from "react-router";
import { Divider } from "semantic-ui-react";
import { connect, useSelector } from "react-redux";
import { ApplicationState } from "../../../redux/store";
import FormSection from "../../../components/FormSection";
import { v4 as uuidv4 } from "uuid";
import useNotify from "../../../hooks/useNotify";
import RHFFloatingSelect from "../../../components/RHF/RHFFloatingSelect";
import { setModalName } from "../../../redux/UI/actions";
import { DigitalFormCreateRequest } from "../../../redux/api/digitalform/types";
import { DIGITALFORMTYPE_MODAL } from "../../../helpers/constants";
import UploadNewFile from "../../../components/UploadNewFile";
import { useTranslation } from "react-i18next";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}
function AddDigitalForm({ setModalName }: PropsFromDispatch) {
  const { t } = useTranslation();
  const methods = useForm<DigitalFormCreateRequest>();
  const navigate = useNavigate();
  const [uploadFile, setUploadFile] = useState<File>();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("templates.edit") ?? false),
  );
  const { data: digitalFormType = [] } = useGetAllDigitalFormTypesQuery();
  const [uploadDigitalFormTemplate] = useUploadDigitalFormTemplateMutation();
  const { notifyInProgress, notifySucess, notifyFailed } = useNotify();

  const onSubmit = async (data: DigitalFormCreateRequest) => {
    const notificationId = uuidv4();
    notifyInProgress({
      name: "Ready Form",
      action: "creating",
      id: notificationId,
    });
    await createDigitalForm({
      name: data.name,
      description: data.description,
      digitalFormTypeId: data.digitalFormTypeId,
      categoryId: data.categoryId,
      disabled: data.disabled,
      tags: [],
    })
      .unwrap()
      .then(async (digitalFormResponse) => {
        if (uploadFile) {
          await uploadDigitalFormTemplate({
            id: digitalFormResponse.id,
            file: uploadFile,
          })
            .unwrap()
            .then((attachmentResponse) => {
              notifySucess({
                name: "Ready Form",
                action: "created",
                id: notificationId,
              });
            })
            .catch((error) => {
              // console.log({ error });
              if (error.status < 500 && error.status >= 400) {
                notifyFailed({
                  name: "Ready Form",
                  action: error.data.errorMessage,
                  content: error.data.errorDescription,
                  id: notificationId,
                });
              } else
                notifyFailed({
                  name: "Ready Form",
                  action: "create",
                  id: notificationId,
                });
            });
        } else
          notifySucess({
            name: "Ready Form",
            action: "created",
            id: notificationId,
          });
      })
      .catch((error) => {
        // console.log({ error });
        if (error.status < 500 && error.status >= 400) {
          notifyFailed({
            name: "Ready Form",
            action: error.data.errorMessage,
            content: error.data.errorDescription,
            id: notificationId,
          });
        } else
          notifyFailed({
            name: "Ready Form",
            action: "create",
            id: notificationId,
          });
      });

    navigate(`/digitalforms`);
  };

  const { data: categories = [] } = useGetAllCategoriesQuery();

  const [createDigitalForm] = useCreateDigitalFormMutation();

  return (
    <InnerAppLayout
      breadcrumbGroup={
        <BreadcrumbGroup
          items={[
            {
              text: `${t("readyForms.createReadyForm.header.breadcrumb.text")}`,
              href: `/digitalforms`,
            },
            {
              text: `${t("readyForms.createReadyForm.header.breadcrumb.currentPage")}`,
              href: "#",
            },
          ]}
        />
      }
      content={
        <Container
          header={
            <SpaceBetween size="xxs">
              <Header>{t("readyForms.createReadyForm.header.label")}</Header>
              <Divider></Divider>
            </SpaceBetween>
          }
        >
          <Box padding={{ right: "xxxl" }} margin={{ right: "xxxl" }}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Form
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        onClick={() => {
                          navigate(-1);
                        }}
                        formAction="none"
                      >
                        {t("readyForms.createReadyForm.header.actions.cancel")}
                      </Button>
                      <Button
                        disabled={!hasPermission}
                        formAction="submit"
                        variant="primary"
                      >
                        {t("readyForms.createReadyForm.header.actions.submit")}
                      </Button>
                    </SpaceBetween>
                  }
                >
                  <FormSection columns={1}>
                    <RHFTextField
                      label={t("readyForms.createReadyForm.header.fields.name")}
                      name="name"
                      stretch={false}
                      rules={{ required: "This field is required" }}
                    />

                    <RHFFloatingSelect
                      name="digitalFormTypeId"
                      label={t(
                        "readyForms.createReadyForm.header.fields.type.label",
                      )}
                      options={digitalFormType.map((type) => ({
                        label: type.type,
                        value: "" + type.id,
                      }))}
                      rules={{ required: "This field is required" }}
                      stretch={false}
                      action={{
                        label: `${t("readyForms.createReadyForm.header.fields.type.actions.modify")}`,
                        onClick: () => {
                          setModalName(DIGITALFORMTYPE_MODAL);
                        },
                      }}
                    />
                    <FormField
                      label={t(
                        "readyForms.createReadyForm.header.fields.uploadFile",
                      )}
                    >
                      <UploadNewFile
                        file={uploadFile}
                        onFileChange={(uploadFile?: File) => {
                          setUploadFile(uploadFile);
                        }}
                      />
                    </FormField>
                    <FormSection
                      expandedSection
                      header={
                        <Box fontSize="heading-s" fontWeight="bold">
                          {t("readyForms.createReadyForm.header.headerText")}
                        </Box>
                      }
                    >
                      <RHFTextArea
                        label={t(
                          "readyForms.createReadyForm.header.fields.notes",
                        )}
                        name="description"
                        stretch
                      />
                    </FormSection>
                  </FormSection>
                </Form>
              </form>
            </FormProvider>
          </Box>
        </Container>
      }
    />
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(AddDigitalForm);
