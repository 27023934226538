import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import {
  Button,
  Container,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import WebViewer, { Core, WebViewerInstance } from "@pdftron/webviewer";
import { disabledElements } from "../../helpers/constants";
import useIsLoading from "../../hooks/useIsLoading";
import { selectValueFromData } from "../../selectors/signature-request";
import { useGetProviderQuery } from "../../redux/api/provider/provider";
import InnerAppLayout from "../../components/InnerAppLayout";
import { FieldType } from "../../template-designer/types";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import {
  RecipientType,
  SignatureRequest,
} from "../../redux/api/signaturerequest/types";
import { defaultFont } from "../../template-designer/temaplet-designer";
import { File_URL } from "../../config";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  SignatureRequestFieldFormatter,
  convertRecipentToOptionDefinition,
  setAnnotStrokeAndFillColor,
} from "./signature-request-field-formatter";
import { SignatureRequestTagsList } from "./signature-request-tags-list";
import { AnnotationColor } from "../types";
import { filterAnnotXMLByRecipientId } from "../../helpers/XMLHelper";

export type RequestSignatureProps = {
  headerText: string;
  fileKey: string;
  isLoading: boolean;
  addlRecipients: RecipientType[];
  primaryRecipient: RecipientType;
  subject: string | undefined;
  messageBody: string | undefined;
  addsignaturerequest: (
    primaryRecipient: RecipientType,
    addlRecipients: RecipientType[],
    signatureRequest: Pick<
      SignatureRequest,
      "ccRecipientEmailList" | "subject" | "message"
    >,
  ) => Promise<void>;
  savetosafe: (xfdfString: string, providerId: string) => Promise<void>;
};

const addBorder = true;

export function SignatureRequestStepTwo({
  fileKey,
  headerText,
  primaryRecipient,
  addlRecipients,
  subject,
  messageBody,
  isLoading,
  savetosafe,
  addsignaturerequest,
}: RequestSignatureProps) {
  const [instance, setInstance] = useState<WebViewerInstance | undefined>(
    undefined,
  );
  const [dropPoint, setDropPoint] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const [documentLoaded, setDocumentLoaded] = useState<boolean>(false);

  const viewer = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const [selectedRecipient, setSelectedRecipient] =
    useState<OptionDefinition | null>(null);

  const [selectedAnnots, setSelectedAnnots] = useState<
    Core.Annotations.FreeTextAnnotation[]
  >([]);

  const {
    data: provider,
    isLoading: isFetching,
    fulfilledTimeStamp,
  } = useGetProviderQuery(
    primaryRecipient?.provider?.id
      ? {
          providerId: primaryRecipient?.provider?.id,
        }
      : skipToken,
  );

  useEffect(() => {
    if (!!primaryRecipient) {
      setSelectedRecipient({
        ...convertRecipentToOptionDefinition(primaryRecipient),
        labelTag: "Primary",
      });
    }
  }, [primaryRecipient]);

  useIsLoading(isLoading || isFetching);

  useEffect(() => {
    if (viewer.current != null && !!fileKey)
      WebViewer(
        {
          path: "/webviewer",
          licenseKey: process.env.REACT_APP_APRYSE_LICENSE_KEY,
        },
        viewer.current,
      )
        .then((instance) => {
          instance.UI.disableElements(disabledElements);
          instance.UI.setToolbarGroup("toolbarGroup-View");
          setInstance(instance);

          const { documentViewer, annotationManager } = instance.Core;

          if ("localhost" === window.location.hostname) {
            instance.UI.loadDocument("/sample.pdf");
          } else {
            instance.UI.loadDocument(encodeURI(File_URL + fileKey), {
              withCredentials: true,
            });
          }

          instance.UI.addEventListener("dragover", dragOver);
          instance.UI.addEventListener("drop", (e) => {
            drop(e, instance);
          });

          annotationManager.addEventListener(
            "annotationSelected",
            (annotations: Core.Annotations.Annotation[], action) => {
              const selectedAnnots = annotationManager
                .getSelectedAnnotations()
                .map((selectedAnnots) => {
                  return selectedAnnots as Core.Annotations.FreeTextAnnotation;
                });
              setSelectedAnnots(selectedAnnots);
            },
          );

          documentViewer.addEventListener("documentLoaded", function () {
            instance.UI.setFitMode(instance.UI.FitMode.FitWidth);
          });
          documentViewer.addEventListener("annotationsLoaded", function () {
            setDocumentLoaded(true);
          });
        })
        .catch((error) => {
          console.log(error);
        });
  }, [fileKey]);

  const setRecipientOnAnnotation = ({
    textAnnot,
    option,
  }: {
    textAnnot: Core.Annotations.FreeTextAnnotation;
    option?: OptionDefinition;
  }) => {
    if (!option?.value) return;
    if (!instance) return;

    textAnnot.setCustomData("recipientId", option?.value);
    setAnnotStrokeAndFillColor({
      instance,
      textAnnot,
      recipients: [...addlRecipients, primaryRecipient],
    });
  };

  // adds FreeTextAnnotation
  const addFreeTextAnnotation = (
    point: { x: number; y: number },
    field: FieldType,
  ) => {
    if (!instance) return;

    const { documentViewer, Annotations } = instance.Core;
    const annotManager = documentViewer.getAnnotationManager();
    const doc = documentViewer.getDocument();
    const displayMode = documentViewer.getDisplayModeManager().getDisplayMode();
    const page = displayMode.getSelectedPages(point, point);
    const width = field.type === "bool" ? 16 : 250;
    const height = field.type === "bool" ? 16 : 50;

    if (!!point.x && page.first == null) {
      return; //don't add field to an invalid page location
    }

    const page_idx =
      page.first !== null ? page.first : documentViewer.getCurrentPage();
    const page_info = doc.getPageInfo(page_idx);
    const page_point = displayMode.windowToPage(point, page_idx);
    const zoom = documentViewer.getZoomLevel();

    var textAnnot = new Annotations.FreeTextAnnotation();
    textAnnot.PageNumber = page_idx;
    const rotation = documentViewer.getCompleteRotation(page_idx) * 90;
    textAnnot.Rotation = rotation;

    if (rotation === 270 || rotation === 90) {
      textAnnot.Width = height / zoom;
      textAnnot.Height = width / zoom;
    } else {
      textAnnot.Width = width / zoom;
      textAnnot.Height = height / zoom;
    }
    textAnnot.X = (page_point.x || page_info.width / 2) - textAnnot.Width / 2;
    textAnnot.Y = (page_point.y || page_info.height / 2) - textAnnot.Height / 2;

    textAnnot.setPadding(new instance.Core.Math.Rect(0, 0, 0, 0));

    //Free text annotation custom data
    textAnnot.setCustomData("type", field.type);
    textAnnot.setCustomData("name", field.label);
    textAnnot.setCustomData("key", field.key);
    textAnnot.setCustomData("isList", field.isList.toString());

    // selected recipeint data is added to annotation as customdata
    !!selectedRecipient &&
      setRecipientOnAnnotation({ textAnnot, option: selectedRecipient });

    if (field.isList) {
      try {
        const index = annotManager
          .getAnnotationsList()
          .filter(
            (annot) =>
              annot.getCustomData("key").toString() === field.key.toString(),
          ).length;

        textAnnot.setCustomData("index", index.toString());
        //Annote name
        textAnnot.setContents([field.label, index.toString()].join("_"));
      } catch (error) {
        console.log(error);
        textAnnot.setContents(field.label);
      }
    } else textAnnot.setContents(field.label);

    // set the type of annote
    textAnnot.Font = defaultFont.fontFamily; // customFont.fontFamily;
    textAnnot.FontSize = "" + defaultFont.fontSize + "pt"; //"" + customFont.fontSize + "pt";

    textAnnot.TextColor = new Annotations.Color(
      AnnotationColor.DefaultPlaceHolderTextColor.r,
      AnnotationColor.DefaultPlaceHolderTextColor.g,
      AnnotationColor.DefaultPlaceHolderTextColor.b,
      AnnotationColor.DefaultPlaceHolderTextColor.a,
    );
    setAnnotStrokeAndFillColor({
      instance,
      textAnnot,
      recipients: [...addlRecipients, primaryRecipient],
    });

    textAnnot.StrokeThickness = 1;
    textAnnot.LockedContents = true;
    textAnnot.disableRotationControl();

    annotManager.deselectAllAnnotations();
    annotManager.addAnnotation(textAnnot, { autoFocus: true });

    //To remove all the annotations that are not added by user, For example when the PDF is a Fillable PDF then default feilds conflicts with the custom Feilds,
    const annotsToDelete = annotManager
      .getAnnotationsList()
      .filter((annot) => !annot.getCustomData("type"));
    annotManager.deleteAnnotations(annotsToDelete, { force: true });
    annotManager.redrawAnnotation(textAnnot);
    annotManager.selectAnnotation(textAnnot); // select the recently added annotation
  }; //end add field

  // Auto map primary recipient data to the fields
  const convertFreeTextAnnotationsToFormFeilds = async () => {
    if (!instance) return;
    if (!documentLoaded) return;
    if (!primaryRecipient?.provider?.id) return;
    if (!provider) return;

    // fetch provider JSON data and display alert if there is an error

    const { Annotations, annotationManager: annotManager } = instance.Core;
    const fieldManager = annotManager.getFieldManager();

    const annotsToDelete: Core.Annotations.Annotation[] = [];
    const annotsToDraw: Core.Annotations.TextWidgetAnnotation[] = [];

    const freeTextAnnotationList: Core.Annotations.FreeTextAnnotation[] =
      annotManager
        .getAnnotationsList()
        .filter(
          (annotation) => annotation instanceof Annotations.FreeTextAnnotation,
        ) as Core.Annotations.FreeTextAnnotation[];

    freeTextAnnotationList.forEach((freeTextAnnotation) => {
      try {
        let widgetAnnotation: Core.Annotations.WidgetAnnotation | undefined;
        let formField: Core.Annotations.Forms.Field | undefined;

        const font = new Annotations.Font({
          name: freeTextAnnotation.Font,
          size: parseInt(freeTextAnnotation.FontSize),
          // this is the color of the text in widget
          fillColor: new Annotations.Color(
            AnnotationColor.DefaultTextColor.r,
            AnnotationColor.DefaultTextColor.g,
            AnnotationColor.DefaultTextColor.b,
            AnnotationColor.DefaultTextColor.a,
          ),
        });

        const field_name = [
          !!freeTextAnnotation.getCustomData("recipientId")
            ? freeTextAnnotation.getCustomData("recipientId")
            : primaryRecipient.id,
          freeTextAnnotation.getCustomData("key").replace(".", "_"),
          freeTextAnnotation.getCustomData("index")?.toString(),
        ]
          .filter((str) => str !== "")
          .join("_");

        // create a form field based on the type of annotation
        if (
          ["string", "date"].includes(freeTextAnnotation.getCustomData("type"))
        ) {
          formField = new Annotations.Forms.Field(field_name, {
            type: "Tx",
            value: selectValueFromData(provider, freeTextAnnotation),
            font: font,
          });
          formField.quadding = "Centered";
          widgetAnnotation = new Annotations.TextWidgetAnnotation(
            formField,
            {},
          );
        } else if (freeTextAnnotation.getCustomData("type") === "signature") {
          const signature_field_name = [
            !!freeTextAnnotation.getCustomData("recipientId")
              ? freeTextAnnotation.getCustomData("recipientId")
              : primaryRecipient.id,
            freeTextAnnotation.getCustomData("key").replace(".", "_"),
          ]
            .filter((str) => str !== "")
            .join("_");
          formField = new Annotations.Forms.Field(signature_field_name, {
            type: "Sig",
            font: font,
          });
          widgetAnnotation = new Annotations.SignatureWidgetAnnotation(
            formField,
            {
              appearance: "_DEFAULT",
              appearances: {
                _DEFAULT: {
                  Normal: {
                    data: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjEuMWMqnEsAAAANSURBVBhXY/j//z8DAAj8Av6IXwbgAAAAAElFTkSuQmCC",
                    offset: {
                      x: 100,
                      y: 100,
                    },
                  },
                },
              },
            },
          );
        } else if (freeTextAnnotation.getCustomData("type") === "bool") {
          formField = new Annotations.Forms.Field(field_name, {
            type: "Btn",
            font: font,
            value: selectValueFromData(provider, freeTextAnnotation),
          });

          widgetAnnotation = new Annotations.CheckButtonWidgetAnnotation(
            formField,
            {
              appearances: {
                Off: {},
                On: {},
              },
              captions: {
                Normal: "n", // Normal options are:  "4" = Tick  "l" = Circle "8" = Cross "u" = Diamond "n" = Square "H" = Star "" = Check
              },
            },
          );
        }
        if (!!widgetAnnotation && !!formField) {
          // set position
          widgetAnnotation.PageNumber = freeTextAnnotation.getPageNumber();
          widgetAnnotation.X = freeTextAnnotation.getX();
          widgetAnnotation.Y = freeTextAnnotation.getY();
          widgetAnnotation.rotation = freeTextAnnotation.Rotation;
          if (
            freeTextAnnotation.Rotation === 0 ||
            freeTextAnnotation.Rotation === 180
          ) {
            widgetAnnotation.Width = freeTextAnnotation.getWidth();
            widgetAnnotation.Height = freeTextAnnotation.getHeight();
          } else {
            widgetAnnotation.Width = freeTextAnnotation.getHeight();
            widgetAnnotation.Height = freeTextAnnotation.getWidth();
          }
          if (addBorder) {
            if (freeTextAnnotation.getCustomData("isRequired") === "true") {
              widgetAnnotation.border = new Annotations.Border({
                color: new Annotations.Color(
                  AnnotationColor.RequiredBorderColor.r,
                  AnnotationColor.RequiredBorderColor.g,
                  AnnotationColor.RequiredBorderColor.b,
                  AnnotationColor.RequiredBorderColor.a,
                ),
                width: 1,
                style: "solid",
              });
              widgetAnnotation.setCustomData("required", "true");
            } else {
              widgetAnnotation.border = new Annotations.Border({
                color: new Annotations.Color(
                  AnnotationColor.DefaultBorderColor.r,
                  AnnotationColor.DefaultBorderColor.g,
                  AnnotationColor.DefaultBorderColor.b,
                  AnnotationColor.DefaultBorderColor.a,
                ),
                width: 1,
                style: "solid",
              });
              widgetAnnotation.setCustomData("required", "");
            }
          }

          // add recipientId on widget annotation if it exists on freeTextAnnot
          if (!!freeTextAnnotation.getCustomData("recipientId")) {
            widgetAnnotation.setCustomData(
              "recipientId",
              freeTextAnnotation.getCustomData("recipientId"),
            );
            widgetAnnotation.Author =
              freeTextAnnotation.getCustomData("recipientId");
          }
          // delete original annotation
          annotsToDelete.push(freeTextAnnotation);

          // draw the annotation to viewer
          annotManager.addAnnotation(widgetAnnotation);
          annotManager.redrawAnnotation(widgetAnnotation);
          fieldManager.addField(formField);
          annotsToDraw.push(widgetAnnotation);
        }
      } catch (error) {
        console.error(error);
      }
    });

    // delete old annotations
    annotManager.deleteAnnotations(annotsToDelete, { force: true });

    annotsToDraw.forEach((annot) => {
      annot.setCustomData("type", "custom");
      annotManager.redrawAnnotation(annot);
    });

    // refresh viewer
    try {
      await annotManager.drawAnnotationsFromList(annotsToDraw);
    } catch (error) {
      console.log(error);
    }
  };

  const dragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    return false;
  };

  const drop = (
    e: React.DragEvent<HTMLDivElement>,
    instance: WebViewerInstance,
  ) => {
    const { documentViewer } = instance.Core;
    const scrollElement = documentViewer.getScrollViewElement();
    const scrollLeft = scrollElement.scrollLeft || 0;
    const scrollTop = scrollElement.scrollTop || 0;
    setDropPoint({ x: e.pageX + scrollLeft, y: e.pageY + scrollTop });
    e.preventDefault();
    return false;
  };

  const dragStart = (e: React.DragEvent<HTMLDivElement>) => {
    e.currentTarget.style.opacity = "0.5";
    const copy = e.currentTarget.cloneNode(true) as HTMLDivElement;
    copy.id = "form-build-drag-image-copy";
    copy.style.width = "250px";
    document.body.appendChild(copy);
    e.dataTransfer.setDragImage(copy, 125, 25);
    e.dataTransfer.setData("text", "form-build-drag-image-copy");
  };

  const dragEnd = (e: React.DragEvent<HTMLDivElement>, field: FieldType) => {
    console.log({ dropPoint, field });
    addFreeTextAnnotation(dropPoint, field);
    e.currentTarget.style.opacity = "1";
    document.body.removeChild(
      document.getElementById("form-build-drag-image-copy") as HTMLDivElement,
    );
    e.preventDefault();
  };

  //Apply provider fields
  useEffect(() => {
    !!documentLoaded &&
      !!provider &&
      !!primaryRecipient &&
      convertFreeTextAnnotationsToFormFeilds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentLoaded, primaryRecipient, fulfilledTimeStamp, provider]);

  const submitSignatureRequest = async () => {
    if (!instance) return;
    const { documentViewer } = instance.Core;
    const annotManager = documentViewer.getAnnotationManager();

    annotManager.deselectAllAnnotations();
    setSelectedAnnots([]);
    await convertFreeTextAnnotationsToFormFeilds();

    let xfdfString: string | undefined;
    try {
      xfdfString = await annotManager.exportAnnotations({
        widgets: true,
        fields: true,
        links: false,
        useDisplayAuthor: true,
        generateInlineAppearances: false,
      });
    } catch (error) {
      console.log(error);
    }
    if (!xfdfString || !primaryRecipient) return;

    const primaryRecipientRequest: RecipientType = {
      ...primaryRecipient,
      xfdfString: filterAnnotXMLByRecipientId(
        xfdfString,
        primaryRecipient?.id,
      ) as string,
    };

    let addlRecipientsRequest: RecipientType[] = addlRecipients?.filter(
      (recipient) =>
        !!recipient?.provider ||
        !!recipient?.member ||
        !!recipient?.emailAddress,
    );

    if (!!addlRecipientsRequest && addlRecipientsRequest.length > 0) {
      addlRecipientsRequest = addlRecipientsRequest
        .map((recipient) => {
          if (!recipient || !xfdfString || !recipient?.id) return undefined;
          const recipientXfdfString = filterAnnotXMLByRecipientId(
            xfdfString,
            recipient?.id,
          ) as string;
          console.log({ recipientXfdfString });
          return {
            ...recipient,
            xfdfString: recipientXfdfString,
          };
        })
        .filter((recipient) => recipient !== undefined) as RecipientType[];
    }

    console.log([primaryRecipientRequest, ...addlRecipientsRequest]);

    if (!!provider && !!provider.id) {
      addsignaturerequest(primaryRecipientRequest, addlRecipientsRequest, {
        ccRecipientEmailList: [],
        subject: subject,
        message: messageBody,
      });
    }
  };

  //TODO: disable headers when documentLoaded is false
  return (
    <InnerAppLayout
      breadcrumbGroup={
        <Header
          variant="h1"
          actions={
            <SpaceBetween size={"s"} direction="horizontal">
              <Button
                onClick={() => {
                  navigate(-1);
                }}
                formAction="none"
              >
                Cancel
              </Button>
              <Button
                onClick={async () => {
                  if (!instance) return;

                  const { Annotations, documentViewer } = instance.Core;
                  const annotManager = documentViewer.getAnnotationManager();

                  annotManager.deselectAllAnnotations();
                  setSelectedAnnots([]);
                  await convertFreeTextAnnotationsToFormFeilds();

                  const annotationsList = annotManager.getAnnotationsList();

                  annotationsList.forEach((annot) => {
                    const inputAnnot =
                      annot as Core.Annotations.WidgetAnnotation;
                    inputAnnot.border = new Annotations.Border({
                      ...inputAnnot.border,
                      width: 0,
                    });
                    annotManager.drawAnnotationsFromList(inputAnnot);
                    annotManager.redrawAnnotation(inputAnnot);
                  });

                  await instance.UI.downloadPdf({ flatten: true });
                }}
              >
                download
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  submitSignatureRequest();
                }}
              >
                Submit
              </Button>
            </SpaceBetween>
          }
        >
          {headerText}
        </Header>
      }
      gridDefinition={[
        { colspan: { default: 3, xl: 2 } },
        { colspan: { default: 9, xl: 10 } },
      ]}
      leftPanel={
        !selectedAnnots.length ? (
          <SignatureRequestTagsList
            selectedRecipient={selectedRecipient}
            setSelectedRecipient={setSelectedRecipient}
            onDragStart={dragStart}
            onDragEnd={dragEnd}
            primaryRecipient={primaryRecipient}
            addlRecipients={addlRecipients}
          />
        ) : (
          <SignatureRequestFieldFormatter
            instance={instance}
            selectedAnnots={selectedAnnots}
            setSelectedAnnots={setSelectedAnnots}
            primaryRecipient={primaryRecipient}
            addlRecipients={addlRecipients}
            setRecipientsOnAnnot={setRecipientOnAnnotation}
            setAnnotStrokeAndFillColor={setAnnotStrokeAndFillColor}
          />
        )
      }
      content={
        <Container fitHeight>
          <div ref={viewer} style={{ height: `calc(100vh - 176px)` }} />
        </Container>
      }
    />
  );
}
