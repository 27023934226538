import {
  Box,
  Button,
  ButtonDropdown,
  Header,
  Pagination,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import "./index.css";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLocalStorage } from "../common/localStorage";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableEmptyState } from "../common/common-components";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";
import StyledRouterLink from "../components/styled-route-link";
import DeleteAlertModal from "../components/Modal/delete-alert-modal";
import useAsyncNotifyWrapper from "../hooks/useAsyncNotifyWrapper";
import {
  useDeletePayerMutation,
  useGetAllPayerQuery,
} from "../redux/api/payers/payers";
import { Payers } from "../redux/api/payers/types";
import {
  PAYER_CONTENT_DISPLAY_OPTIONS,
  PAYER_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../common/table-config";
import LoadingScreen from "../components/LoadingScreen";

export default function PayersTable() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [deletePayer] = useDeletePayerMutation();
  const [selectedItem, setSelectedItem] = useState<Payers>();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Payers-Table-Preferences",
    PAYER_TABLE_DEFAULT_PREFERENCES,
  );
  const {
    data = [],
    isFetching,
    isLoading,
    refetch,
    fulfilledTimeStamp,
  } = useGetAllPayerQuery();
  const { collectionProps, paginationProps } = useCollection(data, {
    propertyFiltering: {
      filteringProperties: [],
      empty: <TableEmptyState resourceName="Payer" />,
    },
  });
  useEffect(() => {
    const temp = data.find((payers) => "" + payers.id === "" + id);
    if (temp) setSelectedItem(temp);
  }, [id, selectedItem]);

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("payer.edit") ?? false),
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("payer.delete") ?? false),
  );

  const tableProps: TableProps<Payers> = {
    header: (
      <Header
        variant="h2"
        counter={`(${data.length})`}
        actions={
          <div>
            <span className="awsui-util-action-stripe-group">
              <Button
                variant="normal"
                iconName="refresh"
                onClick={() => {
                  refetch();
                }}
              ></Button>
            </span>
            <span className="awsui-util-action-stripe-group">
              <ButtonDropdown
                expandableGroups
                disabled={!selectedItem}
                items={[
                  {
                    id: "edit_form",
                    text: hasPermission ? "Edit" : "View",
                  },
                  {
                    id: "delete_form",
                    text: "Delete",
                    disabled: !hasDeletePermission,
                  },
                ]}
                onItemClick={(itemClickDetails) => {
                  const { id } = itemClickDetails.detail;
                  if (id === "delete_form") {
                    if (selectedItem) OpenDeleteModal(true);
                  } else if (id === "edit_form") {
                    navigate(`/payers/${selectedItem?.id}`);
                  }
                }}
              >
                Actions
              </ButtonDropdown>
            </span>

            <span className="awsui-util-action-stripe-group">
              <Button
                onClick={() => {
                  navigate("addnew");
                }}
                variant="primary"
              >
                Add Payer
              </Button>
            </span>
          </div>
        }
      >
        Payer
      </Header>
    ),
    items: data,
    pagination: <Pagination {...paginationProps} />,
    preferences: (
      <TablePreferences
        title="Payer Table Preferences"
        preferences={tablePreferences}
        setPreferences={(preferences) => {
          setTablePreferences(preferences);
        }}
        contentDisplayOptions={PAYER_CONTENT_DISPLAY_OPTIONS}
      />
    ),
    columnDisplay: tablePreferences?.contentDisplay,
    wrapLines: tablePreferences?.wrapLines,
    stripedRows: tablePreferences?.stripedRows,
    contentDensity: tablePreferences?.contentDensity,
    stickyColumns: tablePreferences?.stickyColumns,
    ...collectionProps,
    variant: "container",
    selectionType: "single",
    loadingText: "Loading...",
    resizableColumns: false,
    stickyHeader: true,
    loading: isLoading || isFetching,
    empty: <p>No results to display.</p>,
    selectedItems: selectedItem ? [selectedItem] : [],
    onSelectionChange: ({ detail }) => {
      if (detail.selectedItems && detail.selectedItems.at(0))
        setSelectedItem(detail.selectedItems.at(0));
    },
    onRowClick: ({ detail }) => {
      if (detail.item) setSelectedItem(detail.item);
    },
    trackBy: (item) => "" + item.id,
    columnDefinitions: [
      {
        id: "id",
        header: <div>ID</div>,
        cell: (item) => <span>{item.id}</span>,
      },
      {
        id: "name",
        header: <div>Name</div>,
        cell: (item) => (
          <StyledRouterLink
            className={hasPermission ? "edit_link" : "normal"}
            to={hasPermission ? `/payers/${item.id}` : `#`}
            label={item?.name}
          />
        ),
      },
      {
        id: "payerId",
        header: "Payer ID",
        cell: (item) => <div>{item?.payerId ?? "-"}</div>,
      },
      {
        id: "addressLane1",
        header: "Address Lane 1",
        cell: (item) => <div> {item?.payerAddress?.addressLane1 ?? "-"} </div>,
      },
      {
        id: "addressLane2",
        header: "Address Lane 2",
        cell: (item) => <div> {item?.payerAddress?.addressLane2 ?? "-"} </div>,
      },
      {
        id: "city",
        header: "City",
        cell: (item) => <div> {item?.payerAddress?.city ?? "-"} </div>,
      },
      {
        id: "state",
        header: "State",
        cell: (item) => <div> {item?.payerAddress?.state ?? "-"} </div>,
      },
      {
        id: "zipcode",
        header: "Zip Code",
        cell: (item) => <div> {item?.payerAddress?.zipcode ?? "-"} </div>,
      },
    ],
  };
  return (
    <div style={{ paddingTop: "25px" }}>
      <LoadingScreen isOpen={isLoading} />
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedItem?.id) {
            OpenDeleteModal(false);
            await notifyWrapper({
              promise: deletePayer({
                payerId: selectedItem?.id,
              }),
              resourceName: "payer",
              actionName: "delete",
            });
          }
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={"Delete payer"}
        content={
          <>
            <Box>Name</Box>
            <Box variant="awsui-key-label">{selectedItem?.name}</Box>
          </>
        }
        description={"Are you sure you want to delete this payer?"}
      />
    </div>
  );
}
