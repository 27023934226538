import { Header, Table } from "@cloudscape-design/components";
import { MemberType } from "../redux/api/manageadmin/types";

export function AssociatedAdmins({
  admins,
}: {
  admins: readonly MemberType[];
}) {
  return (
    <Table
      header={<Header counter={`(${admins.length})`}>Associated admins</Header>}
      items={admins}
      columnDefinitions={[
        {
          id: "id",
          header: <div>id</div>,
          cell: (item) => <span>{item.id}</span>,
        },
        {
          id: "name",
          header: <div>Member Name</div>,
          cell: (item) => <span>{item?.firstName + " " + item?.lastName}</span>,
        },
        {
          id: "email",
          header: <div>Email</div>,
          cell: (item) => <span>{item.email}</span>,
        },
      ]}
      visibleColumns={["email", "name"]}
    />
  );
}
