import {
  Box,
  Button,
  Link,
  Modal,
  SpaceBetween,
  Table,
} from "@cloudscape-design/components";
import { useEffect, useMemo, useState } from "react";
import {
  useGetAllCredentialTemplateCategoriesQuery,
  useUpdateCredentialTemplateCategoriesMutation,
} from "../../redux/api/credentialpackages/credentialpackages";
import { CategoryType } from "../../redux/api/managecategory/types";
import { useGetAllCategoryQuery } from "../../redux/api/managecategory/managecategory";
import { useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";

type CredentailTemplateAddFormModalProps = {
  closeModal: VoidFunction;
  visible: boolean;
};
export default function CredentialTemplateAddCategoriesModal({
  visible,
  closeModal,
}: CredentailTemplateAddFormModalProps) {
  const { data: categories = [] } = useGetAllCategoryQuery();
  const [selectedCategories, setSelectedCategories] = useState<CategoryType[]>(
    [],
  );
  const { id: credentialTemplateId } = useParams();

  const { data: templateCategories = [] } =
    useGetAllCredentialTemplateCategoriesQuery(
      credentialTemplateId ? { credentialTemplateId } : skipToken,
    );

  const [updateCredentialTemplateCategories] =
    useUpdateCredentialTemplateCategoriesMutation();

  useEffect(() => {
    setSelectedCategories([]);
  }, [closeModal]);

  const templateCategoryIdList = useMemo(() => {
    return templateCategories.map((category) => category.id + "");
  }, [templateCategories]);

  return (
    <Modal
      onDismiss={closeModal}
      size="large"
      visible={visible}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button onClick={closeModal}>Cancel</Button>
            <Button
              variant="primary"
              onClick={() => {
                if (
                  !selectedCategories ||
                  !credentialTemplateId ||
                  selectedCategories?.length === 0
                ) {
                  closeModal();
                  return;
                }
                const categoryIds = selectedCategories.map(
                  (category) => category.id + "",
                );
                updateCredentialTemplateCategories({
                  id: credentialTemplateId,
                  categories: categoryIds,
                });
                closeModal();
              }}
            >
              Save
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Link Catgories"
    >
      <Table
        variant="embedded"
        columnDefinitions={[
          {
            id: "name",
            header: "Name",
            cell: (item) => <div>{item?.name || "-"}</div>,
            sortingField: "name",
            isRowHeader: true,
          },
        ]}
        isItemDisabled={(item) =>
          templateCategoryIdList?.includes(item?.id + "")
        }
        items={categories}
        selectionType="multi"
        selectedItems={selectedCategories ?? []}
        onSelectionChange={({ detail }) => {
          if (!!detail.selectedItems)
            setSelectedCategories(detail.selectedItems);
        }}
        onRowClick={({ detail }) => {
          const temp = selectedCategories;
          if (
            !detail.item ||
            templateCategoryIdList?.includes(detail.item.id + "")
          )
            return;
          if (temp?.includes(detail.item))
            setSelectedCategories(temp.filter((item) => item !== detail.item));
          else setSelectedCategories([detail.item, ...temp]);
        }}
        stickyHeader
        loadingText="Loading resources"
        sortingDisabled
      />
    </Modal>
  );
}
