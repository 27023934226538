import { MemberType, InvitationType } from "../api/manageadmin/types";

export enum ManageAdminActionTypes {
  SELECT_ACTIVE = "manageadmin/select-active",
  SELECT_INVITED = "manageadmin/select-invited",
}

export interface ManageAdminState {
  selectedActive: MemberType[];
  selectedInvited: InvitationType[];
}
