import { useState } from "react";
import {
  AppLayout,
  Box,
  Button,
  Checkbox,
  Container,
  FormField,
  Grid,
  Header,
  Input,
  Modal,
  SpaceBetween,
  StatusIndicator,
} from "@cloudscape-design/components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import MUIButtonCustomized from "../../components/MuiButtonCustomized";
import useIsLoading from "../../hooks/useIsLoading";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import axios from "../../context/axios";

type FormInputType = {
  password: string;
  confirmPassword: string;
};

function ResetPasswordForm() {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [error, setError] = useState<string>();

  const token = searchParams.get("token");
  const expires = searchParams.get("expires");
  const methods = useForm<FormInputType>();
  useIsLoading(isLoading);

  const [email, setEmail] = useState("");

  useEffect(() => {
    let temp = searchParams.get("email");
    if (!!token && !!temp) {
      setEmail(temp);
    }
  }, [token]);

  const onSubmit = async (data: FormInputType) => {
    if (token && expires) {
      try {
        setIsLoading(true);
        axios
          .post(
            `/api/auth/password?expires=${expires}`,
            {
              email: email,
              password: data.password,
            },
            {
              validateStatus: (status) => status === 200,
              headers: {
                authorization: token,
              },
            },
          )
          .then(() => {
            setIsLoading(false);
            setVisible(true);
          })
          .catch((error) => {
            setIsLoading(false);
            // console.error(error);
            setError(error?.response?.data?.message ?? "Error: ");
          });
      } catch (error) {
        setError("Error: ");
      }
    }
  };

  return (
    <div className="container">
      <div className="centered-element">
        <SpaceBetween direction="vertical" size="xl">
          <img src="/RD_Horizozntal_Color_Logo.svg" style={{ height: 40 }} />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <SpaceBetween direction="vertical" size="xxs">
              <div style={{ width: 500, margin: 5 }}>
                <Container
                  header={
                    <Header
                      variant="h2"
                      description={
                        error && (
                          <StatusIndicator type="error">
                            {error}
                          </StatusIndicator>
                        )
                      }
                    >
                      Reset Password
                    </Header>
                  }
                >
                  <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                      <SpaceBetween direction="vertical" size="l">
                        <FormField label="Email">
                          <Input value={email} readOnly />
                        </FormField>
                        <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
                          <RHFTextField
                            type={showPassword ? "text" : "password"}
                            label="Password"
                            name="password"
                            stretch
                            rules={{ required: "This field is required" }}
                          />
                          <div style={{ marginTop: "32px" }}>
                            <Checkbox
                              onChange={({ detail }) =>
                                setShowPassword(detail.checked)
                              }
                              checked={showPassword}
                            >
                              Show Password
                            </Checkbox>
                          </div>
                        </Grid>

                        <RHFTextField
                          type={showPassword ? "text" : "password"}
                          label="Confirm Password"
                          name="confirmPassword"
                          stretch
                          rules={{
                            required: "This field is required",
                            validate: (value: any, formValues: FieldValues) => {
                              return (
                                value === (formValues.password ?? "") ||
                                "Password must match"
                              );
                            },
                          }}
                        />

                        <MUIButtonCustomized
                          type="submit"
                          label="Reset password"
                        />
                      </SpaceBetween>
                    </form>
                  </FormProvider>
                </Container>
              </div>
            </SpaceBetween>
          </div>
        </SpaceBetween>
        <Modal
          onDismiss={() => setVisible(false)}
          visible={visible}
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="link"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Ok
                </Button>
              </SpaceBetween>
            </Box>
          }
          header="Password reset successful"
        >
          You're password has been successfully reset
        </Modal>
      </div>
    </div>
  );
}

export default ResetPasswordForm;
