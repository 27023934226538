import {
  Button,
  Header,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import Grid from "@mui/material/Grid";
import { FormProvider, useForm } from "react-hook-form";
import { connect } from "react-redux";
import RHFTextField from "../../components/RHF/RHFTextField";
import { setModalName } from "../../redux/UI/actions";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import useIsLoading from "../../hooks/useIsLoading";
import { useAddRoleMutation } from "../../redux/api/role/role";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

type FormInputType = {
  name: string;
  description: string;
};

function AddRole({ setModalName }: PropsFromDispatch) {
  const methods = useForm<FormInputType>();
  const [addRole, { isLoading: isCreating }] = useAddRoleMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  useIsLoading(isCreating);

  const onSubmit = async (data: FormInputType) => {
    await notifyWrapper({
      promise: addRole({
        name: data.name,
        description: data.description,
      }),
      resourceName: "role",
      actionName: "add",
    });

    setModalName("");
  };

  return (
    <Modal
      visible={true}
      size="medium"
      header={<Header variant="h2">Add Role</Header>}
      onDismiss={() => {
        setModalName("");
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <SpaceBetween direction="vertical" size="s">
            <RHFTextField
              label="Name"
              name="name"
              stretch
              rules={{ required: "This field is required" }}
            />

            <RHFTextArea label={"Description"} name={"description"} />

            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  formAction="none"
                  onClick={() => {
                    setModalName("");
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button formAction="submit" variant="primary">
                  Add
                </Button>
              </Grid>
            </Grid>
          </SpaceBetween>
        </form>
      </FormProvider>
    </Modal>
  );
}

const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(AddRole);
