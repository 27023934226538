import {
  Box,
  Button,
  ButtonDropdown,
  Header,
  Link,
  Pagination,
  SpaceBetween,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import { useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProviderLiabilityInsuranceType } from "../../../../redux/api/provider/section/types";
import {
  useDeleteProviderLiabilityInsuranceMutation,
  useGetProviderQuery,
} from "../../../../redux/api/provider/provider";
import DeleteAlertModal from "../../../../components/Modal/delete-alert-modal";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";
import { useLocalStorage } from "../../../../common/localStorage";
import {
  LIABILITYINSURANCE_CONTENT_DISPLAY_OPTIONS,
  LIABILITYINSURANCE_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../../../../common/table-config";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableEmptyState } from "../../../../common/common-components";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../redux/store";
import StyledRouterLink from "../../../../components/styled-route-link";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";

export default function LiabilityInsurance() {
  const navigate = useNavigate();
  const { providerId } = useParams();
  const { data: provider } = useGetProviderQuery(
    !!providerId
      ? {
          providerId: providerId,
        }
      : skipToken,
  );
  const [selectedItem, setSelectedItem] =
    useState<ProviderLiabilityInsuranceType>();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [deleteProviderLiabiltyIsurance] =
    useDeleteProviderLiabilityInsuranceMutation();
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-LiabilityInsurance-Table-Preferences",
    LIABILITYINSURANCE_TABLE_DEFAULT_PREFERENCES,
  );
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false),
  );
  const { items, collectionProps, paginationProps } = useCollection(
    provider?.providerLiabilityInsurances ?? [],
    {
      propertyFiltering: {
        filteringProperties: [],
        empty: <TableEmptyState resourceName="Liability Insurance" />,
      },
    },
  );

  const tableProps: TableProps<ProviderLiabilityInsuranceType> = useMemo(() => {
    return {
      columnDefinitions: [
        {
          id: "id",
          header: "Id",
          cell: (item) => item.id || "-",
          sortingField: "name",
          isRowHeader: true,
        },
        {
          id: "name",
          header: "Name",
          cell: (item) => (
            <StyledRouterLink
              className={hasPermission ? "edit_link" : "normal"}
              to={hasPermission ? `editliabilityinsurance/${item.id}` : `#`}
              label={item?.name}
            />
          ),
        },
        {
          id: "isCurrent",
          header: "Current",
          cell: (item) => (item.isCurrent === true ? "Yes" : "No"),
          sortingField: "alt",
        },
        {
          id: "policyNumber",
          header: "Policy Number",
          cell: (item) => <div> {item.policyNumber ?? "-"} </div>,
        },
        {
          id: "selfInsured",
          header: "Self Insured",
          cell: (item) => (
            <div> {item.selfInsured === true ? "Yes" : "No"} </div>
          ),
        },
        {
          id: "effectiveDate",
          header: "Effective Date",
          cell: (item) => (
            <div> {item.effectiveDate?.toLocaleString() ?? "-"} </div>
          ),
        },
        {
          id: "expirationDate",
          header: "Expiration Date",
          cell: (item) => (
            <div> {item.expirationDate?.toLocaleString() ?? "-"} </div>
          ),
        },
        {
          id: "typeOfCoverage",
          header: "Type Of Coverage",
          cell: (item) => <div> {item.typeOfCoverage ?? "-"} </div>,
        },
        {
          id: "timeWithCurrentCarrierValue",
          header: "Time With Carrier",
          cell: (item) => (
            <div>
              {item.timeWithCurrentCarrierValue ?? "-"}{" "}
              {item.timeWithCurrentCarrierUnit ?? "-"}{" "}
            </div>
          ),
        },
        {
          id: "coveragePerOccurrence",
          header: "Coverage Per Occurrence",
          cell: (item) => <div> {item.coveragePerOccurrence ?? "-"} </div>,
        },
        {
          id: "aggregateCoverage",
          header: "Aggregate Coverage",
          cell: (item) => <div> {item.aggregateCoverage ?? "-"} </div>,
        },
        {
          id: "email",
          header: "Email",
          cell: (item) => <div> {item.email ?? "-"} </div>,
        },
        {
          id: "phone",
          header: "Phone",
          cell: (item) => <div> {item.phone ?? "-"} </div>,
        },
        {
          id: "fax",
          header: "Fax",
          cell: (item) => <div> {item.fax ?? "-"} </div>,
        },
        {
          id: "addressLane1",
          header: "Address Lane1",
          cell: (item) => <div> {item.addressLane1 ?? "-"} </div>,
        },
        {
          id: "addressLane2",
          header: "Address Lane2",
          cell: (item) => <div> {item.addressLane2 ?? "-"} </div>,
        },
        {
          id: "city",
          header: "City",
          cell: (item) => <div> {item.city ?? "-"} </div>,
        },
        {
          id: "state",
          header: "State",
          cell: (item) => <div> {item.state ?? "-"} </div>,
        },
        {
          id: "zip",
          header: "Zip",
          cell: (item) => <div> {item.zip ?? "-"} </div>,
        },
        {
          id: "country",
          header: "Country",
          cell: (item) => <div> {item.country ?? "-"} </div>,
        },
        {
          id: "documentName",
          header: "Document Name",
          cell: (item) => (
            <Link
              external
              href={
                hasPermission && item?.document?.id
                  ? `/safe/${providerId}/documents/${item?.document?.id}`
                  : `#`
              }
            >
              {item.document?.name ?? "-"}
            </Link>
          ),
        },
      ],
      header: (
        <Header
          actions={
            <SpaceBetween size="s" direction="horizontal">
              <ButtonDropdown
                disabled={!selectedItem}
                items={[
                  { text: "Edit", id: "edit", disabled: false },
                  { text: "Delete", id: "delete", disabled: false },
                ]}
                onItemClick={({ detail }) => {
                  if (detail.id === "edit")
                    if (selectedItem && selectedItem.id)
                      navigate(`editliabilityinsurance/${selectedItem.id}`);
                  if (detail.id === "delete") {
                    if (selectedItem && selectedItem.id && !!providerId)
                      OpenDeleteModal(true);
                  }
                }}
              >
                Action
              </ButtonDropdown>
              <Button
                variant="primary"
                onClick={() => {
                  navigate(`editliabilityinsurance`);
                }}
              >
                Add new
              </Button>
            </SpaceBetween>
          }
        >
          Liability Insurance
        </Header>
      ),
      items,
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <TablePreferences
          title="Liability Insurance Table Preferences"
          preferences={tablePreferences}
          setPreferences={(preferences) => {
            setTablePreferences(preferences);
          }}
          contentDisplayOptions={LIABILITYINSURANCE_CONTENT_DISPLAY_OPTIONS}
        />
      ),
      columnDisplay: tablePreferences?.contentDisplay,
      wrapLines: tablePreferences?.wrapLines,
      stripedRows: tablePreferences?.stripedRows,
      contentDensity: tablePreferences?.contentDensity,
      stickyColumns: tablePreferences?.stickyColumns,
      ...collectionProps,
      loadingText: "Loading resources",
      selectionType: "single",
      selectedItems: selectedItem ? [selectedItem] : [],
      onSelectionChange: ({ detail }) => {
        if (detail.selectedItems && detail.selectedItems.at(0))
          setSelectedItem(detail.selectedItems.at(0));
      },
      onRowClick: ({ detail }) => {
        if (detail.item) setSelectedItem(detail.item);
      },
      empty: (
        <Box textAlign="center" color="inherit">
          <b>No resources</b>
          <Box padding={{ bottom: "s" }} variant="p" color="inherit">
            No resources to display.
          </Box>
          <Button>Create resource</Button>
        </Box>
      ),
    };
  }, [tablePreferences, items, selectedItem, provider]);
  return (
    <div style={{ paddingTop: "12px" }}>
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedItem?.id && !!providerId)
            await notifyWrapper({
              promise: deleteProviderLiabiltyIsurance({
                providerId: providerId,
                liabilityInsuranceId: selectedItem.id.toString(),
              }),
              resourceName: "liability insurance",
              actionName: "delete",
            });
          OpenDeleteModal(false);
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={"Delete Liability Insurance"}
        content={
          <>
            <Box>Name</Box>
            <Box variant="awsui-key-label">{selectedItem?.name}</Box>
          </>
        }
        description={
          "Are you sure you want to delete this Liability Insurance?"
        }
      />
    </div>
  );
}
