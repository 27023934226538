import {
  Box,
  Button,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import { find } from "lodash";
import React, { useEffect } from "react";
import { Attribute, Section } from "../../redux/api/section/types";
import { RowType, SectionWithTimeStamp } from "../configure-section";
import { CheckBoxConfig } from "./checkbox-config";
import { DateConfig } from "./date-config";
import { DropDownConfig } from "./dropdown-config";
import { NumberConfig } from "./number-config";
import { StringConfig } from "./string-config";
import { SubSectionConfig } from "./subsection-config";
import {
  FormProvider,
  UseFormReturn,
  useForm,
  useWatch,
} from "react-hook-form";
import { Divider } from "@mui/material";

interface ConfigModalStateProps {
  row: RowType;
  setSelectedItem: React.Dispatch<React.SetStateAction<RowType | null>>;
  section: SectionWithTimeStamp;
  setSection: React.Dispatch<
    React.SetStateAction<SectionWithTimeStamp | undefined>
  >;
}

type FormInputType = {
  name: string;
  defaultValue?: string | boolean;
  options: Array<{ label: string }>;
  description: string;
  required: boolean;
  sensitive: boolean;
};

export interface ModalFormProps {
  methods: UseFormReturn<FormInputType, any>;
}

export function ConfigModal({
  row,
  setSection,
  setSelectedItem,
  section,
}: ConfigModalStateProps) {
  const methods = useForm<FormInputType>();
  useWatch({ control: methods.control, name: "options" });

  useEffect(() => {
    if (row.isChildNode) {
      section.subSections.forEach((subSection) => {
        //note: here row is attribute
        if (
          subSection.id === row.parent_id &&
          subSection.time_stamp === row.parent_time_stamp
        ) {
          const attribute = find(
            subSection.attributes,
            (attribute) =>
              attribute.name === row.name &&
              attribute.type === row.type &&
              attribute.id === row.id
          );
          if (attribute) getAttribute(attribute);
        }
      });
    } else if (row.type === "sub_section") {
      const subSection = find(
        section.subSections,
        (subSection) =>
          subSection.name === row.name &&
          subSection.id === row.id &&
          subSection.time_stamp === row.time_stamp
      );
      if (subSection?.name) methods.setValue("name", subSection.name);
      if (subSection?.description)
        methods.setValue("description", subSection.description);
    } else {
      const attribute = find(
        section.attributes,
        (attribute) =>
          attribute.name === row.name &&
          attribute.type === row.type &&
          attribute.id === row.id
      );
      if (attribute) getAttribute(attribute);
    }
  }, []);

  useEffect(() => {
    if (row.isChildNode) {
      section.subSections.forEach((subSection) => {
        //note: here row is attribute
        if (
          subSection.id === row.parent_id &&
          subSection.time_stamp === row.parent_time_stamp
        ) {
          const attribute = find(
            subSection.attributes,
            (attribute) =>
              attribute.name === row.name &&
              attribute.type === row.type &&
              attribute.id === row.id
          );
          if (attribute) getAttribute(attribute);
        }
      });
    } else if (row.type === "sub_section") {
      const subSection = find(
        section.subSections,
        (subSection) =>
          subSection.name === row.name &&
          subSection.id === row.id &&
          subSection.time_stamp === row.time_stamp
      );
      if (subSection?.name) methods.setValue("name", subSection.name);
      if (subSection?.description)
        methods.setValue("description", subSection.description);
    } else {
      const attribute = find(
        section.attributes,
        (attribute) =>
          attribute.name === row.name &&
          attribute.type === row.type &&
          attribute.id === row.id
      );
      if (attribute) getAttribute(attribute);
    }
  }, [row]);

  const setAttribute = (attribute: Attribute) => {
    attribute.description = methods.getValues("description");
    attribute.defaultValue = methods.getValues("defaultValue")?.toString();
    attribute.optionDefinition = methods.getValues("options");
    attribute.required = methods.getValues("required");
    attribute.sensitive = methods.getValues("sensitive");
  };

  const getAttribute = (attribute: Attribute) => {
    console.log(attribute);
    if (attribute.name) methods.setValue("name", attribute.name);
    if (attribute.description)
      methods.setValue("description", attribute.description);
    if (attribute.optionDefinition)
      methods.setValue("options", attribute.optionDefinition);

    methods.setValue("defaultValue", attribute?.defaultValue);
    methods.setValue("required", attribute.required);
    methods.setValue("sensitive", attribute.sensitive);
  };

  const onSubmit = async (data: FormInputType) => {
    if (row.isChildNode) {
      let temp: SectionWithTimeStamp = JSON.parse(JSON.stringify(section));
      temp.subSections.forEach((subSection) => {
        if (
          subSection.id === row.parent_id &&
          subSection.time_stamp === row.parent_time_stamp
        ) {
          const attribute = find(
            subSection.attributes,
            (attribute) =>
              attribute.name === row.name &&
              attribute.type === row.type &&
              attribute.id === row.id
          );
          if (attribute) setAttribute(attribute);
        }
      });
      setSection(temp);
      setSelectedItem(null);
    } else if (row.type === "sub_section") {
      let temp: SectionWithTimeStamp = JSON.parse(JSON.stringify(section));
      const subSection = find(
        temp.subSections,
        (subSection) =>
          subSection.name === row.name &&
          subSection.id === row.id &&
          subSection.time_stamp === row.time_stamp
      );
      if (subSection) {
        subSection.description = methods.getValues("description");
      }
      setSection(temp);
      setSelectedItem(null);
    } else {
      let temp: Section = JSON.parse(JSON.stringify(section));
      const attribute = find(
        temp.attributes,
        (attribute) =>
          attribute.name === row.name &&
          attribute.type === row.type &&
          attribute.id === row.id
      );
      if (attribute) setAttribute(attribute);
      setSection(temp);
      setSelectedItem(null);
    }
  };

  return (
    <Modal
      onDismiss={() => setSelectedItem(null)}
      visible={!!row}
      header="Configure"
    >
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <SpaceBetween size={"s"}>
            {row.type === "sub_section" && <SubSectionConfig />}
            {"dropdown" === row.type && (
              <DropDownConfig
                options={methods.getValues("options")}
                setOptions={(options) => {
                  methods.setValue("options", options);
                }}
              />
            )}
            {"number" === row.type && <NumberConfig />}
            {"date" === row.type && <DateConfig />}
            {"checkbox" === row.type && <CheckBoxConfig />}
            {"string" === row.type && <StringConfig />}
            <Divider />
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  formAction="none"
                  onClick={() => {
                    setSelectedItem(null);
                  }}
                  variant="link"
                >
                  Cancel
                </Button>
                <Button
                  formAction="submit"
                  onClick={() => {
                    methods.handleSubmit(onSubmit);
                  }}
                  variant="primary"
                >
                  Ok
                </Button>
              </SpaceBetween>
            </Box>
          </SpaceBetween>
        </FormProvider>
      </form>
    </Modal>
  );
}
