import {
  CategoryType,
  GroupsType,
  ManageCategory,
} from "../api/managecategory/types";

export enum ManageCategoryActionTypes {
  SELECT_MANAGECATEGORY = "managecategory/select-managecategory",
  SELECT_CATEGORY = "managecategory/select-category",
  SELECT_GROUPS = "managecategory/select-groups",
  SET_ACTIVE_TAB_ID = "managecategory/set-active-tab-id",
}

export interface ManageCategoryState {
  selectedManageCategory: ManageCategory[];
  selectedCategory: CategoryType[];
  selectedGroups: GroupsType[];
}
