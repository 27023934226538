import {
  Button,
  Container,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import Label from "../../../../components/Label";
import FormSection from "../../../../components/FormSection";
import { Divider } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useGetProviderQuery } from "../../../../redux/api/provider/provider";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";

export default function PersonalInfo() {
  const navigate = useNavigate();
  const { providerId } = useParams();
  const { data: provider, fulfilledTimeStamp } = useGetProviderQuery(
    providerId ? { providerId } : skipToken,
  );

  return (
    <Container
      header={
        <SpaceBetween size="xxs">
          <Header
            actions={
              <Button
                onClick={() => {
                  navigate(`editpersonalinfo`);
                }}
              >
                Edit
              </Button>
            }
          >
            Personal Info
          </Header>
          <Divider></Divider>
        </SpaceBetween>
      }
    >
      <div style={{ paddingTop: "12px" }}>
        <FormSection columns={4}>
          <Label
            label={"First name"}
            value={provider?.firstName ?? "-"}
          ></Label>
          <Label
            label={"Middle name"}
            value={provider?.personalInfo?.middleName ?? "-"}
          ></Label>

          <Label label={"Last name"} value={provider?.lastName ?? "-"}></Label>
          <Label
            label={"NPI Number"}
            value={provider?.npiNumber ?? "-"}
          ></Label>
          <Label
            label={"Degree"}
            value={provider?.personalInfo?.degree?.name ?? "-"}
          ></Label>
          <Label
            label={"Has Other Name"}
            value={
              provider?.personalInfo?.hasOtherName === true
                ? "Yes"
                : "No" ?? "No"
            }
          ></Label>
          {provider?.personalInfo?.hasOtherName === true ? (
            <Label
              label={"Other Name"}
              value={provider?.personalInfo?.otherName ?? "-"}
            ></Label>
          ) : null}
          <Label
            label={"Gender"}
            value={provider?.personalInfo?.gender ?? "-"}
          ></Label>
          <Label
            label={"Date of birth"}
            value={provider?.personalInfo?.dob?.toLocaleString() ?? "-"}
          ></Label>
          <Label
            label={"Place of birth"}
            value={provider?.personalInfo?.placeOfBirth ?? "-"}
          ></Label>
          <Label
            label={"SSN"}
            value={provider?.personalInfo?.ssn ?? "-"}
          ></Label>
          <Label
            label={"Passport No"}
            value={provider?.personalInfo?.passportNo ?? "-"}
          ></Label>
          <Label
            label={"Citizenship"}
            value={provider?.personalInfo?.citizenship ?? "-"}
          ></Label>
          <Label
            label={"Email"}
            value={provider?.personalInfo?.email ?? "-"}
          ></Label>
          <Label
            label={"Mobile"}
            value={provider?.personalInfo?.mobile ?? "-"}
          ></Label>
          <Label
            label={"Home Phone"}
            value={provider?.personalInfo?.homePhone ?? "-"}
          ></Label>
          <Label
            label={"fax"}
            value={provider?.personalInfo?.fax ?? "-"}
          ></Label>
          <Label
            label={"Have you ever served in the US military"}
            value={provider?.personalInfo?.hasWorkedInMilitary ?? "No"}
          ></Label>
          <Label
            label={"Branch of military"}
            value={provider?.personalInfo?.branchOfMilitary ?? "-"}
          ></Label>
          <Label
            label={"Military Date Of Service"}
            value={
              provider?.personalInfo?.militaryDateOfService?.toLocaleString() ??
              "-"
            }
          ></Label>
          <Label
            label={"Address Lane1"}
            value={provider?.personalInfo?.addressLane1 ?? "-"}
          ></Label>
          <Label
            label={"Address Lane2"}
            value={provider?.personalInfo?.addressLane2 ?? "-"}
          ></Label>
          <Label
            label={"City"}
            value={provider?.personalInfo?.city ?? "-"}
          ></Label>
          <Label
            label={"State"}
            value={provider?.personalInfo?.state ?? "-"}
          ></Label>
          <Label
            label={"Zip Code"}
            value={provider?.personalInfo?.zipcode ?? "-"}
          ></Label>
          <Label
            label={"County"}
            value={provider?.personalInfo?.county ?? "-"}
          ></Label>
          <Label
            label={"Country"}
            value={provider?.personalInfo?.country ?? "-"}
          ></Label>
        </FormSection>
      </div>
    </Container>
  );
}
