import {
  ColumnLayout,
  Grid,
  GridProps,
  Header,
  Icon,
  SpaceBetween,
  SpaceBetweenProps,
} from "@cloudscape-design/components";
import { Divider } from "@mui/material";
import { useState } from "react";

type SectionProps = {
  expandedSection?: boolean;
  defaultExpanded?: boolean;
  header?: String | React.ReactNode;
  actions?: React.ReactNode;
  headerDividerSpacing?: SpaceBetweenProps.Size;
  headerDividerTop?: boolean;
  headerDividerBottom?: boolean;
  divider?: boolean;
  spacing?: SpaceBetweenProps.Size;
  children: React.ReactNode;
  textGrid?: boolean;
  columns?: number;
  gridDefinition?: ReadonlyArray<GridProps.ElementDefinition>;
};
function FormSection({
  expandedSection = false,
  defaultExpanded = false,
  header,
  actions,
  headerDividerSpacing,
  headerDividerTop,
  headerDividerBottom,
  divider,
  children,
  spacing,
  textGrid,
  columns,
  gridDefinition,
}: SectionProps) {
  const [expaned, setExpaned] = useState(defaultExpanded);
  if (!gridDefinition && !columns && expandedSection)
    return (
      <SpaceBetween size={spacing ?? "s"}>
        <div>
          {header && (
            <SpaceBetween size={headerDividerSpacing ?? "s"}>
              {headerDividerTop && <Divider></Divider>}
              {
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setExpaned(!expaned);
                    }}
                  >
                    <Header>
                      <SpaceBetween direction="horizontal" size="xs">
                        {!!expandedSection && (
                          <div style={{ marginTop: "0px" }}>
                            {expaned ? (
                              <Icon name="caret-down-filled" />
                            ) : (
                              <Icon name="caret-right-filled" />
                            )}
                          </div>
                        )}
                        <div>{header}</div>
                      </SpaceBetween>
                    </Header>
                  </div>
                  <div>{actions}</div>
                </div>
              }

              {divider && <Divider></Divider>}
              {expaned && <Divider></Divider>}
            </SpaceBetween>
          )}
        </div>
        {expaned && children}
      </SpaceBetween>
    );
  else if (!gridDefinition && !columns)
    return (
      <SpaceBetween size={spacing ?? "s"}>
        {header && (
          <SpaceBetween size={headerDividerSpacing ?? "s"}>
            {headerDividerTop && <Divider></Divider>}
            <b>{header}</b>
            {headerDividerBottom && <Divider></Divider>}
          </SpaceBetween>
        )}
        {divider && <Divider></Divider>}
        {children}
      </SpaceBetween>
    );
  else
    return (
      <SpaceBetween size={spacing ?? "s"}>
        {header && (
          <SpaceBetween size={headerDividerSpacing ?? "s"}>
            {headerDividerTop && <Divider></Divider>}
            <b>{header}</b>
            {headerDividerBottom && <Divider></Divider>}
          </SpaceBetween>
        )}
        {divider && <Divider></Divider>}
        {!!columns && (
          <ColumnLayout
            columns={columns ?? 2}
            variant={textGrid ? "text-grid" : "default"}
          >
            {children}
          </ColumnLayout>
        )}
        {!!gridDefinition && (
          <Grid gridDefinition={gridDefinition}>{children}</Grid>
        )}
      </SpaceBetween>
    );
}

export default FormSection;
