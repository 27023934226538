import {
  Box,
  BreadcrumbGroup,
  Button,
  Container,
  Form,
  FormField,
  Header,
  Select,
  SpaceBetween,
  Textarea,
} from "@cloudscape-design/components";
import { Divider } from "@mui/material";
import {
  useAddProviderDisclousresMutation,
  useGetAllQuestionQuery,
  useGetProviderQuery,
} from "../../../../redux/api/provider/provider";
import { useNavigate, useParams } from "react-router-dom";
import { ProviderDisclosureType } from "../../../../redux/api/provider/section/types";
import { useEffect, useState } from "react";
import "./edit-disclosures.css";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";

const ResponseOptions = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
];
export default function EditDisclousres() {
  const navigate = useNavigate();

  const { providerId } = useParams();

  const [disclosures, setDisclosures] = useState<ProviderDisclosureType[]>([]);

  const {
    data: questions = [],
    fulfilledTimeStamp: question_fulfilledTimeStamp,
  } = useGetAllQuestionQuery();

  const [addProviderDisclousres] = useAddProviderDisclousresMutation();

  const { data: provider, fulfilledTimeStamp } = useGetProviderQuery(
    !!providerId
      ? {
          providerId,
        }
      : skipToken,
  );

  useEffect(() => {
    if (!!provider)
      setDisclosures(
        questions.map((question) => {
          const providerDisclosure = provider.providerDisclosures?.find(
            (providerDisclosure) =>
              providerDisclosure.question.id === question.id,
          );
          return {
            question: question,
            response: providerDisclosure?.response,
            explanation: providerDisclosure?.explanation,
          };
        }),
      );
  }, [fulfilledTimeStamp, question_fulfilledTimeStamp]);
  const { notifyWrapper } = useAsyncNotifyWrapper();

  const handleSubmit = async () => {
    if (!!providerId) {
      await notifyWrapper({
        promise: addProviderDisclousres({
          providerId: providerId,
          disclosures,
        }),
        resourceName: "disclosure",
        actionName: "upload",
      });
      navigate(-1);
    }
  };

  return (
    <div style={{ paddingTop: "25px" }}>
      <SpaceBetween size={"l"}>
        <BreadcrumbGroup
          items={[
            { text: "providers", href: `/providers` },
            {
              text: `Ravi teja`,
              href: `#`,
            },
            { text: `Disclosures`, href: "#" },
          ]}
          ariaLabel="Breadcrumbs"
        />
        <Container
          header={
            <SpaceBetween size="xxs">
              <Header>Disclosures</Header>
              <Divider></Divider>
            </SpaceBetween>
          }
        >
          <div style={{ paddingTop: "12px" }}>
            <Form
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    onClick={() => {
                      navigate(-1);
                    }}
                    formAction="none"
                  >
                    Cancel
                  </Button>
                  <Button onClick={handleSubmit} variant="primary">
                    Submit
                  </Button>
                </SpaceBetween>
              }
            >
              <SpaceBetween size="l">
                {disclosures.map((disclosure) => {
                  return (
                    <SpaceBetween size="xs">
                      <Box variant="awsui-key-label">
                        {disclosure.question.questionText}
                      </Box>
                      <SpaceBetween size="m">
                        <Select
                          ariaRequired
                          data-key="response"
                          selectedOption={
                            ResponseOptions.find(
                              (option) => option.value === disclosure.response,
                            ) ?? null
                          }
                          onChange={({ detail }) => {
                            // Find the index of the item
                            const itemIndex = disclosures.findIndex(
                              (item) =>
                                item.question.id === disclosure.question.id,
                            );
                            if (itemIndex !== -1) {
                              // Create a new copy of the item with the updated name
                              const updatedItem = {
                                ...disclosures[itemIndex],
                                response: detail.selectedOption.value,
                              };
                              // Create a new copy of the array with the updated item
                              const updatedItems = [...disclosures];
                              updatedItems[itemIndex] = updatedItem;
                              // Set the updated array back to the state
                              setDisclosures(updatedItems);
                            }
                          }}
                          options={ResponseOptions}
                        />
                        {disclosure.response === "yes" && (
                          <FormField label="Explanation">
                            <Textarea
                              ariaRequired
                              data-key="explanation"
                              value={disclosure.explanation ?? ""}
                              onChange={(changeDetail) => {
                                // Find the index of the item
                                const itemIndex = disclosures.findIndex(
                                  (item) =>
                                    item.question.id === disclosure.question.id,
                                );
                                if (itemIndex !== -1) {
                                  // Create a new copy of the item with the updated name
                                  const updatedItem = {
                                    ...disclosures[itemIndex],
                                    explanation: changeDetail.detail.value,
                                  };
                                  // Create a new copy of the array with the updated item
                                  const updatedItems = [...disclosures];
                                  updatedItems[itemIndex] = updatedItem;
                                  // Set the updated array back to the state
                                  setDisclosures(updatedItems);
                                }
                              }}
                            />
                          </FormField>
                        )}
                      </SpaceBetween>
                      <Divider></Divider>
                    </SpaceBetween>
                  );
                })}
              </SpaceBetween>
            </Form>
          </div>
        </Container>
      </SpaceBetween>
    </div>
  );
}
