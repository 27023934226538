import { Container } from "@cloudscape-design/components";
import React from "react";
import { WidgetConfig } from "../types";

export function BaseStaticWidget({ config }: { config: WidgetConfig }) {
  const Wrapper = config.provider ?? React.Fragment;
  return (
    <div>
      <Wrapper>
        <Container
          header={<config.header />}
          fitHeight={true}
          footer={config.footer && <config.footer />}
          disableContentPaddings={config.disableContentPaddings}
        >
          <config.content />
        </Container>
      </Wrapper>
    </div>
  );
}
