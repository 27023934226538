import { Attachment } from "../../redux/api/document/types";
import PDFTronViewer from "./PDFTronViewer";
import UploadNewFile from "../UploadNewFile";
import { Container, Header } from "@cloudscape-design/components";

type AttachmentViewerProps = {
  attachment?: Attachment;
  file?: File;
  hideHeader?: boolean;
  maxHeight?: boolean;
  onFileChange: (file?: File) => void;
};

function AttachmentViewer({
  attachment,
  hideHeader = false,
  file,
  maxHeight,
  onFileChange,
}: AttachmentViewerProps) {
  // console.log({ key: attachment });

  if (!!file)
    return (
      <div>
        <PDFTronViewer attachmentFile={file} replaceFile={onFileChange} />
      </div>
    );
  else if (!!attachment && !!attachment.key) {
    return (
      <div>
        <PDFTronViewer
          attachmentKey={attachment.key}
          replaceFile={onFileChange}
        />
      </div>
    );
  } else
    return (
      <Container header={!hideHeader && <Header>Supporting documents</Header>}>
        <UploadNewFile onFileChange={onFileChange} maxHeight={maxHeight} />
      </Container>
    );
}

export default AttachmentViewer;
