import { CATEGORY_MODAL, alertReminderOptions } from "../helpers/constants";
import {
  useGetAllCategoriesQuery,
  useGetAllCategoryGroupQuery,
} from "../redux/api/managecategory/managecategory";
import FormSection from "./FormSection";
import { ApplicationState } from "../redux/store";
import { connect, useSelector } from "react-redux";
import RHFSelect from "./RHF/RHFSelect";
import RHFDatePicker from "./RHF/RHFDatePicker";
import RHFTextField from "./RHF/RHFTextField";
import { setModalName } from "../redux/UI/actions";
import RHFFloatingSelect from "./RHF/RHFFloatingSelect";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function DocumentMetaData({ setModalName }: PropsFromDispatch) {
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("safe.edit") ?? false),
  );

  const { data: categories = [] } = useGetAllCategoriesQuery();

  return (
    <FormSection columns={2}>
      <RHFTextField
        label="Document Name"
        name="name"
        readOnly={!hasPermission}
        placeholder="Enter Document Name"
        rules={{ required: "This field is required" }}
      />
      <RHFFloatingSelect
        name="categoryId"
        label="Category"
        options={categories.map((category) => ({
          label: category.name,
          value: "" + category.id,
        }))}
        enableFiltering={true}
        stretch={false}
        action={{
          label: "Modify",
          onClick: () => {
            setModalName(CATEGORY_MODAL);
          },
        }}
      />

      <RHFDatePicker
        label={"Expiration Date"}
        name={"expirationDate"}
        disabled={!hasPermission}
      />
      <RHFSelect
        disabled={!hasPermission}
        label="Expiration Alert Reminder"
        name={"alertDays"}
        options={alertReminderOptions}
      />
    </FormSection>
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(DocumentMetaData);
