import { api } from "..";
import {
  OrganizationSettingsType,
  OrganizationTypes,
  UpdateOrganizationDetailsType,
} from "./types";

const OrganizationApi = api.injectEndpoints({
  endpoints: (build) => ({
    getOrganizationDetails: build.query<OrganizationTypes, void>({
      query: () => "organization",
      providesTags: ["Organization"],
    }),

    updateOrganization: build.mutation<
      void,
      Pick<OrganizationTypes, "id"> & Partial<UpdateOrganizationDetailsType>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `organization`,
          method: "PUT",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Organization", id: arg.id },
        { type: "Organization" },
      ],
    }),
    updateOrganizationSettings: build.mutation<
      void,
      Pick<OrganizationTypes, "id"> & OrganizationSettingsType
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `organization/settings`,
          method: "POST",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Organization", id: arg.id },
        { type: "Organization" },
      ],
    }),
  }),
});

export const {
  useGetOrganizationDetailsQuery,
  useUpdateOrganizationMutation,
  useUpdateOrganizationSettingsMutation,
} = OrganizationApi;
