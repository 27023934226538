import { FormProvider, useForm } from "react-hook-form";
import { ProviderAffiliationType } from "../../../../redux/api/provider/section/types";
import {
  BreadcrumbGroup,
  Button,
  Container,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import RHFTextField from "../../../../components/RHF/RHFTextField";
import FormSection from "../../../../components/FormSection";
import RHFDatePicker from "../../../../components/RHF/RHFDatePicker";
import { Divider } from "@mui/material";
import {
  useAddProviderAffiliationMutation,
  useGetProviderQuery,
} from "../../../../redux/api/provider/provider";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import RHFCheckBox from "../../../../components/RHF/RHFCheckBox";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";

export default function EditAffiliation() {
  const methods = useForm<ProviderAffiliationType>();
  const { providerId, affiliationId } = useParams();
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const onSubmit = async (data: ProviderAffiliationType) => {
    if (!!providerId) {
      await notifyWrapper({
        promise: addProviderAffiliation({
          providerId: providerId,
          ...data,
        }),
        resourceName: "affiliation",
        actionName: "upload",
      });
      navigate(-1);
    }
  };

  const { data: provider, fulfilledTimeStamp } = useGetProviderQuery(
    providerId ? { providerId } : skipToken,
  );
  const [addProviderAffiliation] = useAddProviderAffiliationMutation();
  const [isCurrent] = methods.watch(["isCurrent"]);
  useEffect(() => {
    methods.clearErrors("endDate");
  }, [isCurrent]);

  useEffect(() => {
    if (provider && provider.providerAffiliations) {
      const affiliation = provider.providerAffiliations.find(
        (affiliation) => affiliation?.id + "" === affiliationId + "",
      );

      if (affiliation) {
        methods.setValue("id", affiliation.id);
        methods.setValue("name", affiliation?.name);
        methods.setValue("startDate", affiliation?.startDate);
        methods.setValue("endDate", affiliation?.endDate);
        methods.setValue("isCurrent", affiliation?.isCurrent);
        methods.setValue("email", affiliation?.email);
        methods.setValue("phone", affiliation?.phone);
        methods.setValue("fax", affiliation?.fax);
      }
    }
  }, [fulfilledTimeStamp, provider, affiliationId]);

  return (
    <div style={{ paddingTop: "25px" }}>
      <SpaceBetween size={"l"}>
        <BreadcrumbGroup
          items={[
            { text: "providers", href: `/providers` },
            {
              text: provider?.firstName ?? "",
              href: `#`,
            },
            { text: `Affiliation`, href: "#" },
          ]}
          ariaLabel="Breadcrumbs"
        />
        <Container
          header={
            <SpaceBetween size="xxs">
              <Header>Affiliation</Header>
              <Divider></Divider>
            </SpaceBetween>
          }
        >
          <div style={{ paddingTop: "12px" }}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Form
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        onClick={() => {
                          navigate(-1);
                        }}
                        formAction="none"
                      >
                        Cancel
                      </Button>
                      <Button formAction="submit" variant="primary">
                        Submit
                      </Button>
                    </SpaceBetween>
                  }
                >
                  <SpaceBetween size="l">
                    <FormSection columns={4}>
                      <RHFTextField
                        label="Name"
                        name="name"
                        stretch
                        rules={{ required: "This field is required" }}
                      />
                      <RHFDatePicker
                        label="Start Date"
                        name="startDate"
                        rules={{ required: "This field is required" }}
                      />
                      <RHFDatePicker
                        label="End Date"
                        name="endDate"
                        rules={
                          isCurrent?.toString() === "false" ||
                          isCurrent === undefined
                            ? { required: "This field is required" }
                            : { required: false }
                        }
                      />

                      <RHFTextField label="Email" name="email" />
                      <RHFTextField
                        label="Phone"
                        name="phone"
                        rules={{
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Please enter a number",
                          },
                        }}
                        stretch
                      />
                      <RHFTextField
                        label="Fax"
                        name="fax"
                        rules={{
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Please enter a number",
                          },
                        }}
                        stretch
                      />
                      <div style={{ paddingTop: "12px" }}>
                        <RHFCheckBox label="Current" name="isCurrent" stretch />
                      </div>
                    </FormSection>
                  </SpaceBetween>
                </Form>
              </form>
            </FormProvider>
          </div>
        </Container>
      </SpaceBetween>
    </div>
  );
}
