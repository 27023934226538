import FormSection from "../../components/FormSection";
import { SpaceBetween } from "@cloudscape-design/components";
import RHFTextField from "../../components/RHF/RHFTextField";
import RHFCheckBox from "../../components/RHF/RHFCheckBox";

export function CheckBoxConfig() {
  return (
    <SpaceBetween size="s">
      <RHFTextField label="Name" name="name" readOnly />
      <RHFTextField
        label="Description"
        info={<i>Optional</i>}
        name="description"
      />

      <FormSection columns={2} header={"Default value"}>
        <RHFCheckBox
          label="Is field checked by default ?"
          name="defaultValue"
        />
      </FormSection>

      <FormSection columns={2} header={"Validations"}>
        <RHFCheckBox label="Is Required" name="required" />
        <RHFCheckBox label="Is Sensitive info" name="sensitive" />
      </FormSection>
    </SpaceBetween>
  );
}
