import { api } from "..";
import {
  DigitalForm,
  DigitalFormCreateRequest,
  DigitalFormManageTagsRequest,
  DigitalFormSignatureRequest,
  DigitalFormType,
  DigitalFormUpdateRequest,
  GetALLSignature,
  GetALLTagsResponse,
  GetDigitalFormResponse,
} from "./types";

const digitalformsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllDigitalForms: build.query<DigitalForm[], void>({
      query: (arg) => "digitalform/all",
      providesTags: ["DigitalForm"],
    }),

    getAllDigitalformTags: build.query<GetALLTagsResponse, void>({
      query: () => "digitalform/tags",
      transformResponse: (response: any, ...rest) => {
        let transformedResponse: GetALLTagsResponse = response.map(
          (key: string) => {
            return { id: key, text: key };
          },
        );
        return transformedResponse;
      },
      providesTags: ["DigitalForm"],
    }),

    getDigitalForm: build.query<GetDigitalFormResponse, string>({
      query: (id) => `digitalform/${id}`,
      providesTags: (result, error, id) => [{ type: "DigitalForm", id: id }],
    }),

    createDigitalForm: build.mutation<
      GetDigitalFormResponse,
      DigitalFormCreateRequest
    >({
      query: ({ ...body }) => {
        return {
          url: `digitalform`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["DigitalForm"],
    }),

    updateDigitalForm: build.mutation<
      GetDigitalFormResponse,
      Pick<DigitalForm, "id"> & Partial<DigitalFormUpdateRequest>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `digitalform/${id}`,
          method: "PUT",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "DigitalForm", id: arg.id },
        { type: "DigitalForm" },
      ],
    }),

    disableDigitalForm: build.mutation<void, Pick<DigitalForm, "id">>({
      query: ({ id }) => {
        return {
          url: `digitalform/${id}`,
          method: "PUT",
          body: { disabled: true },
        };
      },
      invalidatesTags: ["DigitalForm"],
    }),

    enableDigitalForm: build.mutation<void, Pick<DigitalForm, "id">>({
      query: ({ id }) => {
        return {
          url: `digitalform/${id}`,
          method: "PUT",
          body: { disabled: false },
        };
      },
      invalidatesTags: ["DigitalForm"],
    }),

    manageDigitalFormTags: build.mutation<
      void,
      Pick<DigitalForm, "id"> & Partial<DigitalFormManageTagsRequest>
    >({
      query: ({ id, ...tags }) => {
        return {
          url: `digitalform/${id}/tags`,
          method: "POST",
          body: tags,
        };
      },
      invalidatesTags: ["DigitalForm"],
    }),

    deleteDigitalForm: build.mutation<void, Pick<DigitalForm, "id">>({
      query: ({ id }) => {
        return {
          url: `digitalform/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["DigitalForm"],
    }),

    updateDigitalFormAnnotation: build.mutation<
      void,
      Pick<DigitalForm, "id"> & { xfdfString: string }
    >({
      query: ({ id, xfdfString }) => ({
        url: `digitalform/${id}/annotation`,
        method: "POST",
        body: xfdfString,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "DigitalForm", id: arg.id },
      ],
    }),

    digitalformSaveToSafe: build.mutation<
      void,
      Pick<DigitalForm, "id"> & {
        xfdfString: string;
        providerId: string;
      }
    >({
      query: ({ id, ...body }) => ({
        url: `signature/digitalform/${id}/save`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "DigitalForm" },
        { type: "DigitalForm", id: arg.id },
        { type: "Signature", id: arg.id },
      ],
    }),

    uploadDigitalFormTemplate: build.mutation<
      void,
      Pick<DigitalForm, "id"> & { file: File }
    >({
      query({ id, file }) {
        // upload with multipart/form-data
        const formData = new FormData();
        formData.append("file", file);
        return {
          url: `digitalform/${id}/template/upload`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "DigitalForm", id: arg.id },
        { type: "DigitalForm" },
      ],
    }),
    getAllDigitalFormTypes: build.query<DigitalFormType[], void>({
      query: () => `digitalform/type/all`,
      providesTags: ["DigitalForm"],
    }),
    saveAllDigitalFormTypes: build.mutation<void, DigitalFormType[]>({
      query: (digitalFormTypes) => {
        return {
          url: "digitalform/type/all",
          method: "POST",
          body: digitalFormTypes,
        };
      },
      invalidatesTags: ["DigitalForm"],
    }),
  }),
});

export const {
  useGetAllDigitalFormsQuery,
  useGetAllDigitalformTagsQuery,
  useGetDigitalFormQuery,
  useDigitalformSaveToSafeMutation,
  useUpdateDigitalFormMutation,
  useDisableDigitalFormMutation,
  useEnableDigitalFormMutation,
  useManageDigitalFormTagsMutation,
  useDeleteDigitalFormMutation,
  useCreateDigitalFormMutation,
  useUploadDigitalFormTemplateMutation,
  useUpdateDigitalFormAnnotationMutation,
  useGetAllDigitalFormTypesQuery,
  useSaveAllDigitalFormTypesMutation,
} = digitalformsApi;
