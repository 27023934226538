import { FormProvider, useForm } from "react-hook-form";
import {
  Alert,
  Box,
  BreadcrumbGroup,
  Button,
  Container,
  Flashbar,
  Form,
  FormField,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import FormSection from "../../../components/FormSection";
import RHFTextField from "../../../components/RHF/RHFTextField";
import { useGetAllCategoriesQuery } from "../../../redux/api/managecategory/managecategory";
import {
  useGetAllDigitalFormTypesQuery,
  useGetDigitalFormQuery,
  useUpdateDigitalFormMutation,
  useUploadDigitalFormTemplateMutation,
} from "../../../redux/api/digitalform/digitalforms";
import { connect, useSelector } from "react-redux";
import { ApplicationState } from "../../../redux/store";
import RHFTextArea from "../../../components/RHF/RHFTextArea";
import RHFSelect from "../../../components/RHF/RHFSelect";
import { Divider } from "semantic-ui-react";
import InnerAppLayout from "../../../components/InnerAppLayout";
import prettyBytes from "pretty-bytes";
import { v4 as uuidv4 } from "uuid";
import useNotify from "../../../hooks/useNotify";
import { Attachment } from "../../../redux/api/document/types";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import LoadingScreen from "../../../components/LoadingScreen";
import { DigitalFormCreateRequest } from "../../../redux/api/digitalform/types";
import RHFFloatingSelect from "../../../components/RHF/RHFFloatingSelect";
import { DIGITALFORMTYPE_MODAL } from "../../../helpers/constants";
import { setModalName } from "../../../redux/UI/actions";
import UploadNewFile from "../../../components/UploadNewFile";
import { useTranslation } from "react-i18next";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function EditDigitalForm({ setModalName }: PropsFromDispatch) {
  const { t } = useTranslation();
  const methods = useForm<DigitalFormCreateRequest>();
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: categories = [] } = useGetAllCategoriesQuery();
  const { data: form, isLoading } = useGetDigitalFormQuery(id ?? skipToken);
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("templates.edit") ?? false),
  );
  const [uploadFile, setUploadFile] = useState<File>();
  const [template, setTemplate] = useState<Attachment>();
  const { data: digitalFormType = [] } = useGetAllDigitalFormTypesQuery();
  const [updateDigitalForm] = useUpdateDigitalFormMutation();
  const [uploadDigitalFormTemplate] = useUploadDigitalFormTemplateMutation();
  const { notifyInProgress, notifySucess, notifyFailed } = useNotify();
  useEffect(() => {
    if (!!form) {
      methods.setValue("name", form.name);
      methods.setValue("description", form.description);
      methods.setValue("disabled", form.disabled);
      !!form?.digitalFormType?.id &&
        methods.setValue("digitalFormTypeId", form?.digitalFormType?.id);
      if (form.category && form.category.id) {
        methods.setValue("categoryId", "" + form.category.id);
      }
      if (!!form.template) {
        setTemplate(form?.template);
      }
    }
  }, [form]);

  const onSubmit = async (data: DigitalFormCreateRequest) => {
    if (!!form) {
      const notificationId = uuidv4();
      notifyInProgress({
        name: "ready form",
        action: "updating",
        id: notificationId,
      });
      await updateDigitalForm({
        id: form.id,
        name: data.name,
        description: data.description,
        digitalFormTypeId: data.digitalFormTypeId,
        categoryId: data.categoryId,
        disabled: data.disabled,
      })
        .unwrap()
        .then(async (digitalformResponse) => {
          if (!!uploadFile) {
            await uploadDigitalFormTemplate({
              id: form.id,
              file: uploadFile,
            })
              .unwrap()
              .then((attachmentResponse) => {
                notifySucess({
                  name: "ready form",
                  action: "updated",
                  id: notificationId,
                });
              })
              .catch((error) => {
                if (error.status < 500 && error.status >= 400) {
                  notifyFailed({
                    name: "readyform",
                    action: error.data.errorMessage,
                    content: error.data.errorDescription,
                    id: notificationId,
                  });
                } else
                  notifyFailed({
                    name: "readyform",
                    action: "update",
                    id: notificationId,
                  });
              });
          } else
            notifySucess({
              name: "readyform",
              action: "updated",
              id: notificationId,
            });
        })
        .catch((error) => {
          // console.log({ error });
          if (error.status < 500 && error.status >= 400) {
            notifyFailed({
              name: "readyform",
              action: error.data.errorMessage,
              content: error.data.errorDescription,
              id: notificationId,
            });
          } else
            notifyFailed({
              name: "readyform",
              action: "update",
              id: notificationId,
            });
        });
      navigate(`/digitalforms`);
    }
  };

  return (
    <>
      <LoadingScreen isOpen={isLoading} />
      <InnerAppLayout
        breadcrumbGroup={
          <BreadcrumbGroup
            items={[
              {
                text: `${t("readyForms.editReadyForm.header.breadcrumb.text")}`,
                href: `/digitalforms`,
              },
              {
                text: `${t("readyForms.editReadyForm.header.breadcrumb.currentPage")}`,
                href: "#",
              },
            ]}
          />
        }
        content={
          <Container
            header={
              <SpaceBetween size="xxs">
                <Header>{t("readyForms.editReadyForm.header.label")}</Header>
                <Divider></Divider>
              </SpaceBetween>
            }
          >
            <Box padding={{ right: "xxxl" }} margin={{ right: "xxxl" }}>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <Form
                    actions={
                      <SpaceBetween direction="horizontal" size="xs">
                        <Button
                          onClick={() => {
                            navigate(-1);
                          }}
                          formAction="none"
                        >
                          {t("readyForms.editReadyForm.header.actions.cancel")}
                        </Button>
                        <Button
                          disabled={!hasPermission}
                          formAction="submit"
                          variant="primary"
                        >
                          {t("readyForms.editReadyForm.header.actions.submit")}
                        </Button>
                      </SpaceBetween>
                    }
                  >
                    <FormSection columns={1}>
                      <RHFTextField
                        label={t("readyForms.editReadyForm.header.fields.name")}
                        name="name"
                        stretch={false}
                        rules={{ required: "This field is required" }}
                        readOnly={!hasPermission}
                      />
                      <RHFFloatingSelect
                        name="digitalFormTypeId"
                        label={t(
                          "readyForms.editReadyForm.header.fields.type.label",
                        )}
                        options={digitalFormType.map((type) => ({
                          label: type.type,
                          value: "" + type.id,
                        }))}
                        rules={{ required: "This field is required" }}
                        stretch={false}
                        action={{
                          label: `${t("readyForms.editReadyForm.header.fields.type.actions.modify")}`,
                          onClick: () => {
                            setModalName(DIGITALFORMTYPE_MODAL);
                          },
                        }}
                      />
                      <FormField
                        label={t(
                          "readyForms.editReadyForm.header.fields.uploadFile",
                        )}
                      >
                        <SpaceBetween size={"s"}>
                          {template && (
                            <Flashbar
                              items={[
                                {
                                  type: "warning",
                                  content: `${t("readyForms.editReadyForm.header.template.flashbar.content")}`,
                                },
                              ]}
                            />
                          )}
                          {!!template ? (
                            <Alert
                              dismissible
                              onDismiss={() => {
                                setTemplate(undefined);
                              }}
                              header={
                                <div>
                                  {" "}
                                  {t(
                                    "readyForms.editReadyForm.header.template.alert.fileName",
                                  )}
                                  : {template?.name}
                                </div>
                              }
                            >
                              <div>
                                {t(
                                  "readyForms.editReadyForm.header.template.alert.fileSize",
                                )}
                                : {prettyBytes(template?.size)}
                              </div>
                              <div>
                                {" "}
                                {t(
                                  "readyForms.editReadyForm.header.template.alert.fileType",
                                )}
                                : {template?.contentType}
                              </div>
                            </Alert>
                          ) : (
                            <UploadNewFile
                              file={uploadFile}
                              onFileChange={(uploadFile?: File) => {
                                setUploadFile(uploadFile);
                              }}
                            />
                          )}
                        </SpaceBetween>
                      </FormField>
                      <FormSection
                        expandedSection
                        header={
                          <Box fontSize="heading-s" fontWeight="bold">
                            {t("readyForms.editReadyForm.header.headerText")}
                          </Box>
                        }
                      >
                        <RHFTextArea
                          label={t(
                            "readyForms.editReadyForm.header.fields.notes",
                          )}
                          name="description"
                          stretch={true}
                          disabled={!hasPermission}
                        />
                      </FormSection>
                    </FormSection>
                  </Form>
                </form>
              </FormProvider>
            </Box>
          </Container>
        }
      />
    </>
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(EditDigitalForm);
