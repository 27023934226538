import { Reducer } from "redux";
import { ManageAdminActionTypes, ManageAdminState } from "./types";

export const initialState: ManageAdminState = {
    selectedActive: [],
    selectedInvited: [],
};

const reducer: Reducer<ManageAdminState> = (state = initialState, action) => {
    switch (action.type) {
        case ManageAdminActionTypes.SELECT_ACTIVE: {
            return { ...state, selectedActive: action.payload };
        }
        case ManageAdminActionTypes.SELECT_INVITED: {
            return { ...state, selectedInactive: action.payload };
        }

        default: {
            return state;
        }
    }
};

export { reducer as manageAdminReducer };
