export const handleDownload = ({ downloadUrl }: { downloadUrl: string }) => {
  if (!downloadUrl) return null;
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.setAttribute("download", "");
  link.setAttribute("target", "_blank");
  link.click();
};

/* export const selectedOption = (value: any, options: any[]): any => {
  return options?.find((option) => {
    if (typeof value === "object" && value !== null)
      return option?.id?.toString() === value?.id?.toString();
    else return option?.id?.toString() === value?.toString();
  });
}; */
interface HasId {
  id?: string | number; // You can adjust the type of 'id' as needed
}

export const selectedOption = <T extends HasId>(
  value: string | T | undefined,
  options: T[]
): T | undefined => {
  return options?.find((option) => {
    if (typeof value === "object" && value !== null)
      return option?.id === (value as T)?.id;
    else return option?.id?.toString() === value?.toString();
  });
};
