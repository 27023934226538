import {
  Box,
  Button,
  ButtonDropdown,
  Header,
  Pagination,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLocalStorage } from "../common/localStorage";
import {
  CREDENTIALPACKAGE_CONTENT_DISPLAY_OPTIONS,
  CREDENTIALPACKAGE_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../common/table-config";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableEmptyState } from "../common/common-components";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";
import StyledRouterLink from "../components/styled-route-link";
import DeleteAlertModal from "../components/Modal/delete-alert-modal";
import useAsyncNotifyWrapper from "../hooks/useAsyncNotifyWrapper";
import {
  useDeleteCredentialPackageMutation,
  useGetAllCredentialPackagesQuery,
} from "../redux/api/credentialpackages/credentialpackages";
import { CredentialPackage } from "../redux/api/credentialpackages/types";
import ChangeTimeZone from "../components/Timezone";
import { PATH_DASHBOARD } from "../routes/paths";
import LoadingScreen from "../components/LoadingScreen";

export default function CredentialPackages() {
  const {
    data = [],
    isLoading,
    isFetching,
    refetch,
  } = useGetAllCredentialPackagesQuery();
  const { id } = useParams();
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [deletePackage] = useDeleteCredentialPackageMutation();
  const [selectedItem, setSelectedItem] = useState<CredentialPackage>();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-CredentialPackage-Table-Preferences",
    CREDENTIALPACKAGE_TABLE_DEFAULT_PREFERENCES,
  );
  const { items, collectionProps, paginationProps } = useCollection(data, {
    propertyFiltering: {
      filteringProperties: [],
      empty: <TableEmptyState resourceName="Package" />,
    },
    sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
  });
  useEffect(() => {
    const temp = data?.find((packages) => "" + packages === "" + id);
    if (temp) setSelectedItem(temp);
  }, [id, selectedItem]);

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("packages.edit") ?? false),
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("packages.delete") ?? false),
  );

  const tableProps: TableProps<CredentialPackage> = useMemo(() => {
    return {
      header: (
        <Header
          variant="h2"
          counter={`(${data.length})`}
          actions={
            <div>
              <span className="awsui-util-action-stripe-group">
                <Button
                  variant="normal"
                  iconName="refresh"
                  onClick={() => {
                    refetch();
                  }}
                ></Button>
              </span>
              <span className="awsui-util-action-stripe-group">
                <ButtonDropdown
                  expandableGroups
                  disabled={!selectedItem}
                  items={[
                    {
                      id: "edit_package",
                      text: hasPermission ? "Edit package" : "View",
                    },
                    {
                      id: "delete_package",
                      text: "Delete package",
                      disabled: !hasDeletePermission,
                    },
                  ]}
                  onItemClick={(itemClickDetails) => {
                    const { id } = itemClickDetails.detail;
                    if (id === "delete_package") {
                      if (selectedItem) OpenDeleteModal(true);
                    } else if (id === "edit_package") {
                      if (!!selectedItem?.id)
                        navigate(
                          PATH_DASHBOARD.credential.packages.edit(
                            selectedItem?.id,
                          ),
                        );
                    }
                  }}
                >
                  Actions
                </ButtonDropdown>
              </span>

              <span className="awsui-util-action-stripe-group">
                <Button
                  onClick={() => {
                    navigate("addnew");
                  }}
                  variant="primary"
                >
                  Start Package
                </Button>
              </span>
            </div>
          }
        >
          Credential Packages
        </Header>
      ),
      items,
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <TablePreferences
          title="Package Table Preferences"
          preferences={tablePreferences}
          setPreferences={(preferences) => {
            setTablePreferences(preferences);
          }}
          contentDisplayOptions={CREDENTIALPACKAGE_CONTENT_DISPLAY_OPTIONS}
        />
      ),
      columnDisplay: tablePreferences?.contentDisplay,
      wrapLines: tablePreferences?.wrapLines,
      stripedRows: tablePreferences?.stripedRows,
      contentDensity: tablePreferences?.contentDensity,
      stickyColumns: tablePreferences?.stickyColumns,
      ...collectionProps,
      variant: "container",
      selectionType: "single",
      resizableColumns: false,
      stickyHeader: true,
      loading: isLoading || isFetching,
      loadingText: "Loading...",
      empty: <p>No results to display.</p>,
      selectedItems: selectedItem ? [selectedItem] : [],
      onSelectionChange: ({ detail }) => {
        if (detail.selectedItems && detail.selectedItems.at(0))
          setSelectedItem(detail.selectedItems.at(0));
      },
      onRowClick: ({ detail }) => {
        if (detail.item) setSelectedItem(detail.item);
      },
      trackBy: (item) => "" + item.id,
      columnDefinitions: [
        {
          id: "id",
          header: <div>Id</div>,
          cell: (item) => <span>{item.id}</span>,
        },
        {
          id: "name",
          header: <div>Name</div>,
          cell: (item) => (
            <StyledRouterLink
              className={hasPermission ? "edit_link" : "normal"}
              to={
                hasPermission
                  ? PATH_DASHBOARD.credential.packages.edit(item.id)
                  : `#`
              }
              label={item?.name}
            />
          ),
          sortingField: "name",
        },
        {
          id: "packageType",
          header: <div>Package Type</div>,
          cell: (item) => <span>{item?.packageType?.name ?? "-"}</span>,
          sortingField: "packageType.name",
          sortingComparator: (a, b) =>
            (a?.packageType?.name ?? "").localeCompare(
              b?.packageType?.name ?? "",
            ),
        },
        {
          id: "status",
          header: <div>Status</div>,
          cell: (item) => <span>{item?.packageStatus?.name ?? "-"}</span>,
          sortingField: "packageStatus.name",
          sortingComparator: (a, b) =>
            (a?.packageStatus?.name ?? "").localeCompare(
              b?.packageStatus?.name ?? "",
            ),
        },
        {
          id: "provider",
          header: <div>Provider</div>,
          cell: (item) => <span>{item?.provider?.firstName ?? "-"}</span>,
          sortingField: "provider.firstName",
          sortingComparator: (a, b) =>
            (a?.provider?.firstName ?? "").localeCompare(
              b?.provider?.firstName ?? "",
            ),
        },
        {
          id: "credentialTemplate",
          header: <div>Credential Template</div>,
          cell: (item) => <span>{item?.credentialTemplate?.name ?? "-"}</span>,
          sortingField: "credentialTemplate.name",
          sortingComparator: (a, b) =>
            (a?.credentialTemplate?.name ?? "").localeCompare(
              b?.credentialTemplate?.name ?? "",
            ),
        },
        {
          id: "submittedDate",
          header: <div>Submitted Date</div>,
          cell: (item) => (
            <span>{ChangeTimeZone(item?.submittedDate) ?? "-"}</span>
          ),
          sortingField: "submittedDate",
        },
        {
          id: "recredentialingDate",
          header: <div>Re-credentialing Date</div>,
          cell: (item) => (
            <span>{ChangeTimeZone(item?.recredentialingDate) ?? "-"}</span>
          ),
        },
        {
          id: "followupDate",
          header: <div>Follow-up Date</div>,
          cell: (item) => <span>{item?.followupDate ?? "-"}</span>,
        },
      ],
    };
  }, [selectedItem, data, tablePreferences, items]);

  return (
    <div style={{ paddingTop: "25px" }}>
      <LoadingScreen isOpen={isLoading} />
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedItem?.id) {
            OpenDeleteModal(false);
            await notifyWrapper({
              promise: deletePackage({
                credentialPackageId: selectedItem?.id,
              }),
              resourceName: "package",
              actionName: "delete",
            });
          }
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={"Delete Package"}
        content={
          <>
            <Box>Name</Box>
            <Box variant="awsui-key-label">{selectedItem?.name}</Box>
          </>
        }
        description={"Are you sure you want to delete this package?"}
      />
    </div>
  );
}
