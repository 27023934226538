import {
  Box,
  Container,
  FormField,
  Header,
  Select,
  SelectProps,
  SpaceBetween,
} from "@cloudscape-design/components";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { FieldType } from "../../template-designer/types";
import Divider from "@mui/material/Divider";
import { STANDARD_DIGITALFORM_FIELDS } from "../../helpers/df_constants";
import { ListItemStyle } from "../../template-designer/styles";
import { RecipientType } from "../../redux/api/signaturerequest/types";
import { convertRecipentsToOptionDefinitions } from "./signature-request-field-formatter";

export function SignatureRequestTagsList({
  primaryRecipient,
  addlRecipients,
  onDragEnd,
  onDragStart,
  selectedRecipient,
  setSelectedRecipient,
}: {
  selectedRecipient: OptionDefinition | null;
  setSelectedRecipient: React.Dispatch<
    React.SetStateAction<OptionDefinition | null>
  >;
  backgroundColor?: string;
  primaryRecipient: RecipientType;
  addlRecipients: RecipientType[];
  onDragStart: React.DragEventHandler<HTMLDivElement>;
  onDragEnd: (e: React.DragEvent<HTMLDivElement>, field: FieldType) => void;
}) {
  return (
    <Container fitHeight>
      <SpaceBetween size={"m"}>
        <FormField label={<Header variant="h3">Recipient</Header>}>
          <Select
            data-id="selectedRecipient"
            triggerVariant="option"
            selectedOption={selectedRecipient}
            onChange={({ detail }) =>
              setSelectedRecipient(detail.selectedOption)
            }
            options={convertRecipentsToOptionDefinitions(
              addlRecipients,
              primaryRecipient,
            )}
          />
        </FormField>
        <Divider />
        <FormField label={<Header variant="h3">Signature Fields</Header>}>
          {STANDARD_DIGITALFORM_FIELDS.map((field) => {
            return (
              <div key={field.value}>
                {field.properties.map((property) => {
                  const annot_field: FieldType = {
                    label: [field.label, property.label].join("_"),
                    type: property.type,
                    key:
                      property.key ?? [field.value, property.value].join("_"),
                    isList: field.type === "list",
                  };
                  return (
                    <ListItemStyle
                      key={[annot_field.key].join("-")}
                      draggable
                      onDragStart={onDragStart}
                      onDragEnd={(e) => onDragEnd(e, annot_field)}
                      onClick={() => {}}
                    >
                      <SpaceBetween size={"xs"} direction="horizontal">
                        <div>{property.svg}</div>
                        <Box fontSize="body-m">{property.label}</Box>
                      </SpaceBetween>
                    </ListItemStyle>
                  );
                })}
              </div>
            );
          })}
        </FormField>
      </SpaceBetween>
    </Container>
  );
}
