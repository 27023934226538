import {
  Box,
  Container,
  Icon,
  Grid,
  TopNavigation,
  SpaceBetween,
} from "@cloudscape-design/components";
import "./signing-success.css";
export default function SigningSuccess() {
  return (
    <div>
      <TopNavigation
        identity={{
          href: "#",
          title: "Ready Doc",
          logo: {
            //pointing to logo image in public folder
            src: "/RD_Horizozntal_Color_Logo.svg",
            alt: "Readydoc logo",
          },
        }}
      />
      <Box
        margin={{ top: "xxxl" }}
        padding={{ top: "xxxl" }}
        textAlign="center"
      >
        <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}>
          <div></div>
          <SpaceBetween size="s" direction="horizontal">
            <Icon name="thumbs-up-filled" size="large" variant="link" />
            <Box variant="h1" textAlign="center">
              You're all set
            </Box>
          </SpaceBetween>
        </Grid>
      </Box>
    </div>
  );
}
