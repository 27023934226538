import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { addLoadingSpinner, removeLoadingSpinner } from "../redux/UI/actions";

function useIsLoading(isLoading: boolean) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (isLoading) dispatch(addLoadingSpinner());
    else dispatch(removeLoadingSpinner());
  }, [isLoading]);
}

export default useIsLoading;
