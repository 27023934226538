import {
  Button,
  Container,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { useNavigate } from "react-router-dom";
import useIsLoading from "../hooks/useIsLoading";
import { useGetOrganizationDetailsQuery } from "../redux/api/organization/organization";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";
import Label from "../components/Label";
import FormSection from "../components/FormSection";
import LoadingScreen from "../components/LoadingScreen";

function OrganizationSettings() {
  const {
    data: organization,
    fulfilledTimeStamp,
    isLoading,
  } = useGetOrganizationDetailsQuery();

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("organization_settings.edit") ?? false),
  );

  useIsLoading(isLoading);

  const navigate = useNavigate();

  return (
    <div style={{ paddingTop: "25px" }}>
      <LoadingScreen isOpen={isLoading} />
      <SpaceBetween size={"s"}>
        <Header>Organization</Header>
        <SpaceBetween size={"xl"}>
          <Container
            header={
              <Header
                actions={
                  <Button
                    disabled={!hasPermission}
                    onClick={() => {
                      navigate("details/edit");
                    }}
                  >
                    Edit
                  </Button>
                }
              >
                Basic details
              </Header>
            }
          >
            <SpaceBetween size={"l"}>
              <FormSection columns={2}>
                <Label label={"Name"} value={organization?.name} />
                <Label label={"Phone number"} value={organization?.phone} />
              </FormSection>
              <FormSection columns={2} header={"Address"}>
                <Label
                  label={"Address Lane 1"}
                  value={organization?.organizationAddress?.addressLane1}
                />
                <Label
                  label={"Address Lane 1"}
                  value={organization?.organizationAddress?.addressLane2}
                />
                <Label
                  label={"City"}
                  value={organization?.organizationAddress?.city}
                />
                <Label
                  label={"State"}
                  value={organization?.organizationAddress?.state}
                />
                <Label
                  label={"Zip code"}
                  value={organization?.organizationAddress?.zipcode}
                />
              </FormSection>
            </SpaceBetween>
          </Container>
          <Container
            header={
              <Header
                actions={
                  <Button
                    disabled={!hasPermission}
                    onClick={() => {
                      navigate("settings/edit");
                    }}
                  >
                    Edit
                  </Button>
                }
              >
                Settings
              </Header>
            }
          >
            <FormSection columns={2}>
              <Label
                label={"Enable Facility based roles"}
                value={
                  organization?.settings?.enableFacilityRoles ? "Yes" : "No"
                }
              />
              <Label
                label={"Enable Provider based roles"}
                value={
                  organization?.settings?.enableProviderRoles ? "Yes" : "No"
                }
              />
            </FormSection>
          </Container>
        </SpaceBetween>
      </SpaceBetween>
    </div>
  );
}

export default OrganizationSettings;
