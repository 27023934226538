import { api } from "..";
import { SignatureRequest, eSignatureResponse } from "./types";

const SignatureRequestAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAllSignatureRequests: build.query<eSignatureResponse[], void>({
      query: () => "/signature/all",
      providesTags: ["SignatureRequest"],
    }),
    getSignatureRequest: build.query<eSignatureResponse, { id: string }>({
      query: ({ id }) => `/signature/${id}`,
      providesTags: ["SignatureRequest"],
    }),
    addDigitalformSignatureRequest: build.mutation<
      eSignatureResponse,
      { formId: string } & SignatureRequest
    >({
      query: ({ formId, ...body }) => {
        return {
          url: `digitalform/${formId}/requestsignature`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["SignatureRequest"],
    }),
    addPayerEnrollmentSignatureRequest: build.mutation<
      eSignatureResponse,
      { payerEnrollmentId: string } & SignatureRequest
    >({
      query: ({ payerEnrollmentId, ...body }) => {
        return {
          url: `payer/enrollment/${payerEnrollmentId}/requestsignature`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["SignatureRequest"],
    }),
  }),
});

export const {
  useGetAllSignatureRequestsQuery,
  useGetSignatureRequestQuery,
  useAddDigitalformSignatureRequestMutation,
  useAddPayerEnrollmentSignatureRequestMutation,
} = SignatureRequestAPI;
