import {
  Box,
  Button,
  ButtonDropdown,
  Header,
  Pagination,
} from "@cloudscape-design/components";
import Table, { TableProps } from "@cloudscape-design/components/table";
import "./index.css";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { ApplicationState } from "../../../redux/store";
import { CategoryType } from "../../../redux/api/managecategory/types";
import {
  useDeleteCategoryMutation,
  useGetAllCategoryQuery,
} from "../../../redux/api/managecategory/managecategory";
import { setModalName } from "../../../redux/UI/actions";
import {
  CATEGORY_CREATE_MODAL,
  CATEGORY_EDIT_MODAL,
} from "../../../helpers/constants";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import DeleteAlertModal from "../../../components/Modal/delete-alert-modal";
import useAsyncNotifyWrapper from "../../../hooks/useAsyncNotifyWrapper";
import {
  CATEGORY_CONTENT_DISPLAY_OPTIONS,
  CATEGORY_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../../../common/table-config";
import { useLocalStorage } from "../../../common/localStorage";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableEmptyState } from "../../../common/common-components";
import LoadingScreen from "../../../components/LoadingScreen";

interface PropsFromDispatch {
  openModalName: typeof setModalName;
}

function CategoryTable({ openModalName }: PropsFromDispatch) {
  const { managecategoryId } = useParams();
  const navigate = useNavigate();
  const [selectedCategory, setSelectCategory] = useState<CategoryType>();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const { data = [], isFetching, isLoading } = useGetAllCategoryQuery();
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Category-Table-Preferences",
    CATEGORY_TABLE_DEFAULT_PREFERENCES,
  );
  const { items, collectionProps, paginationProps } = useCollection(data, {
    propertyFiltering: {
      filteringProperties: [],
      empty: <TableEmptyState resourceName="Category" />,
    },
  });
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("category.edit") ?? false),
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("category.delete") ?? false),
  );
  useEffect(() => {
    const temp = data.find(
      (category) => "" + category.id === "" + managecategoryId,
    );
    if (temp) setSelectCategory(temp);
  }, [managecategoryId, data]);
  const [deleteCategory] = useDeleteCategoryMutation();

  const tableProps: TableProps<CategoryType> = {
    header: (
      <Header
        counter={`(${data.length})`}
        actions={
          <div>
            <span className="awsui-util-action-stripe-group">
              <ButtonDropdown
                expandableGroups
                disabled={!selectedCategory}
                items={[
                  {
                    id: "edit_form",
                    text: hasPermission ? "Edit" : "View",
                  },
                  {
                    id: "delete_form",
                    text: "Delete",
                    disabled: !hasDeletePermission,
                  },
                ]}
                onItemClick={(itemClickDetails) => {
                  const { id } = itemClickDetails.detail;
                  if (id === "delete_form") {
                    if (!!selectedCategory) {
                      OpenDeleteModal(true);
                    }
                  } else if (id === "edit_form") {
                    openModalName(CATEGORY_EDIT_MODAL);
                  }
                }}
              >
                Actions
              </ButtonDropdown>
            </span>
            <span className="awsui-util-action-stripe-group">
              <Button
                disabled={!hasPermission}
                onClick={() => {
                  openModalName(CATEGORY_CREATE_MODAL);
                }}
                variant="primary"
              >
                Create Category
              </Button>
            </span>
          </div>
        }
      >
        Category
      </Header>
    ),
    items,
    pagination: <Pagination {...paginationProps} />,
    preferences: (
      <TablePreferences
        title="Category Table Preferences"
        preferences={tablePreferences}
        setPreferences={(preferences) => {
          setTablePreferences(preferences);
        }}
        contentDisplayOptions={CATEGORY_CONTENT_DISPLAY_OPTIONS}
      />
    ),
    columnDisplay: tablePreferences?.contentDisplay,
    wrapLines: tablePreferences?.wrapLines,
    stripedRows: tablePreferences?.stripedRows,
    contentDensity: tablePreferences?.contentDensity,
    stickyColumns: tablePreferences?.stickyColumns,
    ...collectionProps,
    variant: "container",
    selectionType: "single",
    resizableColumns: false,
    stickyHeader: true,
    loading: isLoading || isFetching,
    empty: <p>No results to display.</p>,
    selectedItems: selectedCategory ? [selectedCategory] : [],
    onSelectionChange: (selectionChangeDetail) => {
      const temp_categoryId =
        selectionChangeDetail.detail.selectedItems.at(0)?.id;
      if (temp_categoryId) navigate(`/managecategory/${temp_categoryId}`);
    },
    onRowClick: (onRowClickDetail) => {
      const temp = onRowClickDetail.detail.item.id;
      if (temp) navigate(`/managecategory/${temp}`);
    },
    trackBy: (item) => "" + item.id,
    columnDefinitions: [
      {
        id: "id",
        header: <div>id</div>,
        cell: (item) => <span>{item.id}</span>,
      },
      {
        id: "name",
        header: <div>Name</div>,
        cell: (item) => (
          <div
            className={hasPermission ? "edit_link" : ""}
            onClick={() => {
              hasPermission
                ? openModalName(CATEGORY_EDIT_MODAL)
                : openModalName("");
            }}
          >
            {item.name}
          </div>
        ),
      },
      {
        id: "description",
        header: <div>Description</div>,
        cell: (item) => <span>{item.description}</span>,
      },
      {
        id: "groupname",
        header: <div>Group Name</div>,
        cell: (item) => <span>{item.categoryGroup.name}</span>,
      },
      {
        id: "stateRequired",
        header: <div>State Required</div>,
        cell: (item) => <span>{item.stateRequired ? "Yes" : "No"}</span>,
      },
      {
        id: "expirationRequired",
        header: <div>Expiration Required</div>,
        cell: (item) => <span>{item.expirationRequired ? "Yes" : "No"}</span>,
      },
    ],
  };
  return (
    <div>
      <LoadingScreen isOpen={isLoading} />
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedCategory?.id)
            await notifyWrapper({
              promise: deleteCategory({
                categoryId: selectedCategory?.id.toString(),
              }),
              resourceName: "category",
              actionName: "delete",
            });
          OpenDeleteModal(false);
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={"Delete Category"}
        content={
          <>
            <Box>Category Name</Box>
            <Box variant="awsui-key-label">{selectedCategory?.name}</Box>
          </>
        }
        description={"Are you sure you want to delete this category?"}
      />
    </div>
  );
}

const mapDispatchToProps = {
  openModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(CategoryTable);
