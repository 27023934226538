import { RecipientType } from "../redux/api/signaturerequest/types";

export const RecipientTypeOptions = [
  { label: "Provider", value: "provider" },
  { label: "Member", value: "member" },
  { label: "Email", value: "email" },
];

export function getRecipientType(recipient?: RecipientType) {
  if (!!recipient?.provider) return RecipientTypeOptions[0];
  else if (!!recipient?.member) return RecipientTypeOptions[1];
  else if (!!recipient?.emailAddress) return RecipientTypeOptions[2];
  else return RecipientTypeOptions[0];
}

export function getRecipientTypeOption(recipient?: RecipientType) {
  return (
    RecipientTypeOptions.find((option) => option.value === recipient?.type) ??
    RecipientTypeOptions[0]
  );
}

export const AnnotationColor = {
  //Default colors
  DefaultFillColor: { r: 255, g: 255, b: 255, a: 1 }, // white
  DefaultTextColor: { r: 0, g: 0, b: 0, a: 1 },
  DefaultPlaceHolderTextColor: { r: 102, g: 102, b: 102, a: 1 },
  DefaultStrokeColor: { r: 0, g: 165, b: 228, a: 1 }, //Stroke is same as border
  DefaultBorderColor: { r: 0, g: 0, b: 0, a: 1 }, // white
  //required colors
  RequiredStrokeColor: { r: 255, g: 0, b: 0, a: 1 }, //red
  RequiredBorderColor: { r: 255, g: 0, b: 0, a: 1 }, //red
};
