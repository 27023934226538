import {
  BreadcrumbGroup,
  Button,
  Container,
  ExpandableSection,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import InnerAppLayout from "../../components/InnerAppLayout";
import { Divider } from "semantic-ui-react";
import FormSection from "../../components/FormSection";
import RHFTextField from "../../components/RHF/RHFTextField";
import { useCreatePayerMutation } from "../../redux/api/payers/payers";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";

type FormInputProps = {
  name: string;
  payerId: string;
  addressLane1: string;
  addressLane2: string;
  city: string;
  state: string;
  zipcode: string;
};

export default function AddPayer() {
  const methods = useForm<FormInputProps>();
  const navigate = useNavigate();
  const [addPayer] = useCreatePayerMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const onSubmit = async (data: FormInputProps) => {
    await notifyWrapper({
      promise: addPayer({
        name: data?.name,
        payerId: data?.payerId,
        payerAddress: {
          addressLane1: data?.addressLane1,
          addressLane2: data?.addressLane2,
          city: data?.city,
          state: data?.state,
          zipcode: data?.zipcode,
        },
      }),
      resourceName: "payer",
      actionName: "add",
    });

    navigate(`/payers`);
  };

  return (
    <InnerAppLayout
      breadcrumbGroup={
        <BreadcrumbGroup
          items={[
            { text: "Payers", href: `/payers` },

            { text: `Add Payer`, href: "#" },
          ]}
        />
      }
      content={
        <Container
          header={
            <SpaceBetween size="xxs">
              <Header>Payer</Header>
              <Divider></Divider>
            </SpaceBetween>
          }
        >
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Form
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      onClick={() => {
                        navigate("/payers");
                      }}
                      formAction="none"
                    >
                      Cancel
                    </Button>
                    <Button formAction="submit" variant="primary">
                      Submit
                    </Button>
                  </SpaceBetween>
                }
              >
                <FormSection columns={1}>
                  <RHFTextField
                    label="Name"
                    name="name"
                    stretch={false}
                    rules={{ required: "This field is required" }}
                  />
                  <RHFTextField
                    label="Payer ID"
                    name="payerId"
                    stretch={false}
                  />
                  <ExpandableSection headerText="Payer Address">
                    <FormSection header="" columns={2}>
                      <RHFTextField
                        label="Address Lane 1"
                        name="addressLane1"
                        stretch={false}
                      />
                      <RHFTextField
                        label="Address Lane 2"
                        name="addressLane2"
                        stretch={false}
                      />
                      <RHFTextField label="City" name="city" stretch={false} />
                      <RHFTextField
                        label="State"
                        name="state"
                        stretch={false}
                      />
                      <RHFTextField
                        label="Zip Code"
                        name="zipcode"
                        stretch={false}
                      />
                    </FormSection>
                  </ExpandableSection>
                </FormSection>
              </Form>
            </form>
          </FormProvider>
        </Container>
      }
    />
  );
}
