import FormField, {
  FormFieldProps,
} from "@cloudscape-design/components/form-field";
import { useFormContext, Controller } from "react-hook-form";
import { format as FnsFormat } from "date-fns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
// ----------------------------------------------------------------------

type IProps = {
  name: string;
  defaultValue?: string;
  rules?: { required: string | boolean };
  disabled?: boolean;
  testid?: string;
};

type RFimProps = {
  accept?: RegExp;
  dateFormat?: string;
  replace?: (str: string) => string;
};

type Props = IProps & FormFieldProps & RFimProps;

export default function RHFDatePicker({
  name,
  rules,
  label,
  errorText,
  description,
  defaultValue,
  stretch,
  testid,
  replace,
}: Props) {
  const { control } = useFormContext();

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue ?? null}
        render={({ field, fieldState }) => {
          return (
            <FormField
              label={
                <span>
                  {label}{" "}
                  {rules?.required && (
                    <span style={{ color: "#fc0a37" }}>*</span>
                  )}
                </span>
              }
              info={
                description && (
                  <span>
                    <i>{description}</i>
                  </span>
                )
              }
              errorText={errorText ?? fieldState.error?.message}
              stretch={stretch ?? true}
            >
              <DatePicker
                sx={{
                  display: "flex",
                  "& .Mui-error": {
                    "& fieldset": {
                      borderColor: "#7d8998 !important",
                    },
                  },
                  "& .MuiInputBase-root": {
                    border: 1,
                    borderRadius: "var(--border-radius-input-plgbrq, 8px)",
                  },
                  "& .MuiInputBase-input": {
                    borderColor: !!fieldState.error?.message
                      ? "var(--color-text-status-error-wdvepn, #d91515) !important"
                      : "",
                    paddingLeft: !!fieldState.error?.message
                      ? "calc( var(--space-field-horizontal-gg19kw, 12px) - (var(--border-invalid-width-5ktjp2, 8px) - var(--border-field-width-09w7vk, 2px)) ) !important"
                      : "",
                    borderLeftWidth: !!fieldState.error?.message
                      ? "var(--border-invalid-width-5ktjp2, 8px) !important"
                      : "",
                    height: "32px !important",
                    padding: "0px 14px !important",
                    color: !!fieldState.error?.message
                      ? "var(--color-text-status-error-wdvepn, #d91515) !important"
                      : "var(--color-text-body-default-2sxhhn, #000716)",
                    width: "100%",
                    cursor: "text",
                    boxSizing: "border-box",
                    backgroundColor:
                      "var(--color-background-input-default-igdh5e, #ffffff)",
                    borderRadius: "var(--border-radius-input-plgbrq, 8px)",
                    fontSize: "var(--font-body-m-size-dhtyxm, 14px)",
                    lineHeight: "var(--font-body-m-line-height-5wv9x1, 20px)",
                    borderCollapse: "separate",
                    borderSpacing: "0",
                  },
                }}
                data-testId={testid}
                value={
                  moment.isMoment(field.value)
                    ? field.value
                    : moment(field.value)
                }
                onChange={(newValue) => {
                  field.onChange(newValue);
                }}
              />
            </FormField>
          );
        }}
        rules={rules}
      />
    </LocalizationProvider>
  );
}

const defaultFormat = (v: string) => FnsFormat(new Date(v), "MM/dd/yyyy");
