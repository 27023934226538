import {
  Box,
  Button,
  FormField,
  Header,
  Icon,
  Input,
  Link,
  Modal,
  Select,
  SpaceBetween,
} from "@cloudscape-design/components";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setModalName } from "../../../redux/UI/actions";
import { Divider } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useGetProviderQuery } from "../../../redux/api/provider/provider";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import useAsyncNotifyWrapper from "../../../hooks/useAsyncNotifyWrapper";
import { useSaveVerficationMutation } from "../../../redux/api/document/document";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function AddAutoPSVModal({ setModalName }: PropsFromDispatch) {
  const [selectedOption, setSelectedOption] = useState<OptionDefinition | null>(
    null,
  );
  const [inputValue, setInputValue] = useState("");

  const { providerId, documentId } = useParams();

  const { data: provider } = useGetProviderQuery(
    !!providerId
      ? {
          providerId: providerId,
        }
      : skipToken,
  );

  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [addVerification] = useSaveVerficationMutation();

  useEffect(() => {
    if (selectedOption) {
      const temp = provider?.providerLicenses?.find(
        (providerLicense) => providerLicense.state === selectedOption.value,
      );
      temp ? setInputValue(temp.licenseNumber) : setInputValue("");
    }
  }, [provider?.providerLicenses, selectedOption]);

  const handleSubmit = async (
    selectedOption: OptionDefinition,
    inputValue: string,
  ) => {
    if (documentId && selectedOption.value !== undefined) {
      setModalName("");
      await notifyWrapper({
        promise: addVerification({
          documentId,
          selectedOptionValue: selectedOption.value,
          license_no: inputValue,
        }),
        resourceName: "verification",
        actionName: "save",
      });
    }
  };

  return (
    <Modal
      visible={true}
      size="medium"
      header={<Header variant="h2">Add Verification</Header>}
      onDismiss={() => {
        setModalName("");
      }}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="link"
              formAction="none"
              onClick={() => {
                setModalName("");
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                navigate(`/safe/${providerId}/documents/${documentId}/psv/add`);

                setModalName("");
              }}
            >
              Customize
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                if (selectedOption) {
                  handleSubmit(selectedOption, inputValue);
                }
              }}
            >
              Submit
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size="s" direction="vertical">
        <Divider></Divider>
        <FormField
          label="Source"
          info={
            selectedOption?.description && (
              <Link external={true} href={selectedOption?.description}>
                {selectedOption?.description}{" "}
                <Icon name="external" variant="link" />
              </Link>
            )
          }
        >
          <Select
            selectedOption={selectedOption}
            onChange={({ detail }) => setSelectedOption(detail.selectedOption)}
            options={[
              {
                label: "State licenses",
                value: "statelicense",
                options: [
                  {
                    label: "New jersey",
                    value: "newjersey",
                    description:
                      "https://newjersey.mylicense.com/verification/Search.aspx",
                  },
                  {
                    label: "New york",
                    value: "newyork",
                    description: "https://www.op.nysed.gov/verification-search",
                  },
                ],
              },
            ]}
          />
        </FormField>

        {selectedOption && (
          <FormField label="License No">
            <Input
              value={inputValue}
              onChange={(event) => setInputValue(event.detail.value)}
            />
          </FormField>
        )}
      </SpaceBetween>
    </Modal>
  );
}

const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(AddAutoPSVModal);
