import Grid from "@cloudscape-design/components/grid";
import { serviceOverview } from "./wdigets/service-overview";
import { BaseStaticWidget } from "./wdigets/basic-static-widget";
import { usageByFacility } from "./wdigets/usage-by-facility";
import { eSignatureStatus } from "./wdigets/eSignatures-status";
import { documentsSnapShot } from "./wdigets/document-snapshot";

export default function Dashboard() {
  return (
    <Grid
      gridDefinition={[
        { colspan: { l: 12, m: 12, default: 12 } },
        { colspan: { l: 8, m: 8, default: 12 } },
        { colspan: { l: 4, m: 4, default: 12 } },
        { colspan: { l: 12, m: 12, default: 12 } },
        { colspan: { l: 12, m: 12, default: 12 } },
        { colspan: { l: 6, m: 6, default: 12 } },
        { colspan: { l: 6, m: 6, default: 12 } },
        { colspan: { l: 6, m: 6, default: 12 } },
        { colspan: { l: 8, m: 8, default: 12 } },
        { colspan: { l: 4, m: 4, default: 12 } },
      ]}
    >
      {[
        serviceOverview,
        usageByFacility,
        eSignatureStatus,
        documentsSnapShot,
      ].map((widget, index) => (
        <BaseStaticWidget key={index} config={widget} />
      ))}
    </Grid>
  );
}
