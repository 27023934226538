import {
    BreadcrumbGroup,
    Button,
    Container,
    Form,
    Header,
    SpaceBetween,
} from "@cloudscape-design/components";
import InnerAppLayout from "../../../components/InnerAppLayout";
import FormSection from "../../../components/FormSection";
import RHFTextField from "../../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useAsyncNotifyWrapper from "../../../hooks/useAsyncNotifyWrapper";
import { useEffect } from "react";
import {  useGetPrivilegeTypeQuery, useUpdatePrivilegeTypeMutation } from "../../../redux/api/privileges/privileges";
import { PrivilegeType } from "../../../redux/api/privileges/types";
import RHFTextArea from "../../../components/RHF/RHFTextArea";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";

export default function EditPrivilegeType() {
    const methods = useForm<PrivilegeType>();
    const { id } = useParams();
    const { data: privilegetype, fulfilledTimeStamp } = useGetPrivilegeTypeQuery(!!id?{
        privilegeTypeId: id ,
      }:skipToken);

    
    const [updatePrivilegeType] = useUpdatePrivilegeTypeMutation();
    
    const navigate = useNavigate();
    const { notifyWrapper } = useAsyncNotifyWrapper();
    const onSubmit = async (data: PrivilegeType) => {
        if (!!id) {
            await notifyWrapper({
                promise: updatePrivilegeType({
                    ...data,
                }),
                resourceName: "privilegeType",
                actionName: "update",
            });
            navigate(-1);
        }
    };
    useEffect(() => {
        if (!!privilegetype) {
            methods.setValue("id", privilegetype.id);
            methods.setValue("name", privilegetype?.name);
            methods.setValue("description", privilegetype?.description);
        }

    }, [fulfilledTimeStamp, privilegetype]);

    return (
        <InnerAppLayout
            breadcrumbGroup={
                <BreadcrumbGroup
                    items={[
                        { text: "Privilege Type", href: `/privilegetype` },

                        { text: `Edit privilege Type`, href: "#" },
                    ]}
                />
            }
            content={
                <Container header={<Header>Privilege Type</Header>}>
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                            <Form
                                actions={
                                    <SpaceBetween direction="horizontal" size="xs">
                                        <Button
                                            onClick={() => {
                                                navigate("/privilegetype");
                                            }}
                                            formAction="none"
                                        >
                                            Cancel
                                        </Button>
                                        <Button formAction="submit" variant="primary">
                                            Submit
                                        </Button>
                                    </SpaceBetween>
                                }
                            >
                                <FormSection
                                    gridDefinition={[
                                        { colspan: { default: 12, s: 2 } },
                                        { colspan: { default: 12, s: 9 } },
                                    ]}
                                >
                                    <RHFTextField
                                        label="Name"
                                        name="name"
                                        stretch={false}
                                        rules={{ required: "This field is required" }}
                                    />
                                    <RHFTextArea
                                        label="Description"
                                        name="description"
                                        stretch={false}
                                    />
                                </FormSection>
                            </Form>
                        </form>
                    </FormProvider>
                </Container>
            }
        />
    );
}
