import { api } from "..";
import {
  CategoryType,
  CategoryCreateRequest,
  GroupsType,
  GroupsCreateRequestType,
  ManageCategory,
  UpdateCategoryType,
  UpdateGroupsType,
  Category,
} from "./types";

const ManageCategoryApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllManageCategory: build.query<ManageCategory[], void>({
      query: () => "managecategory",
      providesTags: ["ManageCategory"],
    }),
    getAllCategory: build.query<CategoryType[], void>({
      query: () => "category/all",
      providesTags: ["ManageCategory"],
    }),
    getAllCategories: build.query<Category[], void>({
      query: () => "category/all",
      providesTags: ["ManageCategory"],
    }),
    createCategory: build.mutation<void, CategoryCreateRequest>({
      query: ({ ...body }) => {
        return {
          url: `category`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["ManageCategory"],
    }),
    getCategory: build.query<CategoryType, { categoryId: string }>({
      query: ({ categoryId }) => `category/${categoryId}`,
      providesTags: ["ManageCategory"],
    }),
    updateCategory: build.mutation<
      void,
      Pick<CategoryType, "id"> & Partial<UpdateCategoryType>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `category/${id}`,
          method: "PUT",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "ManageCategory", id: arg.id },
        { type: "ManageCategory" },
      ],
    }),
    deleteCategory: build.mutation<
      void,
      {
        categoryId: string;
      }
    >({
      query: ({ categoryId }) => ({
        url: `category/${categoryId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ManageCategory"],
    }),
    getAllCategoryGroup: build.query<GroupsType[], void>({
      query: () => "category/group/all",
      providesTags: ["ManageCategory"],
    }),
    getCategoryGroup: build.query<GroupsType, { id: string }>({
      query: ({ id }) => `category/group/${id}`,
      providesTags: (result) => [{ type: "ManageCategory", id: result?.id }],
    }),
    createGroup: build.mutation<void, GroupsCreateRequestType>({
      query: ({ ...body }) => {
        return {
          url: `category/group`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["ManageCategory"],
    }),
    deleteGroups: build.mutation<
      void,
      {
        groupsId: string;
      }
    >({
      query: ({ groupsId }) => ({
        url: `category/group/${groupsId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ManageCategory"],
    }),

    updateGroup: build.mutation<void, UpdateGroupsType>({
      query: ({ id, ...patch }) => {
        return {
          url: `category/group/${id}`,
          method: "PUT",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "ManageCategory", id: arg.id },
        { type: "ManageCategory" },
      ],
    }),
    saveAllCategories: build.mutation<void, Category[]>({
      query: (types) => {
        return {
          url: `category/all`,
          method: "POST",
          body: types,
        };
      },
      invalidatesTags: ["ManageCategory"],
    }),
  }),
});

export const {
  useGetAllManageCategoryQuery,
  useGetAllCategoryQuery,
  useGetAllCategoriesQuery,
  useCreateCategoryMutation,
  useGetCategoryQuery,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
  useCreateGroupMutation,
  useDeleteGroupsMutation,
  useUpdateGroupMutation,
  useGetAllCategoryGroupQuery,
  useGetCategoryGroupQuery,
  useSaveAllCategoriesMutation,
} = ManageCategoryApi;
