import {
  useGetOrganizationDetailsQuery,
  useUpdateOrganizationSettingsMutation,
} from "../redux/api/organization/organization";
import {
  Button,
  Container,
  Form,
  Header,
  SpaceBetween,
  Tiles,
} from "@cloudscape-design/components";
import useIsLoading from "../hooks/useIsLoading";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApplicationState } from "../redux/store";
import { useSelector } from "react-redux";
import useAsyncNotifyWrapper from "../hooks/useAsyncNotifyWrapper";

export default function OrgSettingsEdit() {
  const {
    data: organization,
    fulfilledTimeStamp,
    isLoading,
  } = useGetOrganizationDetailsQuery();

  const [updateOrganizationSettings, { isLoading: isUpdating }] =
    useUpdateOrganizationSettingsMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [value, setValue] = useState("");

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("organization_settings.edit") ?? false)
  );

  useIsLoading(isLoading || isUpdating);

  const onSubmit = async () => {
    if (organization?.id)
      await notifyWrapper({
        promise: updateOrganizationSettings({
          enableFacilityRoles: value === "enable_facility_roles",
          enableProviderRoles: value === "enable_provider_roles",
          id: organization?.id,
        }),
        resourceName: "organization",
        actionName: "update",
      });
    navigate(-1);
  };

  useEffect(() => {
    if (organization) {
      if (organization?.settings?.enableFacilityRoles) {
        setValue("enable_facility_roles");
      } else if (organization?.settings?.enableProviderRoles) {
        setValue("enable_provider_roles");
      } else {
        setValue("no_selections");
      }
    }
  }, [fulfilledTimeStamp]);

  const navigate = useNavigate();
  return (
    <div style={{ paddingTop: "25px" }}>
      <Form
        header={<Header>Edit organization settings</Header>}
        actions={
          <SpaceBetween size="s" direction="horizontal">
            <Button
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={!hasPermission}
              onClick={() => {
                onSubmit();
              }}
              variant="primary"
            >
              Save
            </Button>
          </SpaceBetween>
        }
      >
        <Container header={<Header>Organization settings</Header>}>
          <Tiles
            onChange={({ detail }) => setValue(detail.value)}
            value={value}
            columns={2}
            items={[
              {
                label: "Enable facility association in roles",
                description:
                  "Roles will be associated with providers, you can control access to application by providers.",
                value: "enable_facility_roles",
              },
              {
                label: "Enable provider association in  roles",
                description:
                  "Roles will be associated with facilities, you can control access to application by facilities.",
                value: "enable_provider_roles",
              },
              {
                label:
                  "Disable role association (Provider & Facility associations)",
                description:
                  "Roles will be associated with providers, you can control access to application by providers.",
                value: "no_selections",
              },
            ]}
          />
        </Container>
      </Form>
    </div>
  );
}
