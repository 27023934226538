import {
  Button,
  Header,
  Link,
  SpaceBetween,
  Table,
} from "@cloudscape-design/components";
import { useState } from "react";
import { skipToken } from "@reduxjs/toolkit/query";
import { useParams } from "react-router";
import { DigitalForm } from "../../../redux/api/digitalform/types";
import {
  useDeleteCredentialPackageFormsMutation,
  useGetAllCredentialPackageFormsQuery,
} from "../../../redux/api/credentialpackages/credentialpackages";
import CredentailPackageAddFormModal from "../modals/credentialpackage-add-forms-modal";

export default function CredentialPackageFormsList({
  selectedForms,
  setSelectForms,
}: {
  forms: DigitalForm[];
  selectedForms: DigitalForm[];
  setSelectForms: (selected: DigitalForm[]) => void;
}) {
  const { id: credentialPackageId } = useParams();
  const { data: digitalforms = [] } = useGetAllCredentialPackageFormsQuery(
    credentialPackageId ? { credentialPackageId } : skipToken,
  );
  const [isAddFormModalVisible, setIsAddFormModalVisible] =
    useState<boolean>(false);
  const [deleteCredentialPackageForms] =
    useDeleteCredentialPackageFormsMutation();
  return (
    <>
      <Table
        columnDefinitions={[
          {
            id: "name",
            header: "Name",
            cell: (item) => <div>{item?.name || "-"}</div>,
            sortingField: "name",
            isRowHeader: true,
          },
          {
            id: "type",
            header: "Type",
            cell: (item) => <div>{item?.digitalFormType?.type || ""}</div>,
            sortingField: "type",
          },
        ]}
        items={digitalforms}
        selectionType="multi"
        selectedItems={selectedForms ?? []}
        onSelectionChange={({ detail }) => {
          if (!!detail.selectedItems) setSelectForms(detail.selectedItems);
        }}
        onRowClick={({ detail }) => {
          const temp = selectedForms;
          if (!detail.item) return;
          if (temp?.includes(detail.item))
            setSelectForms(temp.filter((item) => item !== detail.item));
          else setSelectForms([detail.item, ...temp]);
        }}
        stickyHeader
        loadingText="Loading resources"
        sortingDisabled
        header={
          <Header
            variant="h3"
            actions={
              <SpaceBetween size="s" direction="horizontal">
                <Button
                  onClick={() => {
                    if (!selectedForms || !credentialPackageId) return;
                    const formsId = selectedForms.map((form) => form.id + "");
                    deleteCredentialPackageForms({
                      credentialPackageId: credentialPackageId,
                      forms: formsId,
                    });
                  }}
                >
                  Remove
                </Button>
                <Button
                  onClick={() => {
                    setIsAddFormModalVisible(true);
                  }}
                >
                  Add Ready Form
                </Button>
              </SpaceBetween>
            }
            counter={`(${digitalforms?.length})`}
          >
            Ready Forms
          </Header>
        }
      />
      <CredentailPackageAddFormModal
        closeModal={() => {
          setIsAddFormModalVisible(false);
        }}
        visible={isAddFormModalVisible}
      />
    </>
  );
}
