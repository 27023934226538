import { Container, Header } from "@cloudscape-design/components";
import { Box, styled } from "@mui/material";

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

export default function PermissionDenied() {
  return (
    <Box>
      <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
        <div>
          <Container
            header={
              <div
                style={{
                  textAlign: "center",
                  alignItems: "center !important",
                  flexDirection: "row",
                  display: "flex",
                  margin: "auto",
                  maxWidth: 480,
                }}
              >
                <Header>No permission</Header>
              </div>
            }
          >
            <div>
              The page you're trying to access has restricted access.
              <br />
              Please contact to your account owner.
            </div>
          </Container>
        </div>
      </ContentStyle>
    </Box>
  );
}
