import { SideNavigation } from "@cloudscape-design/components";
import { PATH_DASHBOARD } from "../routes/paths";

type active = {
  activeHref: string;
};

export default function SideBar({ activeHref }: active) {
  return (
    <SideNavigation
      header={{
        text: "Ready Doc™",
        href: "dashboard",
      }}
      activeHref={activeHref}
      items={[
        {
          type: "link",
          text: "Dashboard",
          href: PATH_DASHBOARD.dashboard.list,
        },
        {
          type: "link-group",
          text: "Providers",
          href: PATH_DASHBOARD.provider.manageprovider.list,
          items: [
            {
              type: "link",
              text: "Safe",
              href: PATH_DASHBOARD.safe.list,
            },
          ],
        },
        {
          type: "link",
          text: "Facilities",
          href: PATH_DASHBOARD.managefacilities.list,
        },
        {
          type: "link",
          text: "Ready Forms",
          href: PATH_DASHBOARD.digitalforms.list,
        },
        {
          type: "link",
          text: "Privileges",
          href: PATH_DASHBOARD.privileges.list,
        },
        {
          type: "link",
          text: "e-Signatures",
          href: PATH_DASHBOARD.eSignatures.list,
          // info: <Badge color="blue">New</Badge>,
        },
        {
          type: "link",
          text: "Contracts",
          href: PATH_DASHBOARD.contracts.list,
        },
        {
          type: "link-group",
          text: "Payer Enrollments",
          href: PATH_DASHBOARD.payerenrollment.list,
          items: [
            {
              type: "link",
              text: "Payer Setup",
              href: PATH_DASHBOARD.payers.list,
            },
          ],
        },
        {
          type: "link-group",
          text: "Credential Packages",
          href: PATH_DASHBOARD.credential.packages.list,
          items: [
            {
              type: "link",
              text: "Credential Templates",
              href: PATH_DASHBOARD.credential.templates.list,
            },
          ],
        },
        {
          type: "link-group",
          text: "Reports",
          href: PATH_DASHBOARD.reports.list,
          items: [
            {
              type: "link",
              text: "Expired Documents",
              href: PATH_DASHBOARD.expireddocuments.list,
            },
            {
              type: "link",
              text: "Expiring Documents",
              href: PATH_DASHBOARD.expiringdocuments.list,
            },
          ],
        },
        {
          type: "link",
          text: "Roles and Permissions",
          href: PATH_DASHBOARD.roles.list,
        },
      ]}
    />
  );
}
