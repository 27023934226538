import {
  Box,
  Button,
  ButtonDropdown,
  Header,
  Link,
  Pagination,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import "./index.css";
import { connect } from "react-redux";
import { setModalName } from "../redux/UI/actions";
import { Provider } from "../redux/api/provider/types";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProviderFilters from "../components/filter/provider-filters";
import useFilter from "../hooks/useFilter";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";
import DeleteAlertModal from "../components/Modal/delete-alert-modal";
import { useDeleteProviderMutation } from "../redux/api/provider/provider";
import useAsyncNotifyWrapper from "../hooks/useAsyncNotifyWrapper";
import { TableEmptyState } from "../common/common-components";
import { useLocalStorage } from "../common/localStorage";
import {
  PROVIDER_TABLE_DEFAULT_PREFERENCES,
  PROVIDER_CONTENT_DISPLAY_OPTIONS,
  TablePreferences,
} from "../common/table-config";
import LoadingScreen from "../components/LoadingScreen";
import UploadNewFile from "../components/UploadNewFile";
import UploadCSV from "../components/UploadCSV";
import { ADD_MANAGEPROVIDER_MODAL } from "../helpers/constants";

interface PropsFromDispatch {
  openModalName: typeof setModalName;
}

function ManageProviderTable({ openModalName }: PropsFromDispatch) {
  const navigate = useNavigate();
  const { providerId } = useParams();
  const [selectedItem, setSelectedItem] = useState<Provider>();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const [deleteManageProvider] = useDeleteProviderMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  // const [fileName, setFileName] = useState<File>();
  const [isUploadFileOpen, OpenUploadFileModal] = useState<boolean>(false);
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false),
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.delete") ?? false),
  );

  const {
    filteredProviders,
    filter,
    isLoading,
    isFetching,
    setFilter,
    refetch,
  } = useFilter();

  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Provider-Table-Preferences",
    PROVIDER_TABLE_DEFAULT_PREFERENCES,
  );

  const { items, collectionProps, paginationProps } = useCollection(
    filteredProviders,
    {
      propertyFiltering: {
        filteringProperties: [],
        empty: <TableEmptyState resourceName="Providers" />,
      },
      sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
    },
  );

  useEffect(() => {
    const temp = filteredProviders.find(
      (provider) => "" + provider.id === "" + providerId,
    );
    if (temp) setSelectedItem(temp);
    else {
      if (filteredProviders.at(0)?.id)
        navigate(`/manageprovider/${filteredProviders.at(0)?.id}`);
    }
  }, [providerId, filteredProviders, navigate]);

  const tableProps: TableProps<Provider> = useMemo(() => {
    return {
      header: (
        <Header
          counter={`(${filteredProviders.length})`}
          actions={
            <div>
              <span className="awsui-util-action-stripe-group">
                <Button
                  variant="normal"
                  iconName="refresh"
                  onClick={() => {
                    refetch();
                  }}
                ></Button>
              </span>
              <span className="awsui-util-action-stripe-group">
                <ButtonDropdown
                  expandableGroups
                  disabled={!selectedItem}
                  items={[
                    {
                      id: "edit_form",
                      text: hasPermission ? "Edit" : "View",
                    },
                    {
                      id: "delete_form",
                      text: "Delete",
                      disabled: !hasDeletePermission,
                    },
                  ]}
                  onItemClick={(itemClickDetails) => {
                    const { id } = itemClickDetails.detail;
                    if (id === "delete_form") {
                      if (selectedItem) OpenDeleteModal(true);
                    } else if (id === "edit_form") {
                      if (selectedItem)
                        navigate(`/manageprovider/${selectedItem.id}/profile`);
                    }
                  }}
                >
                  Actions
                </ButtonDropdown>
              </span>
              <span className="awsui-util-action-stripe-group">
                <ButtonDropdown
                  items={[
                    {
                      text: "Import from CSV",
                      id: "import_csv",
                    },
                  ]}
                  mainAction={{
                    text: "Add Provider",
                    onClick: () => {
                      openModalName(ADD_MANAGEPROVIDER_MODAL);
                    },
                  }}
                  onItemClick={(itemClickDetails) => {
                    const { id } = itemClickDetails.detail;
                    if (id === "import_csv") {
                      navigate("/manageprovider/addCsvUpload");
                    }
                  }}
                  variant="primary"
                />
              </span>
            </div>
          }
        >
          Provider
        </Header>
      ),
      filter: <ProviderFilters filter={filter} setFilter={setFilter} />,
      //Begin Collectionpreferences properties
      items,
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <TablePreferences
          preferences={tablePreferences}
          setPreferences={(preferences) => {
            setTablePreferences(preferences);
          }}
          contentDisplayOptions={PROVIDER_CONTENT_DISPLAY_OPTIONS}
        />
      ),
      columnDisplay: tablePreferences?.contentDisplay,
      wrapLines: tablePreferences?.wrapLines,
      stripedRows: tablePreferences?.stripedRows,
      contentDensity: tablePreferences?.contentDensity,
      stickyColumns: tablePreferences?.stickyColumns,
      ...collectionProps,
      //End Collectionpreferences properties
      variant: "container",
      selectionType: "single",
      stickyHeader: true,
      loading: isLoading || isFetching,
      loadingText: "Loading...",
      empty: <p>No results to display.</p>,
      selectedItems: selectedItem ? [selectedItem] : [],
      onSelectionChange: (selectionChangeDetail) => {
        const temp_providerId =
          selectionChangeDetail.detail.selectedItems.at(0)?.id;
        if (temp_providerId) navigate(`/manageprovider/${temp_providerId}`);
      },
      onRowClick: (onRowClickDetail) => {
        const temp = onRowClickDetail.detail.item.id;
        if (temp) navigate(`/manageprovider/${temp}`);
      },
      trackBy: (item) => "" + item.id,
      columnDefinitions: [
        {
          id: "id",
          header: <div>ID</div>,
          cell: (item) => <span>{item.id}</span>,
        },
        {
          id: "fullname",
          header: <div> Full Name</div>,
          sortingField: "firstName",
          cell: (item) => (
            <Link href={`${item.id}/profile`}>
              {item.firstName} {item.lastName}
            </Link>
          ),
        },
        {
          id: "lastName",
          header: <div>Last Name</div>,
          sortingField: "lastName",
          cell: (item) => <span>{item.lastName}</span>,
        },
        {
          id: "firstName",
          header: <div> First Name</div>,
          cell: (item) => <span>{item.firstName}</span>,
        },
        {
          id: "npiNumber",
          header: <div>NPI Number</div>,
          sortingField: "npiNumber",
          cell: (item) => <span>{item?.npiNumber}</span>,
        },
        {
          id: "email",
          header: <div>Email</div>,
          sortingField: "email",
          cell: (item) => <span>{item.email}</span>,
        },
        {
          id: "facility",
          header: <div>Facility Name</div>,
          cell: (item) => <span>{item?.facility?.name}</span>,
          sortingField: "facility.name",
          sortingComparator: (a, b) =>
            (a.facility?.name ?? "").localeCompare(b.facility?.name ?? ""),
        },
        {
          id: "licenseType",
          header: <div>License Type</div>,
          cell: (item) => <span>{item?.licenseType?.name}</span>,
          sortingField: "licenseType.name",
          sortingComparator: (a, b) =>
            (a.licenseType?.name ?? "").localeCompare(
              b.licenseType?.name ?? "",
            ),
        },
      ],
    };
  }, [filteredProviders, selectedItem, tablePreferences, items, isLoading]);
  return (
    <div style={{ paddingTop: "25px" }}>
      <LoadingScreen isOpen={isLoading} />
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedItem?.id)
            await notifyWrapper({
              promise: deleteManageProvider({ providerId: selectedItem?.id }),
              resourceName: "Provider",
              actionName: "delete",
            });
          OpenDeleteModal(false);
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={"Delete provider"}
        content={
          <>
            <Box>Name</Box>
            <Box variant="awsui-key-label">
              {selectedItem?.firstName + " " + selectedItem?.lastName}
            </Box>
          </>
        }
        description={"Are you sure you want to delete this manage provider?"}
      />
      {/* <UploadCSV
        fileName={fileName}
        visible={isUploadFileOpen}
        closeModal={() => OpenUploadFileModal(false)}
      /> */}
    </div>
  );
}

const mapDispatchToProps = {
  openModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(ManageProviderTable);
