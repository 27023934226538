import { connect } from "react-redux";
import LoadingScreen from "./components/LoadingScreen";
import { ApplicationState } from "./redux/store";
import Router from "./routes";

console.log(`current version ${process.env.REACT_APP_VERSION}`);
console.log(`env ${process.env.REACT_APP_ENV}`);

interface PropsFromState {
  is_loading: boolean;
}

if (window.location.host === "localhost:3000") {
  // startMirage();
}

function App({ is_loading }: PropsFromState) {
  return (
    <>
      <LoadingScreen isOpen={is_loading} />
      <Router />
    </>
  );
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    is_loading: state.ui.is_loading,
  };
};

export default connect(mapStateToProps, {})(App);
