import { useEffect, useState } from "react";
import { useGetAllSectionsQuery } from "../redux/api/section/section";
import { Section } from "../redux/api/section/types";

export type SectionFilterType = {
  filterText: string;
  section_type: string;
};

const standardFields: Section[] = [
  {
    name: "Standard Fields",
    description: "",
    facility: false,
    excludeReporting: false,
    excludeDigitalformMapping: false,
    id: "",
    type: "standard",
    attributes: [],
    subSections: [
      {
        id: "",
        name: "",
        description: "",
        sort_order: 1,
        attributes: [
          {
            id: "text_field",
            name: "Text Field",
            description: "",
            type: "string",
            sort_order: 1,
            isDeleted: false,
            isKey: false,
            required: false,
            sensitive: false,
          },
          {
            id: "checkbox",
            name: "Check Box",
            description: "",
            type: "checkbox",
            sort_order: 2,
            isDeleted: false,
            isKey: false,
            required: false,
            sensitive: false,
          },
          {
            id: "signature",
            name: "Signature",
            description: "",
            type: "SIGNATURE",
            sort_order: 3,
            isDeleted: false,
            isKey: false,
            required: false,
            sensitive: false,
          },
        ],
      },
    ],
  },
];

export default function useSectionFilter() {
  const { data = [], isLoading } = useGetAllSectionsQuery({ type: "all" });

  const [filter, setFilter] = useState<SectionFilterType>({
    filterText: "",
    section_type: "all",
  });

  const [filteredSections, setFilteredSections] = useState<Section[]>([]);

  useEffect(() => {
    setFilteredSections(
      [...data, ...standardFields].filter(
        (section) => !section.excludeDigitalformMapping
      )
    );
  }, [data]);

  useEffect(() => {
    let temp_filtered_sections = [...data, ...standardFields].filter(
      (section) => !section.excludeDigitalformMapping
    );

    if (filter.filterText) {
      temp_filtered_sections = temp_filtered_sections.map((section) => {
        return {
          ...section,
          subSections: section.subSections.map((subSection) => {
            return {
              ...subSection,
              attributes: subSection.attributes.filter((attribute) =>
                attribute.name
                  .toLowerCase()
                  .includes(filter.filterText.toLowerCase())
              ),
            };
          }),
        };
      });
    }

    if (filter.section_type === "facility") {
      temp_filtered_sections = temp_filtered_sections.filter(
        (section) => section.facility
      );
    }
    if (filter.section_type === "provider") {
      temp_filtered_sections = temp_filtered_sections.filter(
        (section) => !section.facility
      );
    }
    if (filter.section_type === "standard") {
      temp_filtered_sections = temp_filtered_sections.filter(
        (section) => section.type === "standard"
      );
    }
    setFilteredSections(temp_filtered_sections);
  }, [filter]);

  return { filteredSections, isLoading, filter, setFilter };
}
