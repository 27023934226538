import { Tabs } from "@cloudscape-design/components";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Active from "./sections/active";
import Invited from "./sections/invited";

function ManageAdminDetails() {
  const [activeTabId, setActiveTabId] = useState<string>();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has("activeTab") && searchParams.get("activeTab"))
      setActiveTabId(searchParams.get("activeTab") ?? "groups");
  }, [searchParams]);

  return (
    <Tabs
      activeTabId={activeTabId}
      onChange={(changeDetail) => {
        setActiveTabId(changeDetail.detail.activeTabId);
        setSearchParams({ activeTab: changeDetail.detail.activeTabId });
      }}
      tabs={[
        {
          label: "Active",
          id: "active",
          content: <Active />,
        },
        {
          label: "Invited",
          id: "invited",
          content: <Invited />,
        },
      ]}
    />
  );
}

export default ManageAdminDetails;
