import { FormProvider, useForm } from "react-hook-form";
import {
  useGetOrganizationDetailsQuery,
  useUpdateOrganizationMutation,
} from "../redux/api/organization/organization";
import { AddressType } from "../redux/api/provider/types";
import {
  Button,
  ColumnLayout,
  Container,
  ExpandableSection,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import useIsLoading from "../hooks/useIsLoading";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import RHFTextField from "../components/RHF/RHFTextField";
import { ApplicationState } from "../redux/store";
import { useSelector } from "react-redux";
import useAsyncNotifyWrapper from "../hooks/useAsyncNotifyWrapper";
import FormSection from "../components/FormSection";

type IFormInput = {
  name: string;
  phone: string;
  organizationAddress: AddressType;
};

export default function OrgBasicDetailsEdit() {
  const methods = useForm<IFormInput>();
  const {
    data: organization,
    fulfilledTimeStamp,
    isLoading,
  } = useGetOrganizationDetailsQuery();

  const [updateOrganization, { isLoading: isUpdating }] =
    useUpdateOrganizationMutation();

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("organization_settings.edit") ?? false),
  );
  const { notifyWrapper } = useAsyncNotifyWrapper();
  useIsLoading(isLoading || isUpdating);

  const onSubmit = async (data: IFormInput) => {
    if (organization?.id)
      await notifyWrapper({
        promise: updateOrganization({
          id: organization?.id,
          name: data.name,
          phone: data.phone,
          organizationAddress: {
            addressLane1: data.organizationAddress.addressLane1,
            addressLane2: data.organizationAddress.addressLane2,
            city: data.organizationAddress.city,
            state: data.organizationAddress.state,
            zipcode: data.organizationAddress.zipcode,
          },
        }),
        resourceName: "organization",
        actionName: "update",
      });
    navigate(-1);
  };

  useEffect(() => {
    if (organization) {
      methods.setValue("name", organization.name);
      methods.setValue("phone", organization.phone);
      methods.setValue("organizationAddress", organization.organizationAddress);
    }
  }, [fulfilledTimeStamp]);

  const navigate = useNavigate();
  return (
    <div style={{ paddingTop: "25px" }}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Form
            header={<Header>Edit organization basic details</Header>}
            actions={
              <SpaceBetween size="s" direction="horizontal">
                <Button
                  formAction="none"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Cancel
                </Button>
                <Button disabled={!hasPermission} variant="primary">
                  Save
                </Button>
              </SpaceBetween>
            }
          >
            <Container header={<Header>Basic details</Header>}>
              <SpaceBetween size={"xl"}>
                <SpaceBetween size={"m"}>
                  <ColumnLayout columns={2} variant="default">
                    <RHFTextField
                      label="Organization Name"
                      name="name"
                      stretch
                      rules={{ required: "This field is required" }}
                      readOnly={!hasPermission}
                    />
                    <RHFTextField
                      type="number"
                      label="Phone Number"
                      name="phone"
                      stretch
                      rules={{
                        required: "Phone Should be number",
                        pattern:
                          /^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/g,
                      }}
                      readOnly={!hasPermission}
                    />
                  </ColumnLayout>
                </SpaceBetween>
                <ExpandableSection
                  headerText="Organization Address"
                  defaultExpanded
                >
                  <FormSection header="" columns={2}>
                    <RHFTextField
                      label="Address Lane1"
                      name="organizationAddress.addressLane1"
                      stretch={false}
                    />
                    <RHFTextField
                      label="Address Lane2"
                      name="organizationAddress.addressLane2"
                      stretch={false}
                    />
                    <RHFTextField
                      label="City"
                      name="organizationAddress.city"
                      stretch={false}
                    />
                    <RHFTextField
                      label="State"
                      name="organizationAddress.state"
                      stretch={false}
                    />
                    <RHFTextField
                      label="Zip Code"
                      name="organizationAddress.zipcode"
                      stretch={false}
                    />
                  </FormSection>
                </ExpandableSection>
              </SpaceBetween>
            </Container>
          </Form>
        </form>
      </FormProvider>
    </div>
  );
}
