import {
  BreadcrumbGroup,
  Button,
  Container,
  ExpandableSection,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { FormProvider, useForm } from "react-hook-form";
import RHFTextField from "../../components/RHF/RHFTextField";
import FormSection from "../../components/FormSection";
import RHFSelect from "../../components/RHF/RHFSelect";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import InnerAppLayout from "../../components/InnerAppLayout";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import {
  useGetAllContractTypesQuery,
  useSaveContractMutation,
  useUploadContractAttachmentMutation,
} from "../../redux/api/contracts/contract";
import { useNavigate } from "react-router-dom";
import { useGetAllActiveQuery } from "../../redux/api/manageadmin/manageadmin";
import Divider from "@mui/material/Divider";
import useNotify from "../../hooks/useNotify";
import { v4 as uuidv4 } from "uuid";
import AttachmentViewer from "../../components/PdftronViewerWrappers/AttachmentViewer";
import { useState } from "react";
import RHFFloatingSelect from "../../components/RHF/RHFFloatingSelect";
import { setModalName } from "../../redux/UI/actions";
import { connect } from "react-redux";
import { CONTRACT_TYPE } from "../../helpers/constants";
import { useTranslation } from "react-i18next";

type FormInputType = {
  title: string;
  executionDate: string;
  expirationDate: string;
  lastRenewalDate: string;
  description: string;
  primaryContact: string;
  facility: string;
  contractType: string;
  entityName: string;
  entityPhone: string;
  entityEmail: string;
  deleted: boolean;
};
interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function AddContract({ setModalName }: PropsFromDispatch) {
  const methods = useForm<FormInputType>();
  const { t } = useTranslation();
  const [uploadFile, setUploadFile] = useState<File>();
  const navigate = useNavigate();
  const { notifyInProgress, notifySucess, notifyFailed } = useNotify();

  const onSubmit = async (data: FormInputType) => {
    // post contract, using saveContract, this will return a contract with ID, using this contractId, upload attachment by sedning contractId and attachmentFile using uplodaAttachment
    if (data.contractType && data.facility) {
      const notificationId = uuidv4();
      notifyInProgress({
        name: "contract",
        action: "creating",
        id: notificationId,
      });
      await saveContract({
        title: data?.title,
        executionDate: data?.executionDate,
        expirationDate: data?.expirationDate,
        lastRenewalDate: data?.lastRenewalDate,
        description: data?.description,
        primaryContact: { id: data?.primaryContact },
        facility: { id: data.facility },
        contractType: { id: data.contractType, type: data.contractType },
        entityName: data.entityName,
        entityPhone: data.entityPhone,
        entityEmail: data.entityEmail,
      })
        .unwrap()
        .then(async (contractResponse) => {
          if (uploadFile) {
            await uploadContractAttachment({
              contractId: contractResponse.id,
              file: uploadFile,
            })
              .unwrap()
              .then((attachmentResponse) => {
                notifySucess({
                  name: "contract",
                  action: "created",
                  id: notificationId,
                });
              })
              .catch((error) => {
                // console.log({ error });
                if (error.status < 500 && error.status >= 400) {
                  notifyFailed({
                    name: "contract",
                    action: error.data.errorMessage,
                    content: error.data.errorDescription,
                    id: notificationId,
                  });
                } else
                  notifyFailed({
                    name: "contract",
                    action: "create",
                    id: notificationId,
                  });
              });
          } else
            notifySucess({
              name: "contract",
              action: "created",
              id: notificationId,
            });
        })
        .catch((error) => {
          // console.log({ error });
          if (error.status < 500 && error.status >= 400) {
            notifyFailed({
              name: "contract",
              action: error.data.errorMessage,
              content: error.data.errorDescription,
              id: notificationId,
            });
          } else
            notifyFailed({
              name: "contract",
              action: "create",
              id: notificationId,
            });
        });
    }
    navigate(-1);
  };

  const { data: facilites = [] } = useGetFacilitiesQuery();
  const { data: contractType = [] } = useGetAllContractTypesQuery();
  const { data: members = [] } = useGetAllActiveQuery();

  const [uploadContractAttachment] = useUploadContractAttachmentMutation();
  const [saveContract] = useSaveContractMutation();

  return (
    <InnerAppLayout
      breadcrumbGroup={
        <>
          <BreadcrumbGroup
            items={[
              {
                text: `${t("contracts.addContract.header.breadcrumb.text")}`,
                href: `/contract`,
              },
              {
                text: `${t("contracts.addContract.header.breadcrumb.currentPage")}`,
                href: "#",
              },
            ]}
            ariaLabel="Breadcrumbs"
          />
        </>
      }
      rightPanel={
        <AttachmentViewer
          attachment={undefined}
          file={uploadFile}
          onFileChange={(uploadFile?: File) => {
            setUploadFile(uploadFile);
          }}
        />
      }
      content={
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Form>
              <Container
                header={
                  <Header>{t("contracts.addContract.header.label")}</Header>
                }
                footer={
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      onClick={() => {
                        navigate(-1);
                      }}
                      formAction="none"
                    >
                      {t("contracts.addContract.header.actions.cancel")}
                    </Button>
                    <Button formAction="submit" variant="primary">
                      {t("contracts.addContract.header.actions.submit")}
                    </Button>
                  </SpaceBetween>
                }
                fitHeight
              >
                <div style={{ height: "calc(100vh - 270px)" }}>
                  <SpaceBetween direction="vertical" size="s">
                    <FormSection
                      gridDefinition={[
                        { colspan: 12 },
                        { colspan: 12 },
                        { colspan: 12 },
                      ]}
                    >
                      <RHFFloatingSelect
                        name="contractType"
                        label={t(
                          "contracts.addContract.header.fields.type.label",
                        )}
                        options={contractType.map((type) => ({
                          label: type.type,
                          value: "" + type.id,
                        }))}
                        rules={{ required: "This field is required" }}
                        stretch={false}
                        action={{
                          label: `${t("contracts.addContract.header.fields.type.actions.modify")}`,
                          onClick: () => {
                            setModalName(CONTRACT_TYPE);
                          },
                        }}
                      />
                      <RHFTextField
                        label={t("contracts.addContract.header.fields.title")}
                        name="title"
                        stretch
                        rules={{ required: "This field is required" }}
                      />
                      <RHFTextArea
                        label={t(
                          "contracts.addContract.header.fields.description",
                        )}
                        name="description"
                        stretch
                      />
                    </FormSection>

                    <FormSection columns={3}>
                      <RHFDatePicker
                        label={t(
                          "contracts.addContract.header.fields.executionDate",
                        )}
                        name={"executionDate"}
                      />
                      <RHFDatePicker
                        label={t(
                          "contracts.addContract.header.fields.expirationDate",
                        )}
                        name={"expirationDate"}
                      />
                      <RHFDatePicker
                        label={t(
                          "contracts.addContract.header.fields.lastRenewalDate",
                        )}
                        name={"lastRenewalDate"}
                      />
                    </FormSection>
                    <FormSection columns={3}>
                      <RHFSelect
                        label={t(
                          "contracts.addContract.header.fields.facility",
                        )}
                        name={"facility"}
                        rules={{ required: "This field is required" }}
                        options={facilites.map((facility) => {
                          return { label: facility.name, value: facility.id };
                        })}
                      />
                    </FormSection>
                    <FormSection columns={3}>
                      <RHFSelect
                        label={t(
                          "contracts.addContract.header.fields.primaryContact",
                        )}
                        name={"primaryContact"}
                        rules={{ required: "This field is required" }}
                        options={members.map((member) => {
                          return { label: member.firstName, value: member.id };
                        })}
                      />
                    </FormSection>
                    <Divider variant="middle" />
                    <ExpandableSection
                      headerText={t("contracts.addContract.header.headerText")}
                    >
                      <FormSection columns={2}>
                        <RHFTextField
                          label={t(
                            "contracts.addContract.header.fields.entityName",
                          )}
                          name="entityName"
                        />
                        <RHFTextField
                          label={t(
                            "contracts.addContract.header.fields.entityPhone",
                          )}
                          name="entityPhone"
                        />
                        <RHFTextField
                          label={t(
                            "contracts.addContract.header.fields.entityEmail",
                          )}
                          name="entityEmail"
                        />
                      </FormSection>
                    </ExpandableSection>
                  </SpaceBetween>
                </div>
              </Container>
            </Form>
          </form>
        </FormProvider>
      }
    ></InnerAppLayout>
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(AddContract);
