import {
  Box,
  Button,
  Header,
  Modal,
  SpaceBetween,
  Table,
} from "@cloudscape-design/components";
import { Role } from "../redux/api/role/types";
import { Provider } from "../redux/api/provider/types";
import { useState } from "react";
import { useDeleteRoleProvidersMutation } from "../redux/api/role/role";
import { setModalName } from "../redux/UI/actions";
import { connect } from "react-redux";
import { ADD_ASSOCIATED_PROVIDER } from "../helpers/constants";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";
import useAsyncNotifyWrapper from "../hooks/useAsyncNotifyWrapper";
import { async } from "q";

interface AssociatedProvidersProps {
  role: Role;
}

interface PropsFromDispatch {
  openModalName: typeof setModalName;
}
function AssociatedProviders({
  role,
  openModalName,
}: PropsFromDispatch & AssociatedProvidersProps) {
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("roles.edit") ?? false)
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("roles.delete") ?? false)
  );
  const [selectedProviders, setSelectedProviders] = useState<Provider[]>([]);
  const [open_delete_modal, set_open_delete_modal] = useState<boolean>(false);
  const [deleteRoleProviders] = useDeleteRoleProvidersMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  return (
    <div>
      <Table
        header={
          <Header
            counter={`(${role.providers.length})`}
            actions={
              <SpaceBetween size="m" direction="horizontal">
                <Button
                  disabled={
                    !hasDeletePermission ||
                    !selectedProviders.length ||
                    role.default
                  }
                  onClick={() => {
                    set_open_delete_modal(true);
                  }}
                >
                  Remove Provider from role
                </Button>
                <Button
                  disabled={!hasPermission || role.default}
                  onClick={() => {
                    openModalName(ADD_ASSOCIATED_PROVIDER);
                  }}
                >
                  Add Provider to role
                </Button>
              </SpaceBetween>
            }
          >
            Associated Providers
          </Header>
        }
        selectedItems={selectedProviders}
        onSelectionChange={(selectionChangeDetail) => {
          setSelectedProviders(selectionChangeDetail.detail.selectedItems);
        }}
        onRowClick={(onRowClickDetail) => {
          const index = selectedProviders.indexOf(onRowClickDetail.detail.item);
          if (index >= 0) {
            let temp = [...selectedProviders];
            temp.splice(index);
            setSelectedProviders(temp);
          } else {
            setSelectedProviders([
              ...selectedProviders,
              onRowClickDetail.detail.item,
            ]);
          }
        }}
        selectionType="multi"
        items={role.providers}
        columnDefinitions={[
          {
            id: "id",
            header: <div>id</div>,
            cell: (item) => <span>{item.id}</span>,
          },
          {
            id: "name",
            header: <div>Provider Name</div>,
            cell: (item) => (
              <span>{item?.firstName + " " + item?.lastName}</span>
            ),
          },
        ]}
        visibleColumns={["name"]}
      />
      <Modal
        header={`Remove below providers from role: ${role.name}`}
        disableContentPaddings
        visible={open_delete_modal}
        onDismiss={() => set_open_delete_modal(false)}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="link"
                onClick={() => set_open_delete_modal(false)}
              >
                Cancel
              </Button>
              <Button
                onClick={async () => {
                  if (selectedProviders && selectedProviders.length > 0) {
                    await notifyWrapper({
                      promise: deleteRoleProviders({
                        roleId: role.id,
                        provider_ids: selectedProviders.map(
                          (provider) => provider.id
                        ),
                      }),
                      resourceName: "associated provider",
                      actionName: "delete",
                    });
                    setSelectedProviders([]);
                  }
                  set_open_delete_modal(false);
                }}
                variant="primary"
              >
                Ok
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        <ul>
          {selectedProviders.map((provider) => (
            <li>{provider.firstName + " " + provider.lastName}</li>
          ))}
        </ul>
      </Modal>
    </div>
  );
}

const mapDispatchToProps = {
  openModalName: setModalName,
};
export default connect(null, mapDispatchToProps)(AssociatedProviders);
