import {
  BreadcrumbGroup,
  Button,
  Container,
  ExpandableSection,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import InnerAppLayout from "../../components/InnerAppLayout";
import FormSection from "../../components/FormSection";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import {
  useGetPayerQuery,
  useUpdatePayerMutation,
} from "../../redux/api/payers/payers";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import LoadingScreen from "../../components/LoadingScreen";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";

type FormInputProps = {
  id: string;
  name: string;
  payerId: string;
  addressLane1: string;
  addressLane2: string;
  city: string;
  state: string;
  zipcode: string;
};
export default function EditPayer() {
  const methods = useForm<FormInputProps>();
  const { id } = useParams();
  const {
    data: payer,
    fulfilledTimeStamp,
    isLoading,
  } = useGetPayerQuery(
    !!id
      ? {
          payerId: id,
        }
      : skipToken,
  );
  const [updatePayer] = useUpdatePayerMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const navigate = useNavigate();
  const onSubmit = async (data: FormInputProps) => {
    if (!!id) {
      await notifyWrapper({
        promise: updatePayer({
          id: id,
          name: data?.name,
          payerId: data?.payerId,
          payerAddress: {
            addressLane1: data?.addressLane1,
            addressLane2: data?.addressLane2,
            city: data?.city,
            state: data?.state,
            zipcode: data?.zipcode,
          },
        }),
        resourceName: "payer",
        actionName: "update",
      });
    }
    navigate(-1);
  };
  useEffect(() => {
    if (payer) {
      methods.setValue("name", payer?.name);
      methods.setValue("payerId", payer?.payerId);
      methods.setValue("addressLane1", payer?.payerAddress?.addressLane1);
      methods.setValue("addressLane2", payer?.payerAddress?.addressLane2);
      methods.setValue("state", payer?.payerAddress?.state);
      methods.setValue("city", payer?.payerAddress?.city);
      methods.setValue("zipcode", payer?.payerAddress?.zipcode);
    }
  }, [fulfilledTimeStamp, methods, payer]);

  return (
    <>
      <LoadingScreen isOpen={isLoading} />
      <InnerAppLayout
        breadcrumbGroup={
          <BreadcrumbGroup
            items={[
              { text: "Payers", href: `/payers` },

              { text: `Edit payer`, href: "#" },
            ]}
          />
        }
        content={
          <Container header={<Header>Payers</Header>}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Form
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        onClick={() => {
                          navigate("/payers");
                        }}
                        formAction="none"
                      >
                        Cancel
                      </Button>
                      <Button formAction="submit" variant="primary">
                        Submit
                      </Button>
                    </SpaceBetween>
                  }
                >
                  <FormSection columns={1}>
                    <RHFTextField
                      label="Name"
                      name="name"
                      stretch={false}
                      rules={{ required: "This field is required" }}
                    />
                    <RHFTextField
                      label="Payer ID"
                      name="payerId"
                      stretch={false}
                    />
                    <ExpandableSection headerText="Payer Address">
                      <FormSection header="" columns={2}>
                        <RHFTextField
                          label="Address Lane 1"
                          name="addressLane1"
                          stretch={false}
                        />
                        <RHFTextField
                          label="Address Lane 2"
                          name="addressLane2"
                          stretch={false}
                        />
                        <RHFTextField
                          label="City"
                          name="city"
                          stretch={false}
                        />
                        <RHFTextField
                          label="State"
                          name="state"
                          stretch={false}
                        />
                        <RHFTextField
                          label="Zip Code"
                          name="zipcode"
                          stretch={false}
                        />
                      </FormSection>
                    </ExpandableSection>
                  </FormSection>
                </Form>
              </form>
            </FormProvider>
          </Container>
        }
      />
    </>
  );
}
