import { api } from "..";
import { Section, SectionMetadata } from "./types";

const sectionAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAllSections: build.query<
      Section[],
      { type?: "facility" | "provider" | "all" }
    >({
      query: ({ type }) => `section/all?type=${type}`,
      providesTags: ["Section"],
    }),
    getAllFacilitySections: build.query<Section[], void>({
      query: () => `section/facility/all`,
      providesTags: ["Section"],
    }),
    getAllProviderSections: build.query<Section[], void>({
      query: () => `section/provider/all`,
      providesTags: ["Section"],
    }),
    getSection: build.query<Section, { sectionId: string }>({
      query: ({ sectionId }) => `section/${sectionId}`,
      providesTags: (result, error, { sectionId }) => [
        { type: "Section", id: sectionId },
      ],
    }),
    createSection: build.mutation<void, SectionMetadata>({
      query(section) {
        return {
          url: `section`,
          method: "POST",
          body: section,
        };
      },
      invalidatesTags: (result, error, args) => [{ type: "Section" }],
    }),
    updateSection: build.mutation<void, Section | SectionMetadata>({
      query(section) {
        return {
          url: `section/${section.id}`,
          method: "POST",
          body: section,
        };
      },
      invalidatesTags: (result, error, args) => [
        { type: "Section" },
        { type: "Section", id: args.id },
      ],
    }),

    deleteSection: build.mutation<void, { sectionId: string }>({
      query({ sectionId }) {
        return {
          url: `section/${sectionId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, args) => [
        { type: "Section" },
        { type: "Section", id: args.sectionId },
      ],
    }),
    deleteSubSection: build.mutation<
      void,
      { sectionId: string; subSectionId: string }
    >({
      query({ sectionId, subSectionId }) {
        return {
          url: `section/${sectionId}/subsection/${subSectionId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, args) => [
        { type: "Section" },
        { type: "Section", id: args.sectionId },
      ],
    }),
    deleteAttribute: build.mutation<
      void,
      { sectionId: string; subSectionId: string; attributeId: string }
    >({
      query({ sectionId, subSectionId, attributeId }) {
        return {
          url: `section/${sectionId}/subsection/${subSectionId}/attribute/${attributeId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, args) => [
        { type: "Section" },
        { type: "Section", id: args.sectionId },
      ],
    }),
  }),
});

export const {
  useGetAllFacilitySectionsQuery,
  useCreateSectionMutation,
  useDeleteSectionMutation,
  useDeleteSubSectionMutation,
  useDeleteAttributeMutation,
  useUpdateSectionMutation,
  useGetAllProviderSectionsQuery,
  useGetAllSectionsQuery,
  useGetSectionQuery,
} = sectionAPI;
