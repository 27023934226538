import { FlashbarProps } from "@cloudscape-design/components";
import { NotificationProps, UIActionTypes } from "./types";

export const setModalName = (name: string) => ({
  type: UIActionTypes.SET_MODAL_NAME,
  payload: name,
});

export const removeLoadingSpinner = () => ({
  type: UIActionTypes.REMOVE_SPINNER,
});

export const addLoadingSpinner = () => ({
  type: UIActionTypes.ADD_SPINNER,
});

export const updateOrAddFlashBarItem = (
  item: FlashbarProps.MessageDefinition
) => ({
  type: UIActionTypes.ADD_FLASHBAR_ITEM,
  payload: item,
});

export const dismissFlashBarItem = (id: string) => ({
  type: UIActionTypes.REMOVE_FLASHBAR_ITEM,
  payload: id,
});

export const notifySucess = ({ name, action, id }: NotificationProps) => {
  const content = `Successfully ${action ?? "Updated"} ${name}.`;
  updateOrAddFlashBarItem({
    type: "success",
    dismissible: true,
    dismissLabel: "Dismiss message",
    content,
    id: id,
    onDismiss: () => dismissFlashBarItem(id),
  });
};

export const notifyFailed = ({ name, action, id }: NotificationProps) => {
  updateOrAddFlashBarItem({
    type: "error",
    dismissible: true,
    dismissLabel: "Dismiss message",
    header: `Failed to ${action ?? "Update"} ${name}`,
    content:
      "Your request couldn’t be processed because of an issue with the server. Please contact support",
    id: id,
    onDismiss: () => dismissFlashBarItem(id),
  });
};

export const notifyInProgress = ({ name, action, id }: NotificationProps) => {
  const content = `${action ?? "Updating"} ${name}...`;
  updateOrAddFlashBarItem({
    loading: true,
    type: "info",
    dismissible: false,
    content,
    id: id,
  });
};
