import {
  Button,
  ButtonDropdown,
  Flashbar,
  FlashbarProps,
  Header,
  Pagination,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import { useNavigate } from "react-router-dom";
import {
  useDeleteSectionMutation,
  useGetAllSectionsQuery,
} from "../redux/api/section/section";
import { Section } from "../redux/api/section/types";
import { useEffect, useState } from "react";
import { EditSectionModal } from "./edit-section-modal";
import { setModalName } from "../redux/UI/actions";
import { connect } from "react-redux";
import { CREATE_SECTION_MODAL } from "../helpers/constants";
import useIsLoading from "../hooks/useIsLoading";
import StyledRouterLink from "../components/styled-route-link";
import {
  MANAGEPROFILE_CONTENT_DISPLAY_OPTIONS,
  MANAGEPROFILE_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../common/table-config";
import { useLocalStorage } from "../common/localStorage";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableEmptyState } from "../common/common-components";
import LoadingScreen from "../components/LoadingScreen";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function ManageProfileSections({ setModalName }: PropsFromDispatch) {
  const { data, isLoading } = useGetAllSectionsQuery({ type: "all" });
  const [deleteSection, { isLoading: isDeleting }] = useDeleteSectionMutation();
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState<Section>();
  const [visible, setVisible] = useState<string>("");
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-ManageProfile-Table-Preferences",
    MANAGEPROFILE_TABLE_DEFAULT_PREFERENCES,
  );
  const { items, collectionProps, paginationProps } = useCollection(
    data ?? [],
    {
      propertyFiltering: {
        filteringProperties: [],
        empty: <TableEmptyState resourceName="Manage Profile" />,
      },
    },
  );
  useEffect(() => {
    if (selectedItem && data)
      setSelectedItem(data.find((section) => selectedItem.id === section.id));
  }, [data]);

  const [flashbarItems, setFlashbarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);

  useIsLoading(isDeleting);

  const tableProps: TableProps<Section> = {
    header: (
      <Header
        counter={`(${data?.length})`}
        actions={
          <div>
            <span className="awsui-util-action-stripe-group">
              <Button
                disabled={!selectedItem}
                onClick={() => {
                  if (selectedItem)
                    navigate(`/managesection/${selectedItem.id}`);
                }}
              >
                Configure
              </Button>
            </span>
            <span className="awsui-util-action-stripe-group">
              <ButtonDropdown
                disabled={!selectedItem}
                expandableGroups
                items={[
                  {
                    id: "edit",
                    text: "Edit",
                    disabled: false,
                  },
                  /*     {
                    id: "configure",
                    text: "Configure",
                    disabled: false,
                  }, */
                  {
                    id: "delete",
                    text: "Delete",
                    disabled: false,
                  },
                ]}
                onItemClick={async (itemClickDetails) => {
                  if (itemClickDetails.detail.id === "edit") {
                    setVisible("edit");
                  } else if (itemClickDetails.detail.id === "configure") {
                    if (selectedItem)
                      navigate(`/managesection/${selectedItem.id}`);
                  } else if (itemClickDetails.detail.id === "delete") {
                    if (selectedItem?.id)
                      try {
                        const payload = await deleteSection({
                          sectionId: selectedItem?.id,
                        }).unwrap();
                      } catch (error: any) {
                        if (error?.status === 400) {
                          console.error("rejected", error);
                          setFlashbarItems([
                            {
                              header: error?.data?.errorMessage,
                              type: "error",
                              content: error?.data?.errorDescription,
                              dismissible: true,
                              onDismiss: () => setFlashbarItems([]),
                              id: selectedItem?.id,
                            },
                          ]);
                        }
                      }
                  }
                }}
              >
                Actions
              </ButtonDropdown>
            </span>
            <span className="awsui-util-action-stripe-group">
              <Button
                onClick={() => {
                  setModalName(CREATE_SECTION_MODAL);
                }}
                variant="primary"
              >
                Add Section
              </Button>
            </span>
          </div>
        }
      >
        Manage Section
      </Header>
    ),
    items,
    pagination: <Pagination {...paginationProps} />,
    preferences: (
      <TablePreferences
        title="Profile Table Preferences"
        preferences={tablePreferences}
        setPreferences={(preferences) => {
          setTablePreferences(preferences);
        }}
        contentDisplayOptions={MANAGEPROFILE_CONTENT_DISPLAY_OPTIONS}
      />
    ),
    columnDisplay: tablePreferences?.contentDisplay,
    wrapLines: tablePreferences?.wrapLines,
    stripedRows: tablePreferences?.stripedRows,
    contentDensity: tablePreferences?.contentDensity,
    stickyColumns: tablePreferences?.stickyColumns,
    ...collectionProps,
    variant: "container",
    selectionType: "single",
    selectedItems: selectedItem ? [selectedItem] : [],
    onSelectionChange: (selectionChangeDetail) => {
      if (selectionChangeDetail.detail.selectedItems?.at(0))
        setSelectedItem(selectionChangeDetail.detail.selectedItems?.at(0));
    },
    onRowClick: (onRowClickDetail) => {
      if (onRowClickDetail.detail.item)
        setSelectedItem(onRowClickDetail.detail.item);
    },
    resizableColumns: true,
    stickyHeader: true,
    empty: <p>No results to display.</p>,
    trackBy: (item) => "" + item.id,
    columnDefinitions: [
      {
        id: "name",
        header: <div>Section Name</div>,
        cell: (item) => (
          <StyledRouterLink
            className="edit_link"
            to={`/managesection/${item.id}`}
            label={item.name}
          />
        ),
      },
      {
        id: "type",
        header: <div>Type</div>,
        cell: (item) => <span>{item.type}</span>,
      },
      {
        id: "facility",
        header: <div>Facility</div>,
        cell: (item) => <span>{item.facility ? "Yes" : "No"}</span>,
      },
      {
        id: "description",
        header: <div>Description</div>,
        cell: (item) => <span>{item.description}</span>,
      },
    ],
  };
  return (
    <div>
      <LoadingScreen isOpen={isLoading} />
      <div style={{ paddingTop: "25px" }}>
        {flashbarItems && <Flashbar items={flashbarItems} />}
        <Table {...tableProps} />

        {selectedItem && (
          <EditSectionModal
            visible={visible}
            setVisible={setVisible}
            section={selectedItem}
          />
        )}
      </div>
    </div>
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(ManageProfileSections);
