import {
  Box,
  Button,
  ButtonDropdown,
  Header,
  Pagination,
  SpaceBetween,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import { useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAsyncNotifyWrapper from "../hooks/useAsyncNotifyWrapper";
import { useLocalStorage } from "../common/localStorage";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";
import { TableEmptyState } from "../common/common-components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import StyledRouterLink from "../components/styled-route-link";
import {
  PAYERENROLLMENT_CONTENT_DISPLAY_OPTIONS,
  PAYERENROllMENT_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../common/table-config";
import DeleteAlertModal from "../components/Modal/delete-alert-modal";
import {
  useDeletePayerEnrollmentMutation,
  useGetAllPayerEnrollmentsQuery,
} from "../redux/api/payerenrollment/payerenrollment";
import { PayerEnrollment } from "../redux/api/payerenrollment/types";
import ChangeTimeZone from "../components/Timezone";
import Label from "../components/Label";
import { useGetPayerQuery } from "../redux/api/payers/payers";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import LoadingScreen from "../components/LoadingScreen";

export default function PayerEnrollments() {
  const navigate = useNavigate();
  const { id: payerId } = useParams();
  const {
    data: payerEnrollment = [],
    fulfilledTimeStamp,
    isLoading,
    isFetching,
    refetch,
  } = useGetAllPayerEnrollmentsQuery(payerId ? { payerId } : skipToken);

  const { data: payer } = useGetPayerQuery(payerId ? { payerId } : skipToken);
  const [selectedItem, setSelectedItem] = useState<PayerEnrollment>();
  const [deletePayerEnrollment] = useDeletePayerEnrollmentMutation();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-PayerEnrollment-Table-Preferences",
    PAYERENROllMENT_TABLE_DEFAULT_PREFERENCES,
  );
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("payerenrollment.edit") ?? false),
  );
  const { items, collectionProps, paginationProps } = useCollection(
    payerEnrollment,
    {
      propertyFiltering: {
        filteringProperties: [],
        empty: <TableEmptyState resourceName="Payer Enrollment" />,
      },
      sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
    },
  );

  const tableProps: TableProps<PayerEnrollment> = useMemo(() => {
    return {
      columnDefinitions: [
        {
          id: "id",
          header: "Id",
          cell: (item) => item.id || "-",
          isRowHeader: true,
        },
        {
          id: "payer",
          header: <div>Payer</div>,
          cell: (item) => (
            <StyledRouterLink
              className={hasPermission ? "edit_link" : "normal"}
              to={hasPermission ? `editenrollment/${item.id}` : `#`}
              label={item?.payer?.name}
            />
          ),
          sortingField: "payer.name",
          sortingComparator: (a, b) =>
            (a?.payer?.name ?? "").localeCompare(b?.payer?.name ?? ""),
        },
        {
          id: "status",
          header: <div>Status</div>,
          cell: (item) => <span>{item?.status?.name ?? "-"}</span>,
          sortingField: "status.name",
          sortingComparator: (a, b) =>
            (a?.status?.name ?? "").localeCompare(b?.status?.name ?? ""),
        },
        {
          id: "provider",
          header: <div>Provider</div>,
          cell: (item) => <span>{item?.provider?.firstName ?? "-"}</span>,
          sortingField: "provider.firstName",
          sortingComparator: (a, b) =>
            (a?.provider?.firstName ?? "").localeCompare(
              b?.provider?.firstName ?? "",
            ),
        },
        {
          id: "submittedDate",
          header: <div>Submitted Date</div>,
          cell: (item) => (
            <span>{ChangeTimeZone(item?.submittedDate) ?? "-"}</span>
          ),
        },
        {
          id: "approvedDate",
          header: <div>Approved Date</div>,
          cell: (item) => (
            <span>{ChangeTimeZone(item?.approvedDate) ?? "-"}</span>
          ),
        },
        {
          id: "effectiveDate",
          header: <div>Effective Date</div>,
          cell: (item) => (
            <span>{ChangeTimeZone(item?.effectiveDate) ?? "-"}</span>
          ),
        },
        {
          id: "recredentialingDate",
          header: <div>Re-credentialing Date</div>,
          cell: (item) => (
            <span>{ChangeTimeZone(item?.recredentialingDate) ?? "-"}</span>
          ),
        },
        {
          id: "followupDate",
          header: <div>Followup Date</div>,
          cell: (item) => <span>{item?.followupDate ?? "-"}</span>,
        },
      ],
      items,
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <TablePreferences
          title="Payer Enrollment Table Preferences"
          preferences={tablePreferences}
          setPreferences={(preferences) => {
            setTablePreferences(preferences);
          }}
          contentDisplayOptions={PAYERENROLLMENT_CONTENT_DISPLAY_OPTIONS}
        />
      ),
      columnDisplay: tablePreferences?.contentDisplay,
      wrapLines: tablePreferences?.wrapLines,
      stripedRows: tablePreferences?.stripedRows,
      contentDensity: tablePreferences?.contentDensity,
      stickyColumns: tablePreferences?.stickyColumns,
      ...collectionProps,
      loading: isLoading || isFetching,
      loadingText: "Loading resources",
      selectionType: "single",
      selectedItems: selectedItem ? [selectedItem] : [],
      onSelectionChange: ({ detail }) => {
        if (detail.selectedItems && detail.selectedItems.at(0))
          setSelectedItem(detail.selectedItems.at(0));
      },
      onRowClick: ({ detail }) => {
        if (detail.item) setSelectedItem(detail.item);
      },
      empty: (
        <Box textAlign="center" color="inherit">
          <Box padding={{ bottom: "s" }} variant="p" color="inherit">
            No enrollments to display.
          </Box>
          <Button
            onClick={() => {
              navigate(`addenrollment`);
            }}
          >
            Add New
          </Button>
        </Box>
      ),

      header: (
        <Header
          counter={`(${payerEnrollment.length})`}
          actions={
            <SpaceBetween size="s" direction="horizontal">
              <Button
                variant="normal"
                iconName="refresh"
                onClick={() => {
                  refetch();
                }}
              ></Button>
              <ButtonDropdown
                disabled={!selectedItem}
                items={[
                  { text: "Edit", id: "edit", disabled: false },
                  { text: "Delete", id: "delete", disabled: false },
                ]}
                onItemClick={({ detail }) => {
                  if (detail.id === "edit")
                    if (selectedItem && selectedItem.id)
                      navigate(`editenrollment/${selectedItem.id}`);
                  if (detail.id === "delete") {
                    if (selectedItem && selectedItem.id && payerId)
                      OpenDeleteModal(true);
                  }
                }}
              >
                Action
              </ButtonDropdown>
              <Button
                variant="primary"
                onClick={() => {
                  navigate(`addenrollment`);
                }}
              >
                Add New
              </Button>
            </SpaceBetween>
          }
        >
          {payer?.name}
        </Header>
      ),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedItem,
    tablePreferences,
    items,
    payerEnrollment,
    fulfilledTimeStamp,
  ]);

  return (
    <div>
      <LoadingScreen isOpen={isLoading} />
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (!!selectedItem?.id && !!payerId) {
            OpenDeleteModal(false);
            await notifyWrapper({
              promise: deletePayerEnrollment({
                payerEnrollmentId: selectedItem?.id,
              }),
              resourceName: "payer enrollment",
              actionName: "delete",
            });
          }
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={"Delete Enrollment"}
        content={
          <SpaceBetween size="s" direction="vertical">
            <Label label="Payer" value={selectedItem?.payer.name} />
            <Label
              label="Provider Name"
              value={
                selectedItem?.provider.firstName +
                " " +
                selectedItem?.provider.lastName
              }
            />
          </SpaceBetween>
        }
        description={"Are you sure you want to delete this Payer Enrollment?"}
      />
    </div>
  );
}
