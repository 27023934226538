import {
  Button,
  Form,
  Header,
  Modal,
  Pagination,
  Box,
  Table,
  Container,
} from "@cloudscape-design/components";
import Grid from "@mui/material/Grid";
import { connect } from "react-redux";
import { setModalName } from "../../../../redux/UI/actions";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function AddUserGroupModal({ setModalName }: PropsFromDispatch) {
  return (
    <Modal
      visible={true}
      size="large"
      header={<Header variant="h2">Add User Groups</Header>}
      onDismiss={() => {
        setModalName("");
      }}
      footer={
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              onClick={() => {
                setModalName("");
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="primary"
              onClick={() => {
                console.log("clicked on Add");
                setModalName("");
              }}
            >
              Add
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Form>
        <Container
          header={
            <Header
              variant="h2"
              actions={
                <div>
                  <span className="awsui-util-action-stripe-group">
                    <Button
                      variant="normal"
                      iconName="refresh"
                      onClick={() => {
                        console.log("clicked refresh");
                      }}
                    ></Button>
                  </span>
                </div>
              }
            >
              User Selection
            </Header>
          }
        >
          <Table
            // onSelectionChange={(detail) =>
            //     setSelectedItems(detail.selectedItems)
            // }
            // selectedItems={selectedItems}
            ariaLabels={{
              selectionGroupLabel: "Items selection",
              allItemsSelectionLabel: ({ selectedItems }) =>
                `${selectedItems.length} ${
                  selectedItems.length === 1 ? "item" : "items"
                } selected`,
              itemSelectionLabel: ({ selectedItems }, item) => {
                const isItemSelected = selectedItems.filter(
                  (i) => i.userName === item.userName
                ).length;
                return `${item.userName} is ${
                  isItemSelected ? "" : "not"
                } selected`;
              },
            }}
            columnDefinitions={[
              {
                id: "userName",
                header: "User Name",
                cell: (e) => e.userName,
              },
              {
                id: "emailAddress",
                header: "Email Address",
                cell: (e) => e.emailAddress,
              },
              {
                id: "emailVerified",
                header: "Email Verified",
                cell: (e) => e.emailVerified,
              },
              {
                id: "confirmationStatus",
                header: "ConfirmationStatus",
                cell: (e) => e.confirmationStatus,
              },
              {
                id: "status",
                header: "Status",
                cell: (e) => e.status,
              },
            ]}
            items={[
              {
                userName: "Item 1",
                emailAddress: "First@gmail.com",
                emailVerified: "yes",
                confirmationStatus: "yes",
                status: "enabled",
              },
              {
                userName: "Item 2",
                emailAddress: "second@gmail.com",
                emailVerified: "yes",
                confirmationStatus: "yes",
                status: "enabled",
              },
            ]}
            loadingText="Loading resources"
            selectionType="single"
            trackBy="userName"
            visibleColumns={[
              "userName",
              "emailAddress",
              "emailVerified",
              "confirmationStatus",
              "status",
            ]}
            empty={
              <Box textAlign="center" color="inherit">
                <b>No resources</b>
                <Box padding={{ bottom: "s" }} variant="p" color="inherit">
                  No resources to display.
                </Box>
                <Button>Create resource</Button>
              </Box>
            }
            pagination={
              <Pagination
                currentPageIndex={1}
                pagesCount={2}
                ariaLabels={{
                  nextPageLabel: "Next page",
                  previousPageLabel: "Previous page",
                  pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
                }}
              />
            }
          />
        </Container>
      </Form>
    </Modal>
  );
}

const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(AddUserGroupModal);
