import {
  Box,
  Button,
  Header,
  Modal,
  SpaceBetween,
  Table,
} from "@cloudscape-design/components";
import { Role } from "../redux/api/role/types";
import { Facility } from "../redux/api/facility/types";
import { useState } from "react";
import { useDeleteRoleFacilitiesMutation } from "../redux/api/role/role";
import { setModalName } from "../redux/UI/actions";
import { connect } from "react-redux";
import { ADD_ASSOCIATED_FACILITY } from "../helpers/constants";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";
import useAsyncNotifyWrapper from "../hooks/useAsyncNotifyWrapper";

interface AssociatedFacilitiesProps {
  role: Role;
}

interface PropsFromDispatch {
  openModalName: typeof setModalName;
}
function AssociatedFacilities({
  role,
  openModalName,
}: PropsFromDispatch & AssociatedFacilitiesProps) {
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("roles.edit") ?? false)
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("roles.delete") ?? false)
  );
  const [selectedFacilities, setSelectedFacilities] = useState<Facility[]>([]);
  const [open_delete_modal, set_open_delete_modal] = useState<boolean>(false);
  const [deleteRoleFacilities] = useDeleteRoleFacilitiesMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  return (
    <div>
      <Table
        header={
          <Header
            counter={`(${role.facilities.length})`}
            actions={
              <SpaceBetween size="m" direction="horizontal">
                <Button
                  disabled={
                    !selectedFacilities.length ||
                    role.default ||
                    !hasDeletePermission
                  }
                  onClick={() => {
                    set_open_delete_modal(true);
                  }}
                >
                  Remove facility from role
                </Button>
                <Button
                  disabled={!hasPermission || role.default}
                  onClick={() => {
                    openModalName(ADD_ASSOCIATED_FACILITY);
                  }}
                >
                  Add facility to role
                </Button>
              </SpaceBetween>
            }
          >
            Associated Facilities
          </Header>
        }
        selectedItems={selectedFacilities}
        onSelectionChange={(selectionChangeDetail) => {
          setSelectedFacilities(selectionChangeDetail.detail.selectedItems);
        }}
        onRowClick={(onRowClickDetail) => {
          const index = selectedFacilities.indexOf(
            onRowClickDetail.detail.item
          );
          if (index >= 0) {
            let temp = [...selectedFacilities];
            temp.splice(index);
            setSelectedFacilities(temp);
          } else {
            setSelectedFacilities([
              ...selectedFacilities,
              onRowClickDetail.detail.item,
            ]);
          }
        }}
        selectionType="multi"
        items={role.facilities}
        columnDefinitions={[
          {
            id: "id",
            header: <div>id</div>,
            cell: (item) => <span>{item.id}</span>,
          },
          {
            id: "name",
            header: <div>Facility Name</div>,
            cell: (item) => <span>{item.name}</span>,
          },
        ]}
        visibleColumns={["name"]}
      />
      <Modal
        header={`Remove below facilities from role: ${role.name}`}
        disableContentPaddings
        visible={open_delete_modal}
        onDismiss={() => set_open_delete_modal(false)}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="link"
                onClick={() => set_open_delete_modal(false)}
              >
                Cancel
              </Button>
              <Button
                onClick={async() => {
                  if (selectedFacilities && selectedFacilities.length > 0) {
                    await notifyWrapper({
                      promise: deleteRoleFacilities({
                        roleId: role.id,
                        facility_ids: selectedFacilities.map(
                          (facility) => facility.id
                        ),
                      }),
                      resourceName: "associated facilities",
                      actionName: "delete",
                    });
                    setSelectedFacilities([]);
                  }
                  set_open_delete_modal(false);
                }}
                variant="primary"
              >
                Ok
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        <ul>
          {selectedFacilities.map((facility) => (
            <li>{facility.name}</li>
          ))}
        </ul>
      </Modal>
    </div>
  );
}

const mapDispatchToProps = {
  openModalName: setModalName,
};
export default connect(null, mapDispatchToProps)(AssociatedFacilities);
