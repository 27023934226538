import { Tabs, SpaceBetween, Header } from "@cloudscape-design/components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Categories from "./categories";
import GroupsTable from "./groups";

function ManageCategoryDetails() {
  const [activeTabId, setActiveTabId] = useState<string>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (searchParams.has("activeTab") && searchParams.get("activeTab")) {
      setActiveTabId(searchParams.get("activeTab") ?? "groups");
    }
  }, [searchParams]);

  return (
    <div
      style={{
        padding: "0px 10px 0px 10px",
      }}
    >
      <SpaceBetween size={"xxs"} direction="vertical">
        <Header variant="h1">Manage Categories</Header>
        <Tabs
          activeTabId={activeTabId}
          onChange={(changeDetail) => {
            console.log("managecategory");
            setActiveTabId(changeDetail.detail.activeTabId);
            navigate(
              `/managecategory?activeTab=${changeDetail.detail.activeTabId}`
            );
          }}
          tabs={[
            {
              label: "Categories",
              id: "categories",
              content: <Categories />,
            },
            {
              label: "Groups",
              id: "groups",
              content: <GroupsTable />,
            },
          ]}
        />
      </SpaceBetween>
    </div>
  );
}

export default ManageCategoryDetails;
