import { Divider } from "@mui/material";

import {
  Container,
  Box,
  Header,
  TextFilter,
  SpaceBetween,
  Select,
} from "@cloudscape-design/components";

import { FieldType } from "./types";
import CollapsibleList from "./section-collapsible-list";
import { SectionFilterType } from "../hooks/useSectionFilter";
import useIsLoading from "../hooks/useIsLoading";
import { Section } from "../redux/api/section/types";
import {
  PROVIDER_DIGITALFORM_FIELDS,
  STANDARD_DIGITALFORM_FIELDS,
} from "../helpers/df_constants";

interface TemplateDesignerLeftPanelProps {
  onDragStart: React.DragEventHandler<HTMLDivElement>;
  onDragEnd: (e: React.DragEvent<HTMLDivElement>, field: FieldType) => void;
}
interface FilterProps {
  filteredSections: Section[];
  isLoading: boolean;
  filter: SectionFilterType;
  setFilter: React.Dispatch<React.SetStateAction<SectionFilterType>>;
}

const filterOptions = [
  { label: "All", value: "all" },
  { label: "Facility", value: "facility" },
  { label: "Provider", value: "provider" },
  { label: "Standard", value: "standard" },
];
export default function TemplateDesignerTagsList({
  onDragStart,
  onDragEnd,
  filteredSections,
  isLoading,
  filter,
  setFilter,
}: TemplateDesignerLeftPanelProps & FilterProps) {
  useIsLoading(isLoading);

  return (
    <Container
      header={
        <>
          <Box padding={{ top: "s", left: "s", right: "s", bottom: "m" }}>
            <SpaceBetween size={"s"}>
              <Header>Fields</Header>
              <TextFilter
                filteringText={filter.filterText}
                onChange={(changeDetails) => {
                  setFilter({
                    ...filter,
                    filterText: changeDetails.detail.filteringText,
                  });
                }}
                filteringPlaceholder={"search by field name"}
              ></TextFilter>
              <Select
                selectedOption={
                  filterOptions.find(
                    (option) => option.value === filter.section_type,
                  ) ?? filterOptions[0]
                }
                onChange={({ detail }) =>
                  setFilter({
                    ...filter,
                    section_type: detail.selectedOption.value ?? "all",
                  })
                }
                options={filterOptions}
                selectedAriaLabel="Selected"
              />
            </SpaceBetween>
          </Box>
          <Divider />
        </>
      }
      disableHeaderPaddings
    >
      <div
        style={{
          padding: 0,
          overflow: "auto",
          height: `calc(100vh - 250px)`,
        }}
      >
        <SpaceBetween size={"xxs"} direction="vertical">
          {STANDARD_DIGITALFORM_FIELDS.map((field) => {
            return (
              <CollapsibleList
                field={field}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
              />
            );
          })}
          {PROVIDER_DIGITALFORM_FIELDS.map((field) => {
            return (
              <CollapsibleList
                field={field}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
              />
            );
          })}
        </SpaceBetween>
      </div>
    </Container>
  );
}
