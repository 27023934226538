import { useParams, useSearchParams } from "react-router-dom";
import useIsLoading from "../../hooks/useIsLoading";
import LoadingScreen from "../../components/LoadingScreen";
import {
  Box,
  BreadcrumbGroup,
  ButtonDropdown,
  Container,
  Header,
  SpaceBetween,
  Tabs,
} from "@cloudscape-design/components";
import FormSection from "../../components/FormSection";
import Label from "../../components/Label";
import { useEffect, useState } from "react";
import {
  useDeleteRoleMutation,
  useGetRoleQuery,
} from "../../redux/api/role/role";
import { setModalName } from "../../redux/UI/actions";
import { connect } from "react-redux";
import { EDIT_ROLE } from "../../helpers/constants";
import AssociatedFacilities from "../associated-facilities";
import AssociatedProviders from "../associated-providers";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import { AssociatedAdmins } from "../associated-admins";
import { useGetOrganizationDetailsQuery } from "../../redux/api/organization/organization";
import { Role } from "../../redux/api/role/types";
import { OrganizationSettingsType } from "../../redux/api/organization/types";
import DeleteAlertModal from "../../components/Modal/delete-alert-modal";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import { skipToken } from "@reduxjs/toolkit/dist/query";

interface PropsFromDispatch {
  openModalName: typeof setModalName;
}

function RoleDetails({ openModalName }: PropsFromDispatch) {
  const { id: roleId } = useParams();
  const { data: role, isLoading } = useGetRoleQuery(!!roleId?{
    id: roleId ,
  }:skipToken);
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("roles.edit") ?? false)
  );

  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("roles.delete") ?? false)
  );

  const [activeTabId, setActiveTabId] = useState<string>();
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: organization } = useGetOrganizationDetailsQuery();
  const [deleteRole] = useDeleteRoleMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();

  useEffect(() => {
    const searchParam_activeTabId = searchParams.get("activeTabId");
    if (searchParam_activeTabId)
      setActiveTabId(searchParam_activeTabId.toString());
  }, []);
  useIsLoading(isLoading);

  if (!role) return <LoadingScreen isOpen={true} />;
  else
    return (
      <div style={{ paddingTop: "25px" }}>
        <SpaceBetween size={"s"}>
          <BreadcrumbGroup
            items={[
              { text: "Roles", href: "/settings/roles" },
              {
                text: role.name ?? "",
                href: "#",
              },
            ]}
            ariaLabel="Breadcrumbs"
          />

          <Container
            header={
              <Header
                actions={
                  <ButtonDropdown
                    expandableGroups
                    disabled={!hasPermission || role.default}
                    items={[
                      {
                        id: "edit_form",
                        text: "Edit",
                      },
                      {
                        id: "delete_form",
                        disabled: !hasDeletePermission,
                        text: "Delete",
                      },
                    ]}
                    onItemClick={(itemClickDetails) => {
                      const { id } = itemClickDetails.detail;
                      if (id === "delete_form") {
                        if (roleId) {
                          OpenDeleteModal(true);
                        }
                      } else if (id === "edit_form") {
                        openModalName(EDIT_ROLE);
                      }
                    }}
                  >
                    Actions
                  </ButtonDropdown>
                }
              >
                Details
              </Header>
            }
          >
            <FormSection columns={3}>
              <Label label={"Role Name"} value={role.name} />
              <Label label={"Description"} value={role.description ?? "-"} />
            </FormSection>
          </Container>

          <Tabs
            activeTabId={activeTabId}
            onChange={(changeDetail) => {
              setSearchParams({
                activeTabId: changeDetail.detail.activeTabId,
              });
              setActiveTabId(changeDetail.detail.activeTabId);
            }}
            tabs={roleDetailTabs({
              role,
              organizationSettings: organization?.settings,
            })}
          />
        </SpaceBetween>
        <DeleteAlertModal
          visible={isDeleteModalOpen}
          action={async () => {
            if (roleId)
              await notifyWrapper({
                promise: deleteRole({ roleId: roleId }),
                resourceName: "role",
                actionName: "delete",
              });
            OpenDeleteModal(false);
          }}
          closeModal={() => OpenDeleteModal(false)}
          header={"Delete role"}
          content={
            <>
              <Box>Role Name</Box>
              <Box variant="awsui-key-label">{role?.name}</Box>
            </>
          }
          description={"Are you sure you want to delete this role?"}
        />
      </div>
    );
}

const roleDetailTabs = ({
  role,
  organizationSettings,
}: {
  role: Role;
  organizationSettings: OrganizationSettingsType | undefined;
}) => {
  let tabs = [
    {
      label: "Associated members",
      id: "members",
      content: <AssociatedAdmins admins={role.admins} />,
    },
  ];
  if (!!organizationSettings) {
    if (organizationSettings.enableFacilityRoles)
      tabs = [
        ...tabs,
        {
          label: "Associated facilities",
          id: "facilities",
          content: <AssociatedFacilities role={role} />,
        },
      ];
    else if (organizationSettings.enableProviderRoles) {
      tabs = [
        ...tabs,
        {
          label: "Associated Providers",
          id: "providers",
          content: <AssociatedProviders role={role} />,
        },
      ];
    }
  }
  return tabs;
};
const mapDispatchToProps = {
  openModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(RoleDetails);

const actions = [
  {
    id: "edit_form",
    text: "Edit",
  },
  {
    id: "delete_form",
    text: "Delete",
  },
];
