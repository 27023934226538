import { Button, Header, Modal } from "@cloudscape-design/components";
import Grid from "@mui/material/Grid";
import { Section, SectionMetadata } from "../redux/api/section/types";
import { useEffect, useState } from "react";
import { SectionForm } from "./section-form";
import { useUpdateSectionMutation } from "../redux/api/section/section";
import useIsLoading from "../hooks/useIsLoading";
import useAsyncNotifyWrapper from "../hooks/useAsyncNotifyWrapper";

interface EditSectionModalProps {
  visible: string;
  setVisible: React.Dispatch<React.SetStateAction<string>>;
  section: Section;
}

export function EditSectionModal({
  visible,
  setVisible,
  ...other
}: EditSectionModalProps) {
  const [section, setSection] = useState<SectionMetadata>({ ...other.section });
  const [postSection, { isLoading }] = useUpdateSectionMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  useEffect(() => {
    setSection({ ...other.section });
  }, [other.section]);
  useIsLoading(isLoading);
  return (
    <Modal
      visible={visible === "edit"}
      size="medium"
      header={<Header variant="h2">Edit section</Header>}
      onDismiss={() => {
        setVisible("");
      }}
      footer={
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              onClick={() => {
                setVisible("");
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="primary"
              onClick={async() => {
                await notifyWrapper({
                  promise: postSection({ ...section }),
                  resourceName: "manage profile",
                  actionName: "update",
                });                
                setVisible("");
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      }
    >
      <SectionForm section={section} setSection={setSection} />
    </Modal>
  );
}
