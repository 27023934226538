import { connect } from "react-redux";
import { setModalName } from "../../redux/UI/actions";
import { FormProvider, useForm } from "react-hook-form";
import {
  useGetRoleQuery,
  useUpdateRoleMutation,
} from "../../redux/api/role/role";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import {
  Button,
  Header,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import RHFTextField from "../../components/RHF/RHFTextField";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import { Grid } from "@mui/material";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";

type FormInputType = {
  name: string;
  npiNumber: string;
  description: string;
};

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function EditRole({ setModalName }: PropsFromDispatch) {
  const methods = useForm<FormInputType>();
  const { id: roleId } = useParams();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [updateRole] = useUpdateRoleMutation();

  const { data: role } = useGetRoleQuery({
    id: roleId ?? "",
  });

  useEffect(() => {
    if (role) {
      methods.setValue("name", role?.name ?? "");
      methods.setValue("description", role?.description ?? "");
    }
  }, [role]);

  const onSubmit = async (data: FormInputType) => {
    if (roleId)
      await notifyWrapper({
        promise: updateRole({
          id: roleId,
          name: data.name,
          description: data.description,
        }),
        resourceName: "role",
        actionName: "update",
      });
    setModalName("");
  };

  return (
    <Modal
      visible={true}
      size="medium"
      header={<Header variant="h2">Edit Facility</Header>}
      onDismiss={() => {
        setModalName("");
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <SpaceBetween direction="vertical" size="s">
            <RHFTextField
              label="Name"
              name="name"
              stretch
              rules={{ required: "This field is required" }}
            />

            <RHFTextArea label={"Description"} name={"description"} />

            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  formAction="none"
                  onClick={() => {
                    setModalName("");
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button variant="primary">Save</Button>
              </Grid>
            </Grid>
          </SpaceBetween>
        </form>
      </FormProvider>
    </Modal>
  );
}

const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(EditRole);
