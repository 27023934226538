import { Reducer } from "redux";
import { ManageCategoryState, ManageCategoryActionTypes } from "./types";

export const initialState: ManageCategoryState = {
  selectedManageCategory: [],
  selectedCategory: [],
  selectedGroups: [],
};

const reducer: Reducer<ManageCategoryState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ManageCategoryActionTypes.SELECT_MANAGECATEGORY: {
      return { ...state, selectedManageCategory: action.payload };
    }
    case ManageCategoryActionTypes.SELECT_CATEGORY: {
      return { ...state, selectedCategory: action.payload };
    }
    case ManageCategoryActionTypes.SELECT_GROUPS: {
      return { ...state, selectedGroups: action.payload };
    }
    default: {
      return state;
    }
  }
};

export { reducer as manageCategoryReducer };
