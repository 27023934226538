import {
  Button,
  Header,
  Table,
  TableProps,
  ButtonDropdown,
  Pagination,
  Box,
} from "@cloudscape-design/components";
import { connect } from "react-redux";
import { setModalName } from "../redux/UI/actions";
import {
  useDeleteFacilityMutation,
  useGetFacilitiesQuery,
} from "../redux/api/facility/facility";
import { Facility } from "../redux/api/facility/types";
import { useState, useMemo } from "react";
import StyledRouterLink from "../components/styled-route-link";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";
import { useNavigate } from "react-router";
import DeleteAlertModal from "../components/Modal/delete-alert-modal";
import useAsyncNotifyWrapper from "../hooks/useAsyncNotifyWrapper";
import { useLocalStorage } from "../common/localStorage";
import {
  FACILITY_CONTENT_DISPLAY_OPTIONS,
  FACILITY_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../common/table-config";
import { TableEmptyState } from "../common/common-components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import LoadingScreen from "../components/LoadingScreen";
import { useTranslation } from "react-i18next";

interface PropsFromDispatch {
  openModalName: typeof setModalName;
}

function ManageFacilities({ openModalName }: PropsFromDispatch) {
  const { data = [], isLoading, isFetching, refetch } = useGetFacilitiesQuery();
  const [deleteFacility] = useDeleteFacilityMutation();
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const { t, i18n } = useTranslation();

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("facility.edit") ?? false),
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("facility.delete") ?? false),
  );
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Facility-Table-Preferences",
    FACILITY_TABLE_DEFAULT_PREFERENCES,
  );
  const { items, collectionProps, paginationProps } = useCollection(data, {
    propertyFiltering: {
      filteringProperties: [],
      empty: <TableEmptyState resourceName="Providers" />,
    },
    sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
  });
  const [selectedFacility, setSelectedFacility] = useState<Facility>();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);

  const tableProps: TableProps<Facility> = useMemo(() => {
    return {
      header: (
        <Header
          counter={`(${data.length})`}
          actions={
            <div>
              <span className="awsui-util-action-stripe-group">
                <Button
                  variant="normal"
                  iconName="refresh"
                  onClick={() => {
                    refetch();
                  }}
                ></Button>
              </span>
              <span className="awsui-util-action-stripe-group">
                <ButtonDropdown
                  expandableGroups
                  disabled={!selectedFacility}
                  items={[
                    {
                      id: "edit_form",
                      text: `${t("facility.table.header.actions.facilityEdit")}`,
                    },
                    {
                      id: "delete_form",
                      text: `${t("facility.table.header.actions.facilityDelete")}`,
                      disabled: !hasDeletePermission,
                    },
                  ]}
                  onItemClick={(itemClickDetails) => {
                    const { id } = itemClickDetails.detail;
                    if (id === "delete_form") {
                      if (!!selectedFacility) {
                        OpenDeleteModal(true);
                      }
                    } else if (id === "edit_form") {
                      navigate(`/managefacilities/${selectedFacility?.id}`);
                    }
                  }}
                >
                  {t("facility.table.header.actions.facilityActions")}
                </ButtonDropdown>
              </span>
              <span className="awsui-util-action-stripe-group">
                <Button
                  disabled={!hasPermission}
                  onClick={() => {
                    navigate(`add`);
                  }}
                  variant="primary"
                  data-test="add_facilify"
                >
                  {t(`facility.table.header.actions.addFacility`)}
                </Button>
              </span>
            </div>
          }
        >
          {t(`facility.table.header.label`)}
        </Header>
      ),
      items,
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <TablePreferences
          title="Facility Table Preferences"
          preferences={tablePreferences}
          setPreferences={(preferences) => {
            setTablePreferences(preferences);
          }}
          contentDisplayOptions={FACILITY_CONTENT_DISPLAY_OPTIONS}
        />
      ),
      columnDisplay: tablePreferences?.contentDisplay,
      wrapLines: tablePreferences?.wrapLines,
      stripedRows: tablePreferences?.stripedRows,
      contentDensity: tablePreferences?.contentDensity,
      stickyColumns: tablePreferences?.stickyColumns,
      ...collectionProps,
      variant: "container",
      loading: isLoading || isFetching,
      loadingText: "Loading...",
      selectionType: "single",
      resizableColumns: false,
      stickyHeader: true,
      empty: <p>No results to display.</p>,
      onSelectionChange: (selectionChangeDetail) => {
        if (selectionChangeDetail.detail.selectedItems.at(0))
          setSelectedFacility(selectionChangeDetail.detail.selectedItems.at(0));
      },
      onRowClick: (onRowClickDetail) => {
        setSelectedFacility(onRowClickDetail.detail.item);
      },
      selectedItems: selectedFacility ? [selectedFacility] : [],
      trackBy: (item) => "" + item.id,
      columnDefinitions: [
        {
          id: "name",
          header: <div>{t("facility.table.header.fields.name")}</div>,
          cell: (item) => (
            <StyledRouterLink
              className={hasPermission ? "edit_link" : "normal"}
              to={hasPermission ? `${item.id}` : `#`}
              label={item?.name}
            />
          ),
          sortingField: "name",
        },
        {
          id: "npiNumber",
          header: <div>{t("facility.table.header.fields.npiNumber")}</div>,
          cell: (item) => <span>{item.npiNumber}</span>,
        },
        {
          id: "facilityType",
          header: <div>{t("facility.table.header.fields.facilityType")}</div>,
          cell: (item) => <span>{item?.facilityType?.name}</span>,
        },
        {
          id: "cliaId",
          header: <div>{t("facility.table.header.fields.CLIAID")}</div>,
          cell: (item) => <span>{item?.cliaId}</span>,
        },
        {
          id: "immunizationId",
          header: <div>{t("facility.table.header.fields.immunizationId")}</div>,
          cell: (item) => <span>{item?.immunizationId}</span>,
        },
        {
          id: "taxonomyCode",
          header: <div>{t("facility.table.header.fields.taxonomyCode")}</div>,
          cell: (item) => <span>{item?.taxonomyCode}</span>,
        },
        {
          id: "addressLane1",
          header: <div> {t("facility.table.header.fields.addressLane1")}</div>,
          cell: (item) => (
            <div> {item?.facilityAddress?.addressLane1 ?? "-"} </div>
          ),
        },
        {
          id: "addressLane2",
          header: <div>{t("facility.table.header.fields.addressLane2")}</div>,
          cell: (item) => (
            <div> {item?.facilityAddress?.addressLane2 ?? "-"} </div>
          ),
        },
        {
          id: "city",
          header: <div>{t("facility.table.header.fields.city")}</div>,
          cell: (item) => <div> {item?.facilityAddress?.city ?? "-"} </div>,
        },
        {
          id: "state",
          header: <div>{t("facility.table.header.fields.state")}</div>,
          cell: (item) => <div> {item?.facilityAddress?.state ?? "-"} </div>,
        },
        {
          id: "zipcode",
          header: <div>{t("facility.table.header.fields.zipcode")}</div>,
          cell: (item) => <div> {item?.facilityAddress?.zipcode ?? "-"} </div>,
        },
        {
          id: "country",
          header: <div>{t("facility.table.header.fields.country")}</div>,
          cell: (item) => <div> {item?.facilityAddress?.country ?? "-"} </div>,
        },
      ],
    };
  }, [tablePreferences, items, selectedFacility]);

  return (
    <div style={{ paddingTop: "24px" }}>
      <LoadingScreen isOpen={isLoading} />
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedFacility?.id)
            await notifyWrapper({
              promise: deleteFacility({ facilityId: selectedFacility?.id }),
              resourceName: "facility",
              actionName: "delete",
            });
          OpenDeleteModal(false);
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={t("facility.delete.header.label")}
        content={
          <>
            <Box>{t("facility.delete.header.content.label")}</Box>
            <Box variant="awsui-key-label">{selectedFacility?.name}</Box>
          </>
        }
        description={t("facility.delete.header.content.description")}
      />
    </div>
  );
}

const mapDispatchToProps = {
  openModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(ManageFacilities);
