import {
  Box,
  Container,
  ColumnLayout,
  SpaceBetween,
  Header,
  BreadcrumbGroup,
  Button,
  Link,
} from "@cloudscape-design/components";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  useGetAllDocumentPsvQuery,
  useGetDocumentQuery,
} from "../redux/api/document/document";
import { useGetProviderQuery } from "../redux/api/provider/provider";
import { useNavigate, useParams } from "react-router-dom";
import ChangeTimeZone from "../components/Timezone";
import { Divider } from "@mui/material";
import PSVTable from "./psv/psv-table";

export default function ProviderSafeDetails() {
  const navigate = useNavigate();
  const { providerId, documentId } = useParams();
  const { data: provider } = useGetProviderQuery(
    providerId ? { providerId } : skipToken,
  );
  const { data: psv = [] } = useGetAllDocumentPsvQuery(
    documentId ? { documentId } : skipToken,
  );

  const { data: document } = useGetDocumentQuery(
    documentId && providerId ? { documentId, providerId } : skipToken,
  );

  return (
    <SpaceBetween size="l">
      <BreadcrumbGroup
        items={[
          { text: "Provider Safe", href: `/safe/${provider?.id}` },
          {
            text: provider?.firstName + "" + provider?.lastName,
            href: "#",
          },
        ]}
      />
      <Container
        header={
          <SpaceBetween size="xs">
            <Header
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button>Delete</Button>
                  <Button
                    onClick={() => {
                      navigate("edit");
                    }}
                    variant="primary"
                  >
                    Edit
                  </Button>
                </SpaceBetween>
              }
            >
              {document?.name}
            </Header>
            <Divider />
          </SpaceBetween>
        }
      >
        <ColumnLayout columns={4} variant="text-grid">
          <div>
            <Box variant="awsui-key-label">Document Name</Box>
            <Link
              href={`/safe/${provider?.id}/documents/${document?.id}/edit?preview=true`}
            >
              {document?.name || "-"}
            </Link>
          </div>
          <div>
            <Box variant="awsui-key-label">Category</Box>
            <div>{document?.category?.name}</div>
          </div>
          <div>
            <Box variant="awsui-key-label">Category Group</Box>
            <div>{document?.category?.categoryGroup?.name}</div>
          </div>
          <div>
            <Box variant="awsui-key-label">Expiration Date</Box>
            <div>{ChangeTimeZone(document?.expirationDate) ?? "-"}</div>
          </div>
          <div>
            <Box variant="awsui-key-label">Uploaded Date</Box>
            <div>{ChangeTimeZone(document?.createdDate) ?? "-"}</div>
          </div>
          <div>
            <Box variant="awsui-key-label">File Name</Box>
            <Link href="#">{document?.attachment?.name || "-"}</Link>
          </div>
        </ColumnLayout>
      </Container>
      <PSVTable />
    </SpaceBetween>
  );
}
