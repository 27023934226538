import {
  AttributeEditor,
  Box,
  Button,
  Form,
  Header,
  Input,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import useAsyncNotifyWrapper from "../../../hooks/useAsyncNotifyWrapper";
import {
  useGetAllPrivilegeTypesQuery,
  useSaveAllPrivilegeTypesMutation,
} from "../../../redux/api/privileges/privileges";
import { setModalName } from "../../../redux/UI/actions";
import { connect } from "react-redux";
import { PrivilegeType } from "../../../redux/api/privileges/types";
import { useState, useEffect } from "react";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function AddPrivilegeTypeModal({ setModalName }: PropsFromDispatch) {
  const { t } = useTranslation();
  const { data = [], fulfilledTimeStamp } = useGetAllPrivilegeTypesQuery();
  const [privilegeType, setPrivilegeType] = useState<PrivilegeType[]>([]);
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [saveAllPrivilegeTypes] = useSaveAllPrivilegeTypesMutation();
  const handleSubmit = async () => {
    setModalName("");
    const tmp = [...privilegeType].filter((item) => !!item.name);
    await notifyWrapper({
      promise: saveAllPrivilegeTypes(tmp),
      resourceName: "Privilege Types",
      actionName: "save",
    });
  };
  useEffect(() => {
    const tmpItems = [...data];
    setPrivilegeType(tmpItems);
  }, [data, fulfilledTimeStamp]);

  return (
    <Modal
      header={<Header>{t("privileges.privilegeType.header.label")}</Header>}
      visible={true}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              onClick={() => {
                setModalName("");
              }}
              formAction="none"
            >
              {t("privileges.privilegeType.header.actions.cancel")}
            </Button>
            <Button
              formAction="submit"
              onClick={handleSubmit}
              variant="primary"
            >
              {t("privileges.privilegeType.header.actions.save")}
            </Button>
          </SpaceBetween>
        </Box>
      }
      size="large"
      onDismiss={() => {
        setModalName("");
      }}
    >
      <div>
        <SpaceBetween size={"m"}>
          <Divider></Divider>

          <div
            style={{
              maxHeight: "80vh",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <form
              onSubmit={(event) => {
                event.preventDefault();
                handleSubmit();
              }}
            >
              <Form>
                <AttributeEditor
                  onAddButtonClick={() =>
                    setPrivilegeType([
                      ...privilegeType,
                      { id: "", name: "", description: "", deleted: false },
                    ])
                  }
                  onRemoveButtonClick={({ detail: { itemIndex } }) => {
                    const tmp = [...privilegeType];
                    const item = { ...tmp[itemIndex] };
                    tmp[itemIndex] = {
                      id: item.id,
                      name: item.name,
                      description: item.description,
                      deleted: true,
                    };
                    setPrivilegeType(tmp);
                  }}
                  items={privilegeType.filter((item) => item.deleted !== true)}
                  addButtonText={t(
                    "privileges.privilegeType.header.actions.addPrivilegeType",
                  )}
                  definition={[
                    {
                      label: `${t("privileges.privilegeType.header.fields.type.label")}`,
                      control: (item, itemIndex: number) => (
                        <Input
                          value={item.name}
                          onChange={(changeDetails) => {
                            const tmp = [...privilegeType];
                            tmp[itemIndex] = {
                              id: item.id,
                              name: changeDetails.detail.value,
                              description: item.description,
                              deleted: item.deleted,
                            };
                            setPrivilegeType(tmp);
                          }}
                          placeholder={t(
                            "privileges.privilegeType.header.fields.type.placeHolder",
                          )}
                        />
                      ),
                    },

                    /*   {
                        label: "Description",
                        control: (item, itemIndex: number) => (
                          <Input
                            value={item.description}
                            onChange={(changeDetails) => {
                              const tmp = [...privilegeType];
                              tmp[itemIndex] = {
                                id: item.id,
                                name: item.name,
                                description: changeDetails.detail.value,
                              };
                              setPrivilegeType(tmp);
                            }}
                            placeholder="Enter Description"
                          />
                        ),
                      }, */
                  ]}
                />
              </Form>
            </form>
          </div>
        </SpaceBetween>
      </div>
    </Modal>
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(AddPrivilegeTypeModal);
