import {
  Box,
  BreadcrumbGroup,
  Button,
  ColumnLayout,
  Container,
  Header,
  Link,
  SpaceBetween,
  Table,
  Tabs,
} from "@cloudscape-design/components";
import { useParams } from "react-router-dom";
import {
  useDeleteCredentialTemplateCategoriesMutation,
  useDeleteCredentialTemplateMutation,
  useGetAllCredentialTemplateCategoriesQuery,
  useGetCredentialTemplateQuery,
} from "../../redux/api/credentialpackages/credentialpackages";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import FormsList from "./forms-list";
import Label from "../../components/Label";
import { CategoryType } from "../../redux/api/managecategory/types";
import { PATH_DASHBOARD } from "../../routes/paths";
import { useState } from "react";
import CredentialTemplateAddCategoriesModal from "./credentialtemplate-add-catgories-modal";
import { setModalName } from "../../redux/UI/actions";
import { connect } from "react-redux";
import { EDIT_TEMPLATE } from "../../helpers/constants";
import DeleteAlertModal from "../../components/Modal/delete-alert-modal";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import { DigitalForm } from "../../redux/api/digitalform/types";

interface PropsFromDispatch {
  openModalName: typeof setModalName;
}

function CredentialTemplateDetails({ openModalName }: PropsFromDispatch) {
  const { id: credentialTemplateId } = useParams();

  const { data: credentialTemplate } = useGetCredentialTemplateQuery(
    credentialTemplateId ? { credentialTemplateId } : skipToken,
  );

  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [selectedCategories, setSelectedCategories] = useState<CategoryType[]>(
    [],
  );
  const [selectedForms, setSelectedForms] = useState<DigitalForm[]>([]);
  const [deleteCredentialTemplate] = useDeleteCredentialTemplateMutation();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);

  return (
    <SpaceBetween size="s">
      <BreadcrumbGroup
        items={[
          {
            text: "Credential Templates",
            href: PATH_DASHBOARD.credential.templates.list,
          },
          { text: credentialTemplate?.name ?? "", href: "#" },
        ]}
      />

      <SpaceBetween size="xxl">
        <Container
          header={
            <Header
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    onClick={() => {
                      if (credentialTemplateId) OpenDeleteModal(true);
                    }}
                  >
                    Delete
                  </Button>
                  <Button
                    onClick={() => {
                      if (credentialTemplateId) openModalName(EDIT_TEMPLATE);
                    }}
                  >
                    Edit
                  </Button>
                </SpaceBetween>
              }
            >
              Credential Template
            </Header>
          }
        >
          <ColumnLayout columns={3} variant="text-grid">
            <Label label="Name" value={credentialTemplate?.name ?? "-"} />
            <Label
              label="Description"
              value={credentialTemplate?.name ?? "-"}
            />
            <Label
              label="Created date"
              value={credentialTemplate?.name ?? "-"}
            />
          </ColumnLayout>
        </Container>
        <DeleteAlertModal
          visible={isDeleteModalOpen}
          action={async () => {
            if (credentialTemplateId) {
              OpenDeleteModal(false);
              await notifyWrapper({
                promise: deleteCredentialTemplate({
                  credentialTemplateId: credentialTemplateId,
                }),
                resourceName: "template",
                actionName: "delete",
              });
            }
          }}
          closeModal={() => OpenDeleteModal(false)}
          header={"Delete Template"}
          content={
            <>
              <Box>Name</Box>
              <Box variant="awsui-key-label">{credentialTemplate?.name}</Box>
            </>
          }
          description={"Are you sure you want to delete this template?"}
        />
        <Tabs
          tabs={[
            {
              label: "Forms",
              id: "forms",
              content: (
                <FormsList
                  forms={credentialTemplate?.digitalForm ?? []}
                  selectedForms={selectedForms}
                  setSelectForms={setSelectedForms}
                />
              ),
            },
            {
              label: "Categories",
              id: "documents",
              content: (
                <CategoryList
                  categories={credentialTemplate?.categories ?? []}
                  selectedCategories={selectedCategories}
                  setSelectCategories={setSelectedCategories}
                />
              ),
            },
          ]}
        />
      </SpaceBetween>
    </SpaceBetween>
  );
}
const mapDispatchToProps = {
  openModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(CredentialTemplateDetails);

function CategoryList({
  selectedCategories,
  setSelectCategories,
}: {
  categories: CategoryType[];
  selectedCategories: CategoryType[];
  setSelectCategories: (selectedCategories: CategoryType[]) => void;
}) {
  const { id: credentialTemplateId } = useParams();

  const {
    data: categories = [],
    refetch,
    isFetching,
    isLoading,
  } = useGetAllCredentialTemplateCategoriesQuery(
    credentialTemplateId ? { credentialTemplateId } : skipToken,
  );
  const [isAddCategoryModalVisible, setIsAddCategoryModalVisible] =
    useState<boolean>(false);

  const [deleteCredentialTemplateCategories] =
    useDeleteCredentialTemplateCategoriesMutation();

  return (
    <>
      <Table
        columnDefinitions={[
          {
            id: "name",
            header: "Name",
            cell: (item) => <div>{item?.name || "-"}</div>,
            sortingField: "name",
            isRowHeader: true,
          },
        ]}
        items={categories}
        selectedItems={selectedCategories ?? []}
        onSelectionChange={({ detail }) => {
          if (!!detail.selectedItems) setSelectCategories(detail.selectedItems);
        }}
        onRowClick={({ detail }) => {
          const temp = selectedCategories;
          if (!detail.item) return;
          if (temp?.includes(detail.item))
            setSelectCategories(temp.filter((item) => item !== detail.item));
          else setSelectCategories([detail.item, ...temp]);
        }}
        selectionType="multi"
        loadingText="Loading..."
        loading={isLoading || isFetching}
        stickyHeader
        sortingDisabled
        header={
          <Header
            variant="h3"
            counter={`(${categories.length})`}
            actions={
              <SpaceBetween size="s" direction="horizontal">
                <Button
                  iconName="refresh"
                  onClick={() => {
                    refetch();
                  }}
                ></Button>
                <Button
                  onClick={() => {
                    if (!selectedCategories || !credentialTemplateId) return;
                    const categoryIds = selectedCategories.map(
                      (category) => category.id + "",
                    );
                    deleteCredentialTemplateCategories({
                      credentialTemplateId: credentialTemplateId,
                      categories: categoryIds,
                    });
                  }}
                >
                  Remove
                </Button>
                <Button
                  onClick={() => {
                    setIsAddCategoryModalVisible(true);
                  }}
                >
                  Add Category
                </Button>
              </SpaceBetween>
            }
          >
            Document Categories
          </Header>
        }
      />
      <CredentialTemplateAddCategoriesModal
        closeModal={() => {
          setIsAddCategoryModalVisible(false);
        }}
        visible={isAddCategoryModalVisible}
      />
    </>
  );
}
