import { FormProvider, useForm } from "react-hook-form";
import {
  BreadcrumbGroup,
  Button,
  Container,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { PayerEnrollmentRequest } from "../../redux/api/payerenrollment/types";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import {
  useGetAllPayerEnrollmentStatusQuery,
  useGetPayerEnrollmentQuery,
  useUpdatePayerEnrollmentMutation,
} from "../../redux/api/payerenrollment/payerenrollment";
import { Divider } from "semantic-ui-react";
import FormSection from "../../components/FormSection";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import RHFSelect from "../../components/RHF/RHFSelect";
import {
  PAYER_ENROLLMENT_STATUS,
  alertReminderOptions,
} from "../../helpers/constants";
import { useGetAllProvidersQuery } from "../../redux/api/provider/provider";
import { useGetAllPayerQuery } from "../../redux/api/payers/payers";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import RHFFloatingSelect from "../../components/RHF/RHFFloatingSelect";
import { connect } from "react-redux";
import { setModalName } from "../../redux/UI/actions";
import LoadingScreen from "../../components/LoadingScreen";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function EditPayerEnrollment({ setModalName }: PropsFromDispatch) {
  const methods = useForm<PayerEnrollmentRequest>();
  const { id: payerId, enrollmentId } = useParams();
  const {
    data: payerEnrollment,
    fulfilledTimeStamp,
    isLoading,
  } = useGetPayerEnrollmentQuery(
    !!enrollmentId
      ? {
          payerEnrollmentId: enrollmentId,
        }
      : skipToken,
  );
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const { data: status = [] } = useGetAllPayerEnrollmentStatusQuery();
  const { data: payer = [] } = useGetAllPayerQuery();

  const { data: provider = [] } = useGetAllProvidersQuery({
    includeRecordData: false,
  });
  const onSubmit = async (data: PayerEnrollmentRequest) => {
    if (!!enrollmentId) {
      await notifyWrapper({
        promise: updatePayerEnrollment({
          id: enrollmentId,
          ...data,
        }),
        resourceName: "payerenrollment",
        actionName: "update",
      });
      navigate(-1);
    }
  };

  const [updatePayerEnrollment] = useUpdatePayerEnrollmentMutation();

  useEffect(() => {
    if (payerEnrollment) {
      methods.setValue("description", payerEnrollment?.description);
      !!payerEnrollment?.status?.id &&
        methods.setValue("enrollmentStatusId", payerEnrollment?.status?.id);
      !!payerEnrollment?.provider?.id &&
        methods.setValue("providerId", payerEnrollment?.provider?.id);
      !!payerEnrollment?.payer?.id &&
        methods.setValue("payer", payerEnrollment?.payer?.id);
      methods.setValue("submittedDate", payerEnrollment?.submittedDate);
      methods.setValue("approvedDate", payerEnrollment?.approvedDate);
      methods.setValue("effectiveDate", payerEnrollment?.effectiveDate);
      methods.setValue(
        "recredentialingDate",
        payerEnrollment?.recredentialingDate,
      );
      methods.setValue("followupDate", payerEnrollment?.followupDate);
    }
  }, [fulfilledTimeStamp, payerEnrollment, enrollmentId]);

  return (
    <div style={{ paddingTop: "25px" }}>
      <LoadingScreen isOpen={isLoading} />
      <SpaceBetween size={"l"}>
        <BreadcrumbGroup
          items={[
            { text: "PayerEnrollment", href: `/payerenrollment` },

            { text: `Edit`, href: "#" },
          ]}
          ariaLabel="Breadcrumbs"
        />
        <Container
          header={
            <SpaceBetween size="xxs">
              <Header>Edit Payer Enrollment</Header>
              <Divider></Divider>
            </SpaceBetween>
          }
        >
          <div style={{ paddingTop: "12px" }}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Form
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        onClick={() => {
                          navigate(-1);
                        }}
                        formAction="none"
                      >
                        Cancel
                      </Button>
                      <Button formAction="submit" variant="primary">
                        Submit
                      </Button>
                    </SpaceBetween>
                  }
                >
                  <FormSection columns={4}>
                    {/* <RHFTextArea
                                            label="Description"
                                            name="description"
                                        /> */}
                    <RHFSelect
                      disabled
                      name="payer"
                      label="Payer"
                      options={payer.map((temp) => ({
                        label: temp.name,
                        value: "" + temp.id,
                      }))}
                      stretch={false}
                      rules={{ required: "This field is required" }}
                    />

                    <RHFSelect
                      disabled
                      label="Provider"
                      name={"providerId"}
                      stretch={false}
                      options={provider.map((temp) => ({
                        label: temp.firstName,
                        value: "" + temp.id,
                        tags: temp?.providerLicenses?.map(
                          (type) => type.licenseType,
                        ),
                      }))}
                      rules={{ required: "This field is required" }}
                    />
                    <RHFFloatingSelect
                      name="enrollmentStatusId"
                      label="Status"
                      options={status.map((temp) => ({
                        label: temp.name,
                        value: "" + temp.id,
                      }))}
                      rules={{ required: "This field is required" }}
                      stretch={false}
                      action={{
                        label: "Modify",
                        onClick: () => {
                          setModalName(PAYER_ENROLLMENT_STATUS);
                        },
                      }}
                    />
                    <RHFDatePicker
                      label={"Submitted Date"}
                      name={"submittedDate"}
                      stretch={false}
                    />
                    <RHFDatePicker
                      label="Approved Date"
                      name="approvedDate"
                      stretch={false}
                    />
                    <RHFDatePicker
                      label="Effective Date"
                      name="effectiveDate"
                      stretch={false}
                    />
                    <RHFDatePicker
                      label={"Re-credentialing Date"}
                      name={"recredentialingDate"}
                      stretch={false}
                    />
                    <RHFSelect
                      label="Follow-up Date"
                      name={"followupDate"}
                      stretch={false}
                      options={alertReminderOptions}
                    />
                  </FormSection>
                </Form>
              </form>
            </FormProvider>
          </div>
        </Container>
      </SpaceBetween>
    </div>
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(EditPayerEnrollment);
