import { useEffect, useState } from "react";
import {
  Alert,
  AttributeEditor,
  Box,
  FormField,
  Grid,
  Header,
  Input,
  SpaceBetween,
  Textarea,
  Toggle,
} from "@cloudscape-design/components";
import { getRecipientTypeOption } from "../types";
import * as awsui from "@cloudscape-design/design-tokens";
import Recipient, {
  AddlRecipientColors,
  ColorIcon,
  RecipientAction,
  RecipientValue,
  TypeSelection,
} from "./recipient";
import FormSection from "../../components/FormSection";
import { useSearchParams } from "react-router-dom";
import { useGetProviderQuery } from "../../redux/api/provider/provider";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import useIsLoading from "../../hooks/useIsLoading";
import { RecipientType } from "../../redux/api/signaturerequest/types";
import { v4 as uuidv4 } from "uuid";

type SignatureRequestStepOneProps = {
  primaryRecipient: RecipientType | undefined;
  setPrimaryRecipient: React.Dispatch<
    React.SetStateAction<RecipientType | undefined>
  >;
  addlRecipients: RecipientType[];
  setAddlRecipients: React.Dispatch<React.SetStateAction<RecipientType[]>>;
  subject: string;
  setSubject: React.Dispatch<React.SetStateAction<string>>;
  messageBody: string;
  setMessageBody: React.Dispatch<React.SetStateAction<string>>;
  errorText: string | undefined;
  setErrorText: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export function SignatureRequestStepOne({
  primaryRecipient,
  setPrimaryRecipient,
  addlRecipients,
  setAddlRecipients,
  subject,
  setSubject,
  messageBody,
  setMessageBody,
  errorText,
  setErrorText,
}: SignatureRequestStepOneProps) {
  const [completeInOrder, setCompleteInOrder] = useState<boolean>(false);
  const [searchParams] = useSearchParams();

  const { data: provider, isLoading: isProviderLoading } = useGetProviderQuery(
    searchParams.has("providerId")
      ? { providerId: searchParams.get("providerId") ?? "" }
      : skipToken,
  );

  useIsLoading(isProviderLoading);

  useEffect(() => {
    if (!!provider)
      setPrimaryRecipient({
        id: uuidv4(),
        member: undefined,
        emailAddress: undefined,
        provider: provider,
        action: "sign",
      });
  }, [provider]);

  return (
    <SpaceBetween size={"xxl"}>
      <SpaceBetween size={"xxs"}>
        <Header variant="h3" info={<span style={{ color: "#fc0a37" }}>*</span>}>
          Primary Recipient
        </Header>
        <Recipient
          recipient={primaryRecipient}
          displayLabels
          dragabble={false}
          disableFields={{ action: true }}
          hideDelete
          onRecipientChange={setPrimaryRecipient}
        />
      </SpaceBetween>
      <FormSection
        expandedSection={true}
        defaultExpanded={false}
        header={
          <SpaceBetween size="xs" direction="horizontal">
            <Box fontSize="body-m" fontWeight="bold">
              Additional Recipients
            </Box>
            <Box color="text-body-secondary">
              <i>(optional)</i>
            </Box>
          </SpaceBetween>
        }
      >
        {!!addlRecipients && addlRecipients.length > 1 && false && (
          <SpaceBetween size={"s"} direction="horizontal">
            <span
              style={
                completeInOrder
                  ? { color: awsui.colorTextBodyDefault, fontWeight: 700 }
                  : { color: awsui.colorTextInputDisabled, fontWeight: 500 }
              }
            >
              Complete in Order
            </span>

            <Toggle
              onChange={({ detail }) => setCompleteInOrder(detail.checked)}
              checked={completeInOrder}
            ></Toggle>
            <span
              style={
                !completeInOrder
                  ? { color: awsui.colorTextBodyDefault, fontWeight: 700 }
                  : { color: awsui.colorTextInputDisabled, fontWeight: 500 }
              }
            >
              Complete in any Order
            </span>
          </SpaceBetween>
        )}

        <AttributeEditor
          onAddButtonClick={() =>
            setAddlRecipients([
              ...addlRecipients,
              {
                id: uuidv4(),
                action: "sign",
                backgroundColor:
                  AddlRecipientColors[
                    addlRecipients.length % AddlRecipientColors.length
                  ],
              },
            ])
          }
          onRemoveButtonClick={({ detail: { itemIndex } }) => {
            const tmpItems = [...addlRecipients];
            tmpItems.splice(itemIndex, 1);
            tmpItems.forEach((item, index) => {
              item.backgroundColor =
                AddlRecipientColors[index % AddlRecipientColors.length];
            });
            setAddlRecipients(tmpItems);
          }}
          items={addlRecipients}
          addButtonText="Add Additional Recipient"
          definition={[
            {
              label: "Type",
              control: (item, index) => {
                return (
                  <Grid
                    gridDefinition={[{ colspan: 2 }, { colspan: 10 }]}
                    disableGutters
                  >
                    <div style={{ marginTop: "6px" }}>
                      <ColorIcon backgroundColor={item.backgroundColor} />
                    </div>
                    <TypeSelection
                      displayLabels={false}
                      selectedOption={getRecipientTypeOption(item)}
                      onChange={({ detail }) => {
                        const tmpItems = [...addlRecipients];
                        console.log({ detail });
                        if (!!detail.selectedOption?.value)
                          tmpItems[index] = {
                            ...tmpItems[index],
                            provider: undefined,
                            member: undefined,
                            emailAddress: undefined,
                            type: detail.selectedOption.value,
                          };
                        setAddlRecipients(tmpItems);
                      }}
                    />
                  </Grid>
                );
              },
            },
            {
              label: "Recipient",
              control: (item, index) => (
                <RecipientValue
                  type={getRecipientTypeOption(item).value}
                  recipient={item}
                  onRecipientChange={(recipient) => {
                    const tmpItems = [...addlRecipients];
                    if (!!recipient.provider)
                      tmpItems[index] = {
                        ...tmpItems[index],
                        provider: recipient.provider,
                        member: undefined,
                        emailAddress: undefined,
                      };
                    else if (!!recipient.member)
                      tmpItems[index] = {
                        ...tmpItems[index],
                        member: recipient.member,
                        provider: undefined,
                        emailAddress: undefined,
                      };
                    else
                      tmpItems[index] = {
                        ...tmpItems[index],
                        emailAddress: recipient.emailAddress,
                        provider: undefined,
                        member: undefined,
                      };

                    setAddlRecipients(tmpItems);
                  }}
                />
              ),
            },
            {
              label: "Action",
              control: (item, index) => (
                <RecipientAction
                  recipient={item}
                  onChange={({ detail }) => {
                    console.log({ detail });
                    const tmpItems = [...addlRecipients];
                    if (
                      !!detail.selectedOption &&
                      !!detail.selectedOption.value
                    )
                      tmpItems[index] = {
                        ...tmpItems[index],
                        action: detail.selectedOption.value,
                      };
                    setAddlRecipients(tmpItems);
                  }}
                />
              ),
            },
          ]}
        />
      </FormSection>
      <FormSection
        gridDefinition={[
          { colspan: { default: 12, xs: 9 } },
          { colspan: { default: 12, xs: 9 } },
        ]}
        spacing="l"
        header={
          <Box fontSize="body-m" fontWeight="bold">
            Add Message
          </Box>
        }
        expandedSection
        defaultExpanded={false}
      >
        <FormField label="Subject" info={<i>(optional)</i>} stretch>
          <Input
            value={subject}
            onChange={(changeDetails) => {
              setSubject(changeDetails.detail.value);
            }}
          ></Input>
        </FormField>
        <FormField info={<i>(optional)</i>} label="Message" stretch>
          <Textarea
            value={messageBody}
            onChange={(changeDetails) => {
              setMessageBody(changeDetails.detail.value);
            }}
          />
        </FormField>
      </FormSection>
      {!!errorText && (
        <FormField stretch={true}>
          <Alert statusIconAriaLabel="Error" type="error" header={errorText} />
        </FormField>
      )}
    </SpaceBetween>
  );
}
