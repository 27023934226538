import {
  Box,
  Button,
  ButtonDropdown,
  Header,
  Link,
  Pagination,
  SpaceBetween,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import { useNavigate, useParams } from "react-router-dom";
import {
  useDeleteProviderWorkHistoryMutation,
  useGetProviderQuery,
} from "../../../../redux/api/provider/provider";
import { useState, useMemo } from "react";
import { ProviderWorkHistoryType } from "../../../../redux/api/provider/section/types";
import DeleteAlertModal from "../../../../components/Modal/delete-alert-modal";
import Label from "../../../../components/Label";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";
import { useLocalStorage } from "../../../../common/localStorage";
import {
  TablePreferences,
  WORKHISTORY_CONTENT_DISPLAY_OPTIONS,
  WORKHISTORY_TABLE_DEFAULT_PREFERENCES,
} from "../../../../common/table-config";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableEmptyState } from "../../../../common/common-components";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../redux/store";
import StyledRouterLink from "../../../../components/styled-route-link";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";

export default function WorkHistory() {
  const navigate = useNavigate();
  const { providerId } = useParams();
  const { data: provider } = useGetProviderQuery(
    !!providerId
      ? {
          providerId: providerId,
        }
      : skipToken,
  );
  const [selectedItem, setSelectedItem] = useState<ProviderWorkHistoryType>();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const [deleteProviderWorkHistory] = useDeleteProviderWorkHistoryMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-WorkHistory-Table-Preferences",
    WORKHISTORY_TABLE_DEFAULT_PREFERENCES,
  );
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false),
  );
  const { items, collectionProps, paginationProps } = useCollection(
    provider?.providerWorkHistories ?? [],
    {
      propertyFiltering: {
        filteringProperties: [],
        empty: <TableEmptyState resourceName="Work History" />,
      },
    },
  );

  const tableProps: TableProps<ProviderWorkHistoryType> = useMemo(() => {
    return {
      columnDefinitions: [
        {
          id: "id",
          header: "Id",
          cell: (item) => item.id || "-",
          sortingField: "name",
          isRowHeader: true,
        },
        {
          id: "name",
          header: "Name",
          cell: (item) => (
            <StyledRouterLink
              className={hasPermission ? "edit_link" : "normal"}
              to={hasPermission ? `editworkhistory/${item.id}` : `#`}
              label={item?.name}
            />
          ),
        },
        {
          id: "type",
          header: "Type",
          cell: (item) => item.type || "-",
          sortingField: "alt",
        },
        {
          id: "startDate",
          header: "Start Date",
          cell: (item) => (
            <div> {item.startDate?.toLocaleString() ?? "-"} </div>
          ),
        },
        {
          id: "endDate",
          header: "End Date",
          cell: (item) => <div> {item.endDate?.toLocaleString() ?? "-"} </div>,
        },
        {
          id: "positionHeld",
          header: "Position Held",
          cell: (item) => <div> {item?.positionHeld} </div>,
        },
        {
          id: "gapExplanation",
          header: "Gap Explanation",
          cell: (item) => <div> {item.gapExplanation ?? "-"} </div>,
        },
        {
          id: "referenceName",
          header: "Reference Name",
          cell: (item) => <div> {item.referenceName ?? "-"} </div>,
        },
        {
          id: "referenceEmail",
          header: "Reference Email",
          cell: (item) => <div> {item.referenceEmail ?? "-"} </div>,
        },
        {
          id: "referencePhone",
          header: "Reference Phone",
          cell: (item) => <div> {item.referencePhone ?? "-"} </div>,
        },
        {
          id: "referenceFax",
          header: "Reference Fax",
          cell: (item) => <div> {item.referenceFax ?? "-"} </div>,
        },
        {
          id: "addressLane1",
          header: "Address Lane1",
          cell: (item) => <div> {item.addressLane1 ?? "-"} </div>,
        },
        {
          id: "addressLane2",
          header: "Address Lane2",
          cell: (item) => <div> {item.addressLane2 ?? "-"} </div>,
        },
        {
          id: "city",
          header: "City",
          cell: (item) => <div> {item.city ?? "-"} </div>,
        },
        {
          id: "state",
          header: "State",
          cell: (item) => <div> {item.state ?? "-"} </div>,
        },
        {
          id: "zip",
          header: "Zip",
          cell: (item) => <div> {item.zip ?? "-"} </div>,
        },
        {
          id: "country",
          header: "Country",
          cell: (item) => <div> {item.country ?? "-"} </div>,
        },
        {
          id: "documentName",
          header: "Document Name",
          cell: (item) => (
            <Link
              external
              href={
                hasPermission && item?.document?.id
                  ? `/safe/${providerId}/documents/${item?.document?.id}`
                  : `#`
              }
            >
              {item.document?.name ?? "-"}
            </Link>
          ),
        },
      ],
      header: (
        <Header
          actions={
            <SpaceBetween size="s" direction="horizontal">
              <ButtonDropdown
                disabled={!selectedItem}
                items={[
                  { text: "Edit", id: "edit", disabled: false },
                  { text: "Delete", id: "delete", disabled: false },
                ]}
                onItemClick={({ detail }) => {
                  if (detail.id === "edit")
                    if (selectedItem && selectedItem.id)
                      navigate(`editworkhistory/${selectedItem.id}`);
                  if (detail.id === "delete") {
                    if (selectedItem && selectedItem.id && !!providerId)
                      OpenDeleteModal(true);
                  }
                }}
              >
                Action
              </ButtonDropdown>
              <Button
                variant="primary"
                onClick={() => {
                  navigate(`editworkhistory`);
                }}
              >
                Add new
              </Button>
            </SpaceBetween>
          }
        >
          Work History
        </Header>
      ),
      items,
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <TablePreferences
          title="Work History Table Preferences"
          preferences={tablePreferences}
          setPreferences={(preferences) => {
            setTablePreferences(preferences);
          }}
          contentDisplayOptions={WORKHISTORY_CONTENT_DISPLAY_OPTIONS}
        />
      ),
      columnDisplay: tablePreferences?.contentDisplay,
      wrapLines: tablePreferences?.wrapLines,
      stripedRows: tablePreferences?.stripedRows,
      contentDensity: tablePreferences?.contentDensity,
      stickyColumns: tablePreferences?.stickyColumns,
      ...collectionProps,
      loadingText: "Loading resources",
      selectionType: "single",
      selectedItems: selectedItem ? [selectedItem] : [],
      onSelectionChange: ({ detail }) => {
        if (detail.selectedItems && detail.selectedItems.at(0))
          setSelectedItem(detail.selectedItems.at(0));
      },
      onRowClick: ({ detail }) => {
        if (detail.item) setSelectedItem(detail.item);
      },
      empty: (
        <Box textAlign="center" color="inherit">
          <b>No resources</b>
          <Box padding={{ bottom: "s" }} variant="p" color="inherit">
            No resources to display.
          </Box>
          <Button>Create resource</Button>
        </Box>
      ),
    };
  }, [tablePreferences, items, selectedItem, provider]);

  return (
    <div style={{ paddingTop: "12px" }}>
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedItem?.id && !!providerId)
            await notifyWrapper({
              promise: deleteProviderWorkHistory({
                providerId: providerId,
                workHistoryId: selectedItem.id.toString(),
              }),
              resourceName: "Work History",
              actionName: "delete",
            });
          OpenDeleteModal(false);
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={"Delete Work History"}
        content={
          <>
            <Box>Name</Box>
            <Box>{selectedItem?.name}</Box>
          </>
        }
        description={"Are you sure you want to delete this work history?"}
      />
    </div>
  );
}
