import { SpaceBetween } from "@cloudscape-design/components";
import RHFTextField from "../../components/RHF/RHFTextField";

export function SubSectionConfig() {
  return (
    <SpaceBetween size="s">
      <RHFTextField label="Name" name="name" readOnly />
      <RHFTextField label="Description" name="description" />
    </SpaceBetween>
  );
}
