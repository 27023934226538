import { useSelector } from "react-redux";
import { Attribute } from "../redux/api/section/types";
import RHFCheckBox from "./RHF/RHFCheckBox";
import RHFDatePicker from "./RHF/RHFDatePicker";
import RHFSelect from "./RHF/RHFSelect";
import RHFTextField from "./RHF/RHFTextField";
import { ApplicationState } from "../redux/store";

interface AttributeProps {
  attribute: Attribute;
}

export default function AttributeField({ attribute }: AttributeProps) {
  const hasAccessToSensitiveInfo = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.sensitive_info") ?? false)
  );

  if (attribute.type === "date") {
    return (
      <RHFDatePicker
        label={attribute.name}
        name={attribute.id.toString()} //{`${attribute.id}-${attribute.name}`}
        description={attribute.description}
        defaultValue={attribute.value ?? attribute.defaultValue}
        rules={
          attribute.required
            ? { required: "This field is required" }
            : undefined
        }
      />
    );
  } else if (attribute.type === "dropdown") {
    return (
      <RHFSelect
        label={attribute.name}
        name={attribute.id.toString()} //{`${attribute.id}-${attribute.name}`}
        description={attribute.description}
        defaultValue={attribute.value ?? attribute.defaultValue}
        options={attribute.optionDefinition?.map((option) => {
          return { value: option.label, label: option.label };
        })}
        rules={
          attribute.required
            ? { required: "This field is required" }
            : undefined
        }
      />
    );
  } else if (attribute.type === "number") {
    return (
      <RHFTextField
        label={attribute.name}
        name={attribute.id.toString()} //{`${attribute.id}-${attribute.name}`}
        description={attribute.description}
        defaultValue={attribute.value ?? attribute.defaultValue}
        rules={{
          required: attribute.required ? "This field is required" : undefined,
          pattern: {
            value: new RegExp("^[0-9]*$", "g"),
            message: "Value must be a number",
          },
        }}
      />
    );
  } else if (attribute.type === "checkbox") {
    return (
      <RHFCheckBox
        label={attribute.name}
        name={attribute.id.toString()} //{`${attribute.id}-${attribute.name}`}
        description={attribute.description}
        defaultValue={attribute.value ?? attribute.defaultValue}
        rules={
          attribute.required
            ? { required: "This field is required" }
            : undefined
        }
      />
    );
  } else
    return (
      <RHFTextField
        label={attribute.name}
        type={
          attribute.sensitive && !hasAccessToSensitiveInfo
            ? "password"
            : undefined
        }
        readOnly={attribute.sensitive && !hasAccessToSensitiveInfo}
        name={attribute.id.toString()} //{`${attribute.id}-${attribute.name}`}
        description={attribute.description}
        defaultValue={attribute.value ?? attribute.defaultValue}
        rules={
          attribute.required
            ? { required: "This field is required" }
            : undefined
        }
      />
    );
}
