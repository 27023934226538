import jwtDecode from "jwt-decode";

// routes
import { PATH_AUTH } from "../routes/paths";

import axios from "./axios";

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);
  console.log({ decoded: decoded });
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const getTokenScope = (
  accessToken: string
): { permissions: string[]; is_owner: boolean } => {
  if (!accessToken) {
    return { permissions: [], is_owner: false };
  }
  const decoded = jwtDecode<{ scope: string; role: string }>(accessToken);
  // console.log({ decoded: decoded });

  if (!decoded) {
    return { permissions: [], is_owner: false };
  }

  return {
    permissions: decoded.scope.split(" ") ?? [],
    is_owner: decoded.role?.toLowerCase() === "OWNER".toLowerCase(),
  };
};

const handleTokenExpired = (exp: number) => {
  let expiredTimer;

  const currentTime = Date.now();

  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    localStorage.removeItem("accessToken");
    window.location.href = PATH_AUTH.login;
    alert("Token expired");
  }, timeLeft);
};

const setSession = async (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode<{ exp: number }>(accessToken);
    handleTokenExpired(exp);
  } else {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession, getTokenScope };
