import {
  Button,
  ColumnLayout,
  Container,
  Header,
  StatusIndicator,
} from "@cloudscape-design/components";
import { useNavigate, useParams } from "react-router-dom";
import Label from "../../../components/Label";
import Exclusions, { NoExlusions } from "./exclusions";
import { useGetProviderQuery } from "../../../redux/api/provider/provider";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { setModalName } from "../../../redux/UI/actions";
import { connect } from "react-redux";
import { MANAGEPROVIDER_EDIT_MODAL } from "../../../helpers/constants";

interface PropsFromDispatch {
  openModalName: typeof setModalName;
}

function Overview({ openModalName }: PropsFromDispatch) {
  const navigate = useNavigate();
  const { providerId } = useParams();
  const { data: selectedProvider } = useGetProviderQuery(
    !!providerId
      ? {
          providerId: providerId,
        }
      : skipToken,
  );
  return (
    <div>
      <div style={{ padding: 10 }}>
        <Container
          header={
            <Header
              actions={
                <Button
                  onClick={() => {
                    openModalName(MANAGEPROVIDER_EDIT_MODAL);
                  }}
                >
                  Edit
                </Button>
              }
            >
              General Information
            </Header>
          }
        >
          <ColumnLayout columns={3} variant="text-grid">
            <Label label="First Name" value={selectedProvider?.firstName} />
            <Label label="Last Name" value={selectedProvider?.lastName} />
            <Label label="Email" value={selectedProvider?.email} />
            <Label label="NPI Number" value={selectedProvider?.npiNumber} />
            <Label label="Facility" value={selectedProvider?.facility?.name} />
          </ColumnLayout>
        </Container>
      </div>
      <div style={{ padding: 10 }}>
        <Container
          header={
            <Header
              description={
                <i>
                  Documents that has expiration day in less than 90 days are
                  counted under expiring documents.
                </i>
              }
              actions={
                <Button
                  onClick={() => {
                    navigate("/safe");
                  }}
                >
                  Goto Safe
                </Button>
              }
            >
              Documents Summary
            </Header>
          }
        >
          <ColumnLayout columns={3} variant="text-grid">
            <Label
              label="Total Count"
              value={
                <StatusIndicator type="info">
                  {selectedProvider?.summary.safe.total}
                </StatusIndicator>
              }
            />
            <Label
              label="Expired Count"
              value={
                <StatusIndicator
                  type={
                    selectedProvider?.summary.safe.expired === 0
                      ? "success"
                      : "error"
                  }
                >
                  {selectedProvider?.summary.safe.expired}
                </StatusIndicator>
              }
            />
            <Label
              label="Expiring Count"
              value={
                <StatusIndicator
                  type={
                    selectedProvider?.summary.safe.expiring === 0
                      ? "success"
                      : "warning"
                  }
                >
                  {selectedProvider?.summary.safe.expiring}
                </StatusIndicator>
              }
            />
          </ColumnLayout>
        </Container>
      </div>
      <div style={{ padding: 10 }}>
        {selectedProvider && selectedProvider?.npiNumber ? (
          <Exclusions
            key={selectedProvider?.npiNumber}
            npi_number={selectedProvider?.npiNumber}
          />
        ) : (
          <NoExlusions />
        )}
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  openModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(Overview);
