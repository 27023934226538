import {
  Box,
  BreadcrumbGroup,
  Button,
  Header,
  SpaceBetween,
  Table,
} from "@cloudscape-design/components";
import { useGetAllExpiredDocumentsQuery } from "../redux/api/reporting/reporting";
import ChangeTimeZone from "../components/Timezone";
import { HOST_API } from "../config";

export default function ExpiredDocuments() {
  const { data = [] } = useGetAllExpiredDocumentsQuery();
  return (
    <>
      <BreadcrumbGroup
        items={[
          { text: "Reports", href: "/reports" },
          { text: "Expired documents", href: "#" },
        ]}
        ariaLabel="Breadcrumbs"
      />
      <Table
        columnDefinitions={[
          {
            id: "DocumentName",
            header: "Document Name",
            cell: (item) => item.document?.name,
          },
          {
            id: "expirationDate",
            header: "Expiration Date",
            cell: (item) =>
              ChangeTimeZone(item.document?.expirationDate) ?? "-",
          },
          {
            id: "createdDate",
            header: "Created Date",
            cell: (item) => ChangeTimeZone(item.document?.createdDate) ?? "-",
          },
          {
            id: "category",
            header: "Category",
            cell: (item) => item.document?.category?.name ?? "-",
          },
          {
            id: "lastName",
            header: "Last Name",
            cell: (item) => item.provider.lastName,
            sortingField: "alt",
          },
          {
            id: "firstname",
            header: "First name",
            cell: (item) => item.provider.firstName,
            sortingField: "name",
            isRowHeader: true,
          },
          {
            id: "npi",
            header: "NPI Number",
            cell: (item) => item.provider.npiNumber,
          },
          {
            id: "email",
            header: "Email",
            cell: (item) => item.provider.email,
          },
          {
            id: "licenseType",
            header: "License Type",
            cell: (item) => item.provider?.licenseType?.name,
          },
          {
            id: "facility",
            header: "Facility",
            cell: (item) => item.provider?.facility?.name,
          },
        ]}
        items={data}
        loadingText="Loading resources"
        stickyHeader
        sortingDisabled
        empty={
          <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
            <SpaceBetween size="m">
              <b>No expired documents</b>
            </SpaceBetween>
          </Box>
        }
        header={
          <Header
            description="Comprehensive list of all expired documents"
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  download
                  href={`${HOST_API}/api/reporting/documents/expired?format=csv`}
                >
                  Download CSV
                </Button>
              </SpaceBetween>
            }
          >
            Expired Documents Report
          </Header>
        }
      />
    </>
  );
}
