import { BreadcrumbGroup, SpaceBetween } from "@cloudscape-design/components";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetFacilityQuery,
  usePostFacilityRecordMutation,
} from "../../redux/api/facility/facility";
import useIsLoading from "../../hooks/useIsLoading";
import LoadingScreen from "../../components/LoadingScreen";
import { useGetSectionQuery } from "../../redux/api/section/section";
import RecordSectionForm from "../../components/records/record-section-form";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";

export default function CustomFacilityAddRecord() {
  const navigate = useNavigate();

  const { id, sectionId } = useParams();

  const { data: facility } = useGetFacilityQuery(id ? {id } : skipToken);
  const { notifyWrapper } = useAsyncNotifyWrapper();

  const [postFacilityRecordMutation, { isLoading }] =
    usePostFacilityRecordMutation();

  useIsLoading(isLoading);

  const onSubmit = async (form_data: any) => {
    if (id) {
      await notifyWrapper({
        promise: postFacilityRecordMutation({
          facilityId: id,
          data: form_data,
        }),
        resourceName: "facility",
        actionName: "add",
      });
      onClickBack();
    }
  };

  const onClickBack = () => {
    navigate(`/managefacilities/${id}?activeTabId=${sectionId}`);
  };

  const { data: section } = useGetSectionQuery({
    sectionId: sectionId ?? "",
  });

  if (!section) return <LoadingScreen isOpen={!section} />;
  else
    return (
      <div style={{ paddingTop: "25px" }}>
        <SpaceBetween size={"m"}>
          <BreadcrumbGroup
            items={[
              { text: "Facility", href: `/managefacilities` },
              {
                text: `${facility?.name}`,
                href: `/managefacilities/${id}?activeTabId=${section.id}`,
              },
              { text: `${section.name}`, href: "#" },
            ]}
            ariaLabel="Breadcrumbs"
          />
          <RecordSectionForm
            section={section}
            onClickBack={onClickBack}
            onSubmit={onSubmit}
          />
        </SpaceBetween>
      </div>
    );
}
