import {
  Box,
  Button,
  Container,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import capitalize from "lodash/capitalize";
import FormSection from "../../../../components/FormSection";
import { Divider } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetAllQuestionQuery,
  useGetProviderQuery,
} from "../../../../redux/api/provider/provider";
import { ProviderDisclosureType } from "../../../../redux/api/provider/section/types";
import { useEffect, useState } from "react";
import Label from "../../../../components/Label";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";

export default function Disclosure() {
  const navigate = useNavigate();

  const { providerId } = useParams();

  const [disclosures, setDisclosures] = useState<ProviderDisclosureType[]>([]);

  const {
    data: questions = [],
    fulfilledTimeStamp: question_fulfilledTimeStamp,
  } = useGetAllQuestionQuery();

  const { data: provider, fulfilledTimeStamp } = useGetProviderQuery(
    !!providerId
      ? {
          providerId: providerId,
        }
      : skipToken,
  );

  useEffect(() => {
    if (!!provider)
      setDisclosures(
        questions.map((question) => {
          const providerDisclosure = provider.providerDisclosures?.find(
            (providerDisclosure) =>
              providerDisclosure.question.id === question.id,
          );
          return {
            question: question,
            response: providerDisclosure?.response,
            explanation: providerDisclosure?.explanation,
          };
        }),
      );
  }, [fulfilledTimeStamp, question_fulfilledTimeStamp]);

  return (
    <Container
      header={
        <SpaceBetween size="xxs">
          <Header
            actions={
              <Button
                onClick={() => {
                  navigate(`editdisclousres`);
                }}
              >
                Edit
              </Button>
            }
          >
            Disclosures
          </Header>
          <Divider></Divider>
        </SpaceBetween>
      }
    >
      <div style={{ paddingTop: "12px" }}>
        <FormSection columns={1}>
          {disclosures.map((disclosure) => {
            return (
              <SpaceBetween size="xs">
                <Box>{disclosure.question.questionText}</Box>
                <SpaceBetween size="xs">
                  <Box variant="awsui-key-label">
                    {capitalize(disclosure.response)}
                  </Box>
                  {disclosure.response === "yes" && (
                    <div>
                      <Label
                        label={"Explanation:"}
                        value={disclosure.explanation}
                      ></Label>
                    </div>
                  )}
                </SpaceBetween>
                <Divider></Divider>
              </SpaceBetween>
            );
          })}
        </FormSection>
      </div>
    </Container>
  );
}
