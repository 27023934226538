import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import {
  Button,
  Container,
  Header,
  SpaceBetween,
  StatusIndicator,
  Link,
  Checkbox,
} from "@cloudscape-design/components";
import "./login.css";
import { FormProvider, useForm } from "react-hook-form";
import RHFTextField from "../../components/RHF/RHFTextField";
import { PATH_AUTH } from "../../routes/paths";

type FormInputType = {
  email: string;
  password: string;
};

export default function LoginForm() {
  const { login } = useAuth();
  const methods = useForm<FormInputType>();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className="container">
      <div className="centered-element">
        <SpaceBetween direction="vertical" size="xxl">
          <img
            alt=""
            src="/RD_Horizozntal_Color_Logo.svg"
            style={{
              height: 45,
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <SpaceBetween direction="vertical" size="xxs">
              <div style={{ width: 500, margin: 15 }}>
                <Container
                  header={
                    <Header
                      variant="h1"
                      description={
                        error && (
                          <StatusIndicator type="error">
                            {error}
                          </StatusIndicator>
                        )
                      }
                    >
                      Sign into your account
                    </Header>
                  }
                >
                  <FormProvider {...methods}>
                    <form
                      onSubmit={methods.handleSubmit(
                        async (data: FormInputType) => {
                          try {
                            login(data.email, data.password).catch((error) => {
                              // console.error(error);
                              setError(error);
                            });
                          } catch (error) {
                            // console.error(error);
                            setError("Error: ");
                          }
                        }
                      )}
                    >
                      <SpaceBetween direction="vertical" size="l">
                        <RHFTextField
                          testid="email"
                          label="Email"
                          name="email"
                          stretch
                          rules={{
                            required: "This field is required",
                            pattern: {
                              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                              message: "Invalid email",
                            },
                          }}
                        />
                        <RHFTextField
                          testid="password"
                          type={showPassword ? "text" : "password"}
                          label="Password"
                          name="password"
                          stretch
                          rules={{ required: "This field is required" }}
                        />
                        <Checkbox
                          data-testid="showpassword"
                          onChange={({ detail }) =>
                            setShowPassword(detail.checked)
                          }
                          checked={showPassword}
                        >
                          Show Password
                        </Checkbox>
                        <ul
                          style={{
                            padding: 0,
                            margin: 0,
                            listStyle: "none",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Link
                            data-testid="forgotpassword"
                            href={PATH_AUTH.requestReset}
                          >
                            Forgot password?
                          </Link>
                          <Button variant="primary" data-testid="loginButton">
                            Login
                          </Button>
                        </ul>
                      </SpaceBetween>
                    </form>
                  </FormProvider>
                </Container>
              </div>
            </SpaceBetween>
          </div>
        </SpaceBetween>
      </div>
    </div>
  );
}
