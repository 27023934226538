import {
  Multiselect,
  SpaceBetween,
  TextFilter,
  Select,
  Grid,
} from "@cloudscape-design/components";
import { FilterType } from "../../hooks/useFilter";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";
import { useState } from "react";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { useGetAllLicenseTypesQuery } from "../../redux/api/provider/provider";

interface ProviderFiltersProps {
  filter: FilterType;
  setFilter: React.Dispatch<React.SetStateAction<FilterType>>;
  countText?: string | undefined;
}
export default function ProviderFilters({
  filter,
  setFilter,
  countText,
}: ProviderFiltersProps) {
  const { data: facilities = [] } = useGetFacilitiesQuery();
  const { data: licenseType = [] } = useGetAllLicenseTypesQuery();
  const [selectedProperty, setSelectedProperty] = useState<OptionDefinition>({
    label: "Name",
    value: "name",
  });

  return (
    <SpaceBetween size={"s"}>
      <Grid gridDefinition={[{ colspan: 2 }, { colspan: 8 }]}>
        <Select
          expandToViewport
          selectedOption={selectedProperty}
          onChange={({ detail }) => setSelectedProperty(detail.selectedOption)}
          options={[
            { label: "Name", value: "name" },
            { label: "Email", value: "email" },
            { label: "Facility", value: "facility" },
            { label: "License type", value: "license_type" },
          ]}
        />
        <div>
          {selectedProperty.value === "name" && (
            <TextFilter
              filteringPlaceholder="Find Provider"
              filteringText={filter.filterText}
              onChange={({ detail }) =>
                setFilter({ ...filter, filterText: detail.filteringText })
              }
              countText={countText && `${countText} matches`}
            />
          )}
          {selectedProperty.value === "email" && (
            <TextFilter
              filteringPlaceholder="Find Email"
              filteringText={filter.filterEmail}
              onChange={({ detail }) =>
                setFilter({ ...filter, filterEmail: detail.filteringText })
              }
              countText={countText && `${countText} matches`}
            />
          )}

          {selectedProperty.value === "facility" && (
            <Multiselect
              expandToViewport
              selectedOptions={filter.facilityFilterOptions}
              onChange={({ detail }) => {
                setFilter({
                  ...filter,
                  facilityFilterOptions: [...detail.selectedOptions],
                });
              }}
              options={facilities.map((facility) => ({
                label: facility.name,
                value: facility.id,
              }))}
              placeholder="Choose facilites"
            />
          )}
          {selectedProperty.value === "license_type" && (
            <Multiselect
              expandToViewport
              selectedOptions={filter.licenseTypeFilterOptions}
              onChange={({ detail }) => {
                setFilter({
                  ...filter,
                  licenseTypeFilterOptions: [...detail.selectedOptions],
                });
              }}
              options={licenseType.map((type) => ({
                label: type.name,
                value: type.id,
              }))}
              placeholder="Choose License Types"
            />
          )}
        </div>
      </Grid>
    </SpaceBetween>
  );
}
