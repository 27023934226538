import {
  Box,
  Button,
  ButtonDropdown,
  Header,
  Link,
  Pagination,
  SpaceBetween,
  StatusIndicator,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useMemo } from "react";
import { ProviderSpecialtyInfoType } from "../../../../redux/api/provider/section/types";
import {
  useDeleteProviderSpecialtyInfoMutation,
  useGetProviderQuery,
} from "../../../../redux/api/provider/provider";
import DeleteAlertModal from "../../../../components/Modal/delete-alert-modal";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";
import { useLocalStorage } from "../../../../common/localStorage";
import {
  SPECIALTYINFO_CONTENT_DISPLAY_OPTIONS,
  SPECIALTYINFO_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../../../../common/table-config";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableEmptyState } from "../../../../common/common-components";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../redux/store";
import StyledRouterLink from "../../../../components/styled-route-link";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";

export default function SpecialtyInfo() {
  const navigate = useNavigate();
  const { providerId } = useParams();
  const { data: provider } = useGetProviderQuery(
    !!providerId
      ? {
          providerId: providerId,
        }
      : skipToken,
  );
  const [selectedItem, setSelectedItem] = useState<ProviderSpecialtyInfoType>();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [deleteProviderSpecialtyInfo] =
    useDeleteProviderSpecialtyInfoMutation();
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-SpecialtyInfo-Table-Preferences",
    SPECIALTYINFO_TABLE_DEFAULT_PREFERENCES,
  );
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false),
  );
  const { items, collectionProps, paginationProps } = useCollection(
    provider?.providerSpecialties ?? [],
    {
      propertyFiltering: {
        filteringProperties: [],
        empty: <TableEmptyState resourceName="Specialty Info" />,
      },
    },
  );

  const tableProps: TableProps<ProviderSpecialtyInfoType> = useMemo(() => {
    return {
      variant: "container",
      columnDefinitions: [
        {
          id: "id",
          header: "Id",
          cell: (item) => item.id || "-",
          isRowHeader: true,
        },
        {
          id: "boardName",
          header: "Board Name",
          cell: (item) => (
            <StyledRouterLink
              className={hasPermission ? "edit_link" : "normal"}
              to={hasPermission ? `editspecialtyinfo/${item.id}` : `#`}
              label={item?.boardName}
            />
          ),
        },
        {
          id: "isBoardCertified",
          header: "Is BoardCertified",
          cell: (item) => <div> {item.isBoardCertified ?? "-"} </div>,
        },
        {
          id: "specialty",
          header: "Specialty",
          cell: (item) => <div> {item?.specialty?.name ?? "-"} </div>,
        },
        {
          id: "psv",
          header: "PSV",
          cell: (item) => (
            <StatusIndicator type="success">Verified</StatusIndicator>
          ),
        },
        {
          id: "initialCertificationDate",
          header: "InitialCertification Date",
          cell: (item) => (
            <div>
              {" "}
              {item.initialCertificationDate?.toLocaleString() ?? "-"}{" "}
            </div>
          ),
        },
        {
          id: "expirationDate",
          header: "Expiration Date",
          cell: (item) => (
            <div> {item.expirationDate?.toLocaleString() ?? "-"} </div>
          ),
        },
        {
          id: "reCertificationDate",
          header: "ReCertification Date",
          cell: (item) => (
            <div> {item.reCertificationDate?.toLocaleString() ?? "-"} </div>
          ),
        },
        {
          id: "documentName",
          header: "Document Name",
          cell: (item) => (
            <Link
              external
              href={
                hasPermission && item?.document?.id
                  ? `/safe/${providerId}/documents/${item?.document?.id}`
                  : `#`
              }
            >
              {item.document?.name ?? "-"}
            </Link>
          ),
        },
      ],
      header: (
        <Header
          actions={
            <SpaceBetween size="s" direction="horizontal">
              <ButtonDropdown
                disabled={!selectedItem}
                items={[
                  { text: "Edit", id: "edit", disabled: false },
                  { text: "Delete", id: "delete", disabled: false },
                ]}
                onItemClick={({ detail }) => {
                  if (detail.id === "edit")
                    if (selectedItem && selectedItem.id)
                      navigate(`editspecialtyinfo/${selectedItem.id}`);
                  if (detail.id === "delete") {
                    if (selectedItem && selectedItem.id && !!providerId)
                      OpenDeleteModal(true);
                  }
                }}
              >
                Action
              </ButtonDropdown>
              <Button
                variant="primary"
                onClick={() => {
                  navigate(`editspecialtyinfo`);
                }}
              >
                Add new
              </Button>
            </SpaceBetween>
          }
        >
          Specialty Info
        </Header>
      ),
      items,
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <TablePreferences
          title="Speciality Info Table Preferences"
          preferences={tablePreferences}
          setPreferences={(preferences) => {
            setTablePreferences(preferences);
          }}
          contentDisplayOptions={SPECIALTYINFO_CONTENT_DISPLAY_OPTIONS}
        />
      ),
      stickyHeader: true,
      columnDisplay: tablePreferences?.contentDisplay,
      wrapLines: tablePreferences?.wrapLines,
      stripedRows: tablePreferences?.stripedRows,
      contentDensity: tablePreferences?.contentDensity,
      stickyColumns: tablePreferences?.stickyColumns,
      ...collectionProps,
      loadingText: "Loading resources",
      selectionType: "single",
      selectedItems: selectedItem ? [selectedItem] : [],
      onSelectionChange: ({ detail }) => {
        if (detail.selectedItems && detail.selectedItems.at(0))
          setSelectedItem(detail.selectedItems.at(0));
      },
      onRowClick: ({ detail }) => {
        if (detail.item) setSelectedItem(detail.item);
      },
      empty: (
        <Box textAlign="center" color="inherit">
          <b>No resources</b>
          <Box padding={{ bottom: "s" }} variant="p" color="inherit">
            No resources to display.
          </Box>
          <Button>Create resource</Button>
        </Box>
      ),
    };
  }, [tablePreferences, items, selectedItem, provider]);

  return (
    <div style={{ paddingTop: "12px" }}>
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedItem?.id && !!providerId)
            await notifyWrapper({
              promise: deleteProviderSpecialtyInfo({
                providerId: providerId,
                specialtyInfoId: selectedItem.id.toString(),
              }),
              resourceName: "speciality info",
              actionName: "delete",
            });
          OpenDeleteModal(false);
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={"Delete Speciality Info"}
        content={
          <>
            <Box>Board Name</Box>
            <Box variant="awsui-key-label">{selectedItem?.boardName}</Box>
          </>
        }
        description={"Are you sure you want to delete this Speciality Info?"}
      />
    </div>
  );
}
