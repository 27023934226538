import {
  Box,
  Button,
  Header,
  Pagination,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import "./index.css";
import { connect } from "react-redux";
import { useState } from "react";
import { ApplicationState } from "../../../redux/store";
import { GroupsType } from "../../../redux/api/managecategory/types";
import {
  useDeleteGroupsMutation,
  useGetAllCategoryGroupQuery,
} from "../../../redux/api/managecategory/managecategory";
import { setModalName } from "../../../redux/UI/actions";
import { GROUP_CREATE_MODAL } from "../../../helpers/constants";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import DeleteAlertModal from "../../../components/Modal/delete-alert-modal";
import useAsyncNotifyWrapper from "../../../hooks/useAsyncNotifyWrapper";
import {
  GROUP_CONTENT_DISPLAY_OPTIONS,
  GROUP_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../../../common/table-config";
import { useLocalStorage } from "../../../common/localStorage";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableEmptyState } from "../../../common/common-components";
import StyledRouterLink from "../../../components/styled-route-link";
import LoadingScreen from "../../../components/LoadingScreen";

interface PropsFromDispatch {
  openModalName: typeof setModalName;
}

function GroupsTable({ openModalName }: PropsFromDispatch) {
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const { data = [], isFetching, isLoading } = useGetAllCategoryGroupQuery();
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Group-Table-Preferences",
    GROUP_TABLE_DEFAULT_PREFERENCES,
  );
  const { items, collectionProps, paginationProps } = useCollection(data, {
    propertyFiltering: {
      filteringProperties: [],
      empty: <TableEmptyState resourceName="Group" />,
    },
  });
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("category.edit") ?? false),
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("category.delete") ?? false),
  );
  const [selectedGroups, setSelectGroups] = useState<GroupsType>();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const [deleteGroups] = useDeleteGroupsMutation();

  const tableProps: TableProps<GroupsType> = {
    header: (
      <Header
        counter={`(${data.length})`}
        actions={
          <div>
            <span className="awsui-util-action-stripe-group">
              <Button
                disabled={!(hasDeletePermission && selectedGroups)}
                onClick={() => {
                  if (selectedGroups?.id) {
                    if ((selectedGroups?.categoryList?.length ?? 0) > 0) {
                      alert("Cannot delete a group with categories !!");
                    } else OpenDeleteModal(true);
                  }
                }}
              >
                Delete
              </Button>
            </span>

            <span className="awsui-util-action-stripe-group">
              <Button
                disabled={!hasPermission}
                onClick={() => {
                  openModalName(GROUP_CREATE_MODAL);
                }}
                variant="primary"
              >
                Create Group
              </Button>
            </span>
          </div>
        }
      >
        Groups
      </Header>
    ),
    items,
    pagination: <Pagination {...paginationProps} />,
    preferences: (
      <TablePreferences
        title="Group Table Preferences"
        preferences={tablePreferences}
        setPreferences={(preferences) => {
          setTablePreferences(preferences);
        }}
        contentDisplayOptions={GROUP_CONTENT_DISPLAY_OPTIONS}
      />
    ),
    columnDisplay: tablePreferences?.contentDisplay,
    wrapLines: tablePreferences?.wrapLines,
    stripedRows: tablePreferences?.stripedRows,
    contentDensity: tablePreferences?.contentDensity,
    stickyColumns: tablePreferences?.stickyColumns,
    ...collectionProps,
    variant: "container",
    selectionType: "single",
    resizableColumns: false,
    stickyHeader: true,
    loading: isLoading || isFetching,
    empty: <p>No results to display.</p>,
    selectedItems: selectedGroups ? [selectedGroups] : [],
    onSelectionChange: (selectionChangeDetail) => {
      if (selectionChangeDetail.detail.selectedItems.at(0))
        setSelectGroups(selectionChangeDetail.detail.selectedItems.at(0));
    },
    onRowClick: (onRowClickDetail) => {
      setSelectGroups(onRowClickDetail.detail.item);
    },
    trackBy: (item) => item.id,
    columnDefinitions: [
      {
        id: "id",
        header: <div>id</div>,
        cell: (item) => <span>{item.id}</span>,
      },
      {
        id: "name",
        header: <div>Name</div>,
        cell: (item) => (
          <StyledRouterLink
            className={hasPermission ? "edit_link" : "normal"}
            to={hasPermission ? `groups/${item?.id}` : `#`}
            label={item.name}
          />
        ),
      },
      {
        id: "description",
        header: <div>Description</div>,
        cell: (item) => <span>{item.description}</span>,
      },
      {
        id: "dateCreated",
        header: <div>Date Created</div>,
        cell: (item) => <span>{item.createdDate}</span>,
      },
    ],
  };
  return (
    <div>
      <LoadingScreen isOpen={isLoading} />
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedGroups?.id)
            await notifyWrapper({
              promise: deleteGroups({ groupsId: selectedGroups?.id }),
              resourceName: "group",
              actionName: "delete",
            });
          OpenDeleteModal(false);
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={"Delete group"}
        content={
          <>
            <Box>Group Name</Box>
            <Box variant="awsui-key-label">{selectedGroups?.name}</Box>
          </>
        }
        description={"Are you sure you want to delete this group?"}
      />
    </div>
  );
}

const mapDispatchToProps = {
  openModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(GroupsTable);
