import {
  Button,
  ColumnLayout,
  Container,
  Header,
  Link,
} from "@cloudscape-design/components";
import { useGetExclusionsQuery } from "../../../../redux/api/provider/provider";

interface ExclusionProps {
  npi_number: string;
}
export const NoExlusions = () => {
  return (
    <Container
      header={<Header actions={<Button>Scan</Button>}>Exclusions</Header>}
    >
      <ColumnLayout columns={2} variant="text-grid">
        <i>No Exlcusions found.</i>
      </ColumnLayout>
    </Container>
  );
};

function Exclusions({ npi_number }: ExclusionProps) {
  const { data, isLoading } = useGetExclusionsQuery({ npi_number: npi_number });

  if (data && data?.length > 0)
    return (
      <Container key={npi_number} header="Exclusions">
        <ColumnLayout columns={2} variant="text-grid">
          {data.map((exclusion) => (
            <div className="awsui-child-vertical">
              <div className="awsui-key-label">
                {exclusion.name}
                <Link
                  external
                  externalIconAriaLabel="Opens in a new tab"
                  href={exclusion.link}
                />
              </div>
              <div>Exclusion date: {exclusion.exclusionDate}</div>
            </div>
          ))}
        </ColumnLayout>
      </Container>
    );
  else return <NoExlusions key={npi_number} />;
}

export default Exclusions;
