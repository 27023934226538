import { Box } from "@cloudscape-design/components";

type LabelProp = {
  label: String;
  value: React.ReactNode | String;
  maskContent?: boolean;
};

export default function Label({
  label,
  value,
  maskContent = false,
}: LabelProp) {
  return (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      {maskContent && !!value && typeof value === "string" ? (
        <div>{"*".repeat(value.length)}</div>
      ) : (
        <div>{value}</div>
      )}
    </div>
  );
}
