import {
  BreadcrumbGroup,
  Button,
  Container,
  Form,
  Header,
} from "@cloudscape-design/components";
import { Grid } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSaveDocumentMutation } from "../../redux/api/document/document";
import { useGetProviderQuery } from "../../redux/api/provider/provider";
import InnerAppLayout from "../../components/InnerAppLayout";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import DocumentMetaData from "../../components/DocumentMetaData";
import AttachmentViewer from "../../components/PdftronViewerWrappers/AttachmentViewer";
import { FormProvider, useForm } from "react-hook-form";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import { PATH_DASHBOARD } from "../../routes/paths";

type FormInputType = {
  name: string;
  expirationDate: string;
  createdDate: string;
  alertDays: number;
  notes: string;
  categoryId: string;
};

export default function AddDocument() {
  const navigate = useNavigate();

  // get providerId from url and fetch provider data
  const { providerId } = useParams();
  const { data: provider } = useGetProviderQuery(
    providerId ? { providerId } : skipToken,
  );

  const [uploadFile, setUploadFile] = useState<File>();
  const [saveDocument] = useSaveDocumentMutation();
  const methods = useForm<FormInputType>();
  const documentName = methods.watch("name");

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("safe.edit") ?? false),
  );

  const displayName = useMemo(
    () => `${provider?.firstName} ${provider?.lastName}`,
    [provider],
  );
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const handleSave = async (data: FormInputType) => {
    if (!hasPermission || !providerId) return;
    await notifyWrapper({
      promise: saveDocument({
        providerId,
        name: data?.name,
        alertDays: data?.alertDays,
        expirationDate: data?.expirationDate,
        categoryId: data.categoryId,
        file: uploadFile,
      }),
      resourceName: "Document",
      actionName: "add",
    });
    navigate(PATH_DASHBOARD.safe.edit(providerId));
  };

  useEffect(() => {
    if (!!uploadFile?.name && !documentName)
      methods.setValue("name", uploadFile?.name);
  }, [uploadFile, uploadFile?.size]);

  return (
    <InnerAppLayout
      breadcrumbGroup={
        <BreadcrumbGroup
          items={[
            { text: "Proivders", href: "/providers" },
            { text: "Safe", href: `/safe/${providerId}` },
            {
              text: displayName,
              href: `#`,
            },
            { text: "Add document", href: "#" },
          ]}
        />
      }
      rightPanel={
        <AttachmentViewer
          attachment={undefined}
          file={uploadFile}
          onFileChange={(uploadFile?: File) => {
            setUploadFile(uploadFile);
          }}
        />
      }
      content={
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSave)}>
            <Form>
              <Container
                header={<Header>{documentName ?? "Document Name"}</Header>}
                footer={
                  <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid item>
                      <Button
                        onClick={() => {
                          navigate(-1);
                        }}
                        formAction="none"
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="primary"
                        disabled={!hasPermission}
                        formAction="submit"
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                }
              >
                <DocumentMetaData />
              </Container>
            </Form>
          </form>
        </FormProvider>
      }
    />
  );
}
