import {
  ListItemButton,
  ListItemText,
  ListSubheader,
  styled,
} from "@mui/material";

export const ListSubheaderStyled = styled(ListSubheader)(({ theme }) => ({
  ...theme.typography.overline,
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
  backgroundColor: "none",
}));

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const ListItemTextStyle = styled(ListItemText)(({ theme }) => ({
  whiteSpace: "nowrap",
  transition: theme.transitions.create(["width", "opacity"], {
    duration: theme.transitions.duration.shorter,
  }),
}));

export const ListItemStyle = styled(ListItemButton)(({ theme }) => ({
  ...theme.typography.body2,
  position: "relative",
  textTransform: "capitalize",
  color: theme.palette.text.secondary,
  "&:hover": {
    backgroundColor: "#b5d6f4", //`${alpha(awsui.colorBackgroundButtonPrimaryActive, 0.5)}`,
  },
}));
