const ROOTS_DASHBOARD = "/";
const ROOTS_AUTH = "/auth";

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/signup"),
  verify: path(ROOTS_AUTH, "/verify"),
  resetPassword: path(ROOTS_AUTH, "/resetpassword"),
  requestReset: path(ROOTS_AUTH, "/requestreset"),
  newPassword: path(ROOTS_AUTH, "/new-password"),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,

  reports: {
    list: path(ROOTS_DASHBOARD, "reports"),
  },
  dashboard: {
    list: path(ROOTS_DASHBOARD, "dashboard"),
  },
  expiringdocuments: {
    list: path(ROOTS_DASHBOARD, "expiringdocuments"),
  },
  expireddocuments: {
    list: path(ROOTS_DASHBOARD, "expireddocuments"),
  },
  digitalforms: {
    list: path(ROOTS_DASHBOARD, "digitalforms"),
    templates: {
      designer: (id: string) =>
        path(ROOTS_DASHBOARD, `digitalforms/${id}/templatedesigner`),
    },
    signaturerequest: (id: string) =>
      path(ROOTS_DASHBOARD, `digitalforms/${id}/requestsignature?preview=true`),
  },
  roles: {
    list: path(ROOTS_DASHBOARD, "settings/roles"),
  },
  signaturerequests: {
    list: path(ROOTS_DASHBOARD, "signaturerequests"),
  },
  provider: {
    list: path(ROOTS_DASHBOARD, "providers"),
    edit: (id: string) => path(ROOTS_DASHBOARD, `provider/${id}`),
    manageprovider: {
      list: path(ROOTS_DASHBOARD, "manageprovider"),
      edit: (id: string) => path(ROOTS_DASHBOARD, `manageprovider/${id}`),
    },
  },
  safe: {
    list: path(ROOTS_DASHBOARD, "safe"),
    edit: (providerId: string) => path(ROOTS_DASHBOARD, `safe/${providerId}`),
    add: (providerId: string) =>
      path(ROOTS_DASHBOARD, `safe/${providerId}/documents/addnew`),
  },
  manageadmin: {
    list: path(ROOTS_DASHBOARD, "manageadmin"),
    edit: (id: string) => path(ROOTS_DASHBOARD, `manageadmin/${id}`),
  },
  details: {
    list: path(ROOTS_DASHBOARD, "details/list"),
    detailsContent: (id: string) => path(ROOTS_DASHBOARD, `details/list/${id}`),
  },
  managecategory: {
    list: path(ROOTS_DASHBOARD, "managecategory"),
    edit: (id: string) => path(ROOTS_DASHBOARD, `managecategory/${id}`),
  },
  managesection: {
    list: path(ROOTS_DASHBOARD, "managesection"),
    edit: (id: string) => path(ROOTS_DASHBOARD, `managesection/${id}`),
  },
  managefacilities: {
    list: path(ROOTS_DASHBOARD, "managefacilities"),
    edit: (id: string) => path(ROOTS_DASHBOARD, `managefacilities/${id}`),
  },
  profile: {
    list: path(ROOTS_DASHBOARD, "profile"),
    edit: (id: string) => path(ROOTS_DASHBOARD, `profile/${id}`),
  },
  organization: {
    list: path(ROOTS_DASHBOARD, "organization"),
    edit: (id: string) => path(ROOTS_DASHBOARD, `organization/${id}`),
  },
  contracts: {
    list: path(ROOTS_DASHBOARD, "contract"),
    edit: (id: string) => path(ROOTS_DASHBOARD, `contract/${id}`),
  },
  credential: {
    packages: {
      list: path(ROOTS_DASHBOARD, "credentialpackages"),
      edit: (id: string) => path(ROOTS_DASHBOARD, `credentialpackages/${id}`),
    },
    templates: {
      list: path(ROOTS_DASHBOARD, "credentialtemplates"),
      edit: (id: string) => path(ROOTS_DASHBOARD, `credentialtemplates/${id}`),
    },
  },
  payerenrollment: {
    list: path(ROOTS_DASHBOARD, "payerenrollment"),
    add: path(ROOTS_DASHBOARD, "payerenrollment/addnew"),
    edit: (id: string) => path(ROOTS_DASHBOARD, `payerenrollment/${id}`),
  },
  payers: {
    list: path(ROOTS_DASHBOARD, "payers"),
    edit: (id: string) => path(ROOTS_DASHBOARD, `payers/${id}`),
    templates: {
      designer: (id: string) =>
        path(ROOTS_DASHBOARD, `payers/${id}/templatedesigner`),
    },
  },
  privileges: {
    list: path(ROOTS_DASHBOARD, "privileges"),
    edit: (id: string) => path(ROOTS_DASHBOARD, `privileges/${id}`),
  },
  eSignatures: {
    list: path(ROOTS_DASHBOARD, "esignatures"),
  },
  contracttypes: {
    list: path(ROOTS_DASHBOARD, "contracttypes"),
    edit: (id: string) => path(ROOTS_DASHBOARD, `contractstypes/${id}`),
  },
};

export const PATH_AFTER_LOGIN = PATH_DASHBOARD.dashboard.list;
