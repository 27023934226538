// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import { BarChart, Header } from "@cloudscape-design/components";
import { WidgetConfig } from "../types";
import { useGetAllDashboardQuery } from "../../../redux/api/dashboards/dashboard";

export const usageByFacility: WidgetConfig = {
  title: "Provider Breakdown by Facility",
  description: "An overview of the provider breakdown by facility. ",
  header: UsageByFacilityHeader,
  content: UsageByFacilityContent,
  staticMinHeight: 560,
};

function UsageByFacilityHeader() {
  return (
    <Header variant="h2" description={<i>{usageByFacility.description}</i>}>
      {usageByFacility.title}
    </Header>
  );
}

function UsageByFacilityContent() {
  const { data: dashboardData } = useGetAllDashboardQuery();

  return (
    <BarChart
      fitHeight={true}
      height={205}
      xScaleType="categorical"
      hideFilter={true}
      xTitle="Facility Name"
      yTitle="Count"
      series={
        dashboardData?.servicesBreakdownByFacility ?? 0
          ? dashboardData?.servicesBreakdownByFacility.map(
              (servicesBreakdownByFacility) => {
                return {
                  title: servicesBreakdownByFacility.serviceName,
                  type: "bar",
                  data:
                    servicesBreakdownByFacility.facilityStats.map(
                      (facilityStat) => {
                        return {
                          x: facilityStat.facilityName,
                          y: facilityStat.count,
                        };
                      },
                    ) ?? [],
                };
              },
            ) ?? []
          : []
      }
      xDomain={
        dashboardData?.servicesBreakdownByFacility ?? 0
          ? dashboardData?.servicesBreakdownByFacility
              .at(0)
              ?.facilityStats.map((facilityStats) => facilityStats.facilityName)
          : []
      }
    />
  );
}
