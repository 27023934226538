import { FormProvider, useForm } from "react-hook-form";
import { ProviderThirdPartyLoginType } from "../../../../redux/api/provider/section/types";
import {
  BreadcrumbGroup,
  Button,
  Container,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import FormSection from "../../../../components/FormSection";
import { Divider } from "@mui/material";
import {
  useAddProviderThirdPartyLoginMutation,
  useGetProviderQuery,
} from "../../../../redux/api/provider/provider";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import RHFTextField from "../../../../components/RHF/RHFTextField";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";

export default function EditThirdPartyLogin() {
  const methods = useForm<ProviderThirdPartyLoginType>();
  const { providerId, thirdpartyloginId } = useParams();
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const onSubmit = async (data: ProviderThirdPartyLoginType) => {
    if (!!providerId) {
      await notifyWrapper({
        promise: addProviderThirdPartyLogin({
          providerId: providerId,
          ...data,
        }),
        resourceName: "digitalform template",
        actionName: "upload",
      });
      navigate(-1);
    }
  };

  const { data: provider, fulfilledTimeStamp } = useGetProviderQuery(
    providerId ? { providerId } : skipToken,
  );
  const [addProviderThirdPartyLogin] = useAddProviderThirdPartyLoginMutation();

  useEffect(() => {
    if (provider && provider.providerThirdPartyLogins) {
      const thirdPartyLogin = provider.providerThirdPartyLogins.find(
        (thirdPartyLogin) =>
          thirdPartyLogin?.id + "" === thirdpartyloginId + "",
      );

      if (thirdPartyLogin) {
        methods.setValue("id", thirdPartyLogin.id);
        methods.setValue("accountNo", thirdPartyLogin.accountNo);
        methods.setValue("username", thirdPartyLogin.username);
        methods.setValue("password", thirdPartyLogin.password);
      }
    }
  }, [fulfilledTimeStamp, provider, thirdpartyloginId]);

  return (
    <div style={{ paddingTop: "25px" }}>
      <SpaceBetween size={"l"}>
        <BreadcrumbGroup
          items={[
            { text: "providers", href: `/providers` },
            {
              text: provider?.firstName ?? "",
              href: `#`,
            },
            { text: `Third Party Login`, href: "#" },
          ]}
          ariaLabel="Breadcrumbs"
        />
        <Container
          header={
            <SpaceBetween size="xxs">
              <Header>Third Party Login</Header>
              <Divider></Divider>
            </SpaceBetween>
          }
        >
          <div style={{ paddingTop: "12px" }}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Form
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        onClick={() => {
                          navigate(-1);
                        }}
                        formAction="none"
                      >
                        Cancel
                      </Button>
                      <Button formAction="submit" variant="primary">
                        Submit
                      </Button>
                    </SpaceBetween>
                  }
                >
                  <FormSection columns={4}>
                    <RHFTextField
                      label="Account No"
                      name="accountNo"
                      stretch
                      rules={{ required: "This field is required" }}
                    />
                    <RHFTextField
                      label="Website Url"
                      name="websiteUrl"
                      stretch
                      rules={{ required: "This field is required" }}
                    />
                    <RHFTextField
                      label="Username"
                      name="username"
                      stretch
                      rules={{ required: "This field is required" }}
                    />
                    <RHFTextField
                      type="password"
                      label="Password"
                      name="password"
                      stretch
                      rules={{ required: "This field is required" }}
                    />
                  </FormSection>
                </Form>
              </form>
            </FormProvider>
          </div>
        </Container>
      </SpaceBetween>
    </div>
  );
}
