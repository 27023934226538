import {
  BreadcrumbGroup,
  Button,
  Container,
  Form,
  Header,
} from "@cloudscape-design/components";
import { Grid } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DocumentMetaData from "../../components/DocumentMetaData";
import {
  useGetDocumentQuery,
  usePostDocumentMetaDataMutation,
  useUploadAttachmentMutation,
} from "../../redux/api/document/document";
import { useGetProviderQuery } from "../../redux/api/provider/provider";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import "./edit-document.css";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import InnerAppLayout from "../../components/InnerAppLayout";
import AttachmentViewer from "../../components/PdftronViewerWrappers/AttachmentViewer";
import { FormProvider, useForm } from "react-hook-form";
import useIsLoading from "../../hooks/useIsLoading";
import ChangeTimeZone from "../../components/Timezone";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import LoadingScreen from "../../components/LoadingScreen";

type FormInputType = {
  name: string;
  expirationDate: string;
  createdDate: string;
  alertDays: number;
  notes: string;
  categoryId: string;
};

export default function EditDocument() {
  const navigate = useNavigate();
  const { providerId, documentId } = useParams();

  const { data: provider } = useGetProviderQuery(
    providerId ? { providerId } : skipToken,
  );
  const [uploadAttachment] = useUploadAttachmentMutation();

  const [postDocumentMetaData] = usePostDocumentMetaDataMutation();
  const {
    data: document,
    fulfilledTimeStamp,
    isLoading,
  } = useGetDocumentQuery(
    documentId && providerId ? { documentId, providerId } : skipToken,
  );

  const methods = useForm<FormInputType>();

  const [uploadFile, setUploadFile] = useState<File>();
  const documentName = methods.watch("name");

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("safe.edit") ?? false),
  );
  const displayName = useMemo(
    () => `${provider?.firstName} ${provider?.lastName}`,
    [provider],
  );

  const { notifyWrapper } = useAsyncNotifyWrapper();

  const handleSave = async (data: FormInputType) => {
    if (!hasPermission || !providerId || !document) return;

    await notifyWrapper({
      promise: postDocumentMetaData({
        documentId: document.id,
        providerId,
        name: data?.name,
        alertDays: data?.alertDays,
        expirationDate: data?.expirationDate,
        categoryId: data.categoryId,
      }),
      resourceName: "document",
      actionName: "update",
    });

    navigate(-1);
  };

  useEffect(() => {
    if (!hasPermission || !providerId || !document || !uploadFile) return;

    if (uploadFile?.size > 0)
      uploadAttachment({
        documentId: document.id,
        providerId: providerId,
        file: uploadFile,
      });
  }, [uploadFile]);

  useEffect(() => {
    if (!document) return;
    methods.reset({
      name: document?.name,
      alertDays: document?.alertDays,
      expirationDate: ChangeTimeZone(document?.expirationDate),
      notes: document?.notes,
      categoryId: String(document?.category?.id),
    });
  }, [document, fulfilledTimeStamp, methods]);

  useIsLoading(isLoading);

  return (
    <>
      <LoadingScreen isOpen={isLoading} />
      <InnerAppLayout
        breadcrumbGroup={
          <BreadcrumbGroup
            items={[
              { text: "Proivders", href: "/providers" },

              { text: "Safe", href: `/safe/${providerId}` },
              {
                text: displayName,
                href: `#`,
              },
              { text: "Edit document", href: "#" },
            ]}
          />
        }
        rightPanel={
          <AttachmentViewer
            attachment={document?.attachment}
            file={uploadFile}
            onFileChange={(uploadFile?: File) => {
              setUploadFile(uploadFile);
            }}
          />
        }
        content={
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSave)}>
              <Form>
                <Container
                  header={<Header>{documentName}</Header>}
                  footer={
                    <Grid container justifyContent="flex-end" spacing={2}>
                      <Grid item>
                        <Button
                          formAction="none"
                          onClick={() => {
                            navigate(-1);
                          }}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="primary"
                          disabled={!hasPermission}
                          formAction="submit"
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  }
                >
                  <div>
                    <DocumentMetaData />
                  </div>
                </Container>
              </Form>
            </form>
          </FormProvider>
        }
      />
    </>
  );
}
