import { Link } from "react-router-dom";
import "./index.css";

export default function StyledRouterLink({
  to,
  label,
  className,
  target,
}: {
  to: string;
  label: string;
  className: string;
  target?: React.HTMLAttributeAnchorTarget | undefined;
}) {
  return className === "edit_link" ? (
    <Link className={className} to={to} target={target}>
      {label}
    </Link>
  ) : (
    <div>{label || ""}</div>
  );
}
