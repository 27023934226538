import {
  Box,
  Button,
  FormField,
  Grid,
  Icon,
  Input,
  Select,
  SelectProps,
} from "@cloudscape-design/components";
import { RecipientTypeOptions, getRecipientType } from "../types";
import React, { useEffect, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { useGetAllActiveQuery } from "../../redux/api/manageadmin/manageadmin";
import { useGetAllProvidersQuery } from "../../redux/api/provider/provider";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { NonCancelableEventHandler } from "@cloudscape-design/components/internal/events";
import { MemberType } from "../../redux/api/manageadmin/types";
import { ProviderMetaData } from "../../redux/api/provider/types";
import {
  BackgroundColorType,
  RecipientType,
} from "../../redux/api/signaturerequest/types";
import { useSearchParams } from "react-router-dom";

type RecipientProps = {
  displayLabels?: boolean;
  recipient: RecipientType | undefined;
  onRecipientChange: (recipient: RecipientType) => void;
  dragabble?: boolean;
  hideDelete?: boolean;
  disableFields?: { type?: boolean; email?: boolean; action?: boolean };
};

export default function Recipient({
  hideDelete = false,
  displayLabels = false,
  disableFields,
  recipient,
  onRecipientChange,
  dragabble = true,
}: RecipientProps) {
  const [type, setType] = React.useState<SelectProps.Option | null>(null);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    setType(getRecipientType(recipient));
  }, [recipient]);

  return (
    //TODO: make the grid responsive, get inspiration from https://cloudscape.design/components/attribute-editor/?tabId=playground
    <Grid
      gridDefinition={
        !!dragabble
          ? [
              { colspan: { xs: 0 } },
              { colspan: { xs: 2 } },
              { colspan: { xs: 5 } },
              { colspan: { xs: 3 } },
              { colspan: { xs: 0 } },
            ]
          : [
              { colspan: { default: 11, xs: 3 } },
              { colspan: { default: 11, xs: 3 } },
              { colspan: { default: 11, xs: 3 } },
              { colspan: { default: 11, xs: 0 } },
            ]
      }
    >
      {!!dragabble && <DragIcon displayLabels={displayLabels} />}
      <Grid gridDefinition={[{ colspan: 2 }, { colspan: 10 }]} disableGutters>
        <div style={{ marginTop: "26px" }}>
          <ColorIcon backgroundColor={PrimaryRecipientColor} />
        </div>
        <TypeSelection
          displayLabels={displayLabels}
          selectedOption={type}
          readonly
          onChange={({ detail }) => {
            if (!!detail.selectedOption) {
              setType(detail.selectedOption);
            }
          }}
        />
      </Grid>
      <RecipientValue
        type={type?.value}
        recipient={recipient}
        displayLabels={displayLabels}
        onRecipientChange={onRecipientChange}
        disabled={searchParams.has("providerId")}
      />

      <RecipientAction
        displayLabels={displayLabels}
        recipient={recipient}
        disabled={disableFields?.action}
      />

      {!hideDelete && <DeleteIcon displayLabels onDelete={() => {}} />}
    </Grid>
  );
}

export function TypeSelection({
  selectedOption,
  displayLabels,
  readonly,
  onChange,
}: {
  readonly?: boolean;
  displayLabels: boolean;
  selectedOption: OptionDefinition | null;
  onChange?: NonCancelableEventHandler<SelectProps.ChangeDetail> | undefined;
}) {
  return (
    <FormField label={displayLabels && "Type"} stretch>
      {!!readonly ? (
        <Input value={selectedOption?.label ?? ""} readOnly />
      ) : (
        <Select
          expandToViewport
          disabled={readonly}
          selectedOption={selectedOption}
          onChange={onChange}
          options={RecipientTypeOptions}
        />
      )}
    </FormField>
  );
}

export function RecipientAction({
  recipient,
  displayLabels,
  disabled,
  onChange,
}: {
  recipient?: RecipientType;
  displayLabels?: boolean;
  disabled?: boolean;
  onChange?: NonCancelableEventHandler<SelectProps.ChangeDetail> | undefined;
}) {
  return (
    <FormField label={displayLabels && "Action"}>
      {!!disabled ? (
        <Input
          value={
            (
              ActionOptions.find(
                (option) => option.value === recipient?.action,
              ) ?? ActionOptions[0]
            )?.label
          }
          readOnly
        />
      ) : (
        <Select
          selectedOption={
            ActionOptions.find(
              (option) => option.value === recipient?.action,
            ) ?? ActionOptions[0]
          }
          onChange={onChange}
          options={ActionOptions}
          disabled={disabled}
        />
      )}
    </FormField>
  );
}

export function RecipientValue({
  type,
  disabled,
  recipient,
  displayLabels,
  onRecipientChange,
}: {
  type?: string;
  disabled?: boolean;
  recipient?: RecipientType;
  displayLabels?: boolean;
  onRecipientChange: (recipient: RecipientType) => void;
}) {
  if (type === "provider")
    return (
      <FormField label={displayLabels && "Provider"}>
        <ProviderPickList
          recipient={recipient}
          disabled={disabled}
          onChange={(provider) => {
            onRecipientChange({
              id: uuidv4(),
              member: undefined,
              emailAddress: undefined,
              provider: provider,
              action: recipient?.action ?? "sign",
            });
          }}
        />
      </FormField>
    );

  if (type === "member")
    return (
      <FormField label={displayLabels && "Member"}>
        <MemberPickList
          recipient={recipient}
          disabled={disabled}
          onChange={(member) => {
            onRecipientChange({
              id: uuidv4(),
              provider: undefined,
              emailAddress: undefined,
              member: member,
              action: recipient?.action ?? "sign",
            });
          }}
        />
      </FormField>
    );
  else
    return (
      <FormField label={displayLabels && "Email address"}>
        <Input
          value={recipient?.emailAddress ?? ""}
          placeholder="Enter email address"
          readOnly={disabled}
          onChange={({ detail }) => {
            onRecipientChange({
              id: uuidv4(),
              provider: undefined,
              member: undefined,
              emailAddress: detail.value,
              action: recipient?.action ?? "sign",
            });
          }}
        />
      </FormField>
    );
}

function MemberPickList({
  recipient,
  onChange,
  disabled,
}: {
  disabled?: boolean;
  recipient?: RecipientType;
  onChange: (member?: MemberType) => void;
}) {
  const { data: members = [] } = useGetAllActiveQuery();
  return (
    <Select
      disabled={disabled}
      placeholder="Choose a member"
      options={members.map((member) => ({
        label: member.firstName + " " + member.lastName + "",
        value: member.id,
        description: member.email,
      }))}
      onChange={({ detail }) => {
        onChange(
          members.find((m) => m.id + "" === "" + detail.selectedOption.value),
        );
      }}
      selectedOption={
        members
          .filter((m) => m.id + "" === "" + recipient?.member?.id)
          .map((member) => ({
            label: member.firstName + " " + member.lastName + "",
            value: member.id,
            description: member.email,
          }))
          ?.at(0) ?? null
      }
    />
  );
}

function ProviderPickList({
  recipient,
  onChange,
  disabled,
}: {
  disabled?: boolean;
  recipient?: RecipientType;
  onChange: (provider?: ProviderMetaData) => void;
}) {
  const { data: providers = [] } = useGetAllProvidersQuery({
    includeRecordData: false,
  });

  const displayName = useMemo(() => {
    const provider = providers.find(
      (p) => p.id + "" === recipient?.provider?.id + "",
    );
    return provider?.firstName + " " + provider?.lastName + "";
  }, [providers, recipient]);

  return !!disabled ? (
    <Input value={displayName} readOnly />
  ) : (
    <Select
      disabled={disabled}
      placeholder="Choose a provider"
      filteringType="auto"
      options={providers.map((provider) => ({
        label: provider.firstName + " " + provider.lastName + "",
        value: String(provider.id),
        description: provider.email ?? "",
      }))}
      onChange={({ detail }) => {
        onChange(
          providers.find((p) => p.id + "" === "" + detail.selectedOption.value),
        );
      }}
      selectedOption={
        providers
          .filter((p) => p.id + "" === "" + recipient?.provider?.id)
          .map((provider) => ({
            label: provider.firstName + " " + provider.lastName + "",
            value: provider.id,
            description: provider.email,
          }))
          ?.at(0) ?? null
      }
    />
  );
}

function DeleteIcon({
  onDelete,
  displayLabels,
}: {
  displayLabels: boolean;
  onDelete: VoidFunction;
}) {
  return (
    <FormField>
      <Box float="right">
        <div
          style={{
            paddingTop: displayLabels
              ? "calc(var(--font-body-m-line-height-5wv9x1, 20px))"
              : "",
          }}
        >
          <Button
            iconName="remove"
            onClick={() => {
              onDelete();
            }}
          ></Button>
        </div>
      </Box>
    </FormField>
  );
}

function DragIcon(displayLabels: { displayLabels: boolean }) {
  return (
    <FormField>
      <div
        style={{
          cursor: "move",
          paddingTop: displayLabels
            ? "calc(var(--space-xxs-p8yyaw, 4px) + var(--font-body-m-line-height-5wv9x1, 20px))"
            : "",
        }}
        data-test="drag-icon"
      >
        <Icon name="drag-indicator" />
      </div>
    </FormField>
  );
}

const ActionOptions = [
  {
    label: "e-Signature",
    value: "sign",
    iconSvg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 576 512"
      >
        <path
          fill="black"
          d="M64 464H320c8.8 0 16-7.2 16-16v-6.7l39.8-9.9c2.8-.7 5.6-1.6 8.2-2.7V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3v49.1l-48 48V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16zm96-115l-9.8 32.8c-6.1 20.3-24.8 34.2-46 34.2H96c-8.8 0-16-7.2-16-16s7.2-16 16-16h8.2c7.1 0 13.3-4.6 15.3-11.4l14.9-49.5c3.4-11.3 13.8-19.1 25.6-19.1s22.2 7.7 25.6 19.1l11.6 38.6c7.4-6.2 16.8-9.7 26.8-9.7c15.9 0 30.4 9 37.5 23.2l4.4 8.8h25.6l12-48.2c1.4-5.6 4.3-10.8 8.4-14.9L441.1 191.8l71 71L382.9 391.9c-4.1 4.1-9.2 7-14.9 8.4l-60.1 15c-1.1 .3-2.1 .4-3.2 .5c-.8 .1-1.5 .2-2.3 .2H256c-6.1 0-11.6-3.4-14.3-8.8l-8.8-17.7c-1.7-3.4-5.1-5.5-8.8-5.5s-7.2 2.1-8.8 5.5l-8.8 17.7c-2.9 5.9-9.2 9.4-15.7 8.8s-12.1-5.1-13.9-11.3L160 349zM549.8 139.7l14.4 14.4c15.6 15.6 15.6 40.9 0 56.6l-29.4 29.4-71-71 29.4-29.4c15.6-15.6 40.9-15.6 56.6 0z"
        />
      </svg>
    ),
  },
  {
    label: "Approval",
    value: "approve",
    iconSvg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 576 512"
      >
        <path
          fill="black"
          d="M64 48H320c8.8 0 16 7.2 16 16V220.5c14.6-9.5 30.8-17 48-21.8V64c0-35.3-28.7-64-64-64H64C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c3.3 0 6.6-.3 9.7-.7c-17.9-12.8-33.3-28.8-45.3-47.3H64c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16zm56 80c-13.3 0-24 10.7-24 24s10.7 24 24 24H264c13.3 0 24-10.7 24-24s-10.7-24-24-24H120zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H264c13.3 0 24-10.7 24-24s-10.7-24-24-24H120zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24h48c13.3 0 24-10.7 24-24s-10.7-24-24-24H120zm456 48a144 144 0 1 0 -288 0 144 144 0 1 0 288 0zm-76.7-43.3c6.2 6.2 6.2 16.4 0 22.6l-72 72c-6.2 6.2-16.4 6.2-22.6 0l-40-40c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L416 385.4l60.7-60.7c6.2-6.2 16.4-6.2 22.6 0z"
        />
      </svg>
    ),
  },
];

export const ColorIcon = ({
  backgroundColor,
}: {
  backgroundColor?: BackgroundColorType;
}) => {
  const circleStyle = {
    backgroundColor: `rgba(${backgroundColor?.r}, ${backgroundColor?.g}, ${backgroundColor?.b}, ${backgroundColor?.a})`,
    border: `1px solid rgba(${backgroundColor?.r}, ${backgroundColor?.g}, ${backgroundColor?.b}, ${backgroundColor?.a})`,
    height: "26px",
    width: "12px",
    display: "block",
  };

  return <span style={circleStyle}></span>;
};

export const PrimaryRecipientColor = { r: 255, g: 214, b: 91, a: 1 };
export const AddlRecipientColors = [
  { r: 172, g: 220, b: 230, a: 1 },
  { r: 192, g: 165, b: 207, a: 1 },
  { r: 151, g: 201, b: 191, a: 1 },
  { r: 247, g: 185, b: 148, a: 1 },
  { r: 195, g: 213, b: 230, a: 1 },
  { r: 207, g: 219, b: 127, a: 1 },
  { r: 255, g: 153, b: 128, a: 1 },
  { r: 230, g: 198, b: 230, a: 1 },
  { r: 255, g: 179, b: 198, a: 1 },
];
