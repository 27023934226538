import { Reducer } from "redux";
import {
  Record,
  RecordWithProviderMetada,
  Section,
  SectionMetadata,
} from "../api/section/types";
import { Provider } from "../api/provider/types";
import { ProviderState, ProviderActionTypes } from "./types";

export const initialState: ProviderState = {
  selectedProviders: [],
  activeTabId: "overview",
};

const reducer: Reducer<ProviderState> = (state = initialState, action) => {
  switch (action.type) {
    case ProviderActionTypes.SELECT_PROVIDER: {
      return { ...state, selectedProviders: action.payload };
    }
    case ProviderActionTypes.SET_ACTIVE_TAB_ID: {
      return { ...state, activeTabId: action.payload };
    }
    default: {
      return state;
    }
  }
};

export { reducer as providerReducer };

export const selectProviderRecords = (provider: Provider | undefined) => {
  return [];
};

export const selectRecordsBySectionId = (
  records: Record[],
  sectionId: string
) => records.filter((record) => record.section.id === sectionId);

export const selectRecordsWithProviderMetadataBySectionId = (
  recordsWithProviderMetadata: RecordWithProviderMetada[],
  sectionId: string
) =>
  recordsWithProviderMetadata.filter(
    (record) => "" + record.section.id === "" + sectionId
  );

export const selectRecordsBySectionMetadata = (
  records: Record[],
  section: Section
) => records.filter((record) => record.section.id === section.id);

export const selectRecordBySectionMetadata = (
  records: Record[],
  section: Section
) => records.find((record) => record.section.id === section.id);

export const selectRecordsById = (id: string, records: Record[]) =>
  records.find((record) => record.id === id);

export const selectRecordSections = (records: Record[]) => {
  const map = new Map<string, SectionMetadata>();
  records.forEach((record) => {
    const { subSections, ...metadata } = record.section;
    map.set(metadata.id, metadata);
  });
  return Array.from(map.values());
};
