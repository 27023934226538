import { BreadcrumbGroup, SpaceBetween } from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetFacilityQuery,
  usePostFacilityRecordMutation,
} from "../../redux/api/facility/facility";
import { Section } from "../../redux/api/section/types";
import useIsLoading from "../../hooks/useIsLoading";
import { selectRecordsById } from "../../redux/provider/reducer";
import LoadingScreen from "../../components/LoadingScreen";
import RecordSectionForm from "../../components/records/record-section-form";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";

export default function CustomFacilityEditRecord() {
  const navigate = useNavigate();

  const { id, recordId } = useParams();

  const { data: facility } = useGetFacilityQuery(id ? { id } : skipToken);
  const { notifyWrapper } = useAsyncNotifyWrapper();

  const [postFacilityRecordMutation, { isLoading }] =
    usePostFacilityRecordMutation();

  const [section, setSection] = useState<Section | undefined>();

  useIsLoading(isLoading);

  const onSubmit = async (form_data: any) => {
    if (id && recordId) {
      await notifyWrapper({
        promise: postFacilityRecordMutation({
          facilityId: id,
          recordId,
          data: form_data,
        }),
        resourceName: "readyform template",
        actionName: "upload",
      });
    }
    onClickBack();
  };

  useEffect(() => {
    if (recordId && facility)
      setSection(selectRecordsById(recordId, [...facility.records])?.section);
  }, [facility]);

  const onClickBack = () => {
    navigate(`/managefacilities/${id}?activeTabId=${section?.id}`);
  };

  if (!section) return <LoadingScreen isOpen={!section} />;
  else
    return (
      <div style={{ paddingTop: "25px" }}>
        <SpaceBetween size={"m"}>
          <BreadcrumbGroup
            items={[
              { text: "Facilities", href: `/managefacilities` },
              {
                text: `${facility?.name}`,
                href: `/managefacilities/${id}?activeTabId=${section.id}`,
              },
              { text: `${section.name}`, href: "#" },
            ]}
            ariaLabel="Breadcrumbs"
          />
          <RecordSectionForm
            section={section}
            onClickBack={onClickBack}
            onSubmit={onSubmit}
          />
        </SpaceBetween>
      </div>
    );
}
