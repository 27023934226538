import { Header, Table, TableProps } from "@cloudscape-design/components";
import React, { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetAllProvidersQuery } from "../redux/api/provider/provider";
import { Provider } from "../redux/api/provider/types";

interface ProviderSafeNavigationProps {
  navigateTo: (id: string) => string;
  onChange?: (selectedProvider?: Provider) => void;
}
export default function ProviderSafeNavigation({
  onChange,
  navigateTo,
}: ProviderSafeNavigationProps) {
  const { providerId } = useParams();

  const navigate = useNavigate();

  const { data = [] } = useGetAllProvidersQuery({ includeRecordData: false });

  const selectedItem = useMemo(() => {
    const temp = data.find((provider) => "" + provider.id === "" + providerId);
    if (!!temp) return temp;
    else {
      if (data.at(0)?.id) navigate(navigateTo(`${data.at(0)?.id}`));
      return undefined;
    }
  }, [data, providerId]);

  useEffect(() => {
    onChange && onChange(selectedItem);
  }, [selectedItem, onChange]);

  const tableProps: TableProps<Provider> = React.useMemo(() => {
    return {
      header: <Header variant="h2">Provider Safe</Header>,
      variant: "container",
      selectionType: "single",
      resizableColumns: true,
      stickyHeader: true,
      wrapLines: true,
      selectedItems: selectedItem ? [selectedItem] : [],
      onSelectionChange: (selectionChangeDetail) => {
        const temp_providerId =
          selectionChangeDetail.detail.selectedItems.at(0)?.id;
        if (temp_providerId) navigate(navigateTo(`${temp_providerId}`));
      },
      onRowClick: (onRowClickDetail) => {
        const temp = onRowClickDetail.detail.item.id;
        if (temp) navigate(navigateTo(`${temp}`));
      },
      empty: <p>No results to display.</p>,
      trackBy: (item) => item.id,
      columnDefinitions: [
        {
          id: "id",
          header: <div>ID</div>,
          cell: (item) => <span>{item.id}</span>,
        },
        {
          id: "name",
          header: <div>Name</div>,
          cell: (item) => (
            <span>
              {item.firstName} {item.lastName}
            </span>
          ),
        },
      ],
      visibleColumns: ["name"],
      items: data,
    };
  }, [providerId, selectedItem, data]);

  return <Table {...tableProps} />;
}
