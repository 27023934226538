import {
  Button,
  Header,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import Grid from "@mui/material/Grid";
import { useEffect } from "react";
import { connect } from "react-redux";
import { setModalName } from "../../redux/UI/actions";
import { ApplicationState } from "../../redux/store";
import {
  useGetAllLicenseTypesQuery,
  useGetProviderQuery,
  useUpdateProviderMutation,
} from "../../redux/api/provider/provider";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import FormSection from "../../components/FormSection";
import RHFSelect from "../../components/RHF/RHFSelect";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";
import { useParams } from "react-router-dom";
import useIsLoading from "../../hooks/useIsLoading";
import { useSelector } from "react-redux";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import LoadingScreen from "../../components/LoadingScreen";

interface PropsFromDispatch {
  openModalName: typeof setModalName;
}

type FormInputType = {
  firstName: string;
  lastName: string;
  email: string;
  npiNumber: string;
  facility: string;
  licenseType: string;
};

function ManageProviderEditModal({ openModalName }: PropsFromDispatch) {
  const { providerId } = useParams();

  const { data: provider, isLoading } = useGetProviderQuery(
    !!providerId
      ? {
          providerId: providerId,
        }
      : skipToken,
  );

  const { notifyWrapper } = useAsyncNotifyWrapper();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false),
  );

  useIsLoading(isLoading);

  const methods = useForm<FormInputType>();
  const onSubmit = async (data: FormInputType) => {
    await notifyWrapper({
      promise: updateProvider({
        id: providerId ?? "",
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        npiNumber: data.npiNumber,
        facilityId: data.facility,
        licenseTypeId: data.licenseType,
      }),
      resourceName: "provider",
      actionName: "update",
    });

    openModalName("");
  };

  useEffect(() => {
    if (provider) {
      methods.setValue("firstName", provider.firstName);
      methods.setValue("lastName", provider.lastName);
      methods.setValue("email", provider.email);
      methods.setValue("npiNumber", provider.npiNumber);
    }
  }, [provider]);

  const [updateProvider] = useUpdateProviderMutation();
  const { data: facilites = [] } = useGetFacilitiesQuery();

  const { data: licenseType = [] } = useGetAllLicenseTypesQuery();

  return (
    <Modal
      visible={true}
      size={"medium"}
      data-testid="editProvider"
      header={<Header variant="h2">Edit Provider</Header>}
      onDismiss={() => {
        openModalName("");
      }}
    >
      <LoadingScreen isOpen={isLoading} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <SpaceBetween direction="vertical" size="s">
            <FormSection columns={1}>
              <FormSection
                gridDefinition={[
                  { colspan: { default: 6, m: 6 } },
                  { colspan: { default: 6, m: 6 } },
                ]}
              >
                <RHFTextField
                  testid="firstName"
                  label="First Name"
                  name="firstName"
                  stretch
                  rules={{ required: "This field is required" }}
                  readOnly={!hasPermission}
                />
                <RHFTextField
                  testid="lastName"
                  label="Last Name"
                  name="lastName"
                  stretch
                  rules={{ required: "This field is required" }}
                  readOnly={!hasPermission}
                />
              </FormSection>
              <RHFTextField
                testid="email"
                label="Email"
                name="email"
                stretch
                rules={{ required: "This field is required" }}
                readOnly={!hasPermission}
              />
              <RHFTextField
                testid="npiNumber"
                label="NPI Number"
                name="npiNumber"
                stretch
                rules={{ required: "This field is required" }}
                readOnly={!hasPermission}
              />
              <RHFSelect
                testid="facility"
                label="Facility"
                name={"facility"}
                rules={{ required: "This field is required" }}
                options={facilites.map((facility) => {
                  return { label: facility.name, value: facility.id };
                })}
                disabled={!hasPermission}
              />
              <RHFSelect
                testid="licenseType"
                label="License Type"
                name={"licenseType"}
                rules={{ required: "This field is required" }}
                options={licenseType.map((licenseType) => {
                  return { label: licenseType.name, value: licenseType.id };
                })}
                disabled={!hasPermission}
              />
            </FormSection>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  data-testid="cancel"
                  formAction="none"
                  onClick={() => {
                    openModalName("");
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  data-testid="save"
                  variant="primary"
                  disabled={!hasPermission}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </SpaceBetween>
        </form>
      </FormProvider>
    </Modal>
  );
}
const mapDispatchToProps = {
  openModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(ManageProviderEditModal);
