import { XMLParser, XMLBuilder } from "fast-xml-parser";

export function parseXML(xmlData: string) {
  const parser = new XMLParser({
    ignoreAttributes: false,
  });
  let jObj = parser.parse(xmlData);
  return jObj;
}

export function buildXML(jObj: any) {
  const builder = new XMLBuilder({
    ignoreAttributes: false,
  });
  const xmlContent = builder.build(jObj);
  return xmlContent;
}

export function filterAnnotXMLByRecipientId(
  xfdfString: string,
  recipientId: string
) {
  const jsObject = parseXML(xfdfString);

  delete jsObject["xfdf"]["pdf-info"]["document-actions"];

  // Filter the field objects based on the 'name' attribute
  const filteredFields = filterObjects(
    jsObject.xfdf.fields.field,
    "@_name",
    recipientId
  );

  // Filter the ffield objects based on the 'name' attribute
  const filteredFfields = filterObjects(
    jsObject.xfdf["pdf-info"].ffield,
    "@_name",
    recipientId
  );

  // Filter the widget objects based on the 'name' attribute
  const filteredWidgets = filterObjects(
    jsObject.xfdf["pdf-info"].widget,
    "@_field",
    recipientId
  );

  const filteredJSObject = {
    ...jsObject,
    xfdf: {
      ...jsObject.xfdf,
      fields: {
        field: filteredFields,
      },
      "pdf-info": {
        ...jsObject.xfdf["pdf-info"],
        ffield: filteredFfields,
        widget: filteredWidgets,
      },
    },
  };

  return buildXML(filteredJSObject);
}

// Helper function to filter an object or array of objects based on the 'name' attribute
function filterObjects(obj: any, attributeName: string, recipientId: string) {
  if (Array.isArray(obj)) {
    return obj.filter((item) => item[attributeName].includes(recipientId));
  } else if (obj && obj[attributeName]) {
    if (obj[attributeName].includes(recipientId)) {
      return obj;
    }
  }
  return undefined;
}

const sample = {
  "?xml": {
    "@_version": "1.0",
    "@_encoding": "UTF-8",
  },
  xfdf: {
    "pdf-info": {
      ffield: [
        {
          font: {
            "@_name": "Arial",
            "@_size": "8",
          },
          "@_type": "Tx",
          "@_name":
            "b939ba9f-e247-40cf-84d8-faba64815423_standard_fields_text_field_0",
        },
        {
          font: {
            "@_name": "Arial",
            "@_size": "8",
          },
          "@_type": "Tx",
          "@_name":
            "b939ba9f-e247-40cf-84d8-faba64815423_standard_fields_text_field_1",
        },
      ],
      widget: [
        {
          rect: {
            "@_x1": "189.854",
            "@_x2": "303.133",
            "@_y1": "788.876",
            "@_y2": "811.531",
          },
          border: {
            color: {
              "@_a": "0",
            },
            "@_width": "0",
            "@_style": "null",
          },
          "trn-custom-data": {
            "@_bytes": '{"type":"custom"}',
          },
          "@_field":
            "b939ba9f-e247-40cf-84d8-faba64815423_standard_fields_text_field_0",
          "@_name": "b5b85a82-6269-d62d-4453-34baf9bbf0ce",
          "@_modified-date": "D:20230927151931-05'00'",
          "@_page": "1",
        },
        {
          rect: {
            "@_x1": "347.0536",
            "@_x2": "523.0443",
            "@_y1": "786.9809",
            "@_y2": "822.179",
          },
          border: {
            color: {
              "@_a": "0",
            },
            "@_width": "0",
            "@_style": "null",
          },
          "trn-custom-data": {
            "@_bytes":
              '{"recipientId":"b939ba9f-e247-40cf-84d8-faba64815423","type":"custom"}',
          },
          "@_field":
            "b939ba9f-e247-40cf-84d8-faba64815423_standard_fields_text_field_0",
          "@_name": "4141c458-a9dd-af1c-f711-4af889bed61f",
          "@_modified-date": "D:20230927152001-05'00'",
          "@_page": "1",
        },
        {
          rect: {
            "@_x1": "223.8601",
            "@_x2": "399.8508",
            "@_y1": "739.8154",
            "@_y2": "775.0135",
          },
          border: {
            color: {
              "@_a": "0",
            },
            "@_width": "0",
            "@_style": "null",
          },
          "trn-custom-data": {
            "@_bytes":
              '{"recipientId":"b939ba9f-e247-40cf-84d8-faba64815423","type":"custom"}',
          },
          "@_field":
            "b939ba9f-e247-40cf-84d8-faba64815423_standard_fields_text_field_1",
          "@_name": "bf4d3ecb-e7c9-4dbd-0ba0-424742f3ed4d",
          "@_modified-date": "D:20230927152001-05'00'",
          "@_page": "1",
        },
      ],
      "@_xmlns": "http://www.pdftron.com/pdfinfo",
      "@_version": "2",
      "@_import-version": "4",
    },
    fields: {
      field: [
        {
          value: "Standard_Text Field",
          "@_name":
            "b939ba9f-e247-40cf-84d8-faba64815423_standard_fields_text_field_0",
        },
        {
          value: "Standard_Text Field",
          "@_name":
            "b939ba9f-e247-40cf-84d8-faba64815423_standard_fields_text_field_1",
        },
      ],
    },
    annots: "",
    pages: {
      defmtx: {
        "@_matrix": "1,0,0,-1,0,841.89",
      },
    },
    "@_xmlns": "http://ns.adobe.com/xfdf/",
    "@_xml:space": "preserve",
  },
};
