import {
  Box,
  BreadcrumbGroup,
  Button,
  ColumnLayout,
  Container,
  Header,
  Link,
  SpaceBetween,
  Table,
  Tabs,
} from "@cloudscape-design/components";
import Label from "../../../components/Label";
import { useNavigate, useParams } from "react-router-dom";
import {
  useDeleteCredentialPackageDocumentsMutation,
  useDeleteCredentialPackageMutation,
  useGetAllCredentialPackageDocumentsQuery,
  useGetCredentialPackageQuery,
} from "../../../redux/api/credentialpackages/credentialpackages";
import ChangeTimeZone from "../../../components/Timezone";
import FormSection from "../../../components/FormSection";
import { useState } from "react";
import { AllDocumentResponse } from "../../../redux/api/document/types";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import CredentialPackageAddDocumentsModal from "../modals/credentialpackage-add-documents-modal";
import DeleteAlertModal from "../../../components/Modal/delete-alert-modal";
import useAsyncNotifyWrapper from "../../../hooks/useAsyncNotifyWrapper";
import { setModalName } from "../../../redux/UI/actions";
import { connect } from "react-redux";
import { EDIT_PACKAGE } from "../../../helpers/constants";
import CredentialPackageFormsList from "./credentialpackage-forms-list";
import { DigitalForm } from "../../../redux/api/digitalform/types";

interface PropsFromDispatch {
  openModalName: typeof setModalName;
}

function CredentialPackageDetails({ openModalName }: PropsFromDispatch) {
  const { id: credentialPackageId } = useParams();

  const { data: credentialPackage } = useGetCredentialPackageQuery(
    credentialPackageId ?? skipToken,
  );
  const [selectedDocuments, setSelectedDocuments] = useState<
    AllDocumentResponse[]
  >([]);
  const [selectedForms, setSelectedForms] = useState<DigitalForm[]>([]);
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [deletePackage] = useDeleteCredentialPackageMutation();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);

  return (
    <SpaceBetween size={"s"}>
      <BreadcrumbGroup
        items={[
          { text: "Credential Packages", href: `/credentialpackages` },
          { text: credentialPackage?.name ?? "Pacakge", href: "#" },
        ]}
      />
      <SpaceBetween size={"l"}>
        <Container>
          <FormSection
            expandedSection
            defaultExpanded
            header={<Header>{credentialPackage?.name}</Header>}
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  onClick={() => {
                    if (credentialPackageId) OpenDeleteModal(true);
                  }}
                >
                  Delete
                </Button>
                <Button
                  onClick={() => {
                    if (credentialPackageId) openModalName(EDIT_PACKAGE);
                  }}
                >
                  Edit
                </Button>
              </SpaceBetween>
            }
          >
            <ColumnLayout columns={4} variant="text-grid">
              <Label
                label="Package Type"
                value={credentialPackage?.packageType?.name ?? "-"}
              />
              <Label
                label="Package Name"
                value={credentialPackage?.name ?? "-"}
              />
              <Label
                label="Credential Template"
                value={
                  (
                    <div>
                      <Link
                        href={`/credentailtemplate/${credentialPackage?.credentialTemplate?.id}`}
                      >
                        {credentialPackage?.credentialTemplate?.name}
                      </Link>
                    </div>
                  ) ?? "-"
                }
              />
              <Label
                label="Provider Name"
                value={
                  (
                    <div>
                      <Link
                        href={`/manageprovider/${credentialPackage?.provider?.id}/profile`}
                      >
                        {credentialPackage?.provider?.firstName +
                          " " +
                          credentialPackage?.provider?.lastName}
                      </Link>
                    </div>
                  ) ?? "-"
                }
              />
              <Label
                label="Status"
                value={credentialPackage?.packageStatus?.name ?? "-"}
              />
              <Label
                label="Submitted Date"
                value={ChangeTimeZone(credentialPackage?.submittedDate) ?? "-"}
              />
              <Label
                label="Re-credentialing Date"
                value={
                  ChangeTimeZone(credentialPackage?.recredentialingDate) ?? "-"
                }
              />
              <Label
                label="Follow-up Date"
                value={credentialPackage?.followupDate ?? "-"}
              />
            </ColumnLayout>
          </FormSection>
          <DeleteAlertModal
            visible={isDeleteModalOpen}
            action={async () => {
              if (credentialPackageId) {
                OpenDeleteModal(false);
                await notifyWrapper({
                  promise: deletePackage({
                    credentialPackageId: credentialPackageId,
                  }),
                  resourceName: "package",
                  actionName: "delete",
                });
              }
            }}
            closeModal={() => OpenDeleteModal(false)}
            header={"Delete Package"}
            content={
              <>
                <Box>Name</Box>
                <Box variant="awsui-key-label">{credentialPackage?.name}</Box>
              </>
            }
            description={"Are you sure you want to delete this package?"}
          />
        </Container>
        <CredentialPackageFormsList
          forms={credentialPackage?.digitalForm ?? []}
          selectedForms={selectedForms}
          setSelectForms={setSelectedForms}
        />
        <DocumentList
          documents={credentialPackage?.document ?? []}
          selectedDocuments={selectedDocuments}
          setSelectDocuments={setSelectedDocuments}
        />
      </SpaceBetween>

      {/*   <Tabs
        tabs={[
          {
            label: "Ready Forms",
            id: "forms",
            content: (
              <CredentialPackageFormsList
                forms={credentialPackage?.digitalForm ?? []}
                selectedForms={selectedForms}
                setSelectForms={setSelectedForms}
              />
            ),
          },
          {
            label: "Documents",
            id: "documents",
            content: (
              <DocumentList
                documents={credentialPackage?.document ?? []}
                selectedDocuments={selectedDocuments}
                setSelectDocuments={setSelectedDocuments}
              />
            ),
          },
        ]}
      /> */}
    </SpaceBetween>
  );
}
const mapDispatchToProps = {
  openModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(CredentialPackageDetails);

function DocumentList({
  selectedDocuments,
  setSelectDocuments,
}: {
  documents: AllDocumentResponse[];
  selectedDocuments: AllDocumentResponse[];
  setSelectDocuments: (selectedDocuments: AllDocumentResponse[]) => void;
}) {
  const { id: credentialPackageId } = useParams();
  const {
    data: documentsData = [],
    isFetching,
    isLoading,
  } = useGetAllCredentialPackageDocumentsQuery(
    credentialPackageId ? { credentialPackageId } : skipToken,
  );
  const [isAddDocumentModalVisible, setIsAddDocumentModalVisible] =
    useState<boolean>(false);

  const [deleteCredentialPackageDocuments] =
    useDeleteCredentialPackageDocumentsMutation();

  return (
    <>
      <Table
        columnDefinitions={[
          {
            id: "name",
            header: "Name",
            cell: (item) => <div>{item?.name || "-"}</div>,
            sortingField: "name",
            isRowHeader: true,
          },
        ]}
        items={documentsData}
        selectedItems={selectedDocuments ?? []}
        onSelectionChange={({ detail }) => {
          if (!!detail.selectedItems) setSelectDocuments(detail.selectedItems);
        }}
        onRowClick={({ detail }) => {
          const temp = selectedDocuments;
          if (!detail.item) return;
          if (temp?.includes(detail.item))
            setSelectDocuments(temp.filter((item) => item !== detail.item));
          else setSelectDocuments([detail.item, ...temp]);
        }}
        selectionType="multi"
        loadingText="Loading..."
        loading={isLoading || isFetching}
        stickyHeader
        sortingDisabled
        header={
          <Header
            variant="h3"
            counter={`(${documentsData?.length})`}
            actions={
              <SpaceBetween size="s" direction="horizontal">
                <Button
                  onClick={() => {
                    if (!selectedDocuments || !credentialPackageId) return;
                    const documentIds = selectedDocuments.map(
                      (document) => document.id + "",
                    );
                    deleteCredentialPackageDocuments({
                      credentialPackageId: credentialPackageId,
                      documents: documentIds,
                    });
                  }}
                >
                  Remove
                </Button>
                <Button
                  onClick={() => {
                    setIsAddDocumentModalVisible(true);
                  }}
                >
                  Add Document
                </Button>
              </SpaceBetween>
            }
          >
            Documents
          </Header>
        }
      />
      <CredentialPackageAddDocumentsModal
        closeModal={() => {
          setIsAddDocumentModalVisible(false);
        }}
        visible={isAddDocumentModalVisible}
      />
    </>
  );
}
