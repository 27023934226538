import { FormProvider, useForm } from "react-hook-form";
import {
  ProviderLiabilityInsuranceRequestType,
} from "../../../../redux/api/provider/section/types";
import {
  BreadcrumbGroup,
  Button,
  Container,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import RHFTextField from "../../../../components/RHF/RHFTextField";
import FormSection from "../../../../components/FormSection";
import RHFDatePicker from "../../../../components/RHF/RHFDatePicker";
import { Divider } from "@mui/material";
import {
  useAddProviderLiabilityInsuranceMutation,
  useGetProviderQuery,
} from "../../../../redux/api/provider/provider";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import RHFCheckBox from "../../../../components/RHF/RHFCheckBox";
import RHFSelect from "../../../../components/RHF/RHFSelect";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import DocumentSection from "../../document-section";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";

export default function EditLiabilityInsurance() {
  const methods = useForm<ProviderLiabilityInsuranceRequestType>();
  const { providerId, liabilityinsuranceId } = useParams();
  const [selectedDocument, setSelectedDocument] = useState<OptionDefinition>({
    label: "None - no document selected ",
    value: "-1",
  });
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const onSubmit = async (data: ProviderLiabilityInsuranceRequestType) => {
    if (!!providerId) {
      await notifyWrapper({
        promise: addProviderLiabilityInsurance({
          providerId: providerId,
          ...data,
          ...(selectedDocument.value !== "-1" && {
            documentId: selectedDocument.value,
          }),
        }),
        resourceName: "liability insurance",
        actionName: "upload",
      });
      navigate(-1);
    }
  };

  const { data: provider, fulfilledTimeStamp } = useGetProviderQuery(
    providerId ? { providerId } : skipToken,
  );
  const [addProviderLiabilityInsurance] =
    useAddProviderLiabilityInsuranceMutation();

  useEffect(() => {
    if (provider && provider.providerLiabilityInsurances) {
      const liabilityinsurance = provider.providerLiabilityInsurances.find(
        (liabilityinsurance) =>
          liabilityinsurance?.id + "" === liabilityinsuranceId + "",
      );

      if (liabilityinsurance) {
        methods.setValue("id", liabilityinsurance.id);
        methods.setValue("isCurrent", liabilityinsurance?.isCurrent);
        methods.setValue("name", liabilityinsurance?.name);
        methods.setValue("policyNumber", liabilityinsurance?.policyNumber);
        methods.setValue("selfInsured", liabilityinsurance?.selfInsured);
        methods.setValue("effectiveDate", liabilityinsurance?.effectiveDate);
        methods.setValue("expirationDate", liabilityinsurance?.expirationDate);
        methods.setValue("email", liabilityinsurance?.email);
        methods.setValue("phone", liabilityinsurance?.phone);
        methods.setValue("fax", liabilityinsurance?.fax);
        methods.setValue("typeOfCoverage", liabilityinsurance?.typeOfCoverage);
        methods.setValue(
          "timeWithCurrentCarrierValue",
          liabilityinsurance?.timeWithCurrentCarrierValue,
        );
        methods.setValue(
          "timeWithCurrentCarrierUnit",
          liabilityinsurance?.timeWithCurrentCarrierUnit,
        );
        methods.setValue(
          "coveragePerOccurrence",
          liabilityinsurance?.coveragePerOccurrence,
        );
        methods.setValue(
          "aggregateCoverage",
          liabilityinsurance?.aggregateCoverage,
        );
        methods.setValue("addressLane1", liabilityinsurance?.addressLane1);
        methods.setValue("addressLane2", liabilityinsurance?.addressLane2);
        methods.setValue("city", liabilityinsurance?.city);
        methods.setValue("state", liabilityinsurance?.state);
        methods.setValue("zip", liabilityinsurance?.zip);
        methods.setValue("country", liabilityinsurance?.country);
        liabilityinsurance?.document &&
          setSelectedDocument({
            label: liabilityinsurance?.document?.name,
            value: liabilityinsurance?.document?.id,
          });
      }
    }
  }, [fulfilledTimeStamp, provider, liabilityinsuranceId]);

  return (
    <div style={{ paddingTop: "25px" }}>
      <SpaceBetween size={"l"}>
        <BreadcrumbGroup
          items={[
            { text: "providers", href: `/providers` },
            {
              text: provider?.firstName ?? "",
              href: `#`,
            },
            { text: `Liability Insurance`, href: "#" },
          ]}
          ariaLabel="Breadcrumbs"
        />
        <Container
          header={
            <SpaceBetween size="xxs">
              <Header>Liability Insurance</Header>
              <Divider></Divider>
            </SpaceBetween>
          }
        >
          <div style={{ paddingTop: "12px" }}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Form
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        onClick={() => {
                          navigate(-1);
                        }}
                        formAction="none"
                      >
                        Cancel
                      </Button>
                      <Button formAction="submit" variant="primary">
                        Submit
                      </Button>
                    </SpaceBetween>
                  }
                >
                  <SpaceBetween size="l">
                    <FormSection columns={4}>
                      <RHFTextField
                        label="Name"
                        name="name"
                        stretch
                        rules={{ required: "This field is required" }}
                      />
                      <RHFTextField
                        label="Policy Number"
                        name="policyNumber"
                        stretch
                        rules={{ required: "This field is required" }}
                      />
                      <RHFDatePicker
                        label="Effective Date"
                        name="effectiveDate"
                        rules={{ required: "This field is required" }}
                      />
                      <RHFDatePicker
                        label="Expiration Date"
                        name="expirationDate"
                      />
                      <RHFSelect
                        name="typeOfCoverage"
                        label="Type of Coverage"
                        options={["Individual", "Shared"].map((type) => ({
                          value: type,
                        }))}
                      />
                      <RHFTextField
                        label="Time With Current Carrier Value"
                        name="timeWithCurrentCarrierValue"
                        stretch
                        rules={{
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Please enter a number",
                          },
                        }}
                      />
                      <RHFTextField
                        label="Time With Current Carrier Unit"
                        name="timeWithCurrentCarrierUnit"
                        stretch
                      />
                      <RHFTextField
                        label="Coverage Per Occurrence"
                        name="coveragePerOccurrence"
                        stretch
                      />

                      <RHFTextField
                        label="AggregateCoverage"
                        name="aggregateCoverage"
                        stretch
                        rules={{
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Please enter a number",
                          },
                        }}
                      />
                      <RHFTextField label="Email" name="email" />
                      <RHFTextField
                        label="Phone"
                        name="phone"
                        rules={{
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Please enter a number",
                          },
                        }}
                        stretch
                      />
                      <RHFTextField
                        label="Fax"
                        name="fax"
                        rules={{
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Please enter a number",
                          },
                        }}
                        stretch
                      />
                      <RHFTextField
                        label="Address Lane1"
                        name="addressLane1"
                        stretch
                        rules={{ required: "This field is required" }}
                      />
                      <RHFTextField
                        label="Address Lane2"
                        name="addressLane2"
                        stretch
                        rules={{ required: "This field is required" }}
                      />
                      <RHFTextField
                        label="City"
                        name="city"
                        stretch
                        rules={{ required: "This field is required" }}
                      />
                      <RHFTextField
                        label="State"
                        name="state"
                        stretch
                        rules={{ required: "This field is required" }}
                      />
                      <RHFTextField
                        label="Zip code"
                        name="zip"
                        stretch
                        rules={{ required: "This field is required" }}
                      />
                      <RHFTextField
                        label="Country"
                        name="country"
                        stretch
                        rules={{ required: "This field is required" }}
                      />
                      <div style={{ paddingTop: "12px" }}>
                        <RHFCheckBox label="Current" name="isCurrent" stretch />
                      </div>
                      <div style={{ paddingTop: "12px" }}>
                        <RHFCheckBox
                          label="Self Insured"
                          name="selfInsured"
                          stretch
                        />
                      </div>
                    </FormSection>
                    <DocumentSection
                      providerId={providerId}
                      selectedDocument={selectedDocument}
                      setSelectedDocument={setSelectedDocument}
                    />
                  </SpaceBetween>
                </Form>
              </form>
            </FormProvider>
          </div>
        </Container>
      </SpaceBetween>
    </div>
  );
}
