import {
  Button,
  Header,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import Grid from "@mui/material/Grid";
import { FormProvider, useForm } from "react-hook-form";
import RHFTextField from "../../components/RHF/RHFTextField";
import { useAddProviderMutation } from "../../redux/api/provider/provider";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import FormSection from "../../components/FormSection";
import { setModalName } from "../../redux/UI/actions";
import { connect } from "react-redux";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

type FormInputType = {
  firstName: string;
  lastName: string;
  email: string;
  npiNumber: string;
};

function AddManageProviderModal({ setModalName }: PropsFromDispatch) {
  const methods = useForm<FormInputType>({});
  const { notifyWrapper } = useAsyncNotifyWrapper();

  const onSubmit = async (data: FormInputType) => {
    await notifyWrapper({
      promise: addProvider({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        npiNumber: data.npiNumber,
      }),
      resourceName: "provider",
      actionName: "add",
    });
    setModalName("");
  };
  const [addProvider] = useAddProviderMutation();

  return (
    <Modal
      visible={true}
      size="medium"
      header={<Header variant="h2">Add Provider</Header>}
      onDismiss={() => {
        setModalName("");
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <SpaceBetween direction="vertical" size="l">
            <FormSection>
              <FormSection
                gridDefinition={[
                  { colspan: { default: 6, m: 6 } },
                  { colspan: { default: 6, m: 6 } },
                ]}
              >
                <RHFTextField
                  testid="firstName"
                  label="First Name"
                  name="firstName"
                  stretch={false}
                  rules={{ required: "This field is required" }}
                />
                <RHFTextField
                  testid="lastName"
                  name="lastName"
                  label="Last Name"
                  stretch={false}
                  rules={{ required: "This field is required" }}
                />
              </FormSection>
              <RHFTextField
                testid="email"
                label="Email"
                name="email"
                stretch={false}
                rules={{ required: "This field is required" }}
              />
              <RHFTextField
                testid="npiNumber"
                label="NPI Number"
                name="npiNumber"
                stretch={false}
                rules={{ required: "This field is required" }}
              />
            </FormSection>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  data-testid="cancel"
                  formAction="none"
                  onClick={() => {
                    setModalName("");
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button data-testid="save" variant="primary">
                  Save
                </Button>
              </Grid>
            </Grid>
          </SpaceBetween>
        </form>
      </FormProvider>
    </Modal>
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(AddManageProviderModal);

const AttributeEditorLabel = ({ label }: { label: string }) => (
  <span>
    {label} {<span style={{ color: "#fc0a37" }}>*</span>}
  </span>
);
