// pdftron
import WebViewer, { Core, WebViewerInstance } from "@pdftron/webviewer";
// react
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
// css
import "./styles.css";
// theme
import ThemeProvider from "./theme";
//types
import { FieldType, FontType } from "./types";
// components
import TemplateDesignerHeader from "./template-designer-header";

//constants
import { disabledElements } from "../helpers/constants";
// modal
import { Container } from "@cloudscape-design/components";
import TemplateDesignerTagsList from "./template-designer-tags-list";
import TemplateDesignerAnnotationFormatter from "./template-designer-annotation-formatter";
import useSectionFilter from "../hooks/useSectionFilter";
import InnerAppLayout from "../components/InnerAppLayout";
import { File_URL } from "../config";

export const defaultFont: FontType = {
  fontFamily: "Arial",
  fontSize: "8",
};

export type TemplateDesignerProps = {
  breadCrumbItems: {
    text: string;
    href: string;
  }[];
  fileKey: string;
  saveXMLString: (xfdfString: string) => Promise<void>;
};

export default function TemplateDesigner({
  fileKey,
  breadCrumbItems,
  saveXMLString,
}: TemplateDesignerProps) {
  const [customFont, setCustomFont] = useState<FontType>(defaultFont);

  const { filteredSections, filter, isLoading, setFilter } = useSectionFilter();

  const viewer = useRef<HTMLDivElement>(null);

  const { id } = useParams();

  const [selectedAnnots, setSelectedAnnots] = useState<
    Core.Annotations.FreeTextAnnotation[]
  >([]);

  const [instance, setInstance] = useState<WebViewerInstance | undefined>(
    undefined,
  );

  const [dropPoint, setDropPoint] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    console.log(File_URL + fileKey);

    if (viewer.current != null && !!fileKey)
      WebViewer(
        {
          path: "/webviewer",
          licenseKey: process.env.REACT_APP_APRYSE_LICENSE_KEY,
        },
        viewer.current,
      )
        .then((instance) => {
          instance.UI.disableElements(disabledElements);
          instance.UI.setToolbarGroup("toolbarGroup-View");
          setInstance(instance);

          const { documentViewer, annotationManager } = instance.Core;
          console.log(File_URL + fileKey);

          if ("localhost" === window.location.hostname)
            instance.UI.loadDocument("/sample.pdf");
          else instance.UI.loadDocument(encodeURI(File_URL + fileKey));

          instance.UI.addEventListener("dragover", dragOver);

          instance.UI.addEventListener("drop", (e) => {
            drop(e, instance);
          });

          annotationManager.addEventListener(
            "annotationSelected",
            (annotations: Core.Annotations.Annotation[], action) => {
              const selectedAnnots = annotationManager
                .getSelectedAnnotations()
                .map((selectedAnnots) => {
                  return selectedAnnots as Core.Annotations.FreeTextAnnotation;
                });
              setSelectedAnnots(selectedAnnots);
            },
          );

          documentViewer.addEventListener("documentLoaded", function () {
            instance.UI.setFitMode(instance.UI.FitMode.FitWidth);
          });
        })
        .catch((error) => {
          console.log(error);
        });
  }, [fileKey]);

  const addFreeTextAnnotation = (
    point: { x: number; y: number },
    field: FieldType,
  ) => {
    if (!instance) return;

    const { documentViewer, Annotations } = instance.Core;
    const annotManager = documentViewer.getAnnotationManager();
    const doc = documentViewer.getDocument();
    const displayMode = documentViewer.getDisplayModeManager().getDisplayMode();
    const page = displayMode.getSelectedPages(point, point);

    const width = field.type === "bool" ? 16 : 250;
    const height = field.type === "bool" ? 16 : 50;

    if (!!point.x && page.first == null) {
      return; //don't add field to an invalid page location
    }

    const page_idx =
      page.first !== null ? page.first : documentViewer.getCurrentPage();
    const page_info = doc.getPageInfo(page_idx);
    const page_point = displayMode.windowToPage(point, page_idx);
    const zoom = documentViewer.getZoomLevel();

    var textAnnot = new Annotations.FreeTextAnnotation();
    textAnnot.PageNumber = page_idx;
    const rotation = documentViewer.getCompleteRotation(page_idx) * 90;
    textAnnot.Rotation = rotation;

    if (rotation === 270 || rotation === 90) {
      textAnnot.Width = height / zoom;
      textAnnot.Height = width / zoom;
    } else {
      textAnnot.Width = width / zoom;
      textAnnot.Height = height / zoom;
    }
    textAnnot.X = (page_point.x || page_info.width / 2) - textAnnot.Width / 2;
    textAnnot.Y = (page_point.y || page_info.height / 2) - textAnnot.Height / 2;

    textAnnot.setPadding(new instance.Core.Math.Rect(0, 0, 0, 0));

    textAnnot.setCustomData("type", field.type);
    textAnnot.setCustomData("name", field.label);
    textAnnot.setCustomData("key", field.key);
    textAnnot.setCustomData("isList", field.isList.toString());

    if (field.isList) {
      try {
        const index = annotManager
          .getAnnotationsList()
          .filter(
            (annot) =>
              annot.getCustomData("key").toString() === field.key.toString(),
          ).length;
        textAnnot.setCustomData("index", index.toString());
        //Annote name
        textAnnot.setContents([field.label, index.toString()].join("_"));
      } catch (error) {
        console.log(error);
        textAnnot.setContents(field.label);
      }
    } else textAnnot.setContents(field.label);

    // set the type of annote
    textAnnot.Font = customFont.fontFamily;
    textAnnot.FontSize = "" + customFont.fontSize + "pt";
    textAnnot.FillColor = new Annotations.Color(255, 255, 255, 0);
    textAnnot.TextColor = new Annotations.Color(0, 165, 228);
    textAnnot.StrokeColor =
      textAnnot.getCustomData("isRequired") === "true"
        ? new Annotations.Color(255, 0, 0)
        : new Annotations.Color(0, 165, 228);
    textAnnot.StrokeThickness = 1;
    textAnnot.LockedContents = true;
    textAnnot.disableRotationControl();

    annotManager.deselectAllAnnotations();
    annotManager.addAnnotation(textAnnot, { autoFocus: true });

    //To remove all the annotations that are not added by user, For example when the PDF is a Fillable PDF then default feilds conflicts with the custom Feilds,
    const annotsToDelete = annotManager
      .getAnnotationsList()
      .filter((annot) => !annot.getCustomData("type"));

    annotManager.deleteAnnotations(annotsToDelete, { force: true });
    annotManager.redrawAnnotation(textAnnot);
    annotManager.selectAnnotation(textAnnot);
  };

  const deselectAllAnnotations = () => {
    if (!instance) return;

    const { documentViewer } = instance.Core;
    const annotationManager = documentViewer.getAnnotationManager();

    annotationManager.deselectAllAnnotations();
    setSelectedAnnots([]);
  };

  const submit = async () => {
    if (!instance) return;

    const { documentViewer } = instance.Core;
    const annotationManager = documentViewer.getAnnotationManager();

    annotationManager.deselectAllAnnotations();
    setSelectedAnnots([]);

    if (!id) return;
    let xfdfString: string | undefined;
    try {
      xfdfString = await annotationManager.exportAnnotations({
        widgets: true,
        fields: true,
        links: false,
        useDisplayAuthor: false,
        generateInlineAppearances: false,
      });
    } catch (error) {
      console.log(error);
    }

    console.log({ xfdfString });

    if (xfdfString) saveXMLString(xfdfString);
  };

  const dragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    return false;
  };

  const drop = (
    e: React.DragEvent<HTMLDivElement>,
    instance: WebViewerInstance,
  ) => {
    const { documentViewer } = instance.Core;
    const scrollElement = documentViewer.getScrollViewElement();
    const scrollLeft = scrollElement.scrollLeft || 0;
    const scrollTop = scrollElement.scrollTop || 0;
    setDropPoint({ x: e.pageX + scrollLeft, y: e.pageY + scrollTop });
    e.preventDefault();
    return false;
  };

  const dragStart = (e: React.DragEvent<HTMLDivElement>) => {
    e.currentTarget.style.opacity = "0.5";
    const copy = e.currentTarget.cloneNode(true) as HTMLDivElement;
    copy.id = "form-build-drag-image-copy";
    copy.style.width = "250px";
    document.body.appendChild(copy);
    e.dataTransfer.setDragImage(copy, 125, 25);
    e.dataTransfer.setData("text", "");
  };

  const dragEnd = (e: React.DragEvent<HTMLDivElement>, field: FieldType) => {
    addFreeTextAnnotation(dropPoint, field);
    e.currentTarget.style.opacity = "1";
    document.body.removeChild(
      document.getElementById("form-build-drag-image-copy") as HTMLDivElement,
    );
    e.preventDefault();
  };

  const onNameChanged = (name: string) => {
    if (!instance) return;

    const { documentViewer } = instance.Core;
    const annotManager = documentViewer.getAnnotationManager();
    selectedAnnots.forEach((annot) => {
      annot.setCustomData("name", name);
      annot.setContents(
        [name, annot.getCustomData("index")]
          .filter((item) => item !== "")
          .join("_"),
      );
      annotManager.redrawAnnotation(annot);
    });
  };

  const onIndexChanged = (index: string) => {
    if (!instance) return;

    const { documentViewer } = instance.Core;
    const annotManager = documentViewer.getAnnotationManager();
    selectedAnnots.forEach((annot) => {
      annot.setCustomData("index", index);
      annot.setContents([annot.getCustomData("name"), index].join("_"));

      annotManager.redrawAnnotation(annot);
    });
  };

  const onFontFamilyChanged = (fontFamily: string) => {
    if (!instance) return;

    setCustomFont({ ...customFont, fontFamily: fontFamily });
    const { documentViewer } = instance.Core;
    const annotManager = documentViewer.getAnnotationManager();
    selectedAnnots.forEach((annot) => {
      annot.Font = fontFamily;
      annotManager.redrawAnnotation(annot);
    });
  };

  const onFontSizeChanged = (fontSize: string) => {
    if (!instance) return;
    setCustomFont({ ...customFont, fontSize: fontSize });
    const { documentViewer } = instance.Core;
    const annotManager = documentViewer.getAnnotationManager();
    selectedAnnots.forEach((annot) => {
      annot.FontSize = "" + fontSize + "pt";
      annotManager.redrawAnnotation(annot);
    });
  };

  const onRequiredChanged = (isRequired: boolean) => {
    if (!instance) return;
    const { documentViewer, Annotations } = instance.Core;
    const annotManager = documentViewer.getAnnotationManager();
    selectedAnnots.forEach((annot) => {
      if (isRequired) {
        annot.setCustomData("isRequired", "true");
        annot.StrokeColor = new Annotations.Color(255, 0, 0);
      } else {
        annot.setCustomData("isRequired", "");
        annot.StrokeColor = new Annotations.Color(0, 165, 228);
      }
      annotManager.redrawAnnotation(annot);
    });
  };

  const ViewerHTMLElement = (
    <div
      ref={viewer}
      style={{
        height: `calc(100vh - 140px)`,
      }}
    />
  );

  return (
    <ThemeProvider>
      <div style={{ padding: "0 25px" }}>
        <InnerAppLayout
          breadcrumbGroup={
            <TemplateDesignerHeader
              submit={submit}
              breadCrumbItems={breadCrumbItems}
            />
          }
          leftPanel={
            !selectedAnnots.length ? (
              <TemplateDesignerTagsList
                onDragStart={dragStart}
                onDragEnd={dragEnd}
                filteredSections={filteredSections}
                filter={filter}
                isLoading={isLoading}
                setFilter={setFilter}
              />
            ) : (
              <TemplateDesignerAnnotationFormatter
                selectedAnnots={selectedAnnots}
                defaultFontValue={customFont}
                onNameChanged={onNameChanged}
                onIndexChanged={onIndexChanged}
                onFontSizeChanged={onFontSizeChanged}
                onRequiredChanged={onRequiredChanged}
                onFontFamilyChanged={onFontFamilyChanged}
                deselectAllAnnotations={deselectAllAnnotations}
              />
            )
          }
          content={<Container>{ViewerHTMLElement}</Container>}
        />
      </div>
    </ThemeProvider>
  );
}
