import { FormProvider, useForm } from "react-hook-form";
import {
  ProviderSpecialtyInfoRequestType,
} from "../../../../redux/api/provider/section/types";
import {
  Box,
  BreadcrumbGroup,
  Button,
  Container,
  Form,
  Header,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import FormSection from "../../../../components/FormSection";
import RHFSelect from "../../../../components/RHF/RHFSelect";
import RHFDatePicker from "../../../../components/RHF/RHFDatePicker";
import { Divider } from "@mui/material";
import {
  useAddProviderSpecialtyInfoMutation,
  useGetAllSpecialtyQuery,
  useGetProviderQuery,
} from "../../../../redux/api/provider/provider";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import "./edit-specialty-info.css";
import PDFTronViewer from "../../../../components/PdftronViewerWrappers/PDFTronViewer";
import { specialty_certification_boards } from "../../../../helpers/constants";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import DocumentSection from "../../document-section";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";

export default function EditSpecialtyInfo() {
  const methods = useForm<ProviderSpecialtyInfoRequestType>();
  const { providerId, specialtyinfoId } = useParams();
  const [selectedDocument, setSelectedDocument] = useState<OptionDefinition>({
    label: "None - no document selected ",
    value: "-1",
  });
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const onSubmit = async (data: ProviderSpecialtyInfoRequestType) => {
    if (!!providerId) {
      await notifyWrapper({
        promise: addProviderSpecialtyInfo({
          providerId: providerId,
          ...data,
          specialtyId: data.specialtyId,
          documentId:
            selectedDocument.value !== "-1" ? selectedDocument.value : "",
        }),
        resourceName: "specialty info",
        actionName: "upload",
      });
      navigate(-1);
    }
  };
  const [visible, setVisible] = useState(false);
  const { data: provider, fulfilledTimeStamp } = useGetProviderQuery(
    providerId ? { providerId } : skipToken,
  );

  const { data: all_specialty = [] } = useGetAllSpecialtyQuery();
  const [addProviderSpecialtyInfo] = useAddProviderSpecialtyInfoMutation();

  useEffect(() => {
    if (provider && provider.providerSpecialties) {
      const specialtyInfo = provider.providerSpecialties.find(
        (specialtyinfo) => specialtyinfo?.id + "" === specialtyinfoId + "",
      );

      if (!!specialtyInfo) {
        methods.setValue("id", specialtyInfo.id);
        methods.setValue("isBoardCertified", specialtyInfo.isBoardCertified);
        methods.setValue("boardName", specialtyInfo.boardName);
        methods.setValue(
          "initialCertificationDate",
          specialtyInfo.initialCertificationDate,
        );
        methods.setValue("expirationDate", specialtyInfo.expirationDate);
        methods.setValue(
          "reCertificationDate",
          specialtyInfo.reCertificationDate,
        );
        methods.setValue("specialtyId", specialtyInfo.specialty.id);
        specialtyInfo?.document &&
          setSelectedDocument({
            label: specialtyInfo?.document?.name,
            value: specialtyInfo?.document?.id,
          });
      }
    }
  }, [fulfilledTimeStamp, provider, specialtyinfoId]);

  return (
    <div style={{ paddingTop: "25px" }}>
      <SpaceBetween size={"l"}>
        <BreadcrumbGroup
          items={[
            { text: "providers", href: `/providers` },
            {
              text: provider?.firstName ?? "",
              href: `#`,
            },
            { text: `Specialty Info`, href: "#" },
          ]}
          ariaLabel="Breadcrumbs"
        />
        <Container
          header={
            <SpaceBetween size="xxs">
              <Header>Specialty Info</Header>
              <Divider></Divider>
            </SpaceBetween>
          }
        >
          <div style={{ paddingTop: "12px" }}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Form
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        onClick={() => {
                          navigate(-1);
                        }}
                        formAction="none"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          setVisible(true);
                        }}
                      >
                        Add document
                      </Button>
                      <Button formAction="submit" variant="primary">
                        Submit
                      </Button>
                    </SpaceBetween>
                  }
                >
                  <SpaceBetween size="l">
                    <FormSection
                      gridDefinition={[{ colspan: 4 }, { colspan: 2 }]}
                    >
                      <RHFSelect
                        label="Board Name"
                        name="boardName"
                        options={specialty_certification_boards.map(
                          (board) => ({
                            value: board.boardName,
                          }),
                        )}
                        rules={{ required: "This field is required" }}
                        filteringType="auto"
                      />
                      <RHFSelect
                        name="specialtyId"
                        label="Specialty"
                        options={all_specialty.map((specialty) => ({
                          value: specialty.id.toString(),
                          label: specialty.name,
                        }))}
                        rules={{ required: "This field is required" }}
                        filteringType="auto"
                      />
                      <RHFDatePicker
                        label="Initial Certification Date"
                        name="initialCertificationDate"
                        rules={{ required: false }}
                      />
                      <RHFDatePicker
                        label="Expiration Date"
                        name="expirationDate"
                        rules={{ required: false }}
                      />
                      <RHFDatePicker
                        label="ReCertification Date"
                        name="reCertificationDate"
                        rules={{ required: false }}
                      />
                    </FormSection>
                    <DocumentSection
                      providerId={providerId}
                      selectedDocument={selectedDocument}
                      setSelectedDocument={setSelectedDocument}
                    />
                  </SpaceBetween>
                </Form>
              </form>
            </FormProvider>
          </div>
        </Container>
      </SpaceBetween>
      <Modal
        onDismiss={() => setVisible(false)}
        visible={visible}
        size="max"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="link"
                onClick={() => {
                  setVisible(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  setVisible(false);
                }}
              >
                Ok
              </Button>
            </SpaceBetween>
          </Box>
        }
        header="PSV screenshot"
      >
        <PDFTronViewer attachmentKey={""} replaceFile={(file: File) => {}} />
      </Modal>
    </div>
  );
}
