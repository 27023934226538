import { BreadcrumbGroup, Header } from "@cloudscape-design/components";
import { useParams } from "react-router-dom";
import InnerAppLayout from "../components/InnerAppLayout";
import { useState } from "react";
import { Provider } from "../redux/api/provider/types";
import ProviderSafeContent from "./provider-safe-content";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material";
import ProviderSafeNavigation from "./provider-Safe-Navigation";

export const NoProviderSelected = () => (
  <div>
    <Header variant="h2">Select a Provider</Header>
  </div>
);
export const BootstrapTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ),
)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: 10,
  },
}));

export default function ProviderSafeTable() {
  const [selectedItem, setSelectedItem] = useState<Provider>();
  const { providerId } = useParams();

  return (
    <InnerAppLayout
      breadcrumbGroup={
        <BreadcrumbGroup
          items={
            selectedItem
              ? [
                  { text: "Provider Safe", href: "#" },
                  {
                    text: selectedItem.firstName + " " + selectedItem.lastName,
                    href: "#",
                  },
                ]
              : [{ text: "Provider Safe", href: "#" }]
          }
        />
      }
      leftPanel={
        <ProviderSafeNavigation
          onChange={(selectedProvider) => {
            setSelectedItem(selectedProvider);
          }}
          navigateTo={(providerId: string) => `/safe/${providerId}`}
        />
      }
      content={!!providerId ? <ProviderSafeContent /> : <NoProviderSelected />}
    />
  );
}
