import { FormProvider, useForm } from "react-hook-form";
import { PersonalInfoRequestType } from "../../../../redux/api/provider/section/types";
import {
  BreadcrumbGroup,
  Button,
  Checkbox,
  Container,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import RHFTextField from "../../../../components/RHF/RHFTextField";
import RHFCheckBox from "../../../../components/RHF/RHFCheckBox";
import FormSection from "../../../../components/FormSection";
import RHFSelect from "../../../../components/RHF/RHFSelect";
import RHFDatePicker from "../../../../components/RHF/RHFDatePicker";
import { Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAddProviderPersonalInfoMutation,
  useGetAllDegreeQuery,
  useGetProviderQuery,
  useUpdateProviderMutation,
} from "../../../../redux/api/provider/provider";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";

type FormInputType = PersonalInfoRequestType & {
  firstName: string;
  lastName: string;
};

export default function EditPersonalInfo() {
  const methods = useForm<FormInputType>();
  const navigate = useNavigate();
  const [addProviderPersonalinfo] = useAddProviderPersonalInfoMutation();
  const { providerId } = useParams();
  const { data: provider, fulfilledTimeStamp } = useGetProviderQuery(
    providerId ? { providerId } : skipToken,
  );
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const { data: degrees = [] } = useGetAllDegreeQuery();
  const onSubmit = async (data: FormInputType) => {
    if (!!providerId) {
      await notifyWrapper({
        promise: addProviderPersonalinfo({
          providerId: providerId,
          ...data,
          hasOtherName: hasOtherName,
        }),
        resourceName: "personal info",
        actionName: "upload",
      });
      await notifyWrapper({
        promise: updateProvider({
          id: providerId ?? "",
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          npiNumber: data.npiNumber,
        }),
        resourceName: "provider",
        actionName: "update",
      });
      navigate(-1);
    }
  };
  const [updateProvider] = useUpdateProviderMutation();
  const [hasOtherName, setHasOtherName] = useState<boolean>(false);

  useEffect(() => {
    if (!!provider) {
      const providerPersonalInfo = provider.personalInfo;
      methods.setValue("firstName", provider?.firstName);
      methods.setValue("lastName", provider?.lastName);
      methods.setValue("email", provider?.email);
      methods.setValue("npiNumber", provider?.npiNumber);
      if (providerPersonalInfo) {
        setHasOtherName(providerPersonalInfo?.hasOtherName);
        methods.setValue("id", providerPersonalInfo?.id);
        methods.setValue("middleName", providerPersonalInfo?.middleName);
        providerPersonalInfo?.degree &&
          methods.setValue("degreeId", providerPersonalInfo?.degree.id);
        methods.setValue("hasOtherName", providerPersonalInfo?.hasOtherName);
        methods.setValue("otherName", providerPersonalInfo?.otherName);
        methods.setValue("gender", providerPersonalInfo?.gender);
        methods.setValue("dob", providerPersonalInfo?.dob);
        methods.setValue("placeOfBirth", providerPersonalInfo?.placeOfBirth);
        methods.setValue("ssn", providerPersonalInfo?.ssn);
        methods.setValue("citizenship", providerPersonalInfo?.citizenship);
        methods.setValue("passportNo", providerPersonalInfo?.passportNo);
        methods.setValue("mobile", providerPersonalInfo?.mobile);
        methods.setValue("homePhone", providerPersonalInfo?.homePhone);
        methods.setValue("fax", providerPersonalInfo?.fax);
        methods.setValue(
          "hasWorkedInMilitary",
          providerPersonalInfo?.hasWorkedInMilitary,
        );
        methods.setValue(
          "branchOfMilitary",
          providerPersonalInfo?.branchOfMilitary,
        );
        methods.setValue(
          "militaryDateOfService",
          providerPersonalInfo?.militaryDateOfService,
        );
        methods.setValue("addressLane1", providerPersonalInfo?.addressLane1);
        methods.setValue("addressLane2", providerPersonalInfo?.addressLane2);
        methods.setValue("city", providerPersonalInfo?.city);
        methods.setValue("state", providerPersonalInfo?.state);
        methods.setValue("zipcode", providerPersonalInfo?.zipcode);
        methods.setValue("county", providerPersonalInfo?.county);
        methods.setValue("country", providerPersonalInfo?.country);
      }
    }
  }, [fulfilledTimeStamp, provider]);

  return (
    <div style={{ paddingTop: "25px" }}>
      <SpaceBetween size={"l"}>
        <BreadcrumbGroup
          items={[
            { text: "providers", href: `/providers` },
            {
              text: provider?.firstName ?? "",
              href: `#`,
            },
            { text: `Personal Info`, href: "#" },
          ]}
          ariaLabel="Breadcrumbs"
        />
        <Container
          header={
            <SpaceBetween size="xxs">
              <Header>Personal Info</Header>
              <Divider></Divider>
            </SpaceBetween>
          }
        >
          <div style={{ paddingTop: "12px" }}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Form
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        onClick={() => {
                          navigate(-1);
                        }}
                        formAction="none"
                      >
                        Cancel
                      </Button>
                      <Button formAction="submit" variant="primary">
                        Submit
                      </Button>
                    </SpaceBetween>
                  }
                >
                  <FormSection columns={4}>
                    <RHFTextField
                      label="First Name"
                      name="firstName"
                      rules={{ required: "This field is required" }}
                      stretch
                    />
                    <RHFTextField
                      label="Last Name"
                      name="lastName"
                      rules={{ required: "This field is required" }}
                      stretch
                    />
                    <RHFTextField
                      label="Middle Name"
                      name="middleName"
                      stretch
                    />
                    <RHFTextField
                      label="NPI Number"
                      name="npiNumber"
                      rules={{ required: "This field is required" }}
                    />
                    <RHFSelect
                      name="degreeId"
                      label="Degree"
                      options={degrees.map((degree) => ({
                        value: degree.id,
                        label: degree.name,
                      }))}
                      rules={{ required: "This field is required" }}
                    />
                    <div style={{ paddingTop: "12px" }}>
                      <Checkbox
                        onChange={({ detail }) =>
                          setHasOtherName(detail.checked)
                        }
                        checked={hasOtherName}
                      >
                        Has Other Name
                      </Checkbox>
                    </div>
                    {hasOtherName === true ? (
                      <RHFTextField
                        label="Other Name"
                        name="otherName"
                        stretch
                        rules={{ required: "This field is required" }}
                      />
                    ) : (
                      <></>
                    )}
                    <RHFSelect
                      name="gender"
                      label="Gender"
                      options={["Male", "Female"].map((gender) => ({
                        value: gender,
                      }))}
                      rules={{ required: "This field is required" }}
                    />
                    <RHFDatePicker
                      label="Date of birth"
                      name="dob"
                      rules={{ required: "This field is required" }}
                    />
                    <RHFTextField
                      label="Place Of Birth"
                      name="placeOfBirth"
                      rules={{ required: "This field is required" }}
                      stretch
                    />
                    <RHFTextField
                      type="password"
                      label="SSN"
                      name="ssn"
                      rules={{ required: "This field is required" }}
                      stretch
                    />
                    <RHFTextField
                      label="Passport No"
                      name="passportNo"
                      stretch
                    />
                    <RHFTextField
                      label="Citizenship"
                      name="citizenship"
                      rules={{ required: "This field is required" }}
                    />
                    <div style={{ paddingTop: "12px" }}>
                      <RHFCheckBox
                        label="Have you ever served in the US military"
                        name="hasWorkedInMilitary"
                        stretch
                      />
                    </div>
                    <RHFTextField
                      label="Branch Of Military"
                      name="branchOfMilitary"
                      stretch
                    />
                    <RHFDatePicker
                      label="Military Date Of Service"
                      name="militaryDateOfService"
                    />
                    <RHFTextField
                      label="Email"
                      name="email"
                      rules={{ required: "This field is required" }}
                      stretch
                    />
                    <RHFTextField
                      label="Mobile"
                      name="mobile"
                      rules={{
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "Please enter a number",
                        },
                        required: "This field is required",
                      }}
                      stretch
                    />
                    <RHFTextField
                      label="Home Phone"
                      name="homePhone"
                      rules={{
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "Please enter a number",
                        },
                      }}
                      stretch
                    />
                    <RHFTextField label="Fax" name="fax" stretch />

                    <RHFTextField
                      label="Address Lane1"
                      name="addressLane1"
                      rules={{ required: "This field is required" }}
                      stretch
                    />
                    <RHFTextField
                      label="Address Lane2"
                      name="addressLane2"
                      stretch
                    />
                    <RHFTextField
                      label="City"
                      name="city"
                      rules={{ required: "This field is required" }}
                      stretch
                    />
                    <RHFTextField
                      label="County"
                      name="county"
                      rules={{ required: "This field is required" }}
                      stretch
                    />
                    <RHFTextField
                      label="State"
                      name="state"
                      rules={{ required: "This field is required" }}
                      stretch
                    />
                    <RHFTextField
                      label="Zip Code"
                      name="zipcode"
                      rules={{ required: "This field is required" }}
                      stretch
                    />
                    <RHFTextField
                      label="Country"
                      name="country"
                      rules={{ required: "This field is required" }}
                      stretch
                    />
                  </FormSection>
                </Form>
              </form>
            </FormProvider>
          </div>
        </Container>
      </SpaceBetween>
    </div>
  );
}
