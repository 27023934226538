import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { useGetAllProvidersQuery } from "../redux/api/provider/provider";
import { useEffect, useState } from "react";
import { Provider } from "../redux/api/provider/types";

export type FilterType = {
  filterText: string;
  filterEmail: string;
  facilityFilterOptions: OptionDefinition[];
  licenseTypeFilterOptions: OptionDefinition[];
};

export default function useFilter() {
  const {
    data = [],
    isLoading,
    isFetching,
    refetch,
  } = useGetAllProvidersQuery({
    includeRecordData: false,
  });

  const [filter, setFilter] = useState<FilterType>({
    filterText: "",
    filterEmail: "",
    facilityFilterOptions: [],
    licenseTypeFilterOptions: [],
  });

  const [filteredProviders, setFilteredProviders] = useState<Provider[]>(data);

  useEffect(() => {
    setFilteredProviders(data);
  }, [data]);

  useEffect(() => {
    let temp_filtered_providers = [...data];
    if (filter.filterText) {
      temp_filtered_providers = temp_filtered_providers.filter((provider) =>
        [provider.firstName, provider.lastName]
          .join(" ")
          .toLowerCase()
          .includes(filter.filterText.toLowerCase()),
      );
    }
    if (filter.filterEmail) {
      temp_filtered_providers = temp_filtered_providers.filter(
        (provider) =>
          provider.email
            ?.toLowerCase()
            .includes(filter.filterEmail.toLowerCase()),
      );
    }

    const selectedFaciltiesId = filter.facilityFilterOptions
      .map((option) => option.value + "")
      .filter((value) => !!value);

    if (selectedFaciltiesId && selectedFaciltiesId.length > 0)
      temp_filtered_providers = temp_filtered_providers.filter(
        (provider) =>
          selectedFaciltiesId.indexOf(provider.facility?.id + "") >= 0,
      );
    const selectedLicenseTypeId = filter.licenseTypeFilterOptions
      .map((option) => option.value + "")
      .filter((value) => !!value);

    if (selectedLicenseTypeId && selectedLicenseTypeId.length > 0)
      temp_filtered_providers = temp_filtered_providers.filter(
        (provider) =>
          selectedLicenseTypeId.indexOf(provider.licenseType?.id + "") >= 0,
      );

    setFilteredProviders(temp_filtered_providers);
  }, [filter]);

  return {
    filteredProviders,
    isLoading,
    isFetching,
    filter,
    setFilter,
    refetch,
  };
}
