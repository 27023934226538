import {
  Badge,
  Box,
  BreadcrumbGroup,
  ColumnLayout,
  Container,
  Grid,
  Header,
  ProgressBar,
  SpaceBetween,
} from "@cloudscape-design/components";
import { useGetSignatureRequestQuery } from "../redux/api/signaturerequest/signaturerequest";
import { useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  RecipientResponse,
  eSignatureResponse,
} from "../redux/api/signaturerequest/types";
import LoadingScreen from "../components/LoadingScreen";
import ChangeTimeZone from "../components/Timezone";
import Divider from "@mui/material/Divider";
import InnerAppLayout from "../components/InnerAppLayout";

export default function SignatureDetails() {
  const { id } = useParams();
  const { data, isLoading } = useGetSignatureRequestQuery(
    id ? { id } : skipToken,
  );
  console.log({ data });
  if (!data) return <LoadingScreen isOpen={isLoading} />;
  else
    return (
      <InnerAppLayout
        breadcrumbGroup={
          <BreadcrumbGroup
            items={[
              { text: "e-Signatures", href: "/esignatures" },
              { text: data.subject, href: "#" },
            ]}
          />
        }
        content={
          <SpaceBetween size="l">
            <Container header={<Header>Details</Header>}>
              <Details eSignatureResponse={data} />
            </Container>
            <Container header={<Header>Recipeints</Header>}>
              <Divider>Waiting</Divider>
              <SpaceBetween size="xs">
                {data.recipients.map((recipient, index) => {
                  console.log({ recipient });
                  return (
                    <SpaceBetween size="xxs">
                      <SpaceBetween size="xxs">
                        <GridItem
                          label={"Type"}
                          value={
                            <SpaceBetween size="s" direction="horizontal">
                              {!!recipient.provider
                                ? "Provider"
                                : !!recipient.admin
                                  ? "Member"
                                  : "Email"}
                              {recipient.primary && (
                                <Badge color="grey">Primary recipient</Badge>
                              )}
                            </SpaceBetween>
                          }
                        />
                        <GridItem
                          label={"Email"}
                          value={
                            recipient.provider?.email ??
                            recipient.admin?.email ??
                            recipient?.emailAddress ??
                            "-"
                          }
                        />
                        <GridItem
                          label={"Name"}
                          value={
                            !!recipient.provider
                              ? recipient.provider.firstName +
                                " " +
                                recipient.provider.lastName
                              : !!recipient.admin
                                ? recipient.admin.firstName +
                                  " " +
                                  recipient.admin.lastName
                                : "-"
                          }
                        />
                      </SpaceBetween>
                      <GridItem
                        label={"Action"}
                        value={
                          recipient.action === "sign" ? "Signer" : "Approver"
                        }
                      />
                      {index + 1 !== data.recipients.length && <Divider />}
                    </SpaceBetween>
                  );
                })}
              </SpaceBetween>
            </Container>
            <Container header={<Header>Message</Header>}>
              {!!data.message ? data.message : "-"}
            </Container>
          </SpaceBetween>
        }
      />
    );
}

const Details = ({
  eSignatureResponse,
}: {
  eSignatureResponse: eSignatureResponse;
}) => {
  return (
    <ColumnLayout columns={3} variant="text-grid">
      <SpaceBetween size="l">
        <div>
          <Box variant="awsui-key-label">ID</Box>
          <div>{eSignatureResponse.id}</div>
        </div>
        <div>
          <Box variant="awsui-key-label">File Name</Box>
          <div>{eSignatureResponse.attachment.name}</div>
        </div>
        <ProgressBar
          value={27} //TODO: get this from API
          label="Status"
          description={"Waiting for others"}
          variant="key-value"
          resultText="Available"
          status={"in-progress"}
        />
      </SpaceBetween>

      <SpaceBetween size="l">
        <div>
          <Box variant="awsui-key-label">Subject</Box>
          <div>{eSignatureResponse.subject}</div>
        </div>
        <div>
          <Box variant="awsui-key-label">Created Date</Box>
          <div>{ChangeTimeZone(eSignatureResponse.createdDate)}</div>
        </div>

        <div>
          <Box variant="awsui-key-label">Primary Recipient</Box>
          <RenderRecipient
            recipeint={eSignatureResponse.recipients?.find(
              (r) => r.primary === true,
            )}
          />
        </div>
      </SpaceBetween>

      <SpaceBetween size="l">
        <div>
          <Box variant="awsui-key-label">Sender</Box>
          <div>Ravi</div>
        </div>
        <div>
          <Box variant="awsui-key-label">Completed Date</Box>
          <div>{ChangeTimeZone(eSignatureResponse.completedDate) ?? "-"}</div>
        </div>
        <div>
          <Box variant="awsui-key-label">Asscoaited With</Box>
          <div>Payer</div>
        </div>
      </SpaceBetween>
    </ColumnLayout>
  );
};

const RenderRecipient = ({ recipeint }: { recipeint?: RecipientResponse }) => {
  if (!!recipeint)
    if (!!recipeint.provider)
      return (
        <div>
          <SpaceBetween size="xs" direction="horizontal">
            <Box variant="div">
              {recipeint.provider.firstName} {recipeint.provider.lastName}
            </Box>
          </SpaceBetween>
        </div>
      );
    else if (!!recipeint.admin)
      return (
        <div>
          <SpaceBetween size="xs" direction="horizontal">
            <Box variant="div">
              {recipeint.admin.firstName} {recipeint.admin.lastName}
            </Box>
          </SpaceBetween>
        </div>
      );
    else if (!!recipeint.emailAddress)
      return (
        <div>
          <SpaceBetween size="xs" direction="horizontal">
            <Box variant="div">{recipeint.emailAddress}</Box>
          </SpaceBetween>
        </div>
      );
    else return <div>-</div>;
  else return <div>-</div>;
};

function GridItem({
  label,
  value,
}: {
  label: string;
  value: string | React.ReactNode;
}) {
  return (
    <div>
      <Grid gridDefinition={[{ colspan: 1 }, { colspan: 11 }]} disableGutters>
        <Box variant="awsui-key-label">{label}:</Box>
        <span>{value}</span>
      </Grid>
    </div>
  );
}
