import { Header, Table, TableProps } from "@cloudscape-design/components";
import React, { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetAllPayerQuery } from "../redux/api/payers/payers";
import { Payers } from "../redux/api/payers/types";

interface PayerNavigationProps {
  navigateTo: (id: string) => string;
  onChange?: (selectedPayer?: Payers) => void;
}
export default function PayerNavigation({
  onChange,
  navigateTo,
}: PayerNavigationProps) {
  const { data = [] } = useGetAllPayerQuery();
  const navigate = useNavigate();
  const { id } = useParams();
  const selectedItem = useMemo(() => {
    const temp = data.find((payer) => "" + payer.id === "" + id);
    if (temp) return temp;
    else {
      if (data.at(0)?.id) navigate(navigateTo(`${data.at(0)?.id}`));
      return undefined;
    }
  }, [id, data]);

  useEffect(() => {
    onChange && onChange(selectedItem);
  }, [selectedItem, onChange]);

  const tableProps: TableProps<Payers> = React.useMemo(() => {
    return {
      header: <Header variant="h2">Payer Enrollments</Header>,
      variant: "container",
      selectionType: "single",
      resizableColumns: true,
      stickyHeader: true,
      wrapLines: true,
      selectedItems: selectedItem ? [selectedItem] : [],
      onSelectionChange: (selectionChangeDetail) => {
        const temp_payerId =
          selectionChangeDetail.detail.selectedItems.at(0)?.id;
        if (temp_payerId) navigate(navigateTo(`${temp_payerId}`));
      },
      onRowClick: (onRowClickDetail) => {
        const temp = onRowClickDetail.detail.item.id;
        if (temp) navigate(navigateTo(`${temp}`));
      },
      empty: <p>No results to display.</p>,
      trackBy: (item) => item.id,
      columnDefinitions: [
        {
          id: "id",
          header: <div>ID</div>,
          cell: (item) => <span>{item.id}</span>,
        },
        {
          id: "name",
          header: <div>Name</div>,
          cell: (item) => <span>{item.name}</span>,
        },
      ],
      visibleColumns: ["name"],
      items: data,
    };
  }, [id, selectedItem, data]);

  return <Table {...tableProps} />;
}
