import {
  Button,
  ButtonDropdown,
  Header,
  Pagination,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import { useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAsyncNotifyWrapper from "../hooks/useAsyncNotifyWrapper";
import { useLocalStorage } from "../common/localStorage";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";
import { TableEmptyState } from "../common/common-components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import StyledRouterLink from "../components/styled-route-link";
import {
  SAFECONTENT_CONTENT_DISPLAY_OPTIONS,
  SAFECONTENT_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../common/table-config";
import DeleteAlertModal from "../components/Modal/delete-alert-modal";
import ChangeTimeZone from "../components/Timezone";
import {
  useGetAllProviderSafeDocumentsQuery,
  useGetProviderQuery,
} from "../redux/api/provider/provider";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useDeleteDocumentMutation } from "../redux/api/document/document";
import Label from "../components/Label";
import { Document } from "../redux/api/document/types";
import LoadingScreen from "../components/LoadingScreen";

export default function ProviderSafeContent() {
  const navigate = useNavigate();
  const { providerId } = useParams();
  const {
    data = [],
    fulfilledTimeStamp,
    isLoading,
    isFetching,
    refetch,
  } = useGetAllProviderSafeDocumentsQuery(
    providerId ? { providerId } : skipToken,
  );
  const { data: provider } = useGetProviderQuery(
    providerId ? { providerId } : skipToken,
  );
  const [selectedDocument, setSelectedDocument] = useState<Document>();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const [deleteDocument] = useDeleteDocumentMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const documentId = selectedDocument?.id;

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("safe.edit") ?? false),
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("safe.delete") ?? false),
  );
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-SafeContent-Table-Preferences",
    SAFECONTENT_TABLE_DEFAULT_PREFERENCES,
  );
  const { items, collectionProps, paginationProps } = useCollection(data, {
    propertyFiltering: {
      filteringProperties: [],
      empty: <TableEmptyState resourceName="Safe Content" />,
    },
    sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
  });
  const tableProps: TableProps<Document> = useMemo(() => {
    return {
      items,
      variant: "container",
      stickyHeader: true,
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <TablePreferences
          preferences={tablePreferences}
          setPreferences={(preferences) => {
            setTablePreferences(preferences);
          }}
          contentDisplayOptions={SAFECONTENT_CONTENT_DISPLAY_OPTIONS}
        />
      ),
      columnDisplay: tablePreferences?.contentDisplay,
      wrapLines: tablePreferences?.wrapLines,
      stripedRows: tablePreferences?.stripedRows,
      contentDensity: tablePreferences?.contentDensity,
      stickyColumns: tablePreferences?.stickyColumns,
      ...collectionProps,
      selectionType: "single",
      loading: isLoading || isFetching,
      loadingText: "Loading...",
      selectedItems: selectedDocument ? [selectedDocument] : [],
      onSelectionChange: (selectionChangeDetail) => {
        if (selectionChangeDetail.detail.selectedItems.at(0))
          setSelectedDocument(selectionChangeDetail.detail.selectedItems.at(0));
      },
      onRowClick: (onRowClickDetail) => {
        if (onRowClickDetail.detail.item)
          setSelectedDocument(onRowClickDetail.detail.item);
      },
      trackBy: (item) => item.id,
      columnDefinitions: [
        {
          id: "documentName",
          header: <div>Document Name</div>,
          sortingField: "name",
          cell: (item) => (
            <StyledRouterLink
              className={hasPermission ? "edit_link" : "normal"}
              to={
                hasPermission ? `/safe/${providerId}/documents/${item.id}` : `#`
              }
              label={item?.name}
            />
          ),
        },
        {
          id: "categoryName",
          header: <div>Category Name</div>,
          cell: (item) => <span>{item?.category?.name}</span>,
          sortingField: "category.name",
          sortingComparator: (a, b) =>
            (a?.category?.name ?? "").localeCompare(b?.category?.name ?? ""),
        },
        {
          id: "categoryGroup",
          header: <div>Category Group</div>,
          cell: (item) => <span>{item?.category?.categoryGroup?.name}</span>,
          sortingField: "category.categoryGroup.name",
          sortingComparator: (a, b) =>
            (a.category.categoryGroup.name ?? "").localeCompare(
              b.category.categoryGroup.name ?? "",
            ),
        },
        {
          id: "expiration",
          header: <div>Expiration</div>,
          cell: (item) => <span>{ChangeTimeZone(item.expirationDate)}</span>,
          sortingField: "expirationDate",
        },
        {
          id: "alertDays",
          header: <div>Expiration Reminder</div>,
          sortingField: "alertDays",
          cell: (item) => (
            <span>
              {item.alertDays === -1
                ? "Not Required"
                : `${item.alertDays} days`}
            </span>
          ),
        },
        {
          id: "uploaded",
          header: <div>Uploaded</div>,
          sortingField: "createdDate",
          cell: (item) => <span>{ChangeTimeZone(item.createdDate)}</span>,
        },
      ],
      header: (
        <Header
          actions={
            <div>
              <span className="awsui-util-action-stripe-group">
                <Button
                  variant="normal"
                  iconName="refresh"
                  onClick={() => {
                    refetch();
                  }}
                ></Button>
              </span>
              <span className="awsui-util-action-stripe-group">
                <ButtonDropdown
                  disabled={!selectedDocument}
                  items={[
                    {
                      id: "edit_form",
                      text: hasPermission ? "Edit" : "View",
                    },
                    {
                      id: "delete_form",
                      text: "Delete",
                      disabled: !hasDeletePermission,
                    },
                  ]}
                  onItemClick={(itemClickDetails) => {
                    if (
                      itemClickDetails.detail.id === "delete_form" &&
                      providerId &&
                      documentId
                    )
                      OpenDeleteModal(true);
                    if (
                      itemClickDetails.detail.id === "edit_form" &&
                      providerId &&
                      documentId
                    )
                      navigate(`/safe/${providerId}/documents/${documentId}`);
                  }}
                >
                  Actions
                </ButtonDropdown>
              </span>
              <span>
                <Button
                  variant="primary"
                  onClick={() => {
                    navigate(`/safe/${providerId}/documents/addnew`);
                  }}
                >
                  Add New Document
                </Button>
              </span>
            </div>
          }
        >
          {provider?.firstName} {provider?.lastName}
        </Header>
      ),
    };
  }, [selectedDocument, data, items, fulfilledTimeStamp, tablePreferences]);
  return (
    <div>
      <LoadingScreen isOpen={isLoading} />
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedDocument?.id && providerId && documentId) {
            await notifyWrapper({
              promise: deleteDocument({
                providerId: providerId,
                documentId: documentId,
              }),
              resourceName: "document",
              actionName: "delete",
            });
          }
          OpenDeleteModal(false);
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={"Delete document"}
        content={
          <Label
            label="document name"
            value={<Header variant="h3">{selectedDocument?.name}</Header>}
          ></Label>
        }
        description={"Are you sure you want to delete this document?"}
      />
    </div>
  );
}
