import {
  Box,
  Button,
  Link,
  Modal,
  SpaceBetween,
  Table,
} from "@cloudscape-design/components";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetAllDocumentQuery } from "../../../redux/api/document/document";
import { AllDocumentResponse } from "../../../redux/api/document/types";
import {
  useGetAllCredentialPackageDocumentsQuery,
  useGetCredentialPackageQuery,
  useUpdateCredentialPackageDocumentsMutation,
} from "../../../redux/api/credentialpackages/credentialpackages";

type CredentailTemplateAddFormModalProps = {
  closeModal: VoidFunction;
  visible: boolean;
};
export default function CredentialPackageAddDocumentsModal({
  visible,
  closeModal,
}: CredentailTemplateAddFormModalProps) {
  const { data: documents = [] } = useGetAllDocumentQuery();
  const [selectedDocuments, setSelectedDocuments] = useState<
    AllDocumentResponse[]
  >([]);
  const { id: credentialPackageId } = useParams();
  const { data: credentialPackage } = useGetCredentialPackageQuery(
    credentialPackageId ?? skipToken,
  );
  const { data: packageDocuments = [] } =
    useGetAllCredentialPackageDocumentsQuery(
      credentialPackageId ? { credentialPackageId } : skipToken,
    );
  const packageDocumentsData = documents.filter(
    (doc) => doc.provider?.firstName === credentialPackage?.provider.firstName,
  );
  const [updateCredentialPackageDocuments] =
    useUpdateCredentialPackageDocumentsMutation();

  useEffect(() => {
    setSelectedDocuments([]);
  }, [closeModal]);

  const packageDocumentIdList = useMemo(() => {
    return packageDocuments.map((doc) => doc.id + "");
  }, [packageDocuments]);

  return (
    <Modal
      onDismiss={closeModal}
      size="large"
      visible={visible}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button onClick={closeModal}>Cancel</Button>
            <Button
              variant="primary"
              onClick={() => {
                if (
                  !selectedDocuments ||
                  !credentialPackageId ||
                  selectedDocuments?.length === 0
                ) {
                  closeModal();
                  return;
                }
                const documentIds = selectedDocuments.map((doc) => doc.id + "");
                updateCredentialPackageDocuments({
                  id: credentialPackageId,
                  documents: documentIds,
                });
                closeModal();
              }}
            >
              Save
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Link documents"
    >
      <Table
        variant="embedded"
        columnDefinitions={[
          {
            id: "name",
            header: "Name",
            cell: (item) => <div>{item?.name || "-"}</div>,
            sortingField: "name",
            isRowHeader: true,
          },
        ]}
        isItemDisabled={(item) =>
          packageDocumentIdList?.includes(item?.id + "")
        }
        items={packageDocumentsData}
        selectionType="multi"
        selectedItems={selectedDocuments ?? []}
        onSelectionChange={({ detail }) => {
          if (!!detail.selectedItems)
            setSelectedDocuments(detail.selectedItems);
        }}
        onRowClick={({ detail }) => {
          const temp = selectedDocuments;
          if (
            !detail.item ||
            packageDocumentIdList?.includes(detail.item.id + "")
          )
            return;
          if (temp?.includes(detail.item))
            setSelectedDocuments(temp.filter((item) => item !== detail.item));
          else setSelectedDocuments([detail.item, ...temp]);
        }}
        stickyHeader
        loadingText="Loading resources"
        sortingDisabled
      />
    </Modal>
  );
}
