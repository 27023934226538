import {
  Button,
  Header,
  Pagination,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import { useState } from "react";
import InnerAppLayout from "../components/InnerAppLayout";
import { useGetAllSignatureRequestsQuery } from "../redux/api/signaturerequest/signaturerequest";
import { eSignatureResponse } from "../redux/api/signaturerequest/types";
import ChangeTimeZone from "../components/Timezone";
import StyledRouterLink from "../components/styled-route-link";
import {
  ESIGNATURE_CONTENT_DISPLAY_OPTIONS,
  ESIGNATURE_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../common/table-config";
import { useLocalStorage } from "../common/localStorage";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableEmptyState } from "../common/common-components";
import { useTranslation } from "react-i18next";

export default function SignatureRequestsTable() {
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState<eSignatureResponse>();
  const {
    data = [],
    isFetching,
    isLoading,
    refetch,
  } = useGetAllSignatureRequestsQuery();

  const { items, collectionProps, paginationProps } = useCollection(data, {
    propertyFiltering: {
      filteringProperties: [],
      empty: <TableEmptyState resourceName="Privileges" />,
    },
    sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
  });

  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "e-Signatures-Table-Preferences",
    ESIGNATURE_TABLE_DEFAULT_PREFERENCES,
  );

  const tableProps: TableProps<eSignatureResponse> = {
    header: (
      <Header
        variant="h2"
        actions={
          <div>
            {" "}
            <span className="awsui-util-action-stripe-group">
              <Button
                variant="normal"
                iconName="refresh"
                onClick={() => {
                  refetch();
                }}
              ></Button>
            </span>
          </div>
        }
        counter={`(${data.length})`}
      >
        {t("eSignatures.header.label")}
      </Header>
    ),
    preferences: (
      <TablePreferences
        title="e-Signatures table Preferences"
        preferences={tablePreferences}
        setPreferences={(preferences) => {
          setTablePreferences(preferences);
        }}
        contentDisplayOptions={ESIGNATURE_CONTENT_DISPLAY_OPTIONS}
      />
    ),
    columnDisplay: tablePreferences?.contentDisplay,
    wrapLines: tablePreferences?.wrapLines,
    stripedRows: tablePreferences?.stripedRows,
    contentDensity: tablePreferences?.contentDensity,
    stickyColumns: tablePreferences?.stickyColumns,
    ...collectionProps,
    variant: "container",
    selectionType: "single",
    resizableColumns: false,
    stickyHeader: true,
    loading: isLoading || isFetching,
    loadingText: "Loading...",
    empty: <p>No results to display.</p>,
    selectedItems: selectedItem ? [selectedItem] : [],
    onSelectionChange: (selectionChangeDetail) => {
      if (
        selectionChangeDetail.detail.selectedItems &&
        selectionChangeDetail.detail.selectedItems.at(0)
      )
        setSelectedItem(selectionChangeDetail.detail.selectedItems.at(0));
    },
    onRowClick: (onRowClickDetail) => {
      setSelectedItem(onRowClickDetail.detail.item);
    },
    trackBy: (item) => item.id,
    columnDefinitions: [
      {
        id: "id",
        header: <div>{t("eSignatures.header.fields.id")}</div>,
        cell: (item) => <span>{item.id}</span>,
      },
      {
        id: "subject",
        header: <div>{t("eSignatures.header.fields.subject")}</div>,
        cell: (item) => (
          <StyledRouterLink
            className={"edit_link"}
            to={`/esignatures/${item.id}`}
            label={item.subject}
          />
        ),
      },
      {
        id: "created_date",
        header: <div>{t("eSignatures.header.fields.createdDate")}</div>,
        cell: (item) => <span>{ChangeTimeZone(item.createdDate)}</span>,
        sortingField: "createdDate",
      },
      {
        id: "status",
        header: <div>{t("eSignatures.header.fields.status")}</div>,
        cell: (item) => <span>pending</span>,
        sortingField: "status",
      },
      {
        id: "completed_date",
        header: <div>{t("eSignatures.header.fields.completedDate")}</div>,
        cell: (item) => <span>{ChangeTimeZone(item.completedDate)}</span>,
        sortingField: "completedDate",
      },
      {
        id: "attachment",
        header: <div>{t("eSignatures.header.fields.fileName")}</div>,
        cell: (item) => <span>{item?.attachment?.name}</span>,
      },
      {
        id: "recipients",
        header: <div>{t("eSignatures.header.fields.recipients")}</div>,
        cell: (item) => <span>{item?.recipients?.length}</span>,
      },
    ],
    items,
    pagination: <Pagination {...paginationProps} />,
  };

  return <InnerAppLayout content={<Table {...tableProps} />} />;
}
