import { FormProvider, useForm } from "react-hook-form";
import { ProviderEducationRequestType } from "../../../../redux/api/provider/section/types";
import {
  Box,
  BreadcrumbGroup,
  Button,
  Container,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import RHFTextField from "../../../../components/RHF/RHFTextField";
import FormSection from "../../../../components/FormSection";
import RHFDatePicker from "../../../../components/RHF/RHFDatePicker";
import { Divider } from "@mui/material";
import {
  useAddProviderEducationMutation,
  useGetAllDegreeQuery,
  useGetProviderQuery,
} from "../../../../redux/api/provider/provider";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import RHFFloatingSelect from "../../../../components/RHF/RHFFloatingSelect";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import RHFSelect from "../../../../components/RHF/RHFSelect";
import "./edit-education.css";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";
import DocumentSection from "../../document-section";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";

export default function EditEducation() {
  const { providerId, educationId } = useParams();
  const navigate = useNavigate();
  const methods = useForm<ProviderEducationRequestType>();
  const { data: degrees = [] } = useGetAllDegreeQuery();
  const [type] = methods.watch(["type"]);
  const [selectedDocument, setSelectedDocument] = useState<OptionDefinition>({
    label: "None - no document selected ",
    value: "-1",
  });

  useEffect(() => {
    methods.clearErrors("residencyDirector");
    methods.clearErrors("fellowshipDirector");
  }, [type]);

  const [addProviderEducation] = useAddProviderEducationMutation();

  const { notifyWrapper } = useAsyncNotifyWrapper();
  const onSubmit = async (data: ProviderEducationRequestType) => {
    if (!!providerId) {
      await notifyWrapper({
        promise: addProviderEducation({
          providerId: providerId,
          ...data,
          ...(selectedDocument.value !== "-1" && {
            documentId: selectedDocument.value,
          }),
        }),
        resourceName: "education",
        actionName: "upload",
      });
      navigate(-1);
    }
  };

  const { data: provider } = useGetProviderQuery(
    providerId ? { providerId } : skipToken,
  );
  useEffect(() => {
    if (provider && provider.providerEducations) {
      const education = provider.providerEducations.find(
        (education) => education?.id + "" === educationId + "",
      );

      if (education) {
        methods.setValue("id", education.id);
        methods.setValue("type", education.type);
        methods.setValue("name", education.name);
        methods.setValue("startDate", education.startDate);
        methods.setValue("endDate", education.endDate);
        methods.setValue("isCurrent", education.isCurrent);
        education.degree?.id &&
          methods.setValue("degreeId", education.degree?.id);
        methods.setValue("email", education.email);
        methods.setValue("phone", education.phone);
        methods.setValue("fax", education.fax);
        methods.setValue("addressLane1", education.addressLane1);
        methods.setValue("addressLane2", education.addressLane2);
        methods.setValue("city", education.city);
        methods.setValue("state", education.state);
        methods.setValue("zip", education.zip);
        methods.setValue("country", education.country);
        methods.setValue("residencyDirector", education.residencyDirector);
        methods.setValue("fellowshipDirector", education.fellowshipDirector);
        education?.document &&
          setSelectedDocument({
            label: education?.document?.name,
            value: education?.document?.id,
          });
      }
    }
  }, [provider, educationId]);

  return (
    <SpaceBetween size="s">
      <BreadcrumbGroup
        items={[
          { text: "Providers", href: `/manageprovider/${providerId}` },
          {
            text: provider?.firstName + " " + provider?.lastName,
            href: `/manageprovider/${providerId}?activeTabId=education`,
          },
          {
            text: "education",
            href: "#",
          },
        ]}
      />
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                formAction="none"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </Button>
              <Button variant="primary">Save</Button>
            </SpaceBetween>
          }
        >
          <FormProvider {...methods}>
            <SpaceBetween size="l">
              <Container
                header={
                  <SpaceBetween size="xs">
                    <Header variant="h2">Education details</Header>
                    <Divider />
                  </SpaceBetween>
                }
              >
                <SpaceBetween size="l">
                  <FormSection columns={1} spacing="l">
                    <RHFSelect
                      name="type"
                      label="Type"
                      stretch={false}
                      options={[
                        "Undergraduate",
                        "Graduate",
                        "Medical school",
                        "Internship",
                        "Residency",
                        "Fellowship",
                      ].map((option) => ({
                        label: option,
                        value: option,
                      }))}
                      rules={{ required: "This field is required" }}
                    />
                    <RHFTextField
                      label="Name"
                      name="name"
                      description="Name of the educational insitute or board"
                      stretch={false}
                      rules={{ required: "This field is required" }}
                    />
                    <RHFFloatingSelect
                      name="degreeId"
                      label="Degree"
                      stretch={false}
                      options={degrees.map((degree) => ({
                        value: degree.id,
                        label: degree.name,
                      }))}
                      enableFiltering
                      rules={{ required: "This field is required" }}
                    />
                    <FormSection columns={3}>
                      <RHFDatePicker
                        label="Start Date"
                        name="startDate"
                        stretch={false}
                      />
                      <RHFDatePicker
                        label="End Date"
                        name="providerEducation.endDate"
                        stretch={false}
                      />
                    </FormSection>
                    {type === "Residency" && (
                      <RHFTextField
                        label="Residency Director"
                        name="residencyDirector"
                        rules={
                          type === "Residency"
                            ? { required: "This field is required" }
                            : { required: false }
                        }
                        stretch={false}
                      />
                    )}
                    {type === "Fellowship" && (
                      <RHFTextField
                        label="Fellowship Director"
                        name="fellowshipDirector"
                        rules={
                          type === "Fellowship"
                            ? { required: "This field is required" }
                            : { required: false }
                        }
                        stretch={false}
                      />
                    )}
                  </FormSection>
                  <FormSection
                    header={
                      <Box fontSize="heading-s" variant="h5">
                        Contact details
                      </Box>
                    }
                    headerDividerTop
                  >
                    <RHFTextField label="Email" name="email" stretch={false} />
                    <FormSection columns={3}>
                      <RHFTextField
                        label="Phone"
                        name="phone"
                        stretch={false}
                        rules={{
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Please enter a number",
                          },
                        }}
                      />
                      <RHFTextField
                        label="Fax"
                        name="fax"
                        rules={{
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Please enter a number",
                          },
                        }}
                        stretch={false}
                      />
                    </FormSection>
                    <RHFTextField
                      label="Address Lane1"
                      name="addressLane1"
                      stretch={false}
                    />
                    <RHFTextField
                      label="Address Lane2"
                      name="addressLane2"
                      stretch={false}
                    />
                    <FormSection columns={3}>
                      <RHFTextField label="City" name="city" stretch={false} />
                      <RHFTextField
                        label="Zip Code"
                        name="zipcode"
                        stretch={false}
                      />
                    </FormSection>
                    <FormSection columns={3}>
                      <RHFTextField
                        label="State"
                        name="state"
                        stretch={false}
                      />

                      <RHFTextField
                        label="Country"
                        name="country"
                        stretch={false}
                      />
                    </FormSection>
                  </FormSection>
                  <DocumentSection
                    providerId={providerId}
                    selectedDocument={selectedDocument}
                    setSelectedDocument={setSelectedDocument}
                  />
                </SpaceBetween>
              </Container>
            </SpaceBetween>
          </FormProvider>
        </Form>
      </form>
    </SpaceBetween>
  );
}
