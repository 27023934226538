import {
  Button,
  Header,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { setModalName } from "../../../redux/UI/actions";
import useAsyncNotifyWrapper from "../../../hooks/useAsyncNotifyWrapper";
import {
  useGetCredentialTemplateQuery,
  useUpdateCredentialTemplateMutation,
} from "../../../redux/api/credentialpackages/credentialpackages";
import { CredentialTemplateRequest } from "../../../redux/api/credentialpackages/types";
import { FormProvider, useForm } from "react-hook-form";
import FormSection from "../../../components/FormSection";
import RHFTextField from "../../../components/RHF/RHFTextField";
import RHFSelect from "../../../components/RHF/RHFSelect";
import { useGetAllLicenseTypesQuery } from "../../../redux/api/provider/provider";
import { useNavigate, useParams } from "react-router";
import { Grid } from "@mui/material";
import TransferList from "../../../components/TransferList";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetAllCategoryQuery } from "../../../redux/api/managecategory/managecategory";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

function EditCredentialTemplateModal({ setModalName }: PropsFromDispatch) {
  const methods = useForm<CredentialTemplateRequest>();
  const { id: credentialTemplateId } = useParams();
  const {
    data: credentialTemplate,
    fulfilledTimeStamp,
    isLoading,
  } = useGetCredentialTemplateQuery(
    credentialTemplateId ? { credentialTemplateId } : skipToken,
  );
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const { data: licenses = [] } = useGetAllLicenseTypesQuery();

  const [updateCredentialTemplate] = useUpdateCredentialTemplateMutation();

  const navigate = useNavigate();

  const onSubmit = async (data: CredentialTemplateRequest) => {
    if (!!credentialTemplateId) {
      await notifyWrapper({
        promise: updateCredentialTemplate({
          id: credentialTemplateId,
          ...data,
        }),
        resourceName: "Credential template",
        actionName: "create",
      });
      navigate(-1);
    }
  };

  useEffect(() => {
    if (credentialTemplate) {
      methods.setValue("name", credentialTemplate?.name);
      methods.setValue("licenseTypeId", credentialTemplate?.licenseType?.id);
    }
  }, [fulfilledTimeStamp, methods, credentialTemplate]);
  return (
    <Modal
      header={<Header>Edit Template</Header>}
      visible={true}
      onDismiss={() => {
        setModalName("");
      }}
    >
      <SpaceBetween size={"s"}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormSection>
              <RHFTextField
                label="Name"
                name="name"
                stretch={false}
                rules={{ required: "This field is required" }}
              />
              <FormSection>
                <RHFSelect
                  name="licenseTypeId"
                  label="License Type"
                  options={licenses.map((license) => {
                    return {
                      value: license.id,
                      label: license.name,
                    };
                  })}
                  stretch={false}
                />
              </FormSection>
            </FormSection>
          </form>
        </FormProvider>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              formAction="none"
              onClick={() => {
                setModalName("");
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button formAction="submit" variant="primary">
              Save
            </Button>
          </Grid>
        </Grid>
      </SpaceBetween>
    </Modal>
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(EditCredentialTemplateModal);
