import { FileUpload, Modal } from "@cloudscape-design/components";
import Papa from "papaparse";
import "./UploadNewFile.css";
import { useEffect, useState } from "react";

type UploadNewFileProps = {
  setData: (data: any[]) => void;
  visible: boolean;
  setVisible: (visible: boolean) => void;
};

function UploadCSV({ visible, setData, setVisible }: UploadNewFileProps) {
  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    if (!files || !files?.at(0)) return;
    const selectedFile = files?.at(0);
    if (!!selectedFile)
      Papa.parse(selectedFile, {
        complete: (results) => {
          setData(results.data);
        },
      });
    setVisible(false);
  }, [files]);

  return (
    <Modal
      header={"Upload CSV File"}
      visible={visible}
      onDismiss={() => setVisible(false)}
    >
      <div>
        <FileUpload
          onChange={({ detail }) => setFiles(detail.value)}
          value={files}
          accept=".csv,.xls,.xlsx,.xlr"
          i18nStrings={{
            uploadButtonText: (e) => (e ? "Choose files" : "Choose file"),
            dropzoneText: (e) =>
              e ? "Drop files to upload" : "Drop file to upload",
            removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
            limitShowFewer: "Show fewer files",
            limitShowMore: "Show more files",
            errorIconAriaLabel: "Error",
          }}
          multiple={false}
          showFileLastModified
          showFileSize
          showFileThumbnail
          tokenLimit={3}
          constraintText="Accepted file types are csv, .xls, .xlsx, .xlr"
        />
      </div>
    </Modal>
  );
}
export default UploadCSV;
