import { Textarea, TextareaProps } from "@cloudscape-design/components";
import FormField, {
  FormFieldProps,
} from "@cloudscape-design/components/form-field";
import { useFormContext, Controller } from "react-hook-form";

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  rules?: { required: string; validate?: any };
  type?: TextareaProps;
  disabled?: boolean;
  placeholder?: string;
  testid?: string;
};

type Props = IProps & FormFieldProps;

export default function RHFTextArea({
  info,
  name,
  rules,
  label,
  placeholder,
  errorText,
  description,
  stretch,
  disabled,
  testid
}: Props) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <FormField
            label={
              <span>
                {label}{" "}
                {rules?.required && <span style={{ color: "#fc0a37" }}>*</span>}
              </span>
            }
            description={description}
            info={info}
            errorText={errorText ?? fieldState.error?.message}
            stretch={stretch ?? true}
          >
            <Textarea
              data-testid={testid}
              value={field.value}
              onChange={(changeDetail) =>
                field.onChange(changeDetail.detail.value)
              }
              disabled={disabled}
              placeholder={placeholder}
            />
          </FormField>
        );
      }}
      rules={rules}
    />
  );
}
