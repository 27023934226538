import {
  Button,
  Header,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import Grid from "@mui/material/Grid";
import { FormProvider, useForm } from "react-hook-form";
import { connect } from "react-redux";
import {
  useCreateCategoryMutation,
  useGetAllCategoryGroupQuery,
} from "../../../../redux/api/managecategory/managecategory";
import { setModalName } from "../../../../redux/UI/actions";
import RHFTextField from "../../../../components/RHF/RHFTextField";
import RHFTextArea from "../../../../components/RHF/RHFTextArea";
import RHFSelect from "../../../../components/RHF/RHFSelect";
import RHFCheckBox from "../../../../components/RHF/RHFCheckBox";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}
type FormInputType = {
  name: string;
  description: string;
  stateRequired: boolean;
  expirationRequired: boolean;
  categoryGroup: string;
};

function CategoryCreateModal({ setModalName }: PropsFromDispatch) {
  const methods = useForm<FormInputType>();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const onSubmit = async (data: FormInputType) => {
    if (data.categoryGroup)
      await notifyWrapper({
        promise: createCategory({
          name: data.name,
          description: data.description,
          stateRequired: data.stateRequired,
          expirationRequired: data.expirationRequired,
          categoryGroup: {
            id: data.categoryGroup,
          },
        }),
        resourceName: "category",
        actionName: "add",
      });
    setModalName("");
  };

  const [createCategory, { isLoading: isCreating }] =
    useCreateCategoryMutation();
  const { data = [] } = useGetAllCategoryGroupQuery();
  const categoryGroup = data.map((group) => {
    return { label: group.name, value: group.id };
  });

  return (
    <Modal
      visible={true}
      size="large"
      header={<Header variant="h2">Create Category</Header>}
      onDismiss={() => {
        setModalName("");
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <SpaceBetween direction="vertical" size="s">
            <RHFTextField
              label="Name"
              name="name"
              stretch
              rules={{ required: "This field is required" }}
            />

            <RHFTextArea label="Description" name="description" stretch />
            <RHFSelect
              name="categoryGroup"
              label="Category Group"
              stretch={true}
              options={categoryGroup}
              rules={{ required: "This field is required" }}
            />
            <RHFCheckBox label="State Required" name="stateRequired" />
            <RHFCheckBox
              label="Expiration Required"
              name="expirationRequired"
            />

            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  onClick={() => {
                    setModalName("");
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button variant="primary">Create</Button>
              </Grid>
            </Grid>
          </SpaceBetween>
        </form>
      </FormProvider>
    </Modal>
  );
}

const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(CategoryCreateModal);
