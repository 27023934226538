import {
  Button,
  Header,
  Link,
  SpaceBetween,
  Table,
} from "@cloudscape-design/components";
import { useNavigate } from "react-router-dom";

export default function ReportsList() {
  const navigate = useNavigate();
  return (
    <Table
      columnDefinitions={[
        {
          id: "id",
          header: "ID",
          cell: (item) => item.id,
        },
        {
          id: "name",
          header: "Report Name",
          cell: (item) => (
            <SpaceBetween size={"xxs"}>
              <Link href={`reports/${item.href}`}>
                <span style={{ fontSize: 20 }}>{item.name}</span>
              </Link>
              <span>{item.description}</span>
            </SpaceBetween>
          ),
        },
        {
          id: "actions",
          header: "Actions",
          cell: (item) => (
            <SpaceBetween size="xs" direction="horizontal">
              <Button
                variant="primary"
                onClick={() => {
                  navigate(`/${item.href}`);
                }}
              >
                View
              </Button>
              <Button>Schedule</Button>
            </SpaceBetween>
          ),
        },
      ]}
      items={[
        {
          name: "Expired Documents Report",
          description: "Comprehensive list of all expired documents",
          href: "expireddocuments",
          id: "1",
        },
        {
          name: "Expiring Documents Report",
          description:
            "A detailed report on documents approaching their expiration date.",
          href: "expiringdocuments",
          id: "2",
        },
      ]}
      visibleColumns={["name", "actions"]}
      loadingText="Loading resources"
      sortingDisabled
      header={<Header> Reports </Header>}
    />
  );
}
