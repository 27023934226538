import {
  BreadcrumbGroup,
  Container,
  Header,
} from "@cloudscape-design/components";
import { useNavigate, useParams } from "react-router-dom";
import InnerAppLayout from "../components/InnerAppLayout";
import PayerNavigation from "./payer-nav";
import { Payers } from "../redux/api/payers/types";
import { useState } from "react";
import PayerEnrollments from "./payer-enrollment";

export const NoPayerSelected = () => (
  <div>
    <Header variant="h2">Select a Payer</Header>
  </div>
);

export default function PayerTable() {
  const [selectedItem, setSelectedItem] = useState<Payers>();
  const { id } = useParams();
  return (
    <InnerAppLayout
      breadcrumbGroup={
        <BreadcrumbGroup
          items={
            selectedItem
              ? [
                  { text: "Payer Enrollment", href: "#" },
                  {
                    text: selectedItem.name,
                    href: "#",
                  },
                ]
              : [{ text: "Payer Enrollment", href: "#" }]
          }
        />
      }
      leftPanel={
        <PayerNavigation
          onChange={(selectedPayer) => {
            setSelectedItem(selectedPayer);
          }}
          navigateTo={(id: string) => `/payerenrollment/${id}`}
        />
      }
      content={!!id ? <PayerEnrollments /> : <NoPayerSelected />}
    />
  );
}
