import {
  Header,
  Table,
  TableProps,
  Button,
  Pagination,
} from "@cloudscape-design/components";
import "./index.css";
import { useState, useEffect, useMemo } from "react";
import { ApplicationState } from "../../../../redux/store";
import { InvitationType } from "../../../../redux/api/manageadmin/types";
import {
  useDeleteInviteMutation,
  useGetAllInvitedQuery,
} from "../../../../redux/api/manageadmin/manageadmin";
import { useSelector } from "react-redux";
import DeleteAlertModal from "../../../../components/Modal/delete-alert-modal";
import Label from "../../../../components/Label";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";
import {
  MANAGEADMININVITED_CONTENT_DISPLAY_OPTIONS,
  MANAGEADMININVITED_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../../../../common/table-config";
import { useLocalStorage } from "../../../../common/localStorage";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableEmptyState } from "../../../../common/common-components";
import LoadingScreen from "../../../../components/LoadingScreen";

export default function InvitedTable() {
  const [selectedInvited, setSelectInvited] = useState<InvitationType>();
  const {
    data = [],
    isFetching,
    isLoading,
    refetch,
    fulfilledTimeStamp,
  } = useGetAllInvitedQuery();
  const [deleteInvitedMember] = useDeleteInviteMutation();

  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Invited-Table-Preferences",
    MANAGEADMININVITED_TABLE_DEFAULT_PREFERENCES,
  );
  const { items, collectionProps, paginationProps } = useCollection(data, {
    propertyFiltering: {
      filteringProperties: [],
      empty: <TableEmptyState resourceName="Invited" />,
    },
  });

  useEffect(() => {
    if (!selectedInvited) return;
    let filterSelected = data.filter(
      (invited) => invited.id === selectedInvited?.id,
    );
    if (filterSelected.length) setSelectInvited(filterSelected.at(0));
  }, [fulfilledTimeStamp]);
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("member.delete") ?? false),
  );

  const tableProps: TableProps<InvitationType> = useMemo(() => {
    return {
      header: (
        <Header
          counter={`(${data.length})`}
          actions={
            <div>
              <span className="awsui-util-action-stripe-group">
                <Button
                  variant="normal"
                  iconName="refresh"
                  onClick={() => {
                    refetch();
                  }}
                ></Button>
              </span>
              <span className="awsui-util-action-stripe-group">
                <Button
                  onClick={() => {
                    if (selectedInvited) {
                      const invitedId = selectedInvited.id;
                      if (invitedId !== undefined) {
                        OpenDeleteModal(true);
                      }
                    }
                  }}
                  disabled={!(hasDeletePermission && selectedInvited)}
                >
                  Delete
                </Button>
              </span>
            </div>
          }
        >
          Invitations
        </Header>
      ),
      items,
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <TablePreferences
          preferences={tablePreferences}
          setPreferences={(preferences) => {
            setTablePreferences(preferences);
          }}
          contentDisplayOptions={MANAGEADMININVITED_CONTENT_DISPLAY_OPTIONS}
        />
      ),
      columnDisplay: tablePreferences?.contentDisplay,
      wrapLines: tablePreferences?.wrapLines,
      stripedRows: tablePreferences?.stripedRows,
      contentDensity: tablePreferences?.contentDensity,
      stickyColumns: tablePreferences?.stickyColumns,
      ...collectionProps,
      variant: "container",
      selectionType: "single",
      resizableColumns: true,
      stickyHeader: true,
      loading: isLoading || isFetching,
      loadingText: "Loading...",
      empty: <p>No results to display.</p>,
      selectedItems: selectedInvited ? [selectedInvited] : [],
      onSelectionChange: (selectionChangeDetail) => {
        setSelectInvited(selectionChangeDetail.detail.selectedItems.at(0));
      },
      onRowClick: (onRowClickDetail) => {
        setSelectInvited(onRowClickDetail.detail.item);
      },
      trackBy: (item) => "" + item.id,
      columnDefinitions: [
        {
          id: "id",
          header: <div>ID</div>,
          cell: (item) => <span>{item.id}</span>,
        },
        {
          id: "email",
          header: <div>Email</div>,
          cell: (item) => <span>{item.email}</span>,
        },
        {
          id: "role",
          header: <div>Role</div>,
          cell: (item) => <span>{item.role}</span>,
        },
        {
          id: "inviterName",
          header: <div>Inviter Name</div>,
          cell: (item) => <span>{item.inviter_name}</span>,
        },
        {
          id: "inviterEmail",
          header: <div>Inviter Email</div>,
          cell: (item) => <span>{item.inviter_email}</span>,
        },
        {
          id: "status",
          header: <div>Status</div>,
          cell: (item) => <span>{item.status}</span>,
        },
      ],
    };
  }, [tablePreferences, items, selectedInvited, data, isLoading]);

  return (
    <div style={{ paddingTop: "5px" }}>
      <LoadingScreen isOpen={isLoading} />
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedInvited?.id)
            await notifyWrapper({
              promise: deleteInvitedMember({ invitedId: selectedInvited?.id }),
              resourceName: "Invite",
              actionName: "delete",
            });
          OpenDeleteModal(false);
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={"Delete invitation"}
        content={
          <Label
            label="Invitation Email"
            value={<Header variant="h3">{selectedInvited?.email}</Header>}
          ></Label>
        }
        description={"Are you sure you want to delete this invitation?"}
      />
    </div>
  );
}
