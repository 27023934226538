import { DigitalForm } from "../api/digitalform/types";

export enum DigitalformActionTypes {
  SELECT_DIGITALFORM = "digitalform/select-form",
  ADD_FILTER_TAG = "digitalform/add-filter-tag",
  REMOVE_FILTER_TAG = "digitalform/remove-filter-tag",
  REMOVE_ALL_FILTER_TAG = "digitalform/remove-all-filter-tag",
}
export interface DigitalFormState {
  selectedForms: DigitalForm[];
  filterTags: Tag[];
}
export interface Tag {
  key: string;
  value: string;
}
