import {
  AppLayout,
  BreadcrumbGroup,
  FlashbarProps,
  Header,
} from "@cloudscape-design/components";
import "./index.css";
import ProviderDetail from "./provider-profile/provider-details";
import { Provider } from "../redux/api/provider/types";
import { useState } from "react";
import InnerAppLayout from "../components/InnerAppLayout";
import TopHeaderBar from "../components/TopHeaderBar";
import { dismissFlashBarItem } from "../redux/UI/actions";
import SideBar from "../layout/side-bar";
import { connect } from "react-redux";
import { ApplicationState } from "../redux/store";
import ProviderSafeNavigation from "../providersafe/provider-Safe-Navigation";

export const NoProviderSelected = () => (
  <div id="no-provider-selected">
    <Header variant="h2">Select a Provider</Header>
  </div>
);

interface PropsFromState {
  flashBarItems: FlashbarProps.MessageDefinition[];
}
interface PropsFromDispatch {
  dismissFlashBarItem: typeof dismissFlashBarItem;
}

function ProviderProfileTable({
  flashBarItems,
  dismissFlashBarItem,
}: PropsFromState & PropsFromDispatch) {
  const [selectedItem, setSelectedItem] = useState<Provider>();
  const [navOpen, setNavOpen] = useState<boolean>(false);

  return (
    <>
      <div className="custom-main-header" id="h">
        <TopHeaderBar />
      </div>

      <AppLayout
        contentType="default"
        navigation={<SideBar activeHref="" />}
        navigationOpen={navOpen}
        onNavigationChange={(changeDetails) => {
          setNavOpen(changeDetails.detail.open);
        }}
        navigationWidth={220}
        content={
          <InnerAppLayout
            breadcrumbGroup={
              <BreadcrumbGroup
                items={
                  selectedItem
                    ? [
                        { text: "Providers", href: "/providers" },
                        {
                          text:
                            selectedItem.firstName +
                            " " +
                            selectedItem.lastName,
                          href: "#",
                        },
                      ]
                    : [{ text: "Provider Safe", href: "#" }]
                }
              />
            }
            leftPanel={
              <ProviderSafeNavigation
                onChange={(selectedProvider) => {
                  setSelectedItem(selectedProvider);
                }}
                navigateTo={(providerId: string) => `/providers/${providerId}`}
              />
            }
            content={<ProviderDetail />}
          />
        }
        toolsHide={true}
        // splitPanel={
        //   <SplitPanel header={"Document details"}>
        //     <ProviderSafeDetails
        //       document={{
        //         id: "",
        //         name: "",
        //         expirationDate: "",
        //         createdDate: "",
        //         alertDays: 0,
        //         notes: "",
        //         attachment: {
        //           id: "",
        //           name: "",
        //           size: 0,
        //           contentType: "",
        //           contentMD5Hash: undefined,
        //           key: "",
        //           file: undefined,
        //         },
        //         category: {
        //           id: 0,
        //           name: "",
        //           description: "",
        //           stateRequired: false,
        //           expirationRequired: false,
        //           deleted: false,
        //           categoryGroup: {
        //             id: "",
        //             name: "",
        //           },
        //         },
        //       }}
        //     />
        //   </SplitPanel>
        // }
      />
    </>
  );
}

const mapDispatchToProps = {
  dismissFlashBarItem: dismissFlashBarItem,
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    flashBarItems: state.ui.flashBarItems,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProviderProfileTable);
