import {
  Button,
  Header,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import Grid from "@mui/material/Grid";
import { useEffect } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../redux/store";
import { setModalName } from "../../redux/UI/actions";
import {
  useGetDigitalFormQuery,
  useUpdateDigitalFormMutation,
} from "../../redux/api/digitalform/digitalforms";
import { useGetAllCategoryGroupQuery } from "../../redux/api/managecategory/managecategory";
import RHFTextField from "../../components/RHF/RHFTextField";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import RHFSelect from "../../components/RHF/RHFSelect";
import RHFCheckBox from "../../components/RHF/RHFCheckBox";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import { skipToken } from "@reduxjs/toolkit/dist/query";

interface PropsFromDispatch {
  openModalName: typeof setModalName;
}

type FormInputType = {
  name: string;
  description: string;
  category: string;
  disabled: boolean;
};
function DigitalFormEditModal({ openModalName }: PropsFromDispatch) {
  const methods = useForm<FormInputType>();
  const { id } = useParams();
  const { data = [] } = useGetAllCategoryGroupQuery();
  const { data: form } = useGetDigitalFormQuery(id ?? skipToken);
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("templates.edit") ?? false),
  );

  const [updateDigitalForm] = useUpdateDigitalFormMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  useEffect(() => {
    if (form) {
      methods.setValue("name", form.name);
      methods.setValue("description", form.description);
      methods.setValue("disabled", form.disabled);
      if (form.category && form.category.id) {
        methods.setValue("category", "" + form.category.id);
      }
    }
  }, [form]);

  const onSubmit = async (data: FormInputType) => {
    if (id) {
      await notifyWrapper({
        promise: updateDigitalForm({
          id: id,
          name: data.name,
          description: data.description,
          categoryId: data.category,
          disabled: data.disabled,
        }),
        resourceName: "readyform",
        actionName: "update",
      });
      openModalName("");
    }
  };

  return (
    <Modal
      visible={true}
      size={"medium"}
      header={<Header variant="h2">Edit Form</Header>}
      onDismiss={() => {
        openModalName("");
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <SpaceBetween direction="vertical" size="l">
            <RHFTextField
              label="Ready Form Name"
              name="name"
              stretch
              rules={{ required: "This field is required" }}
              readOnly={!hasPermission}
            />

            <RHFTextArea
              label="Description"
              name="description"
              stretch
              disabled={!hasPermission}
            />

            <RHFSelect
              name="category"
              label="Category"
              stretch={true}
              disabled={!hasPermission}
              optionGroups={data.map((group) => {
                return {
                  label: group.name,
                  options: group.categoryList.map((category) => {
                    return {
                      label: category.name,
                      value: "" + category.id,
                    };
                  }),
                };
              })}
              rules={{ required: "This field is required" }}
            />
            <RHFCheckBox
              label="Disabled"
              name="disabled"
              disabled={!hasPermission}
            />
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  formAction="none"
                  onClick={() => {
                    openModalName("");
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button disabled={!hasPermission} variant="primary">
                  Save
                </Button>
              </Grid>
            </Grid>
          </SpaceBetween>
        </form>
      </FormProvider>
    </Modal>
  );
}
const mapDispatchToProps = {
  openModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(DigitalFormEditModal);
