//process.env.REACT_APP_ENV=dev is set only when npm run start-local is used

// set host api based on environment when REACT_APP_ENV = dev set to "https://api.dev.intivahealth.com" when REACT_APP_ENV = local set locahost else set windwos.localhost

let api_url;

if (process.env.REACT_APP_ENV === "dev") {
  api_url = "https://dev.intivahealth.com";
} else if (process.env.REACT_APP_ENV === "local") {
  api_url = "http://localhost:8080";
}

export const HOST_API = api_url ?? `https://${window.location.hostname}`;
export const File_URL = `https://${window.location.hostname}/file/`;
export const Signing_HOST_API =
  api_url ?? `https://${window.location.hostname}/api/signing`;
export const Signing_File_URL = `https://${window.location.hostname}/signing/file/`;
