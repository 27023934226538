import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { Core, WebViewerInstance } from "@pdftron/webviewer";
import { FontType } from "../../template-designer/types";
import { useState } from "react";
import { defaultFont } from "../../template-designer/temaplet-designer";
import TemplateDesignerAnnotationFormatter from "../../template-designer/template-designer-annotation-formatter";
import { RecipientType } from "../../redux/api/signaturerequest/types";
import { AnnotationColor } from "../types";
import { CircleIcon } from "../../components/circle-icon";

export function SignatureRequestFieldFormatter({
  instance,
  selectedAnnots,
  setSelectedAnnots,
  primaryRecipient,
  addlRecipients,
  setRecipientsOnAnnot,
  setAnnotStrokeAndFillColor,
}: {
  instance: WebViewerInstance | undefined;
  setRecipientsOnAnnot: ({
    textAnnot,
    option,
  }: {
    textAnnot: Core.Annotations.FreeTextAnnotation;
    option?: OptionDefinition;
  }) => void;
  setAnnotStrokeAndFillColor: ({
    instance,
    textAnnot,
    recipients,
  }: {
    instance: WebViewerInstance;
    textAnnot: Core.Annotations.FreeTextAnnotation;
    recipients: RecipientType[];
  }) => void;
  selectedAnnots: Core.Annotations.FreeTextAnnotation[];
  setSelectedAnnots: React.Dispatch<
    React.SetStateAction<Core.Annotations.FreeTextAnnotation[]>
  >;
  primaryRecipient: RecipientType;
  addlRecipients: RecipientType[];
}) {
  const [customFont, setCustomFont] = useState<FontType>(defaultFont);

  const deselectAllAnnotations = () => {
    if (!instance) return;

    const { documentViewer } = instance.Core;
    const annotationManager = documentViewer.getAnnotationManager();

    annotationManager.deselectAllAnnotations();
    setSelectedAnnots([]);
  };

  const onNameChanged = (name: string) => {
    if (!instance) return;

    const { documentViewer } = instance.Core;
    const annotManager = documentViewer.getAnnotationManager();
    selectedAnnots.forEach((annot) => {
      annot.setCustomData("name", name);
      annot.setContents(
        [name, annot.getCustomData("index")]
          .filter((item) => item !== "")
          .join("_"),
      );
      annotManager.redrawAnnotation(annot);
    });
  };

  const onIndexChanged = (index: string) => {
    if (!instance) return;

    const { documentViewer } = instance.Core;
    const annotManager = documentViewer.getAnnotationManager();
    selectedAnnots.forEach((annot) => {
      annot.setCustomData("index", index);
      annot.setContents([annot.getCustomData("name"), index].join("_"));

      annotManager.redrawAnnotation(annot);
    });
  };

  const onFontFamilyChanged = (fontFamily: string) => {
    if (!instance) return;

    setCustomFont({ ...customFont, fontFamily: fontFamily });
    const { documentViewer } = instance.Core;
    const annotManager = documentViewer.getAnnotationManager();
    selectedAnnots.forEach((annot) => {
      annot.Font = fontFamily;
      annotManager.redrawAnnotation(annot);
    });
  };

  const onFontSizeChanged = (fontSize: string) => {
    if (!instance) return;
    setCustomFont({ ...customFont, fontSize: fontSize });
    const { documentViewer } = instance.Core;
    const annotManager = documentViewer.getAnnotationManager();
    selectedAnnots.forEach((annot) => {
      annot.FontSize = "" + fontSize + "pt";
      annotManager.redrawAnnotation(annot);
    });
  };

  const onRecipientChanged = (option: OptionDefinition) => {
    if (!instance) return;
    const { documentViewer } = instance.Core;
    const annotManager = documentViewer.getAnnotationManager();
    selectedAnnots.forEach((annot) => {
      setRecipientsOnAnnot({ textAnnot: annot, option: option });
      annotManager.redrawAnnotation(annot);
    });
  };

  const onRequiredChanged = (isRequired: boolean) => {
    if (!instance) return;
    const { documentViewer } = instance.Core;
    const annotManager = documentViewer.getAnnotationManager();

    selectedAnnots.forEach((annot) => {
      annot.setCustomData("isRequired", isRequired ? "true" : "");
      setAnnotStrokeAndFillColor({
        instance,
        textAnnot: annot,
        recipients: [...addlRecipients, primaryRecipient],
      });
      annotManager.redrawAnnotation(annot);
    });
  };

  return (
    <TemplateDesignerAnnotationFormatter
      selectedAnnots={selectedAnnots}
      defaultFontValue={customFont}
      onNameChanged={onNameChanged}
      onIndexChanged={onIndexChanged}
      onFontSizeChanged={onFontSizeChanged}
      onRequiredChanged={onRequiredChanged}
      onFontFamilyChanged={onFontFamilyChanged}
      deselectAllAnnotations={deselectAllAnnotations}
      selectedRecipient={
        !!getRecipientFromAnnot(selectedAnnots, [
          ...addlRecipients,
          primaryRecipient,
        ])
          ? convertRecipentToOptionDefinition(
              getRecipientFromAnnot(selectedAnnots, [
                ...addlRecipients,
                primaryRecipient,
              ]),
            )
          : null
      }
      options={convertRecipentsToOptionDefinitions(
        addlRecipients,
        primaryRecipient,
      )}
      onSelectedRecipientChanged={onRecipientChanged}
    />
  );
}

const getRecipientFromAnnot = (
  textAnnots: Core.Annotations.FreeTextAnnotation[],
  recipients: RecipientType[],
) => {
  if (!textAnnots) {
    // console.error("No annots to check");
    return;
  }
  if (textAnnots.length === 1) {
    const annotRecipientKey = textAnnots[0].getCustomData("recipientId");
    return recipients.find((recipient) => recipient?.id === annotRecipientKey);
  } else if (textAnnots.length > 0) {
    const annotRecipientKey = textAnnots[0].getCustomData("recipientId");
    const AllAnnotsHaveSameRecipients = textAnnots.every(
      (testAnnot) =>
        testAnnot.getCustomData("recipientId") === annotRecipientKey,
    );
    if (!!AllAnnotsHaveSameRecipients)
      return recipients.find(
        (recipient) => recipient?.id === annotRecipientKey,
      );
  }
};

export const setAnnotStrokeAndFillColor = ({
  instance,
  textAnnot,
  recipients,
}: {
  instance: WebViewerInstance;
  textAnnot: Core.Annotations.FreeTextAnnotation;
  recipients: RecipientType[];
}) => {
  const currentRecipient = getRecipientFromAnnot([textAnnot], recipients);
  if (!currentRecipient || !currentRecipient?.backgroundColor) {
    // console.error("No recipient found");
    return;
  }

  if (textAnnot.getCustomData("isRequired") === "true") {
    // storke is same as border
    textAnnot.StrokeColor = new instance.Core.Annotations.Color(
      AnnotationColor.RequiredStrokeColor.r,
      AnnotationColor.RequiredStrokeColor.g,
      AnnotationColor.RequiredStrokeColor.b,
      AnnotationColor.RequiredStrokeColor.a,
    );
    textAnnot.FillColor = new instance.Core.Annotations.Color(
      currentRecipient.backgroundColor.r,
      currentRecipient.backgroundColor.g,
      currentRecipient.backgroundColor.b,
      currentRecipient.backgroundColor.a,
    );
  } else {
    // storke is same as border
    textAnnot.StrokeColor = new instance.Core.Annotations.Color(
      AnnotationColor.DefaultBorderColor.r,
      AnnotationColor.DefaultBorderColor.g,
      AnnotationColor.DefaultBorderColor.b,
      AnnotationColor.DefaultBorderColor.a,
    );
    textAnnot.FillColor = new instance.Core.Annotations.Color(
      currentRecipient.backgroundColor.r,
      currentRecipient.backgroundColor.g,
      currentRecipient.backgroundColor.b,
      currentRecipient.backgroundColor.a,
    );
  }
};

export const convertRecipentsToOptionDefinitions = (
  addlRecipients: RecipientType[],
  primaryRecipient: RecipientType | undefined,
) => {
  return [
    {
      ...convertRecipentToOptionDefinition(primaryRecipient),
      labelTag: "Primary",
    },
    ...addlRecipients
      .map((recipient) => convertRecipentToOptionDefinition(recipient))
      .filter((option) => !!option && !!option.value),
  ];
};

export const convertRecipentToOptionDefinition = (
  recipient: RecipientType | undefined,
) => {
  if (!!recipient?.provider)
    return {
      label:
        recipient?.provider?.firstName ??
        "" + recipient?.provider?.lastName ??
        "",
      value: recipient.id,
      description: recipient.provider?.email,
      tags: ["Type: Provider", `Action: ${recipient?.action}`],
      iconSvg: !!recipient?.backgroundColor && (
        <CircleIcon
          data-id="circleicon"
          backgroundColor={recipient.backgroundColor}
        />
      ),
    };
  else if (!!recipient?.member)
    return {
      label:
        recipient?.member?.firstName ?? "" + recipient?.member?.lastName ?? "",
      value: recipient.id,
      description: recipient.member?.email,
      tags: ["Type: Member", `Action: ${recipient?.action}`],
      iconSvg: !!recipient.backgroundColor && (
        <CircleIcon
          data-id="circleicon"
          backgroundColor={recipient.backgroundColor}
        />
      ),
    };
  else if (!!recipient?.emailAddress)
    return {
      label: recipient?.emailAddress ?? "",
      value: recipient.id,
      description: recipient?.emailAddress,
      tags: ["Type: Email", `Action: ${recipient?.action}`],
      iconSvg: !!recipient.backgroundColor && (
        <CircleIcon
          data-id="circleicon"
          backgroundColor={recipient.backgroundColor}
        />
      ),
    };
  else
    return {
      label: "",
      value: "",
    };
};
