import FormSection from "../../components/FormSection";
import { SpaceBetween } from "@cloudscape-design/components";
import RHFTextField from "../../components/RHF/RHFTextField";
import RHFCheckBox from "../../components/RHF/RHFCheckBox";

export function NumberConfig() {
  return (
    <SpaceBetween size={"s"}>
      <RHFTextField label="Name" name="name" readOnly />
      <RHFTextField label="Description" name="description" />
      <RHFTextField
        label="Default value"
        name="defaultValue"
        rules={{
          pattern: {
            value: new RegExp("^[0-9]*$", "g"),
            message: "Default value must be a number",
          },
        }}
      />

      <FormSection columns={2} header={"Validations"}>
        <RHFCheckBox label="Is Required" name="required" />
        <RHFCheckBox label="Is Sensitive info" name="sensitive" />
      </FormSection>
    </SpaceBetween>
  );
}
