import { api } from "..";
import { ProfileTypes, UpdateProfileDetailsType } from "./types";

const ProfileApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProfileDetails: build.query<ProfileTypes, void>({
      query: () => "user",
      providesTags: ["Profile"],
    }),

    updateProfile: build.mutation<
      void,
      Pick<ProfileTypes, "id"> & Partial<UpdateProfileDetailsType>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `user`,
          method: "POST",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Profile", id: arg.id },
        { type: "Profile" },
      ],
    }),
  }),
});

export const { useGetProfileDetailsQuery, useUpdateProfileMutation } =
  ProfileApi;
