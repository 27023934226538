// react
import { Navigate, useLocation, useRoutes } from "react-router-dom";
// components
import ProviderProfileTable from "../provider";
import ManageCategoryTable from "../managecategory";
// layout
import DashboardLayout from "../layout/dashboard-layout";
import { PATH_AFTER_LOGIN, PATH_AUTH, PATH_DASHBOARD } from "./paths";
import GroupsEditForm from "../managecategory/managecategory-details/groups/groups-edit-form";
import ProviderSafe from "../providersafe";
import AddDocument from "../providersafe/document/add-document";
import EditDocument from "../providersafe/document/edit-document";
import AuthGuard from "../gaurds/AuthGaurd";
import Support from "../support";
import Profile from "../profile";
import OrganizationSettings from "../organization";
import ManageAdminTable from "../manageadmin/index";
import ManageProviderTable from "../manageprovider/index";
import Invited from "../manageadmin/manageadmin-details/sections/invited";
import ManageProfileSections from "../manageprofile";
import ConfigureSection from "../manageprofile/configure-section";
import Digitalforms from "../digitalform/digitalforms";
import SignUpForm from "../auth/signup/signup";
import ManageFacilities from "../managefacility";
import CustomFacilityEditRecord from "../managefacility/modify-facility-record/custom-facility-edit-record";
import CustomFacilityAddRecord from "../managefacility/modify-facility-record/custom-facility-add-record";
import Roles from "../roles";
import RoleDetails from "../roles/manage-roles/role-details";
import PermissionGaurd from "../gaurds/PermissionGaurd";
import OrgBasicDetailsEdit from "../organization/org-basic-details-edit";
import OrgSettingsEdit from "../organization/org-settings-edit";
import { useEffect } from "react";
import ResetPasswordForm from "../auth/reset-password";
import RequestReset from "../auth/reset-password/request-reset";
import Contracts from "../contracts";
import AddContract from "../contracts/modify-contract/add-contract";
import EditContract from "../contracts/modify-contract/edit-contract";
import ContractType from "../contracts/contracttype";
import ProviderDetail from "../provider/provider-profile/provider-details";
import EditPersonalInfo from "../provider/provider-profile/sections/modify-sections/edit-personal-info";
import EditProfessionalInfo from "../provider/provider-profile/sections/modify-sections/edit-professional-info";
import EditEducation from "../provider/provider-profile/sections/modify-sections/edit-education";
import EditDisclousres from "../provider/provider-profile/sections/modify-sections/edit-disclosures";
import EditAffiliation from "../provider/provider-profile/sections/modify-sections/edit-affiliation";
import EditDea from "../provider/provider-profile/sections/modify-sections/edit-dea";
import EditLiabilityInsurance from "../provider/provider-profile/sections/modify-sections/edit-liabilityinsurance";
import EditLicense from "../provider/provider-profile/sections/modify-sections/edit-license";
import EditPeerReference from "../provider/provider-profile/sections/modify-sections/edit-peer-reference";
import EditSpecialtyInfo from "../provider/provider-profile/sections/modify-sections/edit-specialty-info";
import EditThirdPartyLogin from "../provider/provider-profile/sections/modify-sections/edit-third-party-login";
import EditWorkHistory from "../provider/provider-profile/sections/modify-sections/edit-work-history";
import Privileges from "../privileges";
import EditPrivilege from "../privileges/modify/edit-privilege";
import PrivilegeTypeTable from "../privileges/privilegesType";
import AddPrivilegeType from "../privileges/privilegesType/modify/add-privilegetype";
import EditPrivilegeType from "../privileges/privilegesType/modify/edit-privilegetype";
import AddPrivileges from "../privileges/modify/add-privileges";
import EditDigitalForm from "../digitalform/digitalforms/modify/editDigitalForm";
import AddDigitalForm from "../digitalform/digitalforms/modify/addDigitalForm";
import SignatureRequestsTable from "../signature-requests";
import SignatureDetails from "../signature-requests/signature-detail";
import CredentailPackages from "../credentialpackages";
import CredentailTemplates from "../credentialpackages/templates";
import AddPayerEnrollment from "../payerenrollment/modify/add-payer-enrollment";
import AddCredentailTemplates from "../credentialpackages/modify/add-credentialtemplate";
import AddCredentialPackage from "../credentialpackages/packages/modify/add-package";
import AddPayer from "../payers/modify/add-payer";
import EditPayer from "../payers/modify/edit-payer";
import PayersTable from "../payers";
import PayerTable from "../payerenrollment";
import Dashboard from "../dashboard";
import ReportsList from "../reports";
import ExpiredDocuments from "../reports/expired-documents";
import ExpiringDocuments from "../reports/expiring-documents";
import EditPayerEnrollment from "../payerenrollment/modify/edit-payer-enrollments";
import DigitalformTemplateDesigner from "../template-designer/wrappers/digitalform-template-designer";
import AddFacility from "../managefacility/modify/add-facility";
import EditFacility from "../managefacility/modify/edit-facility";
import DigitalformRequestSignature from "../signature-requests/wrappers/digitalform-signature-request-wrapper";
import ProviderSafeDetails from "../providersafe/provider-safe-details";
import AddPSV from "../providersafe/psv/add-psv";
import CredentialPackageDetails from "../credentialpackages/packages/modify/credential-package-details";
import CredentialTemplateDetails from "../credentialpackages/modify/credentialtemplate-details";
import AddManageProviderCsvUpload from "../manageprovider/modals/add-manageprovidercsvupload";
import { SigningDocument } from "../components/PdftronViewerWrappers/signing-document";
import SigningSuccess from "../pages/signing-success";
import { TestPdftronViewer } from "../components/PdftronViewerWrappers/test-pdftron-viewer";

export const InvalidPathRoute = () => {
  const location = useLocation();
  useEffect(() => {
    console.error(`Invalid path: ${location.pathname}`);
  }, [location.pathname]);

  return <Navigate to={PATH_DASHBOARD.safe.list} replace />;
};

function Router() {
  return useRoutes([
    {
      path: "/",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: "dashboard",
          element: (
            //   <PermissionGaurd permissionKey="analytics.dashboard">
            <Dashboard />
            //   </PermissionGaurd>
          ),
          index: true,
        },
        {
          path: "safe",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="provider.view">
                  <ProviderSafe />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: ":providerId",
              element: (
                <PermissionGaurd permissionKey="provider.view">
                  <ProviderSafe />
                </PermissionGaurd>
              ),
            },
            {
              path: ":providerId/documents/addnew",
              element: (
                <PermissionGaurd permissionKey="provider.edit">
                  <AddDocument />
                </PermissionGaurd>
              ),
            },
            {
              path: ":providerId/documents/:documentId",
              element: (
                <PermissionGaurd permissionKey="provider.view">
                  <ProviderSafeDetails />
                </PermissionGaurd>
              ),
            },
            {
              path: ":providerId/documents/:documentId/psv/:psvId",
              element: (
                <PermissionGaurd permissionKey="provider.view">
                  <ProviderSafeDetails />
                </PermissionGaurd>
              ),
            },
            {
              path: ":providerId/documents/:documentId/psv/add",
              element: <AddPSV />,
            },
            {
              path: ":providerId/documents/:documentId/edit",
              element: (
                <PermissionGaurd permissionKey="provider.view">
                  <EditDocument />
                </PermissionGaurd>
              ),
            },
          ],
        },
        {
          path: "managecategory",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="provider.view">
                  <ManageCategoryTable />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: ":managecategoryId",
              element: (
                <PermissionGaurd permissionKey="provider.view">
                  <ManageCategoryTable />
                </PermissionGaurd>
              ),
            },
            {
              path: "groups/:groupId",
              element: <GroupsEditForm />,
            },
          ],
        },
        {
          path: "manageadmin",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="member.view">
                  <ManageAdminTable />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: ":manageadminId",
              element: (
                <PermissionGaurd permissionKey="member.view">
                  <ManageAdminTable />
                </PermissionGaurd>
              ),
            },
            {
              path: ":invitedId",
              element: <Invited />,
            },
          ],
        },
        {
          path: PATH_DASHBOARD.reports.list,
          children: [
            {
              element: <ReportsList />,
              index: true,
            },
          ],
        },
        {
          path: "expireddocuments",
          element: <ExpiredDocuments />,
        },
        {
          path: "expiringdocuments",
          element: <ExpiringDocuments />,
        },
        {
          path: "manageprovider",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="provider.view">
                  <ManageProviderTable />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: "addCsvUpload",
              element: (
                <PermissionGaurd permissionKey="provider.view">
                  <AddManageProviderCsvUpload />
                </PermissionGaurd>
              ),
            },

            {
              path: ":providerId",
              children: [
                {
                  element: (
                    <PermissionGaurd permissionKey="provider.view">
                      <ManageProviderTable />
                    </PermissionGaurd>
                  ),
                  index: true,
                },
                {
                  path: "profile",
                  children: [
                    {
                      element: (
                        <PermissionGaurd permissionKey="provider.view">
                          <ProviderDetail />
                        </PermissionGaurd>
                      ),
                      index: true,
                    },
                    {
                      path: "editpersonalinfo",
                      element: <EditPersonalInfo />,
                    },
                    {
                      path: "editprofessionalinfo",
                      element: <EditProfessionalInfo />,
                    },
                    {
                      path: "editeducation/:educationId",
                      element: <EditEducation />,
                    },
                    {
                      path: "editeducation",
                      element: <EditEducation />,
                    },
                    {
                      path: "editdisclousres",
                      element: <EditDisclousres />,
                    },
                    {
                      path: "editaffiliation/:affiliationId",
                      element: <EditAffiliation />,
                    },
                    {
                      path: "editaffiliation",
                      element: <EditAffiliation />,
                    },
                    {
                      path: "editdea/:deaId",
                      element: <EditDea />,
                    },
                    {
                      path: "editdea",
                      element: <EditDea />,
                    },
                    {
                      path: "editliabilityinsurance/:liabilityinsuranceId",
                      element: <EditLiabilityInsurance />,
                    },
                    {
                      path: "editliabilityinsurance",
                      element: <EditLiabilityInsurance />,
                    },
                    {
                      path: "editlicense/:licenseId",
                      element: <EditLicense />,
                    },
                    {
                      path: "editlicense",
                      element: <EditLicense />,
                    },
                    {
                      path: "editpeerreference/:peerreferenceId",
                      element: <EditPeerReference />,
                    },
                    {
                      path: "editpeerreference",
                      element: <EditPeerReference />,
                    },
                    {
                      path: "editspecialtyinfo/:specialtyinfoId",
                      element: <EditSpecialtyInfo />,
                    },
                    {
                      path: "editspecialtyinfo",
                      element: <EditSpecialtyInfo />,
                    },
                    {
                      path: "editthirdpartylogin/:thirdpartyloginId",
                      element: <EditThirdPartyLogin />,
                    },
                    {
                      path: "editthirdpartylogin",
                      element: <EditThirdPartyLogin />,
                    },
                    {
                      path: "editworkhistory/:workhistoryId",
                      element: <EditWorkHistory />,
                    },
                    {
                      path: "editworkhistory",
                      element: <EditWorkHistory />,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "managefacilities",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="facility.view">
                  <ManageFacilities />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: ":id",
              element: (
                <PermissionGaurd permissionKey="facility.view">
                  <EditFacility />
                </PermissionGaurd>
              ),
            },
            {
              path: "add",
              element: (
                <PermissionGaurd permissionKey="facility.view">
                  <AddFacility />
                </PermissionGaurd>
              ),
            },
            {
              path: ":id/profile/:recordId",
              element: <CustomFacilityEditRecord />,
            },
            {
              path: ":id/add/:sectionId",
              element: <CustomFacilityAddRecord />,
            },
          ],
        },
        {
          path: "managesection",
          children: [
            {
              element: <ManageProfileSections />,
              index: true,
            },
            {
              path: ":id",
              element: <ConfigureSection />,
            },
          ],
        },
        {
          path: "support",
          children: [
            {
              element: <Support />,
              index: true,
            },
          ],
        },
        {
          path: "org",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="organization_settings.view">
                  <OrganizationSettings />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: "details/edit",
              element: (
                <PermissionGaurd permissionKey="organization_settings.edit">
                  <OrgBasicDetailsEdit />
                </PermissionGaurd>
              ),
            },
            {
              path: "settings/edit",
              element: (
                <PermissionGaurd permissionKey="organization_settings.edit">
                  <OrgSettingsEdit />
                </PermissionGaurd>
              ),
            },
          ],
        },
        {
          path: "settings/profile",
          children: [
            {
              element: <Profile />,
              index: true,
            },
          ],
        },
        {
          path: "settings/roles",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="roles.view">
                  <Roles />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: ":id",
              element: (
                <PermissionGaurd permissionKey="roles.view">
                  <RoleDetails />
                </PermissionGaurd>
              ),
            },
          ],
        },
        {
          path: "privilegetype",
          children: [
            {
              element: <PrivilegeTypeTable />,
              index: true,
            },
            {
              path: "add",
              element: <AddPrivilegeType />,
            },
            {
              path: ":id",
              element: <EditPrivilegeType />,
            },
          ],
        },
        {
          path: "privileges",
          children: [
            {
              element: <Privileges />,
              index: true,
            },
            {
              path: "add",
              element: <AddPrivileges />,
            },
            {
              path: ":id",
              element: <EditPrivilege />,
            },
          ],
        },
        {
          path: PATH_DASHBOARD.payerenrollment.list,
          children: [
            {
              element: <PayerTable />,
              index: true,
            },
            {
              path: ":id/addenrollment",
              element: <AddPayerEnrollment />,
            },
            {
              path: ":id/editenrollment/:enrollmentId",
              element: <EditPayerEnrollment />,
            },
            {
              path: ":id",
              element: (
                <PermissionGaurd permissionKey="provider.view">
                  <PayerTable />
                </PermissionGaurd>
              ),
            },
          ],
        },
        {
          path: "payers",
          children: [
            {
              element: <PayersTable />,
              index: true,
            },
            {
              path: "addnew",
              element: <AddPayer />,
            },
            {
              path: ":id",
              element: <EditPayer />,
            },
          ],
        },
        {
          path: "contract",
          children: [
            {
              element: <Contracts />,
              index: true,
            },
            {
              path: "addnew",
              element: <AddContract />,
            },
            {
              path: ":id",
              element: <EditContract />,
            },
          ],
        },
        {
          path: "contract",
          children: [
            {
              element: <Contracts />,
              index: true,
            },
            {
              path: "addnew",
              element: <AddContract />,
            },
            {
              path: ":id",
              element: <EditContract />,
            },
          ],
        },
        {
          path: "contracttypes",
          element: <ContractType />,
          children: [
            {
              path: ":id",
              element: <ContractType />,
            },
          ],
        },
        {
          path: PATH_DASHBOARD.credential.packages.list,
          children: [
            {
              element: <CredentailPackages />,
              index: true,
            },
            {
              path: "addnew",
              element: <AddCredentialPackage />,
            },
            {
              path: ":id",
              element: <CredentialPackageDetails />,
            },
          ],
        },
        {
          path: PATH_DASHBOARD.credential.templates.list,
          children: [
            {
              element: <CredentailTemplates />,
              index: true,
            },
            {
              path: "addnew",
              element: <AddCredentailTemplates />,
            },
            {
              path: ":id",
              element: <CredentialTemplateDetails />,
            },
          ],
        },
        {
          path: "digitalforms",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="readyforms.view">
                  <Digitalforms />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: ":id",
              children: [
                {
                  element: (
                    <PermissionGaurd permissionKey="readyforms.edit">
                      <EditDigitalForm />
                    </PermissionGaurd>
                  ),
                  index: true,
                },
                {
                  path: "templatedesigner",
                  element: <DigitalformTemplateDesigner />,
                },
                {
                  path: "requestsignature",
                  element: (
                    <PermissionGaurd permissionKey="signature.view">
                      <DigitalformRequestSignature />
                    </PermissionGaurd>
                  ),
                },
              ],
            },
            {
              path: "addNew",
              element: <AddDigitalForm />,
            },
          ],
        },

        {
          path: "esignatures",
          children: [
            {
              element: (
                <PermissionGaurd permissionKey="signature.view">
                  <SignatureRequestsTable />
                </PermissionGaurd>
              ),
              index: true,
            },
            {
              path: ":id",
              element: <SignatureDetails />,
            },
          ],
        },
        {
          path: "testviewer",
          element: <TestPdftronViewer />,
        },
        {
          path: "*",
          element: <InvalidPathRoute />,
        },
      ],
    },
    {
      path: "providers",
      children: [
        {
          element: (
            <PermissionGaurd permissionKey="provider.view">
              <ProviderProfileTable />
            </PermissionGaurd>
          ),
          index: true,
        },
      ],
    },
    {
      path: PATH_AUTH.login,
      element: <Navigate to={PATH_DASHBOARD.safe.list} />,
    },
    {
      path: PATH_AUTH.register,
      element: <SignUpForm />,
    },
    {
      path: PATH_AUTH.resetPassword,
      element: <ResetPasswordForm />,
    },
    {
      path: PATH_AUTH.requestReset,
      element: <RequestReset />,
    },
    {
      path: "signing",
      children: [
        { element: <SigningDocument />, index: true },
        { path: "success", element: <SigningSuccess /> },
      ],
    },
  ]);
}

export default Router;
