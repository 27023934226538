import { api } from "..";
import { ProviderDocuments } from "./types";

const reportingAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAllExpiredDocuments: build.query<ProviderDocuments[], void>({
      query: () => `reporting/documents/expired`,
      providesTags: ["Reporting"],
    }),
    eventLogger: build.query<void, void>({
      query: () => `event-logger`,
      providesTags: ["Reporting"],
    }),
    getAllExpiringDocuments: build.query<
      ProviderDocuments[],
      { days?: string }
    >({
      query: ({ days }) =>
        !!days
          ? `reporting/documents/expiring?days=${days}`
          : `reporting/documents/expiring`,
      providesTags: ["Reporting"],
    }),
  }),
});
export const {
  useEventLoggerQuery,
  useGetAllExpiredDocumentsQuery,
  useGetAllExpiringDocumentsQuery,
} = reportingAPI;
