import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  useDigitalformSaveToSafeMutation,
  useGetDigitalFormQuery,
} from "../../redux/api/digitalform/digitalforms";
import { useNavigate, useParams } from "react-router-dom";
import LoadingScreen from "../../components/LoadingScreen";
import RequestSignature from "../add-eSignature/request-signature";
import { useAddDigitalformSignatureRequestMutation } from "../../redux/api/signaturerequest/signaturerequest";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import { v4 as uuidv4 } from "uuid";
import useNotify from "../../hooks/useNotify";
import {
  Box,
  Button,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { dismissFlashBarItem } from "../../redux/UI/actions";
import { eSignatureResponse } from "../../redux/api/signaturerequest/types";

export default function DigitalformRequestSignature() {
  const { id } = useParams();
  const { data: digitalform, isLoading } = useGetDigitalFormQuery(
    id ?? skipToken,
  );
  const { notifyInProgress, notifyFailed } = useNotify();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [
    addDigitalformSignatureRequest,
    { isLoading: loadingSubmitSignatureRequest },
  ] = useAddDigitalformSignatureRequestMutation();
  const [digitalformSaveToSafe, { isLoading: isSavingToSafe }] =
    useDigitalformSaveToSafeMutation();

  const [signatureResponse, setSignatureResponse] =
    useState<eSignatureResponse>();

  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

  console.log(digitalform?.annotatedTemplate.key);
  if (!digitalform) return <LoadingScreen isOpen={true} />;
  else
    return (
      <>
        <RequestSignature
          fileKey={digitalform.annotatedTemplate.key}
          name={digitalform.name}
          isLoading={isLoading}
          savetosafe={async (xfdfString, providerId) => {
            await notifyWrapper({
              promise: digitalformSaveToSafe({
                id: digitalform.id,
                providerId,
                xfdfString,
              }),
              resourceName: "readyform request signature",
              actionName: "save",
            });
            navigate(-1);
          }}
          addsignaturerequest={async (
            primaryRecipient,
            addlRecipients,
            signatureRequest,
          ) => {
            const notificationId = uuidv4();
            notifyInProgress({
              name: "e-Signature",
              action: "requesting",
              id: notificationId,
            });
            await addDigitalformSignatureRequest({
              formId: digitalform.id,
              id: "",
              primaryRecipient,
              addlRecipients,
              ...signatureRequest,
            })
              .unwrap()
              .then((payload) => {
                console.log({ payload });
                dispatch(dismissFlashBarItem(notificationId));
                setSignatureResponse(payload);
                setShowSuccessModal(true);
              })
              .catch((error) => {
                if (error.status < 500 && error.status >= 400) {
                  notifyFailed({
                    name: "e-Signature",
                    action: error.data.errorMessage,
                    content: error.data.errorDescription,
                    id: notificationId,
                  });
                } else
                  notifyFailed({
                    name: "e-Signature",
                    action: "request",
                    id: notificationId,
                  });
                navigate(-1);
              });
          }}
          loadingSubmitSignatureRequest={loadingSubmitSignatureRequest}
        />
        <Modal
          onDismiss={() => {
            setShowSuccessModal(false);
            navigate(`/esignatures/${signatureResponse?.id}`);
          }}
          visible={!!showSuccessModal}
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="link">Cancel</Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    setShowSuccessModal(false);
                    navigate(`/esignatures/${signatureResponse?.id}`);
                  }}
                >
                  Ok
                </Button>
              </SpaceBetween>
            </Box>
          }
          header="Signature Request Completed!"
        >
          Your signature request has been successfully sent
        </Modal>
      </>
    );
}
