import { FormProvider, useForm } from "react-hook-form";
import {
  useAddFacilityMutation,
  useGetAllFacilityTypesQuery,
} from "../../redux/api/facility/facility";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import useIsLoading from "../../hooks/useIsLoading";
import { useNavigate } from "react-router";
import InnerAppLayout from "../../components/InnerAppLayout";
import {
  BreadcrumbGroup,
  Button,
  Container,
  ExpandableSection,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { Divider } from "semantic-ui-react";
import FormSection from "../../components/FormSection";
import RHFTextField from "../../components/RHF/RHFTextField";
import RHFFloatingSelect from "../../components/RHF/RHFFloatingSelect";
import { connect } from "react-redux";
import { setModalName } from "../../redux/UI/actions";
import { FACILITY_TYPE } from "../../helpers/constants";
import { useTranslation } from "react-i18next";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

type FormInputType = {
  name: string;
  npiNumber: string;
  description: string;
  facilityTypeId: string;
  cliaId: string;
  immunizationId: string;
  taxonomyCode: string;
  addressLane1: string;
  addressLane2: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
};

function AddFacilities({ setModalName }: PropsFromDispatch) {
  const { t } = useTranslation();
  const methods = useForm<FormInputType>();
  const { data: facilityTypes = [] } = useGetAllFacilityTypesQuery();
  const [addFacility, { isLoading: isCreating }] = useAddFacilityMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const navigate = useNavigate();

  useIsLoading(isCreating);

  const onSubmit = async (data: FormInputType) => {
    await notifyWrapper({
      promise: addFacility({
        name: data?.name,
        npiNumber: data?.npiNumber,
        description: data?.description,
        facilityTypeId: data?.facilityTypeId,
        cliaId: data?.cliaId,
        immunizationId: data?.immunizationId,
        taxonomyCode: data?.taxonomyCode,
        facilityAddress: {
          addressLane1: data?.addressLane1,
          addressLane2: data?.addressLane2,
          city: data?.city,
          state: data?.state,
          zipcode: data?.zipcode,
          country: data?.country,
        },
      }),
      resourceName: "facility",
      actionName: "add",
    });
    navigate(-1);
  };
  return (
    <InnerAppLayout
      breadcrumbGroup={
        <BreadcrumbGroup
          items={[
            {
              text: `${t("facility.addFacility.header.breadcrumb.text")}`,
              href: `/managefacilities`,
            },

            {
              text: `${t("facility.addFacility.header.breadcrumb.currentPage")}`,
              href: "#",
            },
          ]}
        />
      }
      content={
        <Container
          header={
            <SpaceBetween size="xxs">
              <Header>{t("facility.addFacility.header.label")}</Header>
              <Divider></Divider>
            </SpaceBetween>
          }
        >
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Form
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      onClick={() => {
                        navigate("/managefacilities");
                      }}
                      formAction="none"
                    >
                      {t("facility.addFacility.header.actions.cancel")}
                    </Button>
                    <Button formAction="submit" variant="primary">
                      {t("facility.addFacility.header.actions.submit")}
                    </Button>
                  </SpaceBetween>
                }
              >
                <SpaceBetween direction="vertical" size="m">
                  <FormSection columns={2}>
                    <RHFTextField
                      label={t("facility.addFacility.header.fields.name")}
                      name="name"
                      stretch={false}
                      rules={{ required: "This field is required" }}
                    />
                    <RHFFloatingSelect
                      name="facilityTypeId"
                      label={t(
                        "facility.addFacility.header.fields.facilityType.label",
                      )}
                      options={facilityTypes.map((type) => ({
                        label: type.name,
                        value: "" + type.id,
                      }))}
                      rules={{ required: "This field is required" }}
                      stretch={false}
                      action={{
                        label: `${t("facility.addFacility.header.fields.facilityType.actions.modify")}`,
                        onClick: () => {
                          setModalName(FACILITY_TYPE);
                        },
                      }}
                    />
                    <RHFTextField
                      label={t("facility.addFacility.header.fields.npiNumber")}
                      name="npiNumber"
                      stretch={false}
                      rules={{ required: "This field is required" }}
                    />
                    <RHFTextField
                      label={t("facility.addFacility.header.fields.CLIAID")}
                      name="cliaId"
                      stretch={false}
                    />
                    <RHFTextField
                      label={t(
                        "facility.addFacility.header.fields.immunizationId",
                      )}
                      name="immunizationId"
                      stretch={false}
                    />
                    <RHFTextField
                      label={t(
                        "facility.addFacility.header.fields.taxonomyCode",
                      )}
                      name="taxonomyCode"
                      stretch={false}
                    />
                  </FormSection>

                  <ExpandableSection
                    headerText={t("facility.addFacility.header.headerText")}
                  >
                    <FormSection header="" columns={2}>
                      <RHFTextField
                        label={t(
                          "facility.addFacility.header.fields.addressLane1",
                        )}
                        name="addressLane1"
                        stretch={false}
                      />
                      <RHFTextField
                        label={t(
                          "facility.addFacility.header.fields.addressLane2",
                        )}
                        name="addressLane2"
                        stretch={false}
                      />
                      <RHFTextField
                        label={t("facility.addFacility.header.fields.city")}
                        name="city"
                        stretch={false}
                      />
                      <RHFTextField
                        label={t("facility.addFacility.header.fields.state")}
                        name="state"
                        stretch={false}
                      />
                      <RHFTextField
                        label={t("facility.addFacility.header.fields.zipcode")}
                        name="zipcode"
                        stretch={false}
                      />
                      <RHFTextField
                        label={t("facility.addFacility.header.fields.country")}
                        name="country"
                        stretch={false}
                      />
                    </FormSection>
                  </ExpandableSection>
                </SpaceBetween>
              </Form>
            </form>
          </FormProvider>
        </Container>
      }
    />
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(AddFacilities);
