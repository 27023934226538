import {
  ColumnLayout,
  Container,
  Header,
  Grid,
  SpaceBetween,
  Button,
} from "@cloudscape-design/components";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import RHFTextField from "../components/RHF/RHFTextField";
import useIsLoading from "../hooks/useIsLoading";
import {
  useGetProfileDetailsQuery,
  useUpdateProfileMutation,
} from "../redux/api/profile/profile";
import Label from "../components/Label";
import FormSection from "../components/FormSection";
import useAsyncNotifyWrapper from "../hooks/useAsyncNotifyWrapper";

type IFormInput = {
  firstName: string;
  lastName: string;
  preferredName: string;
};

function Profile() {
  const {
    data: user,
    fulfilledTimeStamp,
    isLoading,
  } = useGetProfileDetailsQuery();

  const methods = useForm<IFormInput>();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const onSubmit = async (data: IFormInput) => {
    if (user?.id)
      await notifyWrapper({
        promise: updateProfile({
          id: user?.id,
          firstName: data.firstName,
          lastName: data.lastName,
          preferredName: data.preferredName,
        }),
        resourceName: "digitalform template",
        actionName: "upload",
      });
  };

  const [updateProfile, { isLoading: isUpdating }] = useUpdateProfileMutation();

  useIsLoading(isLoading || isUpdating);

  useEffect(() => {
    if (user) {
      methods.setValue("firstName", user.firstName);
      methods.setValue("lastName", user.lastName);
      methods.setValue("preferredName", user.preferredName);
    }
  }, [fulfilledTimeStamp]);

  return (
    <div style={{ paddingTop: "12px" }}>
      <Header>Profile</Header>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <SpaceBetween direction="vertical" size="xl">
            <div>
              <Container>
                <SpaceBetween direction="vertical" size="xxl">
                  <Grid gridDefinition={[{ colspan: 3 }, { colspan: 9 }]}>
                    <div>Name</div>
                    <div>
                      <ColumnLayout columns={2}>
                        <RHFTextField
                          label="First Name"
                          name="firstName"
                          stretch
                          rules={{ required: "This field is required" }}
                        />

                        <RHFTextField
                          label="Last Name"
                          name="lastName"
                          stretch
                          rules={{ required: "This field is required" }}
                        />

                        <RHFTextField
                          label="Prefered Name"
                          name="preferredName"
                          stretch
                          rules={{ required: "This field is required" }}
                        />
                      </ColumnLayout>
                    </div>
                  </Grid>
                </SpaceBetween>
              </Container>
              <div style={{ float: "right", padding: "16px 12px 0 0" }}>
                <Button variant="primary">Save</Button>
              </div>
            </div>
            <Container>
              <FormSection columns={2}>
                <Label label="Email" value={user === null ? "" : user?.email} />
                <Label label="Organization" value={user?.org} />
                <Label label="Role" value={user?.role} />
                <Label
                  label="Last Login"
                  value={
                    user?.lastLogin
                      ? new Date(user?.lastLogin)?.toLocaleString()
                      : "-"
                  }
                />
              </FormSection>
            </Container>
          </SpaceBetween>
        </form>
      </FormProvider>
    </div>
  );
}

export default Profile;
