import { api } from "..";
import {
  MemberType,
  InviteCreateRequest,
  InvitationType,
  UpdateMemberTypes,
} from "./types";

const ManageAdminApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllActive: build.query<MemberType[], void>({
      query: () => "admin/all",
      providesTags: ["ManageAdmin"],
    }),
    getActive: build.query<MemberType, { activeId: string }>({
      query: ({ activeId }) => `admin/${activeId}`,
      providesTags: ["ManageAdmin"],
    }),
    deleteAdmin: build.mutation<
      void,
      {
        adminId: string;
      }
    >({
      query: ({ adminId }) => ({
        url: `admin/${adminId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ManageAdmin"],
    }),
    getAllInvited: build.query<InvitationType[], void>({
      query: () => "invite/all",
      providesTags: ["ManageAdmin"],
    }),
    getInvited: build.query<InvitationType, { invitedId: string }>({
      query: ({ invitedId }) => `invite/${invitedId}`,
      providesTags: ["ManageAdmin"],
    }),
    createInvite: build.mutation<void, InviteCreateRequest>({
      query: ({ ...body }) => {
        return {
          url: "invite",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["ManageAdmin"],
    }),
    deleteInvite: build.mutation<
      void,
      {
        invitedId: string;
      }
    >({
      query: ({ invitedId }) => ({
        url: `invite/${invitedId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ManageAdmin"],
    }),
    updateMember: build.mutation<
      void,
      Pick<MemberType, "id"> & Partial<UpdateMemberTypes>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `invite/${id}`,
          method: "PUT",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "ManageAdmin", id: arg.id },
        { type: "ManageAdmin" },
      ],
    }),
    updateMemberRole: build.mutation<void, { id: string; role_ids: string }>({
      query: ({ id, ...patch }) => {
        console.log({ id });
        return {
          url: `admin/${id}/role`,
          method: "POST",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "ManageAdmin", id: arg.id },
        { type: "ManageAdmin" },
      ],
    }),
  }),
});

export const {
  useGetAllActiveQuery,
  useGetActiveQuery,
  useGetAllInvitedQuery,
  useGetInvitedQuery,
  useCreateInviteMutation,
  useDeleteInviteMutation,
  useUpdateMemberMutation,
  useDeleteAdminMutation,
  useUpdateMemberRoleMutation,
} = ManageAdminApi;
