import {
  Box,
  Button,
  Container,
  Header,
  Link,
  Modal,
  SpaceBetween,
  StatusIndicator,
} from "@cloudscape-design/components";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import RHFTextField from "../../components/RHF/RHFTextField";
import { PATH_AUTH } from "../../routes/paths";
import axios from "../../context/axios";
import { useNavigate } from "react-router-dom";

type FormInputType = {
  email: string;
};

export default function RequestReset() {
  const methods = useForm<FormInputType>();
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  return (
    <div id="request-reset">
      <div className="container">
        <div className="centered-element">
          <SpaceBetween direction="vertical" size="xxl">
            <img
              alt=""
              src="/RD_Horizozntal_Color_Logo.svg"
              style={{ height: 45 }}
            />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <SpaceBetween direction="vertical" size="xxs">
                <div style={{ width: 500, margin: 15 }}>
                  <Container
                    header={
                      <Header
                        variant="h1"
                        description={
                          error && (
                            <StatusIndicator type="error">
                              {error}
                            </StatusIndicator>
                          )
                        }
                      >
                        Reset your password?
                      </Header>
                    }
                  >
                    <FormProvider {...methods}>
                      <form
                        onSubmit={methods.handleSubmit(
                          async (data: FormInputType) => {
                            if (data.email) {
                              try {
                                setIsLoading(true);
                                axios
                                  .post(
                                    `/api/auth/password/reset`,
                                    {
                                      email: data.email,
                                    },
                                    {
                                      validateStatus: (status) =>
                                        status === 200,
                                    },
                                  )
                                  .then(() => {
                                    setIsLoading(false);
                                    setVisible(true);
                                  })
                                  .catch((error) => {
                                    setIsLoading(false);
                                    // console.error(error);
                                    setError(
                                      error?.response?.data?.message ??
                                        "Error: ",
                                    );
                                  });
                              } catch (error) {
                                setIsLoading(false);
                                setError("Error: ");
                              }
                            }
                          },
                        )}
                      >
                        <SpaceBetween direction="vertical" size="l">
                          <RHFTextField
                            label="Email"
                            name="email"
                            stretch
                            rules={{ required: "This field is required" }}
                          />
                          <ul
                            style={{
                              padding: 0,
                              margin: 0,
                              listStyle: "none",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Link href={PATH_AUTH.login}>Back to login</Link>
                            <Button variant="primary">Send email</Button>
                          </ul>
                        </SpaceBetween>
                      </form>
                    </FormProvider>
                  </Container>
                </div>
              </SpaceBetween>
            </div>
          </SpaceBetween>
        </div>
      </div>
      <Modal
        onDismiss={() => setVisible(false)}
        visible={visible}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="link"
                onClick={() => {
                  navigate("/");
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  navigate("/");
                }}
              >
                Ok
              </Button>
            </SpaceBetween>
          </Box>
        }
        header="Password reset email sent"
      >
        Please check your email.
      </Modal>
    </div>
  );
}
