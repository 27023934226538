import {
  Button,
  Form,
  Header,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import { FormProvider, useForm } from "react-hook-form";
import RHFTextField from "../../../components/RHF/RHFTextField";
import FormSection from "../../../components/FormSection";
import RHFTextArea from "../../../components/RHF/RHFTextArea";
import { useCreateContractTypeMutation } from "../../../redux/api/contracts/contract";
import { useNavigate } from "react-router-dom";
import { setModalName } from "../../../redux/UI/actions";
import { connect } from "react-redux";
import useAsyncNotifyWrapper from "../../../hooks/useAsyncNotifyWrapper";

interface PropsFromDispatch {
  openModalName: typeof setModalName;
}

type FormInputType = {
  type: string;
  description: string;
};

function AddContractType({ openModalName }: PropsFromDispatch) {
  const methods = useForm<FormInputType>();
  const navigate = useNavigate();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const onSubmit = async (data: FormInputType) => {
    if (data) {
      await notifyWrapper({
        promise: createContractType({
          type: data?.type,
          description: data?.description,
        }),
        resourceName: "contract type",
        actionName: "add",
      });
      openModalName("");
    }
  };

  const [createContractType] = useCreateContractTypeMutation();

  return (
    <Modal
      visible={true}
      size="medium"
      header={<Header variant="h2">Add Contract Type</Header>}
      onDismiss={() => {
        openModalName("");
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  onClick={() => {
                    openModalName("");
                  }}
                  formAction="none"
                >
                  Cancel
                </Button>
                <Button formAction="submit" variant="primary">
                  Submit
                </Button>
              </SpaceBetween>
            }
          >
            <FormSection columns={1}>
              <RHFTextField
                label="Type"
                name="type"
                stretch
                rules={{ required: "This field is required" }}
              />
              <RHFTextArea label="Description" name="description" stretch />
            </FormSection>
          </Form>
        </form>
      </FormProvider>
    </Modal>
  );
}
const mapDispatchToProps = {
  openModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(AddContractType);
