import {
    Box,
    Button,
    ButtonDropdown,
    Header,
    Pagination,
    Table,
    TableProps,
} from "@cloudscape-design/components";
import { connect } from "react-redux";
import { setModalName } from "../../redux/UI/actions";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import DeleteAlertModal from "../../components/Modal/delete-alert-modal";
import { ContractType } from "../../redux/api/contracts/types";
import { useDeleteContractTypeMutation, useGetAllContractTypesQuery } from "../../redux/api/contracts/contract";
import { ADD_CONTRACT_TYPE, EDIT_CONTRACT_TYPE } from "../../helpers/constants";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import { useLocalStorage } from "../../common/localStorage";
import { CONTRACTTYPE_CONTENT_DISPLAY_OPTIONS, CONTRACTTYPE_TABLE_DEFAULT_PREFERENCES, TablePreferences } from "../../common/table-config";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableEmptyState } from "../../common/common-components";

interface PropsFromDispatch {
    openModalName: typeof setModalName;
}

function ContractTypeTable({ openModalName }: PropsFromDispatch) {
    const { data = [] } = useGetAllContractTypesQuery();
    const navigate = useNavigate();
    const { id } = useParams();
    const [selectedItem, setSelectedItem] = useState<ContractType>();
    const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
    const [deleteContractType] = useDeleteContractTypeMutation();
    const { notifyWrapper } = useAsyncNotifyWrapper();
    const [tablePreferences, setTablePreferences] = useLocalStorage(
        "RD-ContractType-Table-Preferences",
        CONTRACTTYPE_TABLE_DEFAULT_PREFERENCES
    );
    const { items, collectionProps, paginationProps } = useCollection(
        data,
        {
            propertyFiltering: {
                filteringProperties: [],
                empty: <TableEmptyState resourceName="ContractTypes" />,
            },
        }
    );
    const hasPermission = useSelector(
        (state: ApplicationState) =>
            state.ui.is_owner ||
            (state.ui.permissions?.includes("contracttype.edit") ?? false)
    );
    const hasDeletePermission = useSelector(
        (state: ApplicationState) =>
            state.ui.is_owner ||
            (state.ui.permissions?.includes("contracttype.delete") ?? false)
    );
    useEffect(() => {
        const temp = data.find(
            (contract) => "" + contract.id === "" + id
        );
        if (temp) setSelectedItem(temp);
    }, [id])
    const tableProps: TableProps<ContractType> = useMemo(() => {
        return {
            header: (
                <Header
                    actions={
                        <div>
                            <span className="awsui-util-action-stripe-group">
                                <ButtonDropdown
                                    expandableGroups
                                    disabled={!selectedItem}
                                    items={[
                                        {
                                            id: "edit_form",
                                            text: hasPermission ? "Edit" : "View",
                                        },
                                        {
                                            id: "delete_form",
                                            text: "Delete",
                                            disabled: !hasDeletePermission,
                                        },
                                    ]}
                                    onItemClick={(itemClickDetails) => {
                                        const { id } = itemClickDetails.detail;
                                        if (id === "delete_form") {
                                            if (selectedItem) OpenDeleteModal(true);
                                        } else if (id === "edit_form") {
                                            openModalName(EDIT_CONTRACT_TYPE);
                                        }
                                    }}
                                >
                                    Actions
                                </ButtonDropdown>
                            </span>
                            <span className="awsui-util-action-stripe-group">
                                <Button
                                    onClick={() => {
                                        openModalName(ADD_CONTRACT_TYPE);
                                    }}
                                    variant="primary"
                                >
                                    Add Contract Type
                                </Button>
                            </span>
                        </div>
                    }
                >
                    Contract Type
                </Header>
            ),
            items,
            pagination: <Pagination {...paginationProps} />,
            preferences: (
                <TablePreferences
                    title="Contract Type Table Preferences"
                    preferences={tablePreferences}
                    setPreferences={(preferences) => {
                        setTablePreferences(preferences);
                    }}
                    contentDisplayOptions={CONTRACTTYPE_CONTENT_DISPLAY_OPTIONS}
                />
            ),
            columnDisplay: tablePreferences?.contentDisplay,
            wrapLines: tablePreferences?.wrapLines,
            stripedRows: tablePreferences?.stripedRows,
            contentDensity: tablePreferences?.contentDensity,
            stickyColumns: tablePreferences?.stickyColumns,
            ...collectionProps,
            variant: "container",
            selectionType: "single",
            resizableColumns: true,
            stickyHeader: true,
            empty: <p>No results to display.</p>,
            selectedItems: selectedItem ? [selectedItem] : [],
            onSelectionChange: (selectionChangeDetail) => {
                const temp_contractId =
                    selectionChangeDetail.detail.selectedItems.at(0)?.id;
                if (temp_contractId) navigate(`/contracttypes/${temp_contractId}`);
            },
            onRowClick: (onRowClickDetail) => {
                const temp = onRowClickDetail.detail.item.id;
                if (temp) navigate(`/contracttypes/${temp}`);
            },
            trackBy: (item) => "" + item.id,
            columnDefinitions: [
                {
                    id: "id",
                    header: <div>id</div>,
                    cell: (item) => <span>{item.id}</span>,
                },
                {
                    id: "type",
                    header: <div>Type</div>,
                    cell: (item) => <div
                        className={hasPermission ? "edit_link" : ""}
                        onClick={() => {
                            hasPermission ? openModalName(EDIT_CONTRACT_TYPE) : openModalName("")
                        }}
                    >{item.type}</div>,
                },
                {
                    id: "description",
                    header: <div>Description</div>,
                    cell: (item) => <span>{item.description}</span>,
                },

            ],
        };
    }, [selectedItem, data, tablePreferences, items]);
    return (
        <div style={{ paddingTop: "25px" }}>
            <Table {...tableProps} />
            <DeleteAlertModal
                visible={isDeleteModalOpen}
                action={async () => {
                    if (selectedItem?.id) {
                        await notifyWrapper({
                            promise: deleteContractType({
                                contractTypeId: selectedItem?.id,
                            }),
                            resourceName: "contract type",
                            actionName: "delete",
                        });
                    } OpenDeleteModal(false);
                }}
                closeModal={() => OpenDeleteModal(false)}
                header={"Delete Contract Type"}
                content={
                    <>
                        <Box>Type</Box>
                        <Box variant="awsui-key-label">{selectedItem?.type}</Box>
                    </>
                }
                description={"Are you sure you want to delete this contract type?"}
            />
        </div>
    );
}

const mapDispatchToProps = {
    openModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(ContractTypeTable);
