import {
  Button,
  Header,
  Modal,
  SpaceBetween,
  TagEditorProps,
} from "@cloudscape-design/components";
import Grid from "@mui/material/Grid";
import { connect } from "react-redux";
import { setModalName } from "../../redux/UI/actions";
import { useCreateDigitalFormMutation } from "../../redux/api/digitalform/digitalforms";
import { useGetAllCategoryGroupQuery } from "../../redux/api/managecategory/managecategory";
import { FormProvider, useForm } from "react-hook-form";
import RHFTextField from "../../components/RHF/RHFTextField";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import RHFSelect from "../../components/RHF/RHFSelect";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

type FormInputType = {
  name: string;
  description: string;
  category: string;
  tags: ReadonlyArray<TagEditorProps.Tag>;
};

function DigitalFormCreateModal({ setModalName }: PropsFromDispatch) {
  const methods = useForm<FormInputType>();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const onSubmit = async (data: FormInputType) => {
    setModalName("");
    await notifyWrapper({
      promise: createDigitalForm({
        name: data.name,
        description: data.description,
        categoryId: data.category,
        digitalFormTypeId: "",
        disabled: true,
        tags: [],
      }),
      resourceName: "ready form",
      actionName: "add",
    });
  };

  const { data = [] } = useGetAllCategoryGroupQuery();

  const [createDigitalForm, { isLoading: isCreating }] =
    useCreateDigitalFormMutation();

  return (
    <Modal
      visible={true}
      size="large"
      header={<Header variant="h2">Create Ready Form</Header>}
      onDismiss={() => {
        setModalName("");
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <SpaceBetween direction="vertical" size="l">
            <RHFTextField
              label="Ready Form Name"
              name="name"
              stretch
              rules={{ required: "This field is required" }}
            />

            <RHFTextArea label="Description" name="description" stretch />

            <RHFSelect
              name="category"
              label="Category"
              stretch={true}
              optionGroups={data.map((group) => {
                return {
                  label: group.name,
                  options: group.categoryList.map((category) => {
                    return {
                      label: category.name,
                      value: String(category.id),
                    };
                  }),
                };
              })}
              rules={{ required: "This field is required" }}
            />

            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  onClick={() => {
                    setModalName("");
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button variant="primary">Create</Button>
              </Grid>
            </Grid>
          </SpaceBetween>
        </form>
      </FormProvider>
    </Modal>
  );
}

const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(DigitalFormCreateModal);
