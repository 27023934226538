import {
  Button,
  Container,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { useNavigate, useParams } from "react-router-dom";
import { Divider } from "@mui/material";
import FormSection from "../../../../components/FormSection";
import Label from "../../../../components/Label";
import { useGetProviderQuery } from "../../../../redux/api/provider/provider";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";

export default function ProfessionalInfo() {
  const navigate = useNavigate();
  const { providerId } = useParams();

  const { data: provider, fulfilledTimeStamp } = useGetProviderQuery(
    providerId ? { providerId } : skipToken,
  );
  return (
    <Container
      header={
        <SpaceBetween size="xxs">
          <Header
            actions={
              <Button
                onClick={() => {
                  navigate(`editprofessionalinfo`);
                }}
              >
                Edit
              </Button>
            }
          >
            Professional Info
          </Header>
          <Divider></Divider>
        </SpaceBetween>
      }
    >
      <div style={{ paddingTop: "12px" }}>
        <SpaceBetween size="m">
          <FormSection columns={4}>
            <Label
              label={"CAQH"}
              value={provider?.professionalInfo?.caqh ?? "-"}
            ></Label>
            <Label
              label={"License Type"}
              value={provider?.professionalInfo?.licenseType?.name ?? "-"}
            ></Label>
            <Label
              label={"Primary Specialty"}
              value={provider?.professionalInfo?.primarySpecialty?.name ?? "-"}
            ></Label>
            <Label
              label={"Title"}
              value={provider?.professionalInfo?.title ?? "-"}
            ></Label>
            <Label
              label={"Work Phone"}
              value={provider?.professionalInfo?.workPhone ?? "-"}
            ></Label>
            <Label
              label={"Work PhoneAlt"}
              value={provider?.professionalInfo?.workPhoneAlt ?? "-"}
            ></Label>
          </FormSection>
          <Divider></Divider>
          <FormSection
            columns={4}
            header={<Header variant="h3">Practice Info</Header>}
          >
            <Label
              label={"Practice Name"}
              value={provider?.professionalInfo?.practiceInfo?.name ?? "-"}
            ></Label>
            <Label
              label={"Years Of Practice"}
              value={
                provider?.professionalInfo?.practiceInfo?.yearsOfPractice ?? "-"
              }
            ></Label>
            <Label
              label={"Start Date"}
              value={
                provider?.professionalInfo?.practiceInfo?.startDate?.toLocaleString() ??
                "-"
              }
            ></Label>
            <Label
              label={"End Date"}
              value={
                provider?.professionalInfo?.practiceInfo?.endDate?.toLocaleString() ??
                "-"
              }
            ></Label>

            <Label
              label={"Address Lane1"}
              value={
                provider?.professionalInfo?.practiceInfo?.addressLane1 ?? "-"
              }
            ></Label>
            <Label
              label={"Address Lane2"}
              value={
                provider?.professionalInfo?.practiceInfo?.addressLane2 ?? "-"
              }
            ></Label>
            <Label
              label={"City"}
              value={provider?.professionalInfo?.practiceInfo?.city ?? "-"}
            ></Label>
            <Label
              label={"State"}
              value={provider?.professionalInfo?.practiceInfo?.state ?? "-"}
            ></Label>
            <Label
              label={"Zip Code"}
              value={provider?.professionalInfo?.practiceInfo?.zipcode ?? "-"}
            ></Label>
            <Label
              label={"Country"}
              value={provider?.professionalInfo?.practiceInfo?.country ?? "-"}
            ></Label>
            <Label
              label={"Pratice Contact Name"}
              value={
                provider?.professionalInfo?.practiceInfo?.contactName ?? "-"
              }
            ></Label>
            <Label
              label={"Pratice Contact Title"}
              value={
                provider?.professionalInfo?.practiceInfo?.contactTitle ?? "-"
              }
            ></Label>
            <Label
              label={"Pratice Contact Phone"}
              value={
                provider?.professionalInfo?.practiceInfo?.contactPhone ?? "-"
              }
            ></Label>
            <Label
              label={"Pratice Contact Email"}
              value={
                provider?.professionalInfo?.practiceInfo?.contactEmail ?? "-"
              }
            ></Label>
            <Label
              label={"Pratice Contact Fax"}
              value={
                provider?.professionalInfo?.practiceInfo?.contactFax ?? "-"
              }
            ></Label>
          </FormSection>
        </SpaceBetween>
      </div>
    </Container>
  );
}
