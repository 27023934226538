import { v4 as uuidv4 } from "uuid";
import useNotify from "./useNotify";
import { MutationActionCreatorResult } from "@reduxjs/toolkit/dist/query/core/buildInitiate";

export default function useAsyncNotifyWrapper() {
  const { notifyInProgress, notifySucess, notifyFailed } = useNotify();

  const notifyWrapper = async ({
    promise,
    actionName,
    resourceName,
    disableProgressNotification,
  }: {
    promise: MutationActionCreatorResult<any>;
    actionName: string;
    resourceName: string;
    disableProgressNotification?: boolean;
  }) => {
    const notificationId = uuidv4();

    try {
      notifyInProgress({
        name: resourceName,
        action: !!actionName.endsWith("e")
          ? actionName.substring(0, 3) + "ing"
          : actionName + "ing",
        id: notificationId,
      });
      await promise
        .unwrap()
        .then((result) => {
          notifySucess({
            name: resourceName,
            // if action name is ending with e then just append d
            action: !!actionName.endsWith("e")
              ? actionName + "d"
              : actionName + "ed",
            id: notificationId,
          });
        })
        .catch((error) => {
          // console.log({ error });
          if (error.status < 500 && error.status >= 400) {
            notifyFailed({
              name: resourceName,
              action: error.data.errorMessage,
              content: error.data.errorDescription,
              id: notificationId,
            });
          } else
            notifyFailed({
              name: resourceName,
              action: actionName,
              id: notificationId,
            });
        });
    } catch (error) {
      // console.log({ error });
      notifyFailed({
        name: resourceName,
        action: actionName,
        id: notificationId,
      });
    }
  };
  return { notifyWrapper };
}
