import {
  Button,
  Container,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { sortBy } from "lodash";
import AttributeField from "../AttributeField";
import FormSection from "../FormSection";
import LoadingScreen from "../LoadingScreen";
import { Section, SubSection } from "../../redux/api/section/types";
import { FormProvider, useForm } from "react-hook-form";

interface RecordSectionFormProps {
  /// section with attribute values
  section: Section;
  onClickBack: VoidFunction;
  onSubmit: (data: any) => void;
}
export default function RecordSectionForm({
  section,
  onClickBack,
  onSubmit,
}: RecordSectionFormProps) {
  const methods = useForm();

  if (!section) return <LoadingScreen isOpen={!section} />;
  else
    return (
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button onClick={onClickBack} formAction="none">
                  Cancel
                </Button>
                <Button formAction="submit" variant="primary">
                  Submit
                </Button>
              </SpaceBetween>
            }
          >
            <Container header={<Header> {section.name}</Header>}>
              <SpaceBetween size={"l"}>
                {converSectionToSortedList(section).map((subSection, index) => {
                  return (
                    <FormSection
                      columns={2}
                      key={subSection.name}
                      header={subSection.name}
                    >
                      {sortBy(subSection.attributes, "sort_order").map(
                        (attribute) => {
                          return (
                            <AttributeField
                              key={attribute.id}
                              attribute={attribute}
                            />
                          );
                        }
                      )}
                    </FormSection>
                  );
                })}
              </SpaceBetween>
            </Container>
          </Form>
        </form>
      </FormProvider>
    );
}

const converSectionToSortedList = (section: Section): Array<SubSection> => {
  let sectionList: Array<SubSection> = [];
  if (section.subSections)
    sectionList = [...sectionList, ...section.subSections];

  return sortBy(sectionList, "sort_order", "name");
};
