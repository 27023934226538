import { useState } from "react";
import useAuth from "../../hooks/useAuth";
import {
  AppLayout,
  Checkbox,
  ColumnLayout,
  Container,
  FormField,
  Grid,
  Header,
  Input,
  Link,
  SpaceBetween,
  StatusIndicator,
} from "@cloudscape-design/components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import MUIButtonCustomized from "../../components/MuiButtonCustomized";
import { getIntivtationDetails } from "../../context/JWTContext";
import useIsLoading from "../../hooks/useIsLoading";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import RHFCheckBox from "../../components/RHF/RHFCheckBox";

type FormInputType = {
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  agree: boolean;
};

function SignUpForm() {
  const { register } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [error, setError] = useState<string>();

  const invite_hash = searchParams.get("invitation");
  const methods = useForm<FormInputType>();
  useIsLoading(isLoading);

  useEffect(() => {
    if (invite_hash) {
      setIsLoading(true);
      getIntivtationDetails(invite_hash)
        .then((user) => {
          if (user?.email) setEmail(user?.email);
          if (user?.role) setRole(user?.role);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [invite_hash]);

  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");

  const onSubmit = async (data: FormInputType) => {
    if (invite_hash) {
      try {
        register({
          password: data.password,
          frist_name: data.firstName,
          last_name: data.lastName,
          email: email,
          role: role,
          invite_hash: invite_hash,
        })
          .then(() => {
            navigate("/");
          })
          .catch((error) => {
            // console.error(error);
            setError(error?.response?.data?.message ?? "Error: ");
          });
      } catch (error) {
        setError("Error: ");
      }
    }
  };

  return (
    <div className="container">
      <div className="centered-element">
        <SpaceBetween direction="vertical" size="xl">
          <img src="/RD_Horizozntal_Color_Logo.svg" style={{ height: 40 }} />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <SpaceBetween direction="vertical" size="xxs">
              <div style={{ width: 500, margin: 5 }}>
                <Container
                  header={
                    <Header
                      variant="h2"
                      description={
                        <SpaceBetween size={"s"} direction="vertical">
                          <div>
                            Request a quote for our Ready Doc™ Credentialing
                            Plus or try our free edition of Ready Doc™
                            Credentialing
                          </div>
                          {error && (
                            <StatusIndicator type="error">
                              {error}
                            </StatusIndicator>
                          )}
                        </SpaceBetween>
                      }
                    >
                      Create your ReadyDoc account
                    </Header>
                  }
                >
                  <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                      <SpaceBetween direction="vertical" size="l">
                        <ColumnLayout columns={2}>
                          <RHFTextField
                            label="First Name"
                            name="firstName"
                            stretch
                            rules={{ required: "This field is required" }}
                          />

                          <RHFTextField
                            label="Last Name"
                            name="lastName"
                            stretch
                            rules={{ required: "This field is required" }}
                          />
                        </ColumnLayout>

                        {!!role && (
                          <FormField label="Role">
                            <Input value={role} readOnly />
                          </FormField>
                        )}

                        <FormField label="Email">
                          <Input
                            value={email}
                            readOnly={!!invite_hash}
                            onChange={(event) => setEmail(event.detail.value)}
                          />
                        </FormField>
                        <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
                          <RHFTextField
                            type={showPassword ? "text" : "password"}
                            label="Password"
                            name="password"
                            stretch
                            rules={{ required: "This field is required" }}
                          />
                          <div style={{ marginTop: "32px" }}>
                            <Checkbox
                              onChange={({ detail }) =>
                                setShowPassword(detail.checked)
                              }
                              checked={showPassword}
                            >
                              Show Password
                            </Checkbox>
                          </div>
                        </Grid>

                        <RHFTextField
                          type={showPassword ? "text" : "password"}
                          label="Confirm Password"
                          name="confirmPassword"
                          stretch
                          rules={{
                            required: "This field is required",
                            validate: (value: any, formValues: FieldValues) => {
                              return (
                                value === (formValues.password ?? "") ||
                                "Password must match"
                              );
                            },
                          }}
                        />
                        <RHFCheckBox
                          label={
                            <span>
                              I agree to Ready Doc{" "}
                              <a href="#">Terms of Service </a>
                              and <a href="#">Privacy Policy</a>.
                            </span>
                          }
                          name="agree"
                          rules={{ required: "This field is required" }}
                        />
                        <MUIButtonCustomized
                          type="submit"
                          label="Create account"
                        />
                        <span>
                          Already have an account?{" "}
                          <Link href="/login">Login</Link>
                        </span>
                      </SpaceBetween>
                    </form>
                  </FormProvider>
                </Container>
              </div>
            </SpaceBetween>
          </div>
        </SpaceBetween>
      </div>
    </div>
  );
}

export default SignUpForm;
