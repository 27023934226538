import "./index.css";
import ManageCategoryDetails from "./managecategory-details/managecategory-details";

function ManageCategoryTable() {
  return (
    <div style={{ paddingTop: "24px" }}>
      <ManageCategoryDetails />
    </div>
  );
}

export default ManageCategoryTable;
