import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import {
  useGetAllFacilityTypesQuery,
  useGetFacilityQuery,
  useUpdateFacilityMutation,
} from "../../redux/api/facility/facility";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import useIsLoading from "../../hooks/useIsLoading";
import { useEffect } from "react";
import InnerAppLayout from "../../components/InnerAppLayout";
import {
  BreadcrumbGroup,
  Button,
  Container,
  ExpandableSection,
  Form,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { Divider } from "semantic-ui-react";
import FormSection from "../../components/FormSection";
import RHFTextField from "../../components/RHF/RHFTextField";
import RHFFloatingSelect from "../../components/RHF/RHFFloatingSelect";
import { FACILITY_TYPE } from "../../helpers/constants";
import { setModalName } from "../../redux/UI/actions";
import { connect } from "react-redux";
import LoadingScreen from "../../components/LoadingScreen";
import { useTranslation } from "react-i18next";

interface PropsFromDispatch {
  setModalName: typeof setModalName;
}

type FormInputType = {
  name: string;
  npiNumber: string;
  description: string;
  facilityTypeId: string;
  cliaId: string;
  immunizationId: string;
  taxonomyCode: string;
  addressLane1: string;
  addressLane2: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
};

function EditFacility({ setModalName }: PropsFromDispatch) {
  const { t } = useTranslation();
  const methods = useForm<FormInputType>();
  const navigate = useNavigate();
  const { id: facilityId } = useParams();
  const {
    data: facility,
    fulfilledTimeStamp,
    isLoading,
  } = useGetFacilityQuery(
    !!facilityId
      ? {
          id: facilityId,
        }
      : skipToken,
  );
  const { data: facilityType = [] } = useGetAllFacilityTypesQuery();
  const [updateFacility, { isLoading: isCreating }] =
    useUpdateFacilityMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  useIsLoading(isCreating);
  useEffect(() => {
    if (facility) {
      methods.setValue("name", facility?.name ?? "");
      methods.setValue("npiNumber", facility?.npiNumber ?? "");
      methods.setValue("description", facility?.description ?? "");
      methods.setValue("facilityTypeId", facility?.facilityType?.id ?? "");
      methods.setValue("cliaId", facility?.cliaId ?? "");
      methods.setValue("immunizationId", facility?.immunizationId ?? "");
      methods.setValue("taxonomyCode", facility?.taxonomyCode ?? "");
      methods.setValue("addressLane1", facility?.facilityAddress?.addressLane1);
      methods.setValue("addressLane2", facility?.facilityAddress?.addressLane2);
      methods.setValue("state", facility?.facilityAddress?.state);
      methods.setValue("city", facility?.facilityAddress?.city);
      methods.setValue("zipcode", facility?.facilityAddress?.zipcode);
      methods.setValue("country", facility?.facilityAddress?.country);
    }
  }, [fulfilledTimeStamp, methods, facility]);

  const onSubmit = async (data: FormInputType) => {
    if (facilityId)
      await notifyWrapper({
        promise: updateFacility({
          id: facilityId,
          name: data?.name,
          npiNumber: data?.npiNumber,
          description: data?.description,
          facilityTypeId: data?.facilityTypeId,
          cliaId: data?.cliaId,
          immunizationId: data?.immunizationId,
          taxonomyCode: data?.taxonomyCode,
          facilityAddress: {
            addressLane1: data?.addressLane1,
            addressLane2: data?.addressLane2,
            city: data?.city,
            state: data?.state,
            zipcode: data?.zipcode,
            country: data?.country,
          },
        }),
        resourceName: "facility",
        actionName: "update",
      });
    navigate(-1);
  };
  return (
    <>
      <LoadingScreen isOpen={isLoading} />
      <InnerAppLayout
        breadcrumbGroup={
          <BreadcrumbGroup
            items={[
              {
                text: `${t("facility.editFacility.header.breadcrumb.text")}`,
                href: `/managefacilities`,
              },

              {
                text: `${t("facility.editFacility.header.breadcrumb.currentPage")}`,
                href: "#",
              },
            ]}
          />
        }
        content={
          <Container
            header={
              <SpaceBetween size="xxs">
                <Header>{t("facility.editFacility.header.label")}</Header>
                <Divider></Divider>
              </SpaceBetween>
            }
          >
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Form
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        onClick={() => {
                          navigate("/managefacilities");
                        }}
                        formAction="none"
                      >
                        {t("facility.editFacility.header.actions.cancel")}
                      </Button>
                      <Button formAction="submit" variant="primary">
                        {t("facility.editFacility.header.actions.submit")}
                      </Button>
                    </SpaceBetween>
                  }
                >
                  <SpaceBetween direction="vertical" size="m">
                    <FormSection columns={2}>
                      <RHFTextField
                        label={t("facility.editFacility.header.fields.name")}
                        name="name"
                        stretch={false}
                        rules={{ required: "This field is required" }}
                      />
                      <RHFFloatingSelect
                        name="facilityTypeId"
                        label={t(
                          "facility.editFacility.header.fields.facilityType.label",
                        )}
                        options={facilityType.map((type) => ({
                          label: type.name,
                          value: "" + type.id,
                        }))}
                        rules={{ required: "This field is required" }}
                        stretch={false}
                        action={{
                          label: `${t("facility.editFacility.header.fields.facilityType.actions.modify")}`,
                          onClick: () => {
                            setModalName(FACILITY_TYPE);
                          },
                        }}
                      />
                      <RHFTextField
                        label={t(
                          "facility.editFacility.header.fields.npiNumber",
                        )}
                        name="npiNumber"
                        stretch={false}
                        rules={{ required: "This field is required" }}
                      />
                      <RHFTextField
                        label={t("facility.editFacility.header.fields.CLIAID")}
                        name="cliaId"
                        stretch={false}
                      />
                      <RHFTextField
                        label={t(
                          "facility.editFacility.header.fields.immunizationId",
                        )}
                        name="immunizationId"
                        stretch={false}
                      />
                      <RHFTextField
                        label={t(
                          "facility.editFacility.header.fields.taxonomyCode",
                        )}
                        name="taxonomyCode"
                        stretch={false}
                      />
                    </FormSection>

                    <ExpandableSection headerText="Facility Address">
                      <FormSection header="" columns={2}>
                        <RHFTextField
                          label={t(
                            "facility.editFacility.header.fields.addressLane1",
                          )}
                          name="addressLane1"
                          stretch={false}
                        />
                        <RHFTextField
                          label={t(
                            "facility.editFacility.header.fields.addressLane2",
                          )}
                          name="addressLane2"
                          stretch={false}
                        />
                        <RHFTextField
                          label={t("facility.editFacility.header.fields.city")}
                          name="city"
                          stretch={false}
                        />
                        <RHFTextField
                          label={t("facility.editFacility.header.fields.state")}
                          name="state"
                          stretch={false}
                        />
                        <RHFTextField
                          label={t(
                            "facility.editFacility.header.fields.zipcode",
                          )}
                          name="zipcode"
                          stretch={false}
                        />
                        <RHFTextField
                          label={t(
                            "facility.editFacility.header.fields.country",
                          )}
                          name="country"
                          stretch={false}
                        />
                      </FormSection>
                    </ExpandableSection>
                  </SpaceBetween>
                </Form>
              </form>
            </FormProvider>
          </Container>
        }
      />
    </>
  );
}
const mapDispatchToProps = {
  setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(EditFacility);
