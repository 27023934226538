import {
  Box,
  Button,
  ButtonDropdown,
  Header,
  Pagination,
  SpaceBetween,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import { useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProviderAffiliationType } from "../../../../redux/api/provider/section/types";
import {
  useDeleteProviderAffiliationMutation,
  useGetProviderQuery,
} from "../../../../redux/api/provider/provider";
import DeleteAlertModal from "../../../../components/Modal/delete-alert-modal";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";
import { useLocalStorage } from "../../../../common/localStorage";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableEmptyState } from "../../../../common/common-components";
import {
  AFFILIATION_CONTENT_DISPLAY_OPTIONS,
  AFFILIATION_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../../../../common/table-config";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../redux/store";
import StyledRouterLink from "../../../../components/styled-route-link";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";

export default function Affiliation() {
  const navigate = useNavigate();
  const { providerId } = useParams();
  const { data: provider } = useGetProviderQuery(
    !!providerId
      ? {
        providerId: providerId,
      }
      : skipToken,
  );
  const [selectedItem, setSelectedItem] = useState<ProviderAffiliationType>();
  const [deleteProviderAffiliation] = useDeleteProviderAffiliationMutation();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Affiliation-Table-Preferences",
    AFFILIATION_TABLE_DEFAULT_PREFERENCES,
  );
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false),
  );
  const { items, collectionProps, paginationProps } = useCollection(
    provider?.providerAffiliations ?? [],
    {
      propertyFiltering: {
        filteringProperties: [],
        empty: <TableEmptyState resourceName="Affiliation" />,
      },
    },
  );
  const tableProps: TableProps<ProviderAffiliationType> = useMemo(() => {
    return {
      columnDefinitions: [
        {
          id: "id",
          header: "Id",
          cell: (item) => item.id || "-",
          sortingField: "name",
          isRowHeader: true,
        },
        {
          id: "name",
          header: "Name",
          cell: (item) => (
            <StyledRouterLink
              className={hasPermission ? "edit_link" : "normal"}
              to={hasPermission ? `editaffiliation/${item.id}` : `#`}
              label={item.name}
            />
          ),
          sortingField: "alt",
        },
        {
          id: "isCurrent",
          header: "Current",
          cell: (item) => (item.isCurrent === true ? "Yes" : "No"),
        },
        {
          id: "startDate",
          header: "Start Date",
          cell: (item) => (
            <div> {item.startDate?.toLocaleString() ?? "-"} </div>
          ),
        },
        {
          id: "endDate",
          header: "End Date",
          cell: (item) => <div> {item.endDate?.toLocaleString() ?? "-"} </div>,
        },
        {
          id: "email",
          header: "Email",
          cell: (item) => <div> {item.email ?? "-"} </div>,
        },
        {
          id: "phone",
          header: "Phone",
          cell: (item) => <div> {item.phone ?? "-"} </div>,
        },
        {
          id: "fax",
          header: "Fax",
          cell: (item) => <div> {item.fax ?? "-"} </div>,
        },
      ],
      items,
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <TablePreferences
          title="Affiliation Table Preferences"
          preferences={tablePreferences}
          setPreferences={(preferences) => {
            setTablePreferences(preferences);
          }}
          contentDisplayOptions={AFFILIATION_CONTENT_DISPLAY_OPTIONS}
        />
      ),
      columnDisplay: tablePreferences?.contentDisplay,
      wrapLines: tablePreferences?.wrapLines,
      stripedRows: tablePreferences?.stripedRows,
      contentDensity: tablePreferences?.contentDensity,
      stickyColumns: tablePreferences?.stickyColumns,
      ...collectionProps,
      loadingText: "Loading resources",
      selectionType: "single",
      selectedItems: selectedItem ? [selectedItem] : [],
      onSelectionChange: ({ detail }) => {
        if (detail.selectedItems && detail.selectedItems.at(0))
          setSelectedItem(detail.selectedItems.at(0));
      },
      onRowClick: ({ detail }) => {
        if (detail.item) setSelectedItem(detail.item);
      },
      empty: (
        <Box textAlign="center" color="inherit">
          <b>No resources</b>
          <Box padding={{ bottom: "s" }} variant="p" color="inherit">
            No resources to display.
          </Box>
          <Button>Create resource</Button>
        </Box>
      ),

      header: (
        <Header
          actions={
            <SpaceBetween size="s" direction="horizontal">
              <ButtonDropdown
                disabled={!selectedItem}
                items={[
                  { text: "Edit", id: "edit", disabled: false },
                  { text: "Delete", id: "delete", disabled: false },
                ]}
                onItemClick={({ detail }) => {
                  if (detail.id === "edit")
                    if (selectedItem && selectedItem.id)
                      navigate(`editaffiliation/${selectedItem.id}`);
                  if (detail.id === "delete") {
                    if (selectedItem && selectedItem.id && !!providerId)
                      OpenDeleteModal(true);
                  }
                }}
              >
                Action
              </ButtonDropdown>
              <Button
                variant="primary"
                onClick={() => {
                  navigate(`editaffiliation`);
                }}
              >
                Add new
              </Button>
            </SpaceBetween>
          }
        >
          Affiliations
        </Header>
      ),
    };
  }, [selectedItem, tablePreferences, items, provider]);

  return (
    <div style={{ paddingTop: "12px" }}>
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedItem?.id && !!providerId)
            await notifyWrapper({
              promise: deleteProviderAffiliation({
                providerId: providerId,
                affiliationId: selectedItem.id.toString(),
              }),
              resourceName: "affiliation",
              actionName: "delete",
            });
          OpenDeleteModal(false);
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={"Delete Affiliation"}
        content={
          <>
            <Box>Name</Box>
            <Box variant="awsui-key-label">{selectedItem?.name}</Box>
          </>
        }
        description={"Are you sure you want to delete this affiliation?"}
      />
    </div>
  );
}
