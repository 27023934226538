import {
    AttributeEditor,
    Box,
    Button,
    Form,
    Header,
    Input,
    Modal,
    SpaceBetween,
} from "@cloudscape-design/components";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import Divider from "@mui/material/Divider";
import { setModalName } from "../../../redux/UI/actions";
import useAsyncNotifyWrapper from "../../../hooks/useAsyncNotifyWrapper";
import { useGetAllPackageStatusQuery, useSaveAllStatusTypesMutation } from "../../../redux/api/credentialpackages/credentialpackages";
import { CredentialPackageStatus } from "../../../redux/api/credentialpackages/types";

interface PropsFromDispatch {
    setModalName: typeof setModalName;
}

function PackageStatusModal({ setModalName }: PropsFromDispatch) {
    const { data = [], fulfilledTimeStamp } = useGetAllPackageStatusQuery();
    const [packageStatus, setPackageStatus] = useState<CredentialPackageStatus[]>([]);
    const { notifyWrapper } = useAsyncNotifyWrapper();
    const [saveAllPackageStatus] = useSaveAllStatusTypesMutation();
    const handleSubmit = async () => {
        setModalName("");

        const tmp = [...packageStatus].filter((item) => !!item.name);
        await notifyWrapper({
            promise: saveAllPackageStatus(tmp),
            resourceName: "Package Status",
            actionName: "save",
        });
    };
    useEffect(() => {
        const tmpItems = [...data];
        setPackageStatus(tmpItems);
    }, [data, fulfilledTimeStamp]);

    return (
        <Modal
            header={<Header>Package Status</Header>}
            visible={true}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            onClick={() => {
                                setModalName("");
                            }}
                            formAction="none"
                        >
                            Cancel
                        </Button>
                        <Button
                            formAction="submit"
                            onClick={handleSubmit}
                            variant="primary"
                        >
                            Save
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            size="max"
            onDismiss={() => {
                setModalName("");
            }}
        >
            <div>
                <SpaceBetween size={"m"}>
                    <Divider></Divider>

                    <div
                        style={{
                            maxHeight: "80vh",
                            overflowY: "scroll",
                            overflowX: "hidden",
                        }}
                    >
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                handleSubmit();
                            }}
                        >
                            <Form>
                                <AttributeEditor
                                    onAddButtonClick={() =>
                                        setPackageStatus([
                                            ...packageStatus,
                                            { id: "", name: "", description: "", deleted: false },
                                        ])
                                    }
                                    onRemoveButtonClick={({ detail: { itemIndex } }) => {
                                        const tmp = [...packageStatus];
                                        const item = { ...tmp[itemIndex] };
                                        tmp[itemIndex] = {
                                            id: item.id,
                                            name: item.name,
                                            description: item.description,
                                            deleted: true,
                                        };
                                        setPackageStatus(tmp);
                                    }}
                                    items={packageStatus.filter((item) => item.deleted !== true)}
                                    addButtonText="Add Package Status"
                                    definition={[
                                        {
                                            label: "Status",
                                            control: (item, itemIndex: number) => (
                                                <Input
                                                    value={item.name}
                                                    onChange={(changeDetails) => {
                                                        const tmp = [...packageStatus];
                                                        tmp[itemIndex] = {
                                                            id: item.id,
                                                            name: changeDetails.detail.value,
                                                            description: item.description,
                                                            deleted: item.deleted,
                                                        };
                                                        setPackageStatus(tmp);
                                                    }}
                                                    placeholder="Enter Name"
                                                />
                                            ),
                                        },

                                        /*   {
                                            label: "Description",
                                            control: (item, itemIndex: number) => (
                                              <Input
                                                value={item.description}
                                                onChange={(changeDetails) => {
                                                  const tmp = [...privilegeType];
                                                  tmp[itemIndex] = {
                                                    id: item.id,
                                                    name: item.name,
                                                    description: changeDetails.detail.value,
                                                  };
                                                  setPrivilegeType(tmp);
                                                }}
                                                placeholder="Enter Description"
                                              />
                                            ),
                                          }, */
                                    ]}
                                />
                            </Form>
                        </form>
                    </div>
                </SpaceBetween>
            </div>
        </Modal>
    );
}
const mapDispatchToProps = {
    setModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(PackageStatusModal);
