import { ListItemStyle } from "./styles";
import { FieldType } from "./types";
import * as awsui from "@cloudscape-design/design-tokens";
import { DIGITALFORM_FIELDS_Type } from "../helpers/df_constants";
import { Box, ExpandableSection } from "@cloudscape-design/components";

export default function CollapsibleList({
  onDragStart,
  onDragEnd,
  field,
}: {
  onDragStart: React.DragEventHandler<HTMLDivElement>;
  onDragEnd: (e: React.DragEvent<HTMLDivElement>, field: FieldType) => void;
  field: DIGITALFORM_FIELDS_Type;
}) {
  return (
    <div
      style={{ borderBottom: `1px solid ${awsui.colorBorderDividerDefault}` }}
    >
      <div key={field.value}>
        <ExpandableSection headerText={field.label}>
          {field.properties.map((property) => {
            const annot_field: FieldType = {
              label: [field.label, property.label].join("_"),
              type: property.type,
              key: property.key ?? [field.value, property.value].join("."),
              isList: field.type === "list",
            };
            return (
              <ListItemStyle
                key={[annot_field.key].join("-")}
                draggable
                onDragStart={(e) => onDragStart(e)}
                onDragEnd={(e) => onDragEnd(e, annot_field)}
                onClick={() => {}}
              >
                <Box fontSize="body-m">{property.label}</Box>
              </ListItemStyle>
            );
          })}
        </ExpandableSection>
      </div>
    </div>
  );
}
