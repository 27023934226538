import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { api } from "./api";
import { digitalFormReducer } from "./digitalform/reducer";
import { DigitalFormState } from "./digitalform/types";
import { manageAdminReducer } from "./admin/reducer";
import { ManageAdminState } from "./admin/types";
import { manageCategoryReducer } from "./category/reducer";
import { ManageCategoryState } from "./category/types";
import { providerReducer } from "./provider/reducer";
import { ProviderState } from "./provider/types";
import { uiReducer } from "./UI/reducer";
import { UIState } from "./UI/types";
import { AuthState } from "../context/types";
import { jwtReducer } from "../context/JWTContext";

const rootReducer = combineReducers({
  ui: uiReducer,
  digitalform: digitalFormReducer,
  provider: providerReducer,
  managecategory: manageCategoryReducer,
  manageadmin: manageAdminReducer,
  auth: jwtReducer,
  [api.reducerPath]: api.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  // adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

// The top-level state object
export interface ApplicationState {
  auth: AuthState;
  ui: UIState;
  digitalform: DigitalFormState;
  provider: ProviderState;
  managecategory: ManageCategoryState;
  manageadmin: ManageAdminState;
}
