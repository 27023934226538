import {
  Badge,
  Button,
  Header,
  Pagination,
  SpaceBetween,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import "./index.css";
import { useEffect, useMemo, useState } from "react";
import { MemberType } from "../../../../redux/api/manageadmin/types";
import {
  useGetAllActiveQuery,
  useDeleteAdminMutation,
} from "../../../../redux/api/manageadmin/manageadmin";
import { setModalName } from "../../../../redux/UI/actions";
import { connect } from "react-redux";
import { EDIT_MEMBER } from "../../../../helpers/constants";
import { useNavigate, useParams } from "react-router";
import StyledRouterLink from "../../../../components/styled-route-link";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../redux/store";
import DeleteAlertModal from "../../../../components/Modal/delete-alert-modal";
import Label from "../../../../components/Label";
import useAsyncNotifyWrapper from "../../../../hooks/useAsyncNotifyWrapper";
import { useLocalStorage } from "../../../../common/localStorage";
import {
  MANAGEADMINACTIVE_CONTENT_DISPLAY_OPTIONS,
  MANAGEADMINACTIVE_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../../../../common/table-config";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableEmptyState } from "../../../../common/common-components";
import LoadingScreen from "../../../../components/LoadingScreen";

interface PropsFromDispatch {
  openModalName: typeof setModalName;
}

function ActiveTable({ openModalName }: PropsFromDispatch) {
  const navigate = useNavigate();
  const { manageadminId } = useParams();
  const [selectedActive, setSelectActive] = useState<MemberType>();
  const [isDeleteModalOpen, OpenDeleteModal] = useState<boolean>(false);
  const {
    data = [],
    isLoading,
    isFetching,
    refetch,
    fulfilledTimeStamp,
  } = useGetAllActiveQuery();

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("member.edit") ?? false),
  );

  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("member.delete") ?? false),
  );
  const [deleteAdmin] = useDeleteAdminMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Active-Table-Preferences",
    MANAGEADMINACTIVE_TABLE_DEFAULT_PREFERENCES,
  );
  const { items, collectionProps, paginationProps } = useCollection(data, {
    propertyFiltering: {
      filteringProperties: [],
      empty: <TableEmptyState resourceName="Active" />,
    },
  });

  useEffect(() => {
    const temp = data.find((admin) => "" + admin.id === "" + manageadminId);
    if (temp) setSelectActive(temp);
    else {
      if (data.at(0)?.id) navigate(`/manageadmin/${data.at(0)?.id}`);
    }
  }, [data, manageadminId, fulfilledTimeStamp]);

  useEffect(() => {
    if (selectedActive?.id) navigate(`/manageadmin/${selectedActive?.id}`);
  }, [selectedActive?.id]);

  const tableProps: TableProps<MemberType> = useMemo(() => {
    return {
      header: (
        <Header
          counter={`(${data.length})`}
          actions={
            <SpaceBetween size={"s"} direction="horizontal">
              <Button
                variant="normal"
                iconName="refresh"
                onClick={() => {
                  refetch();
                }}
              ></Button>
              <Button
                onClick={() => {
                  if (selectedActive) {
                    const adminId = selectedActive.id;
                    if (adminId !== undefined) OpenDeleteModal(true);
                  }
                }}
                disabled={
                  !(
                    hasDeletePermission &&
                    selectedActive &&
                    !selectedActive.role.default
                  )
                }
              >
                Delete
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  openModalName(EDIT_MEMBER);
                }}
                disabled={!selectedActive || selectedActive.role.default}
              >
                {hasPermission ? "Edit" : "View"}
              </Button>
            </SpaceBetween>
          }
        >
          Members
        </Header>
      ),
      items,
      pagination: <Pagination {...paginationProps} />,
      preferences: (
        <TablePreferences
          title="Active Table Preferences"
          preferences={tablePreferences}
          setPreferences={(preferences) => {
            setTablePreferences(preferences);
          }}
          contentDisplayOptions={MANAGEADMINACTIVE_CONTENT_DISPLAY_OPTIONS}
        />
      ),
      columnDisplay: tablePreferences?.contentDisplay,
      wrapLines: tablePreferences?.wrapLines,
      stripedRows: tablePreferences?.stripedRows,
      contentDensity: tablePreferences?.contentDensity,
      stickyColumns: tablePreferences?.stickyColumns,
      ...collectionProps,
      variant: "container",
      selectionType: "single",
      resizableColumns: true,
      stickyHeader: true,
      loading: isLoading || isFetching,
      loadingText: "Loading...",
      empty: (
        <p>
          <b>No results</b>
          <p>There are no results to display.</p>
        </p>
      ),
      onSelectionChange: (selectionChangeDetail) => {
        const temp_member = selectionChangeDetail.detail.selectedItems.at(0);
        if (temp_member) setSelectActive(temp_member);
      },
      onRowClick: (onRowClickDetail) => {
        const temp_member = onRowClickDetail.detail.item;
        if (temp_member) setSelectActive(temp_member);
      },
      selectedItems: selectedActive ? [selectedActive] : [],
      trackBy: (item) => "" + item.id,
      columnDefinitions: [
        {
          id: "id",
          header: <div>ID</div>,
          cell: (item) => <span>{item.id}</span>,
        },
        {
          id: "firstName",
          header: <div>First Name</div>,
          cell: (item) => <span>{item.firstName}</span>,
        },
        {
          id: "lastName",
          header: <div>Last Name</div>,
          cell: (item) => <span>{item.lastName}</span>,
        },
        {
          id: "email",
          header: <div>Email</div>,
          cell: (item) => <span>{item.email}</span>,
          width: 280,
        },
        {
          id: "role",
          header: <div>Role</div>,
          cell: (item) => (
            <SpaceBetween size="s" direction="horizontal">
              <StyledRouterLink
                className={hasPermission ? "edit_link" : "normal"}
                to={hasPermission ? `/settings/roles/${item.role.id}` : `#`}
                label={item.role.name}
              />
              {item.role.default && <Badge>default</Badge>}
            </SpaceBetween>
          ),
        },
        {
          id: "lastlogin",
          header: <div>Last Login</div>,
          cell: (item) => (
            <span>
              {item.lastLogin
                ? new Date(item.lastLogin)?.toLocaleString()
                : "-"}
            </span>
          ),
        },
      ],
    };
  }, [selectedActive, data, tablePreferences, items, isLoading]);

  return (
    <div style={{ paddingTop: "5px" }}>
      <LoadingScreen isOpen={isLoading} />
      <Table {...tableProps} />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedActive?.id)
            await notifyWrapper({
              promise: deleteAdmin({ adminId: selectedActive?.id }),
              resourceName: "active",
              actionName: "delete",
            });
          OpenDeleteModal(false);
        }}
        closeModal={() => OpenDeleteModal(false)}
        header={"Delete Admin"}
        content={
          <Label
            label="Admin Name"
            value={
              <Header variant="h3">
                {selectedActive?.firstName + " " + selectedActive?.lastName}
              </Header>
            }
          ></Label>
        }
        description={"Are you sure you want to delete this admin?"}
      />
    </div>
  );
}
const mapDispatchToProps = {
  openModalName: setModalName,
};
export default connect(null, mapDispatchToProps)(ActiveTable);
