import { capitalize, groupBy, remove, uniq } from "lodash";
import {
  useGetAllPermissionsQuery,
  useUpdateRolePermissionsMutation,
} from "../../redux/api/role/role";
import { useMemo, useState } from "react";
import {
  Button,
  Container,
  Header,
  Tabs,
  TabsProps,
} from "@cloudscape-design/components";
import PermissionsTabContent from "./permissions-tab-content";
import {
  RolePermissions,
  findLowerPermissionKeys,
} from "../../redux/api/role/types";
import useIsLoading from "../../hooks/useIsLoading";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";

export type PermissionsTableProps = {
  roles: RolePermissions[];
  setRoles: React.Dispatch<React.SetStateAction<RolePermissions[]>>;
};
export default function PermissionsTable({
  roles: rolePermissions,
  setRoles,
}: PermissionsTableProps) {
  const { data: permissions, isLoading } = useGetAllPermissionsQuery();

  const [updateRolePermission, { isLoading: isUpdating }] =
    useUpdateRolePermissionsMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();

  useIsLoading(isLoading || isUpdating);
  const permission_groups = useMemo(() => {
    if (permissions)
      return groupBy(permissions, (permission) => {
        return permission.key?.split(".").at(0);
      });
  }, [permissions]);

  const onRolePermissionChange = ({
    role,
    key,
    checked,
  }: {
    checked: boolean;
    role: RolePermissions;
    key: string;
  }) => {
    if (rolePermissions && permissions) {
      let temp_role_permissions = [...rolePermissions];
      let role_index = temp_role_permissions.findIndex(
        (temp_role_permission) => temp_role_permission.id === role.id
      );

      if (role_index === -1) {
        return;
      } else {
        if (checked) {
          temp_role_permissions[role_index].permissions = uniq([
            ...findLowerPermissionKeys(permissions, key),
            ...temp_role_permissions[role_index].permissions,
          ]);
        } else {
          remove(temp_role_permissions[role_index].permissions, (permission) =>
            findLowerPermissionKeys(permissions, key).includes(permission)
          );
        }
      }
      setRoles(temp_role_permissions);
    }
  };

  const tabs: TabsProps.Tab[] = useMemo(() => {
    if (!permission_groups || !rolePermissions) return [];
    else
      return [
        ...Object.entries(permission_groups).map(([key, permissions]) => {
          return {
            label: capitalize(key?.replace("_", " ")),
            id: key,
            content: (
              <PermissionsTabContent
                rolePermissions={rolePermissions}
                group={key}
                permissions={permissions}
                onRolePermissionChange={onRolePermissionChange}
              />
            ),
          };
        }),
      ];
  }, [permission_groups, rolePermissions]);

  const [activeTabId, setActiveTabId] = useState<string>();

  return (
    <Container
      header={
        <Header
          variant="h2"
          actions={
            <Button
              variant="primary"
              onClick={async (clickDetails) => {
                await notifyWrapper({
                  promise: updateRolePermission(rolePermissions),
                  resourceName: "Permissions",
                  actionName: "update",
                });

              }}
            >
              Save
            </Button>
          }
        >
          Permissions
        </Header>
      }
    >
      <Tabs
        activeTabId={activeTabId}
        onChange={(changeDetail) => {
          setActiveTabId(changeDetail.detail.activeTabId);
        }}
        tabs={tabs}
      />
    </Container>
  );
}
