// awsui
import {
  Box,
  Container,
  Form,
  Header,
  SpaceBetween,
  Button,
} from "@cloudscape-design/components";
//hook-form
import { FormProvider, useForm, useFormState } from "react-hook-form";
// mui
import { Divider } from "@mui/material";
// pdftron
import { Core } from "@pdftron/webviewer";
// lodash
import range from "lodash/range";
import sortBy from "lodash/sortBy";
// constants
import { FontFamily } from "../helpers/constants";
//types
import { FontType } from "./types";
//components
import FormSection from "../components/FormSection";
import RHFTextField from "../components/RHF/RHFTextField";
import RHFCheckBox from "../components/RHF/RHFCheckBox";
import RHFSelect from "../components/RHF/RHFSelect";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { useEffect } from "react";

interface TemplateDesignerAnnotationFormatterProps {
  selectedAnnots: Core.Annotations.FreeTextAnnotation[];
  onFontFamilyChanged: (fontFamily: string) => void;
  onFontSizeChanged: (fontSize: string) => void;
  onRequiredChanged: (isRequired: boolean) => void;
  onIndexChanged: (index: string) => void;
  onNameChanged: (name: string) => void;
  deselectAllAnnotations: VoidFunction;
  defaultFontValue: FontType;
  options?: OptionDefinition[];
  selectedRecipient?: OptionDefinition | null;
  onSelectedRecipientChanged?: (option: OptionDefinition) => void;
}

export type FormInputType = {
  name: string;
  serailNo: string;
  isRequired: boolean;
  fontFamily: string;
  fontSize: string;
  recipient?: OptionDefinition;
};

export default function TemplateDesignerAnnotationFormatter({
  selectedAnnots,
  defaultFontValue,
  onNameChanged,
  options,
  selectedRecipient,
  onIndexChanged,
  onFontSizeChanged,
  onRequiredChanged,
  onFontFamilyChanged,
  deselectAllAnnotations,
  onSelectedRecipientChanged,
}: TemplateDesignerAnnotationFormatterProps) {
  const methods = useForm<FormInputType>({
    // this is not the right way to set field values,see recipient field for the correct way,
    // defaultValue is used only when field value is undefined.
    defaultValues: {
      name: selectedAnnots?.at(0)?.getCustomData("name") ?? "",
      serailNo: selectedAnnots?.at(0)?.getCustomData("index") ?? "",
      isRequired: selectedAnnots?.at(0)?.getCustomData("isRequired") === "true",
      fontFamily:
        selectedAnnots.length === 1
          ? selectedAnnots.at(0)?.Font
          : defaultFontValue.fontFamily,
      fontSize:
        selectedAnnots.length === 1
          ? selectedAnnots.at(0)?.FontSize.match(/(\d+)/)?.at(0) ??
            defaultFontValue.fontSize
          : defaultFontValue.fontSize,
    },
  });
  const { dirtyFields } = useFormState({
    control: methods.control,
  });

  useEffect(() => {
    methods.setValue("recipient", selectedRecipient ?? undefined);
  }, [methods, selectedRecipient]);

  return (
    <Container
      header={
        <>
          <Box padding={{ top: "s", left: "s", right: "s", bottom: "m" }}>
            <SpaceBetween size={"s"}>
              <Header>
                {selectedAnnots.length === 1
                  ? `${selectedAnnots.length} Field selected`
                  : `${selectedAnnots.length} Fields selected`}
              </Header>
            </SpaceBetween>
          </Box>
          <Divider />
        </>
      }
      disableHeaderPaddings
    >
      <div
        style={{
          padding: 0,
          overflow: "auto",
          height: `calc(100vh - 208px)`,
        }}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit((data) => {
              if (dirtyFields.fontFamily) onFontFamilyChanged(data.fontFamily);
              if (dirtyFields.fontSize) onFontSizeChanged(data.fontSize);
              if (dirtyFields.isRequired) onRequiredChanged(data.isRequired);
              if (dirtyFields.name) onNameChanged(data.name);
              if (dirtyFields.serailNo) onIndexChanged(data.serailNo);
              if (dirtyFields.recipient) {
                const selectedOption = options?.find(
                  (option) => option.value === data.recipient,
                );

                !!selectedOption &&
                  !!onSelectedRecipientChanged &&
                  onSelectedRecipientChanged(selectedOption);
              }
              deselectAllAnnotations();
            })}
          >
            <Form
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button formAction="none" onClick={deselectAllAnnotations}>
                    Cancel
                  </Button>
                  <Button variant="primary">Submit</Button>
                </SpaceBetween>
              }
            >
              <SpaceBetween size="s">
                {!!options && (
                  <RHFSelect
                    css="recipient-select"
                    triggerVariant="option"
                    name="recipient"
                    label={<Header variant="h3">Recipient</Header>}
                    options={options}
                  />
                )}
                <Divider />
                <FormSection columns={2}>
                  {selectedAnnots.length === 1 && (
                    <RHFTextField name={"name"} label="Name" />
                  )}
                  {selectedAnnots.length === 1 &&
                    selectedAnnots?.at(0)?.getCustomData("isList") ===
                      "true" && (
                      <div style={{ width: "64px" }}>
                        <RHFTextField name="serailNo" label="Serial no" />
                      </div>
                    )}
                  <RHFCheckBox
                    name={"isRequired"}
                    label="Is required"
                    stringfy={false}
                  />
                </FormSection>
                <FormSection columns={2} header="Formatting">
                  <RHFSelect
                    name="fontFamily"
                    label="Font Family"
                    options={sortBy(FontFamily).map((fontFamily) => ({
                      value: fontFamily,
                    }))}
                  />
                  <RHFSelect
                    name="fontSize"
                    label="Font Size"
                    options={range(8, 42).map((fontSize) => ({
                      value: `${fontSize}`,
                    }))}
                  />
                </FormSection>
              </SpaceBetween>
            </Form>
          </form>
        </FormProvider>
      </div>
    </Container>
  );
}
