// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import Header from "@cloudscape-design/components/header";
import PieChart from "@cloudscape-design/components/pie-chart";
import { Box } from "@cloudscape-design/components";
import { WidgetConfig } from "../types";
import {
  colorChartsPaletteCategorical40,
  colorChartsStatusPositive,
} from "@cloudscape-design/design-tokens";
import { useGetAllDashboardQuery } from "../../../redux/api/dashboards/dashboard";

export const eSignatureStatus: WidgetConfig = {
  title: "eSignatures Status",
  description: "Overview of all your eSignatures",
  header: eSignatureStatusHeader,
  content: ESignatureStatusWidget,
};

function eSignatureStatusHeader() {
  return <Header variant="h2">{eSignatureStatus.title}</Header>;
}

function ESignatureStatusWidget() {
  const { data, isLoading, isFetching, isError } = useGetAllDashboardQuery();

  return (
    <PieChart
      data={[
        {
          title: "Completed",
          value: data?.esignatureStatus?.completed ?? 0,
          color: colorChartsStatusPositive,
        },
        {
          title: "Pending",
          color: colorChartsPaletteCategorical40,
          value: data?.esignatureStatus?.pending ?? 0,
        },
      ]}
      detailPopoverContent={(datum, sum) => [
        { key: "Count", value: datum.value },
        {
          key: "Percentage",
          value: `${((datum.value / sum) * 100).toFixed(0)}%`,
        },
      ]}
      segmentDescription={(datum, sum) =>
        `${((datum.value / sum) * 100).toFixed(0)}% (${datum.value})`
      }
      ariaDescription="Pie chart showing how many resources are currently in which state."
      ariaLabel="Pie chart"
      hideFilter
      empty={
        <Box textAlign="center" color="inherit">
          <b>No data available.</b>
          <Box variant="p" color="inherit">
            There is no data available.
          </Box>
        </Box>
      }
      noMatch={
        <Box textAlign="center" color="inherit">
          <b>No matching data.</b>
          <Box variant="p" color="inherit">
            There is no matching data to display.
          </Box>
        </Box>
      }
    />
  );
}
