import { api } from "..";
import {
  AllDocumentResponse,
  Document,
  DocumentRequest,
  Psv,
  PsvRequest,
} from "./types";

const documentAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAllDocument: build.query<AllDocumentResponse[], void>({
      query: () => `document/all`,
      providesTags: ["Document"],
    }),
    getDocument: build.query<
      Document,
      { providerId: string; documentId: string }
    >({
      query: ({ providerId, documentId }) =>
        `provider/${providerId}/document/${documentId}`,
      providesTags: (result, error, { documentId }) => [
        { type: "Document", id: documentId },
      ],
    }),

    getAllDocumentPsv: build.query<Psv[], { documentId: string }>({
      query: ({ documentId }) => `document/${documentId}/psv/all`,
      providesTags: ["Document"],
    }),

    getDocumentPsv: build.query<Psv, { documentId: String; psvId: String }>({
      query: ({ documentId, psvId }) => `document/${documentId}/psv/${psvId}`,
      providesTags: ["Document"],
    }),
    postDocumentMetaData: build.mutation<Document, DocumentRequest>({
      query({
        documentId,
        providerId,
        name,
        expirationDate,
        alertDays,
        notes,
      }) {
        return {
          url: `provider/${providerId}/document/${documentId}/metadata`,
          method: "PUT",
          body: { name, expirationDate, alertDays, notes },
        };
      },
      invalidatesTags: (result, error, args) => [{ type: "Document" }],
    }),
    deleteDocument: build.mutation<
      void,
      { providerId: string; documentId: string }
    >({
      query({ documentId, providerId }) {
        return {
          url: `provider/${providerId}/document/${documentId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, args) => [{ type: "Provider" }],
    }),
    uploadAttachment: build.mutation<
      Document,
      { providerId: string; documentId: string; file: File }
    >({
      query({ documentId, providerId, file }) {
        // upload with multipart/form-data
        const formData = new FormData();
        if (!!file) formData.append("file", file);
        return {
          url: `provider/${providerId}/document/${documentId}/upload`,
          method: "PUT",
          body: formData,
        };
      },
      invalidatesTags: (result, error, args) => [{ type: "Document" }],
    }),
    saveDocument: build.mutation<Document, DocumentRequest>({
      query({
        providerId,
        name,
        expirationDate,
        alertDays,
        notes,
        file,
        categoryId,
      }) {
        // upload with multipart/form-data
        const formData = new FormData();
        if (name) formData.append("name", name);
        if (expirationDate) formData.append("expirationDate", expirationDate);
        if (alertDays) formData.append("alertDays", String(alertDays));
        if (notes) formData.append("notes", notes);
        if (file) formData.append("file", file);
        if (categoryId) formData.append("categoryId", String(categoryId));
        return {
          url: `provider/${providerId}/document`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result, error, args) => [
        { type: "Document" },
        { type: "Provider" },
        { type: "Provider", id: args.providerId },
      ],
    }),
    updateDocument: build.mutation<Document, DocumentRequest>({
      query({
        documentId,
        providerId,
        name,
        expirationDate,
        alertDays,
        notes,
        file,
        categoryId,
      }) {
        // upload with multipart/form-data
        const formData = new FormData();
        if (name) formData.append("name", name);
        if (expirationDate) formData.append("expirationDate", expirationDate);
        if (alertDays) formData.append("alertDays", String(alertDays));
        if (notes) formData.append("notes", notes);
        if (file) formData.append("file", file);
        if (categoryId) formData.append("categoryId", String(categoryId));
        return {
          url: `provider/${providerId}/document/${documentId}`,
          method: "PUT",
          body: formData,
        };
      },
      invalidatesTags: (result, error, args) => [
        { type: "Document" },
        { type: "Provider" },
        { type: "Provider", id: args.providerId },
      ],
    }),
    savePsv: build.mutation<Psv, PsvRequest>({
      query({
        documentId,
        sourceType,
        primarySource,
        status,
        expiration,
        alertDays,
        file,
      }) {
        const formData = new FormData();
        if (sourceType) formData.append("sourceType", sourceType);
        if (primarySource) formData.append("primarySource", primarySource);
        if (status) formData.append("status", status);
        if (expiration) formData.append("expiration", expiration);
        if (alertDays) formData.append("alertDays", String(alertDays));
        if (file) formData.append("file", file);
        return {
          url: `document/${documentId}/psv/upload`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result, error, args) => [{ type: "Document" }],
    }),
    updatePSV: build.mutation<Psv, PsvRequest>({
      query: ({
        documentId,
        id,
        sourceType,
        primarySource,
        status,
        expiration,
        alertDays,
        file,
      }) => {
        const formData = new FormData();
        if (sourceType) formData.append("sourceType", sourceType);
        if (primarySource) formData.append("primarySource", primarySource);
        if (status) formData.append("status", status);
        if (expiration) formData.append("expiration", expiration);
        if (alertDays) formData.append("alertDays", String(alertDays));
        if (file) formData.append("file", file);
        return {
          url: `document/${documentId}/psv/${id}/upload`,
          method: "PUT",
          body: formData,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Document", id: arg.id },
        { type: "Document" },
      ],
    }),
    deletePsv: build.mutation<void, { documentId: string; psvId: string }>({
      query({ documentId, psvId }) {
        return {
          url: `document/${documentId}/psv/${psvId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, args) => [{ type: "Document" }],
    }),
    saveVerfication: build.mutation<
      void,
      { documentId: string; selectedOptionValue: string; license_no: string }
    >({
      query: ({ documentId, selectedOptionValue, ...body }) => {
        return {
          url: `document/${documentId}/psv/auto?taskName=${selectedOptionValue}`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Document"],
    }),
  }),
});

export const {
  useGetAllDocumentQuery,
  useDeleteDocumentMutation,
  usePostDocumentMetaDataMutation,
  useGetDocumentQuery,
  useSaveDocumentMutation,
  useUpdateDocumentMutation,
  useGetAllDocumentPsvQuery,
  useSavePsvMutation,
  useUpdatePSVMutation,
  useGetDocumentPsvQuery,
  useDeletePsvMutation,
  useSaveVerficationMutation,
  useUploadAttachmentMutation,
} = documentAPI;
