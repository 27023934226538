import { api } from "..";
import {
    Dashboard,
 
} from "./types";

const dashboardAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAllDashboard: build.query<Dashboard, void>({
      query: () => `dashboard`,
      providesTags: ["Dashboard"],
    }),
      
  }),
});
export const {
    useGetAllDashboardQuery
} = dashboardAPI;
