import { remove } from "lodash";
import { Reducer } from "redux";
import { UIActionTypes, UIState } from "./types";

export const initialState: UIState = {
  modalName: "",
  flashBarItems: [],
  is_loading: false,
  permissions: [],
  is_owner: false,
};

const reducer: Reducer<UIState> = (state = initialState, action) => {
  switch (action.type) {
    case UIActionTypes.SET_ROLE_PERMISSIONS: {
      return {
        ...state,
        permissions: [...action.payload.permissions],
        is_owner: action.payload.is_owner,
      };
    }
    case UIActionTypes.ADD_SPINNER: {
      if (!state.is_loading) return { ...state, is_loading: true };
      else return state;
    }
    case UIActionTypes.REMOVE_SPINNER: {
      if (state.is_loading) return { ...state, is_loading: false };
      else return state;
    }
    case UIActionTypes.SET_MODAL_NAME: {
      return { ...state, modalName: action.payload };
    }
    case UIActionTypes.ADD_FLASHBAR_ITEM: {
      let updatedflashBarItems = [...state.flashBarItems];
      const notification = action.payload;
      const existingIndex = updatedflashBarItems.findIndex(
        (existingNotification) => existingNotification.id === notification.id
      );
      if (existingIndex !== -1) {
        // If the notification already exists, update it
        updatedflashBarItems[existingIndex] = notification;
      } else {
        // If the notification doesn't exist, add it
        updatedflashBarItems = [action.payload, ...updatedflashBarItems];
      }
      return {
        ...state,
        flashBarItems: updatedflashBarItems,
      };
    }
    case UIActionTypes.REMOVE_FLASHBAR_ITEM: {
      let updatedflashBarItems = [...state.flashBarItems];
      remove(updatedflashBarItems, (item) => item.id === action.payload);
      return {
        ...state,
        flashBarItems: updatedflashBarItems,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as uiReducer };
