import { Button, Header, SpaceBetween } from "@cloudscape-design/components";
import { connect } from "react-redux";
import { INVITE_MODAL } from "../helpers/constants";
import { setModalName } from "../redux/UI/actions";
import "./index.css";
import ManageadminDetails from "./manageadmin-details/manageadmin-details";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";

interface PropsFromDispatch {
  openModalName: typeof setModalName;
}

function ManageAdminTable({ openModalName }: PropsFromDispatch) {
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("member.edit") ?? false),
  );
  return (
    <div style={{ paddingTop: "25px" }}>
      <SpaceBetween size={"s"} direction="vertical">
        <Header
          actions={
            <Button
              disabled={!hasPermission}
              onClick={() => {
                openModalName(INVITE_MODAL);
              }}
              variant="primary"
            >
              Invite Member
            </Button>
          }
        >
          Members
        </Header>
        <ManageadminDetails />
      </SpaceBetween>
    </div>
  );
}
const mapDispatchToProps = {
  openModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(ManageAdminTable);
