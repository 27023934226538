import {
  Button,
  Flashbar,
  Form,
  FormField,
  Header,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import Grid from "@mui/material/Grid";
import { connect } from "react-redux";
import { setModalName } from "../../redux/UI/actions";
import { useRef, useState } from "react";
import {
  useGetDigitalFormQuery,
  useUploadDigitalFormTemplateMutation,
} from "../../redux/api/digitalform/digitalforms";
import { useParams } from "react-router-dom";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import Label from "../../components/Label";
import { skipToken } from "@reduxjs/toolkit/dist/query";

interface PropsFromDispatch {
  openModalName: typeof setModalName;
}

function DigitalformUploadModal({ openModalName }: PropsFromDispatch) {
  const { id } = useParams();
  const { data: form } = useGetDigitalFormQuery(id ?? skipToken);
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [uploadDigitalFormTemplate] = useUploadDigitalFormTemplateMutation();
  const { notifyWrapper } = useAsyncNotifyWrapper();
  return (
    <Modal
      visible={true}
      header={<Header variant="h2">Upload file</Header>}
      onDismiss={() => {
        openModalName("");
      }}
      footer={
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              onClick={() => {
                openModalName("");
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="primary"
              disabled={!file}
              onClick={async () => {
                if (file && form) {
                  await notifyWrapper({
                    promise: uploadDigitalFormTemplate({
                      id: form.id,
                      file,
                    }),
                    resourceName: "digitalform template",
                    actionName: "upload",
                  });
                  openModalName("");
                }
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      }
    >
      <SpaceBetween size={"s"}>
        <Flashbar
          items={[
            {
              type: "warning",
              content: "Adding a new file, replaces the existing file.",
            },
          ]}
        />

        <Form>
          <SpaceBetween size="s">
            <Label label="Ready Form" value={form?.name} />
            <FormField label="Upload file">
              <Button
                iconName="upload"
                onClick={(clickDetails) => {
                  hiddenFileInput && hiddenFileInput.current?.click();
                }}
              >
                Upload File
                <input
                  ref={hiddenFileInput}
                  type="file"
                  onChange={(fileUpload) => {
                    fileUpload.currentTarget.files?.item(0) &&
                      setFile(fileUpload.currentTarget.files?.item(0));
                  }}
                  style={{ display: "none" }}
                />
              </Button>
              {file && (
                <span>
                  <i>{file?.name}</i>
                </span>
              )}
            </FormField>
          </SpaceBetween>
        </Form>
      </SpaceBetween>
    </Modal>
  );
}
const mapDispatchToProps = {
  openModalName: setModalName,
};

export default connect(null, mapDispatchToProps)(DigitalformUploadModal);
