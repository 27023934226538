import { api } from "..";
import { Attachment } from "../document/types";
import {
  Contract,
  ContractRequest,
  ContractType,
  ContractTypeRequest,
  UpdateContractTypeRequest,
} from "./types";

const contractAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAllContracts: build.query<Contract[], void>({
      query: () => `contract/all`,
      providesTags: ["Contract"],
    }),
    getContract: build.query<Contract, { contractId: string }>({
      query: ({ contractId }) => `contract/${contractId}`,
      providesTags: (result, error, { contractId }) => [
        { type: "Contract", id: contractId },
      ],
    }),
    getContractAttachment: build.query<Attachment, { contractId: string }>({
      query: ({ contractId }) => `contract/${contractId}/attachment`,
      providesTags: (result, error, { contractId }) => [
        { type: "Contract", id: contractId },
      ],
    }),
    getAllContractTypes: build.query<ContractType[], void>({
      query: () => `contract/type/all`,
      providesTags: ["Contract"],
    }),

    getContractType: build.query<ContractType, { contractTypeId: string }>({
      query: ({ contractTypeId }) => `contract/type/${contractTypeId}`,
      providesTags: ["Contract"],
    }),
    createContractType: build.mutation<void, ContractTypeRequest>({
      query: ({ ...body }) => {
        return {
          url: "contract/type",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Contract"],
    }),
    deleteContractType: build.mutation<
      void,
      {
        contractTypeId: string;
      }
    >({
      query: ({ contractTypeId }) => ({
        url: `contract/type/${contractTypeId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Contract"],
    }),
    updateContractType: build.mutation<
      void,
      Pick<ContractType, "id"> & Partial<UpdateContractTypeRequest>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `contract/type/${id}`,
          method: "POST",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Contract", id: arg.id },
        { type: "Contract" },
      ],
    }),
    saveContract: build.mutation<Contract, ContractRequest>({
      query(contract) {
        return {
          url: `contract`,
          method: "POST",
          body: contract,
        };
      },
      invalidatesTags: (result, error, args) => [{ type: "Contract" }],
    }),
    updateContract: build.mutation<
      Contract,
      Pick<Contract, "id"> & Partial<ContractRequest>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `contract/${id}`,
          method: "POST",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Contract", id: arg.id },
        { type: "Contract" },
      ],
    }),
    deleteContract: build.mutation<
      void,
      {
        contractId: string;
      }
    >({
      query: ({ contractId }) => ({
        url: `contract/${contractId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Contract"],
    }),
    uploadContractAttachment: build.mutation<
      void,
      { contractId: string; file: File }
    >({
      query({ contractId, file }) {
        // upload with multipart/form-data
        const formData = new FormData();
        if (file) formData.append("file", file);

        return {
          url: `contract/${contractId}/attachment`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result, error, args) => [{ type: "Contract" }],
    }),
    saveAllContractTypes: build.mutation<void, ContractType[]>({
      query: (types) => {
        return {
          url: `contract/type/all`,
          method: "POST",
          body: types,
        };
      },
      invalidatesTags: ["Contract"],
    }),
  }),
});
export const {
  useGetContractAttachmentQuery,
  useUploadContractAttachmentMutation,
  useSaveContractMutation,
  useGetContractQuery,
  useGetAllContractsQuery,
  useGetAllContractTypesQuery,
  useUpdateContractTypeMutation,
  useGetContractTypeQuery,
  useUpdateContractMutation,
  useCreateContractTypeMutation,
  useDeleteContractMutation,
  useDeleteContractTypeMutation,
  useSaveAllContractTypesMutation,
} = contractAPI;
