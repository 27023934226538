import {
  Box,
  BreadcrumbGroup,
  Button,
  Container,
  Form,
  FormField,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useGetAllLicenseTypesQuery } from "../../redux/api/provider/provider";
import InnerAppLayout from "../../components/InnerAppLayout";
import FormSection from "../../components/FormSection";
import RHFTextField from "../../components/RHF/RHFTextField";
import { useCreateCredentialTemplateMutation } from "../../redux/api/credentialpackages/credentialpackages";
import { CredentialTemplateRequest } from "../../redux/api/credentialpackages/types";
import useAsyncNotifyWrapper from "../../hooks/useAsyncNotifyWrapper";
import RHFSelect from "../../components/RHF/RHFSelect";

export default function AddCredentailTemplates() {
  const methods = useForm<CredentialTemplateRequest>();
  const navigate = useNavigate();

  const { data: licenses = [] } = useGetAllLicenseTypesQuery();
  const { notifyWrapper } = useAsyncNotifyWrapper();

  const [addCredentialTemplate] = useCreateCredentialTemplateMutation();
  const onSubmit = async (data: CredentialTemplateRequest) => {
    await notifyWrapper({
      promise: addCredentialTemplate(data),
      resourceName: "Credential template",
      actionName: "create",
    });
    navigate(-1);
  };
  return (
    <InnerAppLayout
      breadcrumbGroup={
        <>
          <BreadcrumbGroup
            items={[
              { text: "Credential Templates", href: `/credentialtemplates` },
              { text: `Templates`, href: "#" },
            ]}
          />
        </>
      }
      content={
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Form
              actions={
                <Box float="right">
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      onClick={() => {
                        navigate(-1);
                      }}
                      formAction="none"
                    >
                      Cancel
                    </Button>
                    <Button formAction="submit" variant="primary">
                      Submit
                    </Button>
                  </SpaceBetween>
                </Box>
              }
            >
              <SpaceBetween direction="vertical" size="xl">
                <Container header={<Header>Create Template</Header>}>
                  <SpaceBetween direction="vertical" size="m">
                    <RHFTextField
                      label="Name"
                      name="name"
                      description="Enter the name of your credential template template for easy identification"
                      stretch={false}
                      rules={{ required: "This field is required" }}
                    />
                    <FormSection>
                      <RHFSelect
                        name="licenseTypeId"
                        label="License Type"
                        description="Select the applicable license category for this credential template. Providers may hold various types of licenses depending on their specialization or practice area. Ensure alignment between the chosen license type and the credentials being documented."
                        options={licenses.map((license) => {
                          return { label: license.name, value: license.id };
                        })}
                        stretch={false}
                      />
                    </FormSection>
                  </SpaceBetween>
                </Container>
              </SpaceBetween>
            </Form>
          </form>
        </FormProvider>
      }
    />
  );
}
