import {
  Button,
  Grid,
  Box,
  BreadcrumbGroup,
} from "@cloudscape-design/components";
import { useNavigate } from "react-router-dom";

interface TemplateDesignerHeaderProps {
  breadCrumbItems: {
    text: string;
    href: string;
  }[];
  submit: () => Promise<void>;
}

export default function TemplateDesignerHeader({
  breadCrumbItems,
  submit,
}: TemplateDesignerHeaderProps) {
  const navigate = useNavigate();
  return (
    <div
      style={{
        paddingLeft: "18px",
        paddingRight: "22px",
        paddingTop: "8px",
        paddingBottom: "42px",
      }}
    >
      <Box>
        <div style={{ float: "left" }}>
          <BreadcrumbGroup items={breadCrumbItems} />
        </div>
        <div style={{ float: "right" }}>
          <Grid gridDefinition={[{ colspan: 0 }, { colspan: 0 }]}>
            <Button
              onClick={() => {
                navigate(-1);
              }}
              variant="normal"
            >
              Cancel
            </Button>

            <Button
              variant="primary"
              onClick={async () => {
                await submit();
              }}
            >
              Save
            </Button>
          </Grid>
        </div>
      </Box>
    </div>
  );
}
