import {
  Checkbox,
  Form,
  FormField,
  Input,
  Select,
  SpaceBetween,
  Textarea,
} from "@cloudscape-design/components";
import { SectionMetadata } from "../redux/api/section/types";

interface SectionFormProps {
  section: SectionMetadata;
  setSection: React.Dispatch<React.SetStateAction<SectionMetadata>>;
}

export function SectionForm({ section, setSection }: SectionFormProps) {
  return (
    <Form>
      <SpaceBetween direction="vertical" size="s">
        <FormField label="Name">
          <Input
            value={section?.name ?? ""}
            onChange={(changeDetails) => {
              setSection({ ...section, name: changeDetails.detail.value });
            }}
          ></Input>
        </FormField>
        <FormField label="Type">
          <Select
            selectedOption={
              sectionTypeOptions.find(
                (option) => option.value === section.type
              ) ?? null
            }
            onChange={({ detail }) => {
              if (detail.selectedOption.value)
                setSection({ ...section, type: detail.selectedOption.value });
            }}
            options={sectionTypeOptions}
            selectedAriaLabel="Selected"
            filteringType="auto"
          />
        </FormField>
        <FormField label="Description" info="optional">
          <Textarea
            value={section?.description}
            onChange={(changeDetails) => {
              setSection({
                ...section,
                description: changeDetails.detail.value,
              });
            }}
          ></Textarea>
        </FormField>
        <FormField>
          <Checkbox
            checked={section?.facility}
            onChange={(changeDetail) => {
              setSection({
                ...section,
                facility: changeDetail.detail.checked,
              });
            }}
          >
            Is Facility
          </Checkbox>
        </FormField>
        <FormField>
          <Checkbox
            checked={section?.excludeReporting}
            onChange={(changeDetail) => {
              setSection({
                ...section,
                excludeReporting: changeDetail.detail.checked,
              });
            }}
          >
            Exclude this section from reporting.
          </Checkbox>
        </FormField>
        <FormField>
          <Checkbox
            checked={section?.excludeDigitalformMapping}
            onChange={(changeDetail) => {
              setSection({
                ...section,
                excludeDigitalformMapping: changeDetail.detail.checked,
              });
            }}
          >
            Exclude this section from digitalform mapping.
          </Checkbox>
        </FormField>
      </SpaceBetween>
    </Form>
  );
}

const sectionTypeOptions = [
  { value: "single", label: "Single" },
  { value: "list", label: "List" },
];
