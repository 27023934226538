import moment from "moment";
//import dateFormat from "dateformat";

const ChangeTimeZone = (
  datestring?: string | Date | number | undefined,
  options: {
    date?: string;
    formate?: string;
  } = { formate: "MM/DD/YYYY" },
) => {
  // console.log(typeof datestring);
  if (!!options.date) {
    return moment(
      new Date(options.date.replace(/-/g, "/").slice(0, 10)),
    ).format("MM/DD/YYYY");
  } else if (!!datestring) {
    if (typeof datestring === "string") {
      // const date = new Date(datestring.replace(/-/g, "/").slice(0, 10));
      //return  dateFormat(date, options?.formate);
      return moment(datestring).format(options?.formate);
    } else if (typeof datestring === "number")
      return moment(datestring).format(options?.formate);
  }
};

export default ChangeTimeZone;
